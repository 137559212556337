/**
 * Generated by orval v6.11.1 🍺
 * Do not edit manually.
 * Candidate.fyi API
 * API for Candidate.fyi
 * OpenAPI spec version: v1
 */
import {
  useQuery,
  useInfiniteQuery,
  useMutation
} from '@tanstack/react-query'
import type {
  UseQueryOptions,
  UseInfiniteQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  UseInfiniteQueryResult,
  QueryKey
} from '@tanstack/react-query'
import type {
  OpenAICompletionResponse,
  OpenAICompletion,
  CandidateEmailsEmailsList200One,
  CandidateEmailsEmailsList200Two,
  CandidateEmailsEmailsListParams,
  Email,
  EmailBody,
  EmailPreviewResponse,
  EmailPreviewRequest,
  CandidateEmailsEmailsTokensList200One,
  CandidateEmailsEmailsTokensList200Two,
  CandidateEmailsEmailsTokensListParams,
  CandidateEmailsTemplatesList200One,
  CandidateEmailsTemplatesList200Two,
  CandidateEmailsTemplatesListParams,
  EmailTemplate,
  EmailTemplateBody,
  DuplicateEmailTemplate,
  ContentCouponList200One,
  ContentCouponList200Two,
  ContentCouponListParams,
  CouponCodeWrite,
  CouponCodeWriteBody,
  ContentLibraryContentList200One,
  ContentLibraryContentList200Two,
  ContentLibraryContentListParams,
  LibraryBlock,
  LibraryBlockBody,
  ContentMedia,
  ContentTokensList200One,
  ContentTokensList200Two,
  ContentTokensListParams,
  ExternalApplicationsList200One,
  ExternalApplicationsList200Two,
  ExternalApplicationsListParams,
  ExternalATSApplication,
  ExternalATSPassthroughApplication,
  ExternalApplicationsPassthroughReadParams,
  ExternalCandidatesList200One,
  ExternalCandidatesList200Two,
  ExternalCandidatesListParams,
  ExternalCandidatesEmailsList200One,
  ExternalCandidatesEmailsList200Two,
  ExternalCandidatesEmailsListParams,
  ExternalATSCandidateEmail,
  ExternalATSCandidateEmailBody,
  ExternalATSCandidate,
  CandidatePortalInvitation,
  ExternalDepartmentsList200One,
  ExternalDepartmentsList200Two,
  ExternalDepartmentsListParams,
  ExternalATSDepartments,
  RefreshATSApplication,
  RefreshATSCandidateApplications,
  ExternalJobsList200One,
  ExternalJobsList200Two,
  ExternalJobsListParams,
  ExternalATSJob,
  ExternalOfficesList200One,
  ExternalOfficesList200Two,
  ExternalOfficesListParams,
  ExternalATSOffice,
  RefreshATSCandidate,
  ExternalStagesList200One,
  ExternalStagesList200Two,
  ExternalStagesListParams,
  ExternalStagesNamesList200One,
  ExternalStagesNamesList200Two,
  ExternalStagesNamesListParams,
  ExternalATSJobInterviewStageAPI,
  ExternalUsersList200One,
  ExternalUsersList200Two,
  ExternalUsersListParams,
  ExternalATSRemoteUser,
  ExternalATSRemoteUserUpdate,
  FeatureSchema,
  FeatureFlagsListParams,
  FeatureFlag,
  FollowUpList200One,
  FollowUpList200Two,
  FollowUpListParams,
  Followup,
  FollowupMessage,
  FollowupMessageBody,
  MergeWebhook,
  ZoomWebhook,
  JobsList200One,
  JobsList200Two,
  JobsListParams,
  Job,
  JobBody,
  JobsExposedStagesList200One,
  JobsExposedStagesList200Two,
  JobsExposedStagesListParams,
  CopyJob,
  ExposedJobStage,
  ImportExposedStages,
  JobsStagesBlocksList200One,
  JobsStagesBlocksList200Two,
  JobsStagesBlocksListParams,
  StageBlock,
  StageBlockBody,
  JobsStagesList200One,
  JobsStagesList200Two,
  JobsStagesListParams,
  ExposedJobStageBody,
  MergeIntegration,
  MergeToken,
  NotificationsList200One,
  NotificationsList200Two,
  NotificationsListParams,
  Notification,
  NotificationUpdate,
  NotificationUpdateBody,
  OrgGenericTagsList200One,
  OrgGenericTagsList200Two,
  OrgGenericTagsListParams,
  Tag,
  TagBody,
  IntegrationAccount,
  OrgInterviewerTagsList200One,
  OrgInterviewerTagsList200Two,
  OrgInterviewerTagsListParams,
  InterviewerTag,
  InterviewerTagBody,
  ExternalATSRemoteUserLight,
  OrgInterviewerTagsUsersListParams,
  OrgSchedulingCalendarsList200One,
  OrgSchedulingCalendarsList200Two,
  OrgSchedulingCalendarsListParams,
  OrgSchedulingCalendar,
  OrgSchedulingExternalCalendarsList200One,
  OrgSchedulingExternalCalendarsList200Two,
  OrgSchedulingExternalCalendarsListParams,
  Organization,
  OrganizationBody,
  OrgSlackIntegration,
  IntegrateSlack,
  GenericAPI,
  GenericAPIBody,
  OrgSlackIntegrationUpdate,
  OrgSlackIntegrationUpdateBody,
  MyOrgMember,
  OrgTeamMembersList200One,
  OrgTeamMembersList200Two,
  OrgTeamMembersListParams,
  OrganizationMemberEditable,
  OrganizationMemberEditableBody,
  OrganizationMemberExportCSV,
  OrgTeamMembersTagsExportListParams,
  OrganizationMemberTagsImportCSV,
  OrgTeamMembersTagsImportCreateBody,
  OrgTeamMembersTagActivitiesList200One,
  OrgTeamMembersTagActivitiesList200Two,
  OrgTeamMembersTagActivitiesListParams,
  OrgmemberInterviewerTagActivity,
  OrgmemberInterviewerTagActivityUpdate,
  OrgmemberInterviewerTagActivityUpdateBody,
  ModifyOrgMemberInterviewerTag,
  ModifyOrgMemberInterviewerTagBody,
  OrgZoomUsersList200One,
  OrgZoomUsersList200Two,
  OrgZoomUsersListParams,
  OrganizationPublic,
  PortalApplicationStage,
  CouponCodeRead,
  PortalFollowUpList200One,
  PortalFollowUpList200Two,
  PortalFollowUpListParams,
  FollowupMarkRead,
  CandidateProfile,
  PortalEvent,
  EventLimitedWrite,
  EventLimitedWriteBody,
  PortalRecruiterAvailability,
  PortalSchedulingRecruiterJobAvailabilityReadParams,
  PortalRecruitingEvent,
  PortalRecruitingEventCheckin,
  AvailabilitySuggestion,
  PortalSchedulingRequestAvailability,
  SubmitSchedulingRequest,
  SubmitSchedulingRequestBody,
  PortalSchedulingRequestModel,
  PortalSchedulingRequestQuickCreate,
  SchedulingAnalyticsDeclinesInterviewersList200One,
  SchedulingAnalyticsDeclinesInterviewersList200Two,
  SchedulingAnalyticsDeclinesInterviewersListParams,
  InterviewerDeclineReport,
  SchedulingAnalyticsDeclinesInterviewersExportListParams,
  SchedulingAnalyticsDeclinesInterviewtemplatesList200One,
  SchedulingAnalyticsDeclinesInterviewtemplatesList200Two,
  SchedulingAnalyticsDeclinesInterviewtemplatesListParams,
  InterviewTemplateDeclinesAnalytics,
  SchedulingAnalyticsDeclinesInterviewtemplatesExportListParams,
  SchedulingAnalyticsEventsActivitiesList200One,
  SchedulingAnalyticsEventsActivitiesList200Two,
  SchedulingAnalyticsEventsActivitiesListParams,
  SchedulingAnalyticsReschedulesDepartmentsList200One,
  SchedulingAnalyticsReschedulesDepartmentsList200Two,
  SchedulingAnalyticsReschedulesDepartmentsListParams,
  EventDepartmentReschedulesAnalytics,
  SchedulingAnalyticsReschedulesDepartmentsExportListParams,
  SchedulingAnalyticsReschedulesInterviewTemplatesList200One,
  SchedulingAnalyticsReschedulesInterviewTemplatesList200Two,
  SchedulingAnalyticsReschedulesInterviewTemplatesListParams,
  InterviewTemplateReschedulesAnalytics,
  SchedulingAnalyticsReschedulesInterviewTemplatesExportListParams,
  SchedulingAnalyticsReschedulesJobsList200One,
  SchedulingAnalyticsReschedulesJobsList200Two,
  SchedulingAnalyticsReschedulesJobsListParams,
  ExternalATSJobReschedulesAnalytics,
  SchedulingAnalyticsReschedulesJobsExportListParams,
  SchedulingAnalyticsReschedulesOfficesList200One,
  SchedulingAnalyticsReschedulesOfficesList200Two,
  SchedulingAnalyticsReschedulesOfficesListParams,
  EventOfficeReschedulesAnalytics,
  SchedulingAnalyticsReschedulesOfficesExportListParams,
  SchedulingAnalyticsRoundsPipelineList200One,
  SchedulingAnalyticsRoundsPipelineList200Two,
  SchedulingAnalyticsRoundsPipelineListParams,
  RoundPipelineAnalytics,
  SchedulingAnalyticsRoundsPipelineExportListParams,
  SchedulingAnalyticsTagsCapacityList200One,
  SchedulingAnalyticsTagsCapacityList200Two,
  SchedulingAnalyticsTagsCapacityListParams,
  TagInterviewingCapacityWeekOverWeek,
  SchedulingAnalyticsTagsCapacityExportListParams,
  CalendarEventsResponse,
  CalendarEventsRequest,
  ExtTimezoneResponse,
  SchedulingCalendarRoomsList200One,
  SchedulingCalendarRoomsList200Two,
  SchedulingCalendarRoomsListParams,
  CalendarRoomAvailabilityResponse,
  SchedulingCalendarRoomsAvailabiltyListParams,
  CalendarRoom,
  SchedulingCalendarinvitetemplatesList200One,
  SchedulingCalendarinvitetemplatesList200Two,
  SchedulingCalendarinvitetemplatesListParams,
  CalendarInviteTemplate,
  CalendarInviteTemplateBody,
  CopyCalendarInviteTemplate,
  SchedulingEventsDepartmentsList200One,
  SchedulingEventsDepartmentsList200Two,
  SchedulingEventsDepartmentsListParams,
  EventDepartmentAnalytics,
  SchedulingEventsDepartmentsExportListParams,
  SchedulingEventsInterviewTemplatesList200One,
  SchedulingEventsInterviewTemplatesList200Two,
  SchedulingEventsInterviewTemplatesListParams,
  InterviewTemplateAnalytics,
  SchedulingEventsInterviewTemplatesExportListParams,
  SchedulingEventsInterviewersList200One,
  SchedulingEventsInterviewersList200Two,
  SchedulingEventsInterviewersListParams,
  EventInterviewerRemoteUserLight,
  SchedulingEventsInterviewersExportListParams,
  SchedulingEventsJobsList200One,
  SchedulingEventsJobsList200Two,
  SchedulingEventsJobsListParams,
  ExternalATSJobSchedulingAnalytics,
  SchedulingEventsJobsExportListParams,
  SchedulingEventsOfficesList200One,
  SchedulingEventsOfficesList200Two,
  SchedulingEventsOfficesListParams,
  EventOfficeAnalytics,
  SchedulingEventsOfficesExportListParams,
  SchedulingEventsOwnersList200One,
  SchedulingEventsOwnersList200Two,
  SchedulingEventsOwnersListParams,
  EventOwnerRemoteUserLight,
  SchedulingEventsOwnersExportListParams,
  SchedulingEventsList200One,
  SchedulingEventsList200Two,
  SchedulingEventsListParams,
  SchedulingGroupEventsList200One,
  SchedulingGroupEventsList200Two,
  SchedulingGroupEventsListParams,
  SchedulingGroupApplicationsMissingSchedulingRequestList200One,
  SchedulingGroupApplicationsMissingSchedulingRequestList200Two,
  SchedulingGroupApplicationsMissingSchedulingRequestListParams,
  InterviewerBlockAvailabilityResponse,
  InterviewerBlockAvailabilityPayload,
  AdminCreateInterviewEvent,
  AdminCreateBulkInterviewEvent,
  InterviewRecommendationResponse,
  InterviewRecommendationRequest,
  SchedulingInterviewplantemplatesList200One,
  SchedulingInterviewplantemplatesList200Two,
  SchedulingInterviewplantemplatesListParams,
  InterviewPlanTemplate,
  InterviewPlanTemplateRead,
  InterviewPlanTemplateReadBody,
  CopyInterviewPlanTemplate,
  SchedulingInterviewtemplatesList200One,
  SchedulingInterviewtemplatesList200Two,
  SchedulingInterviewtemplatesListParams,
  InterviewTemplateRead,
  InterviewTemplateReadBody,
  SchedulingInterviewtemplatesSlotsList200One,
  SchedulingInterviewtemplatesSlotsList200Two,
  SchedulingInterviewtemplatesSlotsListParams,
  InterviewerTemplateSlotCreateUpdate,
  InterviewerTemplateSlotCreateUpdateBody,
  SchedulingJobSettingsList200One,
  SchedulingJobSettingsList200Two,
  SchedulingJobSettingsListParams,
  JobScheduleSetting,
  JobScheduleSettingBody,
  SchedulingJobsBookingLinksRead200One,
  SchedulingJobsBookingLinksRead200Two,
  SchedulingJobsBookingLinksReadParams,
  SchedulingMembersList200One,
  SchedulingMembersList200Two,
  SchedulingMembersListParams,
  OrgMemberInterviewerScheduleSetting,
  OrgMemberInterviewerScheduleSettingBody,
  RecruitingEventCheckIn,
  SchedulingRecruitingEventsList200One,
  SchedulingRecruitingEventsList200Two,
  SchedulingRecruitingEventsListParams,
  RecruitingEvent,
  RecruitingEventDetails,
  RecruitingEventDetailsBody,
  SchedulingRecruitingEventsApplicationsList200One,
  SchedulingRecruitingEventsApplicationsList200Two,
  SchedulingRecruitingEventsApplicationsListParams,
  SchedulingSchedulinginterviewrequestsList200One,
  SchedulingSchedulinginterviewrequestsList200Two,
  SchedulingSchedulinginterviewrequestsListParams,
  SchedulingInterviewRequest,
  SchedulingInterviewRequestBody,
  SchedulingInterviewRequestATSMapping,
  SchedulingInterviewRequestATSMappingBody,
  SchedulingSchedulingrequestsList200One,
  SchedulingSchedulingrequestsList200Two,
  SchedulingSchedulingrequestsListParams,
  SchedulingRequest,
  SchedulingSchedulingrequestsSlackChannelsList200One,
  SchedulingSchedulingrequestsSlackChannelsList200Two,
  SchedulingSchedulingrequestsSlackChannelsListParams,
  SchedulingRequestRead,
  SchedulingSchedulingrequestsReadParams,
  SchedulingRequestReadBody,
  SchedulingRequestRepairStage,
  CreateSchedulingRequestSlackChannel,
  SchedulingSchedulingrequestsActivitiesList200One,
  SchedulingSchedulingrequestsActivitiesList200Two,
  SchedulingSchedulingrequestsActivitiesListParams,
  SchedulingActivity,
  SchedulingActivityWrite,
  SchedulingActivityWriteBody,
  SchedulingTokensList200One,
  SchedulingTokensList200Two,
  SchedulingTokensListParams,
  SchedulingUserSchedulingCalendarsList200One,
  SchedulingUserSchedulingCalendarsList200Two,
  SchedulingUserSchedulingCalendarsListParams,
  SchedulingUserSchedulingCalendarsExternalUsersSyncCreateParams,
  SchedulingUserSchedulingCalendarsMeSyncCreateParams,
  UserSchedulingCalendar,
  UserScheduleSetting,
  UserScheduleSettingBody,
  Stats,
  StatsReadParams,
  CandidateEngagementAnalytics,
  StatsAnalyticsEnagagementReadParams,
  DeclineAnalytics,
  StatsAnalyticsInterviewDeclinesReadParams,
  PulseAnalytics,
  StatsAnalyticsPulseReadParams,
  ReschedulingAnalytics,
  StatsAnalyticsReSchedulingReadParams,
  SchedulingAnalytics,
  StatsAnalyticsSchedulingReadParams,
  SessionAnalytics,
  StatsAnalyticsSessionReadParams,
  GroupStats,
  StatsCandidateReadParams,
  JobActivation,
  StatsJobReadParams,
  StatsPulsesExposedStagesList200One,
  StatsPulsesExposedStagesList200Two,
  StatsPulsesExposedStagesListParams,
  StatsPulsesExternalJobsList200One,
  StatsPulsesExternalJobsList200Two,
  StatsPulsesExternalJobsListParams,
  StatsSessionsList200One,
  StatsSessionsList200Two,
  StatsSessionsListParams,
  SurveyPulsesList200One,
  SurveyPulsesList200Two,
  SurveyPulsesListParams,
  CandidateStagePulseSurvey,
  CandidateStagePulseSurveyBody,
  PulseCSV,
  SurveyPulsesReportListParams,
  TokenRefresh,
  CustomTokenObtainPair,
  TrainingClassAttendanceList200One,
  TrainingClassAttendanceList200Two,
  TrainingClassAttendanceListParams,
  TrainingClassEventAttendance,
  TrainingClassEventAttendanceBody,
  TrainingClassesList200One,
  TrainingClassesList200Two,
  TrainingClassesListParams,
  TrainingClassWrite,
  TrainingClassWriteBody,
  TrainingPathsList200One,
  TrainingPathsList200Two,
  TrainingPathsListParams,
  TrainingPathWrite,
  TrainingPathWriteBody,
  TrainingPathsProgressReportList200One,
  TrainingPathsProgressReportList200Two,
  TrainingPathsProgressReportListParams,
  TrainingPathProgressionReport,
  TrainingPathsProgressReportExportListParams,
  TrainingPathRead,
  TrainingTraineesList200One,
  TrainingTraineesList200Two,
  TrainingTraineesListParams,
  Trainee,
  TraineeBody,
  UpdateUser,
  UpdateUserBody,
  CandidateMagicLink,
  CandidateInvitation,
  CandidateInvitationBody,
  UsersMeEmailsList200One,
  UsersMeEmailsList200Two,
  UsersMeEmailsListParams,
  SenderEmailAddress,
  ReadonlySenderEmailAddress,
  ReadonlySenderEmailAddressBody,
  UserPreference,
  UserPreferenceBody,
  Oauth2TokenResponse,
  GoogleOauth2Body,
  GoogleOauth2,
  MicrosoftOauth2Body,
  MicrosoftOauth2,
  OktaOauth2,
  OktaConfigResponse,
  OktaConfigRequest,
  OktaDetailsSearchResponse,
  OktaDomainSearch,
  ZoomIntegration
} from '../types'
import { customInstance } from '../mutator/apiClient';
import type { ErrorType } from '../mutator/apiClient';
import { customInstanceNoTransform } from '../mutator/apiClient';



export const aiGenerateBlockCreate = (
    openAICompletion: OpenAICompletion,
 ) => {
      return customInstance<OpenAICompletionResponse>(
      {url: `/ai/generate-block`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: openAICompletion
    },
      );
    }
  


    export type AiGenerateBlockCreateMutationResult = NonNullable<Awaited<ReturnType<typeof aiGenerateBlockCreate>>>
    export type AiGenerateBlockCreateMutationBody = OpenAICompletion
    export type AiGenerateBlockCreateMutationError = ErrorType<unknown>

    export const useAiGenerateBlockCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof aiGenerateBlockCreate>>, TError,{data: OpenAICompletion}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof aiGenerateBlockCreate>>, {data: OpenAICompletion}> = (props) => {
          const {data} = props ?? {};

          return  aiGenerateBlockCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof aiGenerateBlockCreate>>, TError, {data: OpenAICompletion}, TContext>(mutationFn, mutationOptions);
    }
    
export const candidateEmailsEmailsList = (
    params?: CandidateEmailsEmailsListParams,
 signal?: AbortSignal
) => {
      return customInstance<CandidateEmailsEmailsList200One | CandidateEmailsEmailsList200Two>(
      {url: `/candidate-emails/emails/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getCandidateEmailsEmailsListQueryKey = (params?: CandidateEmailsEmailsListParams,) => [`/candidate-emails/emails/`, ...(params ? [params]: [])];

    
export type CandidateEmailsEmailsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof candidateEmailsEmailsList>>>
export type CandidateEmailsEmailsListInfiniteQueryError = ErrorType<unknown>

export const useCandidateEmailsEmailsListInfinite = <TData = Awaited<ReturnType<typeof candidateEmailsEmailsList>>, TError = ErrorType<unknown>>(
 params?: CandidateEmailsEmailsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof candidateEmailsEmailsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCandidateEmailsEmailsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof candidateEmailsEmailsList>>> = ({ signal }) => candidateEmailsEmailsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof candidateEmailsEmailsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type CandidateEmailsEmailsListQueryResult = NonNullable<Awaited<ReturnType<typeof candidateEmailsEmailsList>>>
export type CandidateEmailsEmailsListQueryError = ErrorType<unknown>

export const useCandidateEmailsEmailsList = <TData = Awaited<ReturnType<typeof candidateEmailsEmailsList>>, TError = ErrorType<unknown>>(
 params?: CandidateEmailsEmailsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof candidateEmailsEmailsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCandidateEmailsEmailsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof candidateEmailsEmailsList>>> = ({ signal }) => candidateEmailsEmailsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof candidateEmailsEmailsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const candidateEmailsEmailsCreate = (
    emailBody: EmailBody,
 ) => {
      return customInstance<Email>(
      {url: `/candidate-emails/emails/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: emailBody
    },
      );
    }
  


    export type CandidateEmailsEmailsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof candidateEmailsEmailsCreate>>>
    export type CandidateEmailsEmailsCreateMutationBody = EmailBody
    export type CandidateEmailsEmailsCreateMutationError = ErrorType<unknown>

    export const useCandidateEmailsEmailsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof candidateEmailsEmailsCreate>>, TError,{data: EmailBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof candidateEmailsEmailsCreate>>, {data: EmailBody}> = (props) => {
          const {data} = props ?? {};

          return  candidateEmailsEmailsCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof candidateEmailsEmailsCreate>>, TError, {data: EmailBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const candidateEmailsEmailsPreviewCreate = (
    emailPreviewRequest: EmailPreviewRequest,
 ) => {
      return customInstance<EmailPreviewResponse>(
      {url: `/candidate-emails/emails/preview/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: emailPreviewRequest
    },
      );
    }
  


    export type CandidateEmailsEmailsPreviewCreateMutationResult = NonNullable<Awaited<ReturnType<typeof candidateEmailsEmailsPreviewCreate>>>
    export type CandidateEmailsEmailsPreviewCreateMutationBody = EmailPreviewRequest
    export type CandidateEmailsEmailsPreviewCreateMutationError = ErrorType<unknown>

    export const useCandidateEmailsEmailsPreviewCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof candidateEmailsEmailsPreviewCreate>>, TError,{data: EmailPreviewRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof candidateEmailsEmailsPreviewCreate>>, {data: EmailPreviewRequest}> = (props) => {
          const {data} = props ?? {};

          return  candidateEmailsEmailsPreviewCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof candidateEmailsEmailsPreviewCreate>>, TError, {data: EmailPreviewRequest}, TContext>(mutationFn, mutationOptions);
    }
    
export const candidateEmailsEmailsTokensList = (
    params?: CandidateEmailsEmailsTokensListParams,
 signal?: AbortSignal
) => {
      return customInstance<CandidateEmailsEmailsTokensList200One | CandidateEmailsEmailsTokensList200Two>(
      {url: `/candidate-emails/emails/tokens/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getCandidateEmailsEmailsTokensListQueryKey = (params?: CandidateEmailsEmailsTokensListParams,) => [`/candidate-emails/emails/tokens/`, ...(params ? [params]: [])];

    
export type CandidateEmailsEmailsTokensListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof candidateEmailsEmailsTokensList>>>
export type CandidateEmailsEmailsTokensListInfiniteQueryError = ErrorType<unknown>

export const useCandidateEmailsEmailsTokensListInfinite = <TData = Awaited<ReturnType<typeof candidateEmailsEmailsTokensList>>, TError = ErrorType<unknown>>(
 params?: CandidateEmailsEmailsTokensListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof candidateEmailsEmailsTokensList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCandidateEmailsEmailsTokensListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof candidateEmailsEmailsTokensList>>> = ({ signal }) => candidateEmailsEmailsTokensList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof candidateEmailsEmailsTokensList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type CandidateEmailsEmailsTokensListQueryResult = NonNullable<Awaited<ReturnType<typeof candidateEmailsEmailsTokensList>>>
export type CandidateEmailsEmailsTokensListQueryError = ErrorType<unknown>

export const useCandidateEmailsEmailsTokensList = <TData = Awaited<ReturnType<typeof candidateEmailsEmailsTokensList>>, TError = ErrorType<unknown>>(
 params?: CandidateEmailsEmailsTokensListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof candidateEmailsEmailsTokensList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCandidateEmailsEmailsTokensListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof candidateEmailsEmailsTokensList>>> = ({ signal }) => candidateEmailsEmailsTokensList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof candidateEmailsEmailsTokensList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const candidateEmailsEmailsRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<Email>(
      {url: `/candidate-emails/emails/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getCandidateEmailsEmailsReadQueryKey = (id: string,) => [`/candidate-emails/emails/${id}/`];

    
export type CandidateEmailsEmailsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof candidateEmailsEmailsRead>>>
export type CandidateEmailsEmailsReadInfiniteQueryError = ErrorType<unknown>

export const useCandidateEmailsEmailsReadInfinite = <TData = Awaited<ReturnType<typeof candidateEmailsEmailsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof candidateEmailsEmailsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCandidateEmailsEmailsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof candidateEmailsEmailsRead>>> = ({ signal }) => candidateEmailsEmailsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof candidateEmailsEmailsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type CandidateEmailsEmailsReadQueryResult = NonNullable<Awaited<ReturnType<typeof candidateEmailsEmailsRead>>>
export type CandidateEmailsEmailsReadQueryError = ErrorType<unknown>

export const useCandidateEmailsEmailsRead = <TData = Awaited<ReturnType<typeof candidateEmailsEmailsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof candidateEmailsEmailsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCandidateEmailsEmailsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof candidateEmailsEmailsRead>>> = ({ signal }) => candidateEmailsEmailsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof candidateEmailsEmailsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const candidateEmailsEmailsUpdate = (
    id: string,
    emailBody: EmailBody,
 ) => {
      return customInstance<Email>(
      {url: `/candidate-emails/emails/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: emailBody
    },
      );
    }
  


    export type CandidateEmailsEmailsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof candidateEmailsEmailsUpdate>>>
    export type CandidateEmailsEmailsUpdateMutationBody = EmailBody
    export type CandidateEmailsEmailsUpdateMutationError = ErrorType<unknown>

    export const useCandidateEmailsEmailsUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof candidateEmailsEmailsUpdate>>, TError,{id: string;data: EmailBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof candidateEmailsEmailsUpdate>>, {id: string;data: EmailBody}> = (props) => {
          const {id,data} = props ?? {};

          return  candidateEmailsEmailsUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof candidateEmailsEmailsUpdate>>, TError, {id: string;data: EmailBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const candidateEmailsTemplatesList = (
    params?: CandidateEmailsTemplatesListParams,
 signal?: AbortSignal
) => {
      return customInstance<CandidateEmailsTemplatesList200One | CandidateEmailsTemplatesList200Two>(
      {url: `/candidate-emails/templates/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getCandidateEmailsTemplatesListQueryKey = (params?: CandidateEmailsTemplatesListParams,) => [`/candidate-emails/templates/`, ...(params ? [params]: [])];

    
export type CandidateEmailsTemplatesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof candidateEmailsTemplatesList>>>
export type CandidateEmailsTemplatesListInfiniteQueryError = ErrorType<unknown>

export const useCandidateEmailsTemplatesListInfinite = <TData = Awaited<ReturnType<typeof candidateEmailsTemplatesList>>, TError = ErrorType<unknown>>(
 params?: CandidateEmailsTemplatesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof candidateEmailsTemplatesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCandidateEmailsTemplatesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof candidateEmailsTemplatesList>>> = ({ signal }) => candidateEmailsTemplatesList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof candidateEmailsTemplatesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type CandidateEmailsTemplatesListQueryResult = NonNullable<Awaited<ReturnType<typeof candidateEmailsTemplatesList>>>
export type CandidateEmailsTemplatesListQueryError = ErrorType<unknown>

export const useCandidateEmailsTemplatesList = <TData = Awaited<ReturnType<typeof candidateEmailsTemplatesList>>, TError = ErrorType<unknown>>(
 params?: CandidateEmailsTemplatesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof candidateEmailsTemplatesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCandidateEmailsTemplatesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof candidateEmailsTemplatesList>>> = ({ signal }) => candidateEmailsTemplatesList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof candidateEmailsTemplatesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const candidateEmailsTemplatesCreate = (
    emailTemplateBody: EmailTemplateBody,
 ) => {
      return customInstance<EmailTemplate>(
      {url: `/candidate-emails/templates/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: emailTemplateBody
    },
      );
    }
  


    export type CandidateEmailsTemplatesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof candidateEmailsTemplatesCreate>>>
    export type CandidateEmailsTemplatesCreateMutationBody = EmailTemplateBody
    export type CandidateEmailsTemplatesCreateMutationError = ErrorType<unknown>

    export const useCandidateEmailsTemplatesCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof candidateEmailsTemplatesCreate>>, TError,{data: EmailTemplateBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof candidateEmailsTemplatesCreate>>, {data: EmailTemplateBody}> = (props) => {
          const {data} = props ?? {};

          return  candidateEmailsTemplatesCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof candidateEmailsTemplatesCreate>>, TError, {data: EmailTemplateBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const candidateEmailsTemplatesRead = (
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<EmailTemplate>(
      {url: `/candidate-emails/templates/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getCandidateEmailsTemplatesReadQueryKey = (id: number,) => [`/candidate-emails/templates/${id}/`];

    
export type CandidateEmailsTemplatesReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof candidateEmailsTemplatesRead>>>
export type CandidateEmailsTemplatesReadInfiniteQueryError = ErrorType<unknown>

export const useCandidateEmailsTemplatesReadInfinite = <TData = Awaited<ReturnType<typeof candidateEmailsTemplatesRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof candidateEmailsTemplatesRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCandidateEmailsTemplatesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof candidateEmailsTemplatesRead>>> = ({ signal }) => candidateEmailsTemplatesRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof candidateEmailsTemplatesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type CandidateEmailsTemplatesReadQueryResult = NonNullable<Awaited<ReturnType<typeof candidateEmailsTemplatesRead>>>
export type CandidateEmailsTemplatesReadQueryError = ErrorType<unknown>

export const useCandidateEmailsTemplatesRead = <TData = Awaited<ReturnType<typeof candidateEmailsTemplatesRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof candidateEmailsTemplatesRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getCandidateEmailsTemplatesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof candidateEmailsTemplatesRead>>> = ({ signal }) => candidateEmailsTemplatesRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof candidateEmailsTemplatesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const candidateEmailsTemplatesUpdate = (
    id: number,
    emailTemplateBody: EmailTemplateBody,
 ) => {
      return customInstance<EmailTemplate>(
      {url: `/candidate-emails/templates/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: emailTemplateBody
    },
      );
    }
  


    export type CandidateEmailsTemplatesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof candidateEmailsTemplatesUpdate>>>
    export type CandidateEmailsTemplatesUpdateMutationBody = EmailTemplateBody
    export type CandidateEmailsTemplatesUpdateMutationError = ErrorType<unknown>

    export const useCandidateEmailsTemplatesUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof candidateEmailsTemplatesUpdate>>, TError,{id: number;data: EmailTemplateBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof candidateEmailsTemplatesUpdate>>, {id: number;data: EmailTemplateBody}> = (props) => {
          const {id,data} = props ?? {};

          return  candidateEmailsTemplatesUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof candidateEmailsTemplatesUpdate>>, TError, {id: number;data: EmailTemplateBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const candidateEmailsTemplatesPartialUpdate = (
    id: number,
    emailTemplateBody: EmailTemplateBody,
 ) => {
      return customInstance<EmailTemplate>(
      {url: `/candidate-emails/templates/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: emailTemplateBody
    },
      );
    }
  


    export type CandidateEmailsTemplatesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof candidateEmailsTemplatesPartialUpdate>>>
    export type CandidateEmailsTemplatesPartialUpdateMutationBody = EmailTemplateBody
    export type CandidateEmailsTemplatesPartialUpdateMutationError = ErrorType<unknown>

    export const useCandidateEmailsTemplatesPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof candidateEmailsTemplatesPartialUpdate>>, TError,{id: number;data: EmailTemplateBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof candidateEmailsTemplatesPartialUpdate>>, {id: number;data: EmailTemplateBody}> = (props) => {
          const {id,data} = props ?? {};

          return  candidateEmailsTemplatesPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof candidateEmailsTemplatesPartialUpdate>>, TError, {id: number;data: EmailTemplateBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const candidateEmailsTemplatesDelete = (
    id: number,
 ) => {
      return customInstance<void>(
      {url: `/candidate-emails/templates/${id}/`, method: 'delete'
    },
      );
    }
  


    export type CandidateEmailsTemplatesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof candidateEmailsTemplatesDelete>>>
    
    export type CandidateEmailsTemplatesDeleteMutationError = ErrorType<unknown>

    export const useCandidateEmailsTemplatesDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof candidateEmailsTemplatesDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof candidateEmailsTemplatesDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  candidateEmailsTemplatesDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof candidateEmailsTemplatesDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions);
    }
    
export const candidateEmailsTemplatesDuplicateCreate = (
    id: number,
    duplicateEmailTemplate: DuplicateEmailTemplate,
 ) => {
      return customInstance<EmailTemplate>(
      {url: `/candidate-emails/templates/${id}/duplicate/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: duplicateEmailTemplate
    },
      );
    }
  


    export type CandidateEmailsTemplatesDuplicateCreateMutationResult = NonNullable<Awaited<ReturnType<typeof candidateEmailsTemplatesDuplicateCreate>>>
    export type CandidateEmailsTemplatesDuplicateCreateMutationBody = DuplicateEmailTemplate
    export type CandidateEmailsTemplatesDuplicateCreateMutationError = ErrorType<unknown>

    export const useCandidateEmailsTemplatesDuplicateCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof candidateEmailsTemplatesDuplicateCreate>>, TError,{id: number;data: DuplicateEmailTemplate}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof candidateEmailsTemplatesDuplicateCreate>>, {id: number;data: DuplicateEmailTemplate}> = (props) => {
          const {id,data} = props ?? {};

          return  candidateEmailsTemplatesDuplicateCreate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof candidateEmailsTemplatesDuplicateCreate>>, TError, {id: number;data: DuplicateEmailTemplate}, TContext>(mutationFn, mutationOptions);
    }
    
export const contentCouponList = (
    params?: ContentCouponListParams,
 signal?: AbortSignal
) => {
      return customInstance<ContentCouponList200One | ContentCouponList200Two>(
      {url: `/content/coupon/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getContentCouponListQueryKey = (params?: ContentCouponListParams,) => [`/content/coupon/`, ...(params ? [params]: [])];

    
export type ContentCouponListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof contentCouponList>>>
export type ContentCouponListInfiniteQueryError = ErrorType<unknown>

export const useContentCouponListInfinite = <TData = Awaited<ReturnType<typeof contentCouponList>>, TError = ErrorType<unknown>>(
 params?: ContentCouponListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof contentCouponList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getContentCouponListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof contentCouponList>>> = ({ signal }) => contentCouponList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof contentCouponList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ContentCouponListQueryResult = NonNullable<Awaited<ReturnType<typeof contentCouponList>>>
export type ContentCouponListQueryError = ErrorType<unknown>

export const useContentCouponList = <TData = Awaited<ReturnType<typeof contentCouponList>>, TError = ErrorType<unknown>>(
 params?: ContentCouponListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof contentCouponList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getContentCouponListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof contentCouponList>>> = ({ signal }) => contentCouponList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof contentCouponList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const contentCouponCreate = (
    couponCodeWriteBody: CouponCodeWriteBody,
 ) => {
      return customInstance<CouponCodeWrite>(
      {url: `/content/coupon/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: couponCodeWriteBody
    },
      );
    }
  


    export type ContentCouponCreateMutationResult = NonNullable<Awaited<ReturnType<typeof contentCouponCreate>>>
    export type ContentCouponCreateMutationBody = CouponCodeWriteBody
    export type ContentCouponCreateMutationError = ErrorType<unknown>

    export const useContentCouponCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentCouponCreate>>, TError,{data: CouponCodeWriteBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof contentCouponCreate>>, {data: CouponCodeWriteBody}> = (props) => {
          const {data} = props ?? {};

          return  contentCouponCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof contentCouponCreate>>, TError, {data: CouponCodeWriteBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const contentCouponRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<CouponCodeWrite>(
      {url: `/content/coupon/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getContentCouponReadQueryKey = (id: string,) => [`/content/coupon/${id}/`];

    
export type ContentCouponReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof contentCouponRead>>>
export type ContentCouponReadInfiniteQueryError = ErrorType<unknown>

export const useContentCouponReadInfinite = <TData = Awaited<ReturnType<typeof contentCouponRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof contentCouponRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getContentCouponReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof contentCouponRead>>> = ({ signal }) => contentCouponRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof contentCouponRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ContentCouponReadQueryResult = NonNullable<Awaited<ReturnType<typeof contentCouponRead>>>
export type ContentCouponReadQueryError = ErrorType<unknown>

export const useContentCouponRead = <TData = Awaited<ReturnType<typeof contentCouponRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof contentCouponRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getContentCouponReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof contentCouponRead>>> = ({ signal }) => contentCouponRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof contentCouponRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const contentCouponUpdate = (
    id: string,
    couponCodeWriteBody: CouponCodeWriteBody,
 ) => {
      return customInstance<CouponCodeWrite>(
      {url: `/content/coupon/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: couponCodeWriteBody
    },
      );
    }
  


    export type ContentCouponUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof contentCouponUpdate>>>
    export type ContentCouponUpdateMutationBody = CouponCodeWriteBody
    export type ContentCouponUpdateMutationError = ErrorType<unknown>

    export const useContentCouponUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentCouponUpdate>>, TError,{id: string;data: CouponCodeWriteBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof contentCouponUpdate>>, {id: string;data: CouponCodeWriteBody}> = (props) => {
          const {id,data} = props ?? {};

          return  contentCouponUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof contentCouponUpdate>>, TError, {id: string;data: CouponCodeWriteBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const contentCouponPartialUpdate = (
    id: string,
    couponCodeWriteBody: CouponCodeWriteBody,
 ) => {
      return customInstance<CouponCodeWrite>(
      {url: `/content/coupon/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: couponCodeWriteBody
    },
      );
    }
  


    export type ContentCouponPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof contentCouponPartialUpdate>>>
    export type ContentCouponPartialUpdateMutationBody = CouponCodeWriteBody
    export type ContentCouponPartialUpdateMutationError = ErrorType<unknown>

    export const useContentCouponPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentCouponPartialUpdate>>, TError,{id: string;data: CouponCodeWriteBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof contentCouponPartialUpdate>>, {id: string;data: CouponCodeWriteBody}> = (props) => {
          const {id,data} = props ?? {};

          return  contentCouponPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof contentCouponPartialUpdate>>, TError, {id: string;data: CouponCodeWriteBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const contentCouponDelete = (
    id: string,
 ) => {
      return customInstance<void>(
      {url: `/content/coupon/${id}/`, method: 'delete'
    },
      );
    }
  


    export type ContentCouponDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof contentCouponDelete>>>
    
    export type ContentCouponDeleteMutationError = ErrorType<unknown>

    export const useContentCouponDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentCouponDelete>>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof contentCouponDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  contentCouponDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof contentCouponDelete>>, TError, {id: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const contentLibraryContentList = (
    params?: ContentLibraryContentListParams,
 ) => {
      return customInstance<ContentLibraryContentList200One | ContentLibraryContentList200Two>(
      {url: `/content/library/content/`, method: 'get',
        params
    },
      );
    }
  

export const getContentLibraryContentListQueryKey = (params?: ContentLibraryContentListParams,) => [`/content/library/content/`, ...(params ? [params]: [])];

    
export type ContentLibraryContentListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof contentLibraryContentList>>>
export type ContentLibraryContentListInfiniteQueryError = ErrorType<unknown>

export const useContentLibraryContentListInfinite = <TData = Awaited<ReturnType<typeof contentLibraryContentList>>, TError = ErrorType<unknown>>(
 params?: ContentLibraryContentListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof contentLibraryContentList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getContentLibraryContentListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof contentLibraryContentList>>> = ({ pageParam }) => contentLibraryContentList({ page: pageParam, ...params }, );


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof contentLibraryContentList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ContentLibraryContentListQueryResult = NonNullable<Awaited<ReturnType<typeof contentLibraryContentList>>>
export type ContentLibraryContentListQueryError = ErrorType<unknown>

export const useContentLibraryContentList = <TData = Awaited<ReturnType<typeof contentLibraryContentList>>, TError = ErrorType<unknown>>(
 params?: ContentLibraryContentListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof contentLibraryContentList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getContentLibraryContentListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof contentLibraryContentList>>> = () => contentLibraryContentList(params, );


  

  const query = useQuery<Awaited<ReturnType<typeof contentLibraryContentList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const contentLibraryContentCreate = (
    libraryBlockBody: LibraryBlockBody,
 ) => {
      return customInstance<LibraryBlock>(
      {url: `/content/library/content/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: libraryBlockBody
    },
      );
    }
  


    export type ContentLibraryContentCreateMutationResult = NonNullable<Awaited<ReturnType<typeof contentLibraryContentCreate>>>
    export type ContentLibraryContentCreateMutationBody = LibraryBlockBody
    export type ContentLibraryContentCreateMutationError = ErrorType<unknown>

    export const useContentLibraryContentCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentLibraryContentCreate>>, TError,{data: LibraryBlockBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof contentLibraryContentCreate>>, {data: LibraryBlockBody}> = (props) => {
          const {data} = props ?? {};

          return  contentLibraryContentCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof contentLibraryContentCreate>>, TError, {data: LibraryBlockBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const contentLibraryContentRead = (
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<LibraryBlock>(
      {url: `/content/library/content/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getContentLibraryContentReadQueryKey = (id: number,) => [`/content/library/content/${id}/`];

    
export type ContentLibraryContentReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof contentLibraryContentRead>>>
export type ContentLibraryContentReadInfiniteQueryError = ErrorType<unknown>

export const useContentLibraryContentReadInfinite = <TData = Awaited<ReturnType<typeof contentLibraryContentRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof contentLibraryContentRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getContentLibraryContentReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof contentLibraryContentRead>>> = ({ signal }) => contentLibraryContentRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof contentLibraryContentRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ContentLibraryContentReadQueryResult = NonNullable<Awaited<ReturnType<typeof contentLibraryContentRead>>>
export type ContentLibraryContentReadQueryError = ErrorType<unknown>

export const useContentLibraryContentRead = <TData = Awaited<ReturnType<typeof contentLibraryContentRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof contentLibraryContentRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getContentLibraryContentReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof contentLibraryContentRead>>> = ({ signal }) => contentLibraryContentRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof contentLibraryContentRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const contentLibraryContentUpdate = (
    id: number,
    libraryBlockBody: LibraryBlockBody,
 ) => {
      return customInstance<LibraryBlock>(
      {url: `/content/library/content/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: libraryBlockBody
    },
      );
    }
  


    export type ContentLibraryContentUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof contentLibraryContentUpdate>>>
    export type ContentLibraryContentUpdateMutationBody = LibraryBlockBody
    export type ContentLibraryContentUpdateMutationError = ErrorType<unknown>

    export const useContentLibraryContentUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentLibraryContentUpdate>>, TError,{id: number;data: LibraryBlockBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof contentLibraryContentUpdate>>, {id: number;data: LibraryBlockBody}> = (props) => {
          const {id,data} = props ?? {};

          return  contentLibraryContentUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof contentLibraryContentUpdate>>, TError, {id: number;data: LibraryBlockBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const contentLibraryContentPartialUpdate = (
    id: number,
    libraryBlockBody: LibraryBlockBody,
 ) => {
      return customInstance<LibraryBlock>(
      {url: `/content/library/content/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: libraryBlockBody
    },
      );
    }
  


    export type ContentLibraryContentPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof contentLibraryContentPartialUpdate>>>
    export type ContentLibraryContentPartialUpdateMutationBody = LibraryBlockBody
    export type ContentLibraryContentPartialUpdateMutationError = ErrorType<unknown>

    export const useContentLibraryContentPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentLibraryContentPartialUpdate>>, TError,{id: number;data: LibraryBlockBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof contentLibraryContentPartialUpdate>>, {id: number;data: LibraryBlockBody}> = (props) => {
          const {id,data} = props ?? {};

          return  contentLibraryContentPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof contentLibraryContentPartialUpdate>>, TError, {id: number;data: LibraryBlockBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const contentLibraryContentDelete = (
    id: number,
 ) => {
      return customInstance<void>(
      {url: `/content/library/content/${id}/`, method: 'delete'
    },
      );
    }
  


    export type ContentLibraryContentDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof contentLibraryContentDelete>>>
    
    export type ContentLibraryContentDeleteMutationError = ErrorType<unknown>

    export const useContentLibraryContentDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentLibraryContentDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof contentLibraryContentDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  contentLibraryContentDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof contentLibraryContentDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Upload media for an org
 */
export const contentMediaUploadCreate = (
    contentMedia: ContentMedia,
 ) => {
      return customInstanceNoTransform<ContentMedia>(
      {url: `/content/media/upload`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
      data: contentMedia
    },
      );
    }
  


    export type ContentMediaUploadCreateMutationResult = NonNullable<Awaited<ReturnType<typeof contentMediaUploadCreate>>>
    export type ContentMediaUploadCreateMutationBody = ContentMedia
    export type ContentMediaUploadCreateMutationError = ErrorType<unknown>

    export const useContentMediaUploadCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof contentMediaUploadCreate>>, TError,{data: ContentMedia}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof contentMediaUploadCreate>>, {data: ContentMedia}> = (props) => {
          const {data} = props ?? {};

          return  contentMediaUploadCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof contentMediaUploadCreate>>, TError, {data: ContentMedia}, TContext>(mutationFn, mutationOptions);
    }
    
export const contentTokensList = (
    params?: ContentTokensListParams,
 signal?: AbortSignal
) => {
      return customInstance<ContentTokensList200One | ContentTokensList200Two>(
      {url: `/content/tokens/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getContentTokensListQueryKey = (params?: ContentTokensListParams,) => [`/content/tokens/`, ...(params ? [params]: [])];

    
export type ContentTokensListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof contentTokensList>>>
export type ContentTokensListInfiniteQueryError = ErrorType<unknown>

export const useContentTokensListInfinite = <TData = Awaited<ReturnType<typeof contentTokensList>>, TError = ErrorType<unknown>>(
 params?: ContentTokensListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof contentTokensList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getContentTokensListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof contentTokensList>>> = ({ signal }) => contentTokensList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof contentTokensList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ContentTokensListQueryResult = NonNullable<Awaited<ReturnType<typeof contentTokensList>>>
export type ContentTokensListQueryError = ErrorType<unknown>

export const useContentTokensList = <TData = Awaited<ReturnType<typeof contentTokensList>>, TError = ErrorType<unknown>>(
 params?: ContentTokensListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof contentTokensList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getContentTokensListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof contentTokensList>>> = ({ signal }) => contentTokensList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof contentTokensList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const externalApplicationsList = (
    params?: ExternalApplicationsListParams,
 signal?: AbortSignal
) => {
      return customInstance<ExternalApplicationsList200One | ExternalApplicationsList200Two>(
      {url: `/external/applications/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getExternalApplicationsListQueryKey = (params?: ExternalApplicationsListParams,) => [`/external/applications/`, ...(params ? [params]: [])];

    
export type ExternalApplicationsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof externalApplicationsList>>>
export type ExternalApplicationsListInfiniteQueryError = ErrorType<unknown>

export const useExternalApplicationsListInfinite = <TData = Awaited<ReturnType<typeof externalApplicationsList>>, TError = ErrorType<unknown>>(
 params?: ExternalApplicationsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof externalApplicationsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalApplicationsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalApplicationsList>>> = ({ signal }) => externalApplicationsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof externalApplicationsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ExternalApplicationsListQueryResult = NonNullable<Awaited<ReturnType<typeof externalApplicationsList>>>
export type ExternalApplicationsListQueryError = ErrorType<unknown>

export const useExternalApplicationsList = <TData = Awaited<ReturnType<typeof externalApplicationsList>>, TError = ErrorType<unknown>>(
 params?: ExternalApplicationsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof externalApplicationsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalApplicationsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalApplicationsList>>> = ({ signal }) => externalApplicationsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof externalApplicationsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const externalApplicationsExternalSearchIdRead = (
    cfyiAtsExtSearchId: string,
 signal?: AbortSignal
) => {
      return customInstance<ExternalATSApplication>(
      {url: `/external/applications/external-search-id/${cfyiAtsExtSearchId}/`, method: 'get', signal
    },
      );
    }
  

export const getExternalApplicationsExternalSearchIdReadQueryKey = (cfyiAtsExtSearchId: string,) => [`/external/applications/external-search-id/${cfyiAtsExtSearchId}/`];

    
export type ExternalApplicationsExternalSearchIdReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof externalApplicationsExternalSearchIdRead>>>
export type ExternalApplicationsExternalSearchIdReadInfiniteQueryError = ErrorType<unknown>

export const useExternalApplicationsExternalSearchIdReadInfinite = <TData = Awaited<ReturnType<typeof externalApplicationsExternalSearchIdRead>>, TError = ErrorType<unknown>>(
 cfyiAtsExtSearchId: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof externalApplicationsExternalSearchIdRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalApplicationsExternalSearchIdReadQueryKey(cfyiAtsExtSearchId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalApplicationsExternalSearchIdRead>>> = ({ signal }) => externalApplicationsExternalSearchIdRead(cfyiAtsExtSearchId, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof externalApplicationsExternalSearchIdRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(cfyiAtsExtSearchId), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ExternalApplicationsExternalSearchIdReadQueryResult = NonNullable<Awaited<ReturnType<typeof externalApplicationsExternalSearchIdRead>>>
export type ExternalApplicationsExternalSearchIdReadQueryError = ErrorType<unknown>

export const useExternalApplicationsExternalSearchIdRead = <TData = Awaited<ReturnType<typeof externalApplicationsExternalSearchIdRead>>, TError = ErrorType<unknown>>(
 cfyiAtsExtSearchId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof externalApplicationsExternalSearchIdRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalApplicationsExternalSearchIdReadQueryKey(cfyiAtsExtSearchId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalApplicationsExternalSearchIdRead>>> = ({ signal }) => externalApplicationsExternalSearchIdRead(cfyiAtsExtSearchId, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof externalApplicationsExternalSearchIdRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(cfyiAtsExtSearchId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * retrieves application directly from ats
 */
export const externalApplicationsPassthroughRead = (
    cfyiAtsExtSearchId: string,
    params?: ExternalApplicationsPassthroughReadParams,
 signal?: AbortSignal
) => {
      return customInstance<ExternalATSPassthroughApplication>(
      {url: `/external/applications/${cfyiAtsExtSearchId}/passthrough`, method: 'get',
        params, signal
    },
      );
    }
  

export const getExternalApplicationsPassthroughReadQueryKey = (cfyiAtsExtSearchId: string,
    params?: ExternalApplicationsPassthroughReadParams,) => [`/external/applications/${cfyiAtsExtSearchId}/passthrough`, ...(params ? [params]: [])];

    
export type ExternalApplicationsPassthroughReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof externalApplicationsPassthroughRead>>>
export type ExternalApplicationsPassthroughReadInfiniteQueryError = ErrorType<unknown>

export const useExternalApplicationsPassthroughReadInfinite = <TData = Awaited<ReturnType<typeof externalApplicationsPassthroughRead>>, TError = ErrorType<unknown>>(
 cfyiAtsExtSearchId: string,
    params?: ExternalApplicationsPassthroughReadParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof externalApplicationsPassthroughRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalApplicationsPassthroughReadQueryKey(cfyiAtsExtSearchId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalApplicationsPassthroughRead>>> = ({ signal }) => externalApplicationsPassthroughRead(cfyiAtsExtSearchId,params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof externalApplicationsPassthroughRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(cfyiAtsExtSearchId), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ExternalApplicationsPassthroughReadQueryResult = NonNullable<Awaited<ReturnType<typeof externalApplicationsPassthroughRead>>>
export type ExternalApplicationsPassthroughReadQueryError = ErrorType<unknown>

export const useExternalApplicationsPassthroughRead = <TData = Awaited<ReturnType<typeof externalApplicationsPassthroughRead>>, TError = ErrorType<unknown>>(
 cfyiAtsExtSearchId: string,
    params?: ExternalApplicationsPassthroughReadParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof externalApplicationsPassthroughRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalApplicationsPassthroughReadQueryKey(cfyiAtsExtSearchId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalApplicationsPassthroughRead>>> = ({ signal }) => externalApplicationsPassthroughRead(cfyiAtsExtSearchId,params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof externalApplicationsPassthroughRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(cfyiAtsExtSearchId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const externalApplicationsRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<ExternalATSApplication>(
      {url: `/external/applications/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getExternalApplicationsReadQueryKey = (id: string,) => [`/external/applications/${id}/`];

    
export type ExternalApplicationsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof externalApplicationsRead>>>
export type ExternalApplicationsReadInfiniteQueryError = ErrorType<unknown>

export const useExternalApplicationsReadInfinite = <TData = Awaited<ReturnType<typeof externalApplicationsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof externalApplicationsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalApplicationsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalApplicationsRead>>> = ({ signal }) => externalApplicationsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof externalApplicationsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ExternalApplicationsReadQueryResult = NonNullable<Awaited<ReturnType<typeof externalApplicationsRead>>>
export type ExternalApplicationsReadQueryError = ErrorType<unknown>

export const useExternalApplicationsRead = <TData = Awaited<ReturnType<typeof externalApplicationsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof externalApplicationsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalApplicationsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalApplicationsRead>>> = ({ signal }) => externalApplicationsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof externalApplicationsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const externalCandidatesList = (
    params?: ExternalCandidatesListParams,
 signal?: AbortSignal
) => {
      return customInstance<ExternalCandidatesList200One | ExternalCandidatesList200Two>(
      {url: `/external/candidates/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getExternalCandidatesListQueryKey = (params?: ExternalCandidatesListParams,) => [`/external/candidates/`, ...(params ? [params]: [])];

    
export type ExternalCandidatesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof externalCandidatesList>>>
export type ExternalCandidatesListInfiniteQueryError = ErrorType<unknown>

export const useExternalCandidatesListInfinite = <TData = Awaited<ReturnType<typeof externalCandidatesList>>, TError = ErrorType<unknown>>(
 params?: ExternalCandidatesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof externalCandidatesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalCandidatesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalCandidatesList>>> = ({ signal }) => externalCandidatesList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof externalCandidatesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ExternalCandidatesListQueryResult = NonNullable<Awaited<ReturnType<typeof externalCandidatesList>>>
export type ExternalCandidatesListQueryError = ErrorType<unknown>

export const useExternalCandidatesList = <TData = Awaited<ReturnType<typeof externalCandidatesList>>, TError = ErrorType<unknown>>(
 params?: ExternalCandidatesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof externalCandidatesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalCandidatesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalCandidatesList>>> = ({ signal }) => externalCandidatesList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof externalCandidatesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const externalCandidatesEmailsList = (
    candidateId: string,
    params?: ExternalCandidatesEmailsListParams,
 signal?: AbortSignal
) => {
      return customInstance<ExternalCandidatesEmailsList200One | ExternalCandidatesEmailsList200Two>(
      {url: `/external/candidates/${candidateId}/emails/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getExternalCandidatesEmailsListQueryKey = (candidateId: string,
    params?: ExternalCandidatesEmailsListParams,) => [`/external/candidates/${candidateId}/emails/`, ...(params ? [params]: [])];

    
export type ExternalCandidatesEmailsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof externalCandidatesEmailsList>>>
export type ExternalCandidatesEmailsListInfiniteQueryError = ErrorType<unknown>

export const useExternalCandidatesEmailsListInfinite = <TData = Awaited<ReturnType<typeof externalCandidatesEmailsList>>, TError = ErrorType<unknown>>(
 candidateId: string,
    params?: ExternalCandidatesEmailsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof externalCandidatesEmailsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalCandidatesEmailsListQueryKey(candidateId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalCandidatesEmailsList>>> = ({ signal }) => externalCandidatesEmailsList(candidateId,params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof externalCandidatesEmailsList>>, TError, TData>(queryKey, queryFn, {enabled: !!(candidateId), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ExternalCandidatesEmailsListQueryResult = NonNullable<Awaited<ReturnType<typeof externalCandidatesEmailsList>>>
export type ExternalCandidatesEmailsListQueryError = ErrorType<unknown>

export const useExternalCandidatesEmailsList = <TData = Awaited<ReturnType<typeof externalCandidatesEmailsList>>, TError = ErrorType<unknown>>(
 candidateId: string,
    params?: ExternalCandidatesEmailsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof externalCandidatesEmailsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalCandidatesEmailsListQueryKey(candidateId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalCandidatesEmailsList>>> = ({ signal }) => externalCandidatesEmailsList(candidateId,params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof externalCandidatesEmailsList>>, TError, TData>(queryKey, queryFn, {enabled: !!(candidateId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const externalCandidatesEmailsRead = (
    candidateId: string,
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<ExternalATSCandidateEmail>(
      {url: `/external/candidates/${candidateId}/emails/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getExternalCandidatesEmailsReadQueryKey = (candidateId: string,
    id: string,) => [`/external/candidates/${candidateId}/emails/${id}/`];

    
export type ExternalCandidatesEmailsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof externalCandidatesEmailsRead>>>
export type ExternalCandidatesEmailsReadInfiniteQueryError = ErrorType<unknown>

export const useExternalCandidatesEmailsReadInfinite = <TData = Awaited<ReturnType<typeof externalCandidatesEmailsRead>>, TError = ErrorType<unknown>>(
 candidateId: string,
    id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof externalCandidatesEmailsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalCandidatesEmailsReadQueryKey(candidateId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalCandidatesEmailsRead>>> = ({ signal }) => externalCandidatesEmailsRead(candidateId,id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof externalCandidatesEmailsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(candidateId && id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ExternalCandidatesEmailsReadQueryResult = NonNullable<Awaited<ReturnType<typeof externalCandidatesEmailsRead>>>
export type ExternalCandidatesEmailsReadQueryError = ErrorType<unknown>

export const useExternalCandidatesEmailsRead = <TData = Awaited<ReturnType<typeof externalCandidatesEmailsRead>>, TError = ErrorType<unknown>>(
 candidateId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof externalCandidatesEmailsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalCandidatesEmailsReadQueryKey(candidateId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalCandidatesEmailsRead>>> = ({ signal }) => externalCandidatesEmailsRead(candidateId,id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof externalCandidatesEmailsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(candidateId && id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const externalCandidatesEmailsUpdate = (
    candidateId: string,
    id: string,
    externalATSCandidateEmailBody: ExternalATSCandidateEmailBody,
 ) => {
      return customInstance<ExternalATSCandidateEmail>(
      {url: `/external/candidates/${candidateId}/emails/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: externalATSCandidateEmailBody
    },
      );
    }
  


    export type ExternalCandidatesEmailsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof externalCandidatesEmailsUpdate>>>
    export type ExternalCandidatesEmailsUpdateMutationBody = ExternalATSCandidateEmailBody
    export type ExternalCandidatesEmailsUpdateMutationError = ErrorType<unknown>

    export const useExternalCandidatesEmailsUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof externalCandidatesEmailsUpdate>>, TError,{candidateId: string;id: string;data: ExternalATSCandidateEmailBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof externalCandidatesEmailsUpdate>>, {candidateId: string;id: string;data: ExternalATSCandidateEmailBody}> = (props) => {
          const {candidateId,id,data} = props ?? {};

          return  externalCandidatesEmailsUpdate(candidateId,id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof externalCandidatesEmailsUpdate>>, TError, {candidateId: string;id: string;data: ExternalATSCandidateEmailBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const externalCandidatesEmailsPartialUpdate = (
    candidateId: string,
    id: string,
    externalATSCandidateEmailBody: ExternalATSCandidateEmailBody,
 ) => {
      return customInstance<ExternalATSCandidateEmail>(
      {url: `/external/candidates/${candidateId}/emails/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: externalATSCandidateEmailBody
    },
      );
    }
  


    export type ExternalCandidatesEmailsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof externalCandidatesEmailsPartialUpdate>>>
    export type ExternalCandidatesEmailsPartialUpdateMutationBody = ExternalATSCandidateEmailBody
    export type ExternalCandidatesEmailsPartialUpdateMutationError = ErrorType<unknown>

    export const useExternalCandidatesEmailsPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof externalCandidatesEmailsPartialUpdate>>, TError,{candidateId: string;id: string;data: ExternalATSCandidateEmailBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof externalCandidatesEmailsPartialUpdate>>, {candidateId: string;id: string;data: ExternalATSCandidateEmailBody}> = (props) => {
          const {candidateId,id,data} = props ?? {};

          return  externalCandidatesEmailsPartialUpdate(candidateId,id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof externalCandidatesEmailsPartialUpdate>>, TError, {candidateId: string;id: string;data: ExternalATSCandidateEmailBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const externalCandidatesRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<ExternalATSCandidate>(
      {url: `/external/candidates/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getExternalCandidatesReadQueryKey = (id: string,) => [`/external/candidates/${id}/`];

    
export type ExternalCandidatesReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof externalCandidatesRead>>>
export type ExternalCandidatesReadInfiniteQueryError = ErrorType<unknown>

export const useExternalCandidatesReadInfinite = <TData = Awaited<ReturnType<typeof externalCandidatesRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof externalCandidatesRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalCandidatesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalCandidatesRead>>> = ({ signal }) => externalCandidatesRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof externalCandidatesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ExternalCandidatesReadQueryResult = NonNullable<Awaited<ReturnType<typeof externalCandidatesRead>>>
export type ExternalCandidatesReadQueryError = ErrorType<unknown>

export const useExternalCandidatesRead = <TData = Awaited<ReturnType<typeof externalCandidatesRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof externalCandidatesRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalCandidatesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalCandidatesRead>>> = ({ signal }) => externalCandidatesRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof externalCandidatesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const externalCandidatesInviteCreate = (
    id: string,
    candidatePortalInvitation: CandidatePortalInvitation,
 ) => {
      return customInstance<CandidatePortalInvitation>(
      {url: `/external/candidates/${id}/invite/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: candidatePortalInvitation
    },
      );
    }
  


    export type ExternalCandidatesInviteCreateMutationResult = NonNullable<Awaited<ReturnType<typeof externalCandidatesInviteCreate>>>
    export type ExternalCandidatesInviteCreateMutationBody = CandidatePortalInvitation
    export type ExternalCandidatesInviteCreateMutationError = ErrorType<unknown>

    export const useExternalCandidatesInviteCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof externalCandidatesInviteCreate>>, TError,{id: string;data: CandidatePortalInvitation}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof externalCandidatesInviteCreate>>, {id: string;data: CandidatePortalInvitation}> = (props) => {
          const {id,data} = props ?? {};

          return  externalCandidatesInviteCreate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof externalCandidatesInviteCreate>>, TError, {id: string;data: CandidatePortalInvitation}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * List all external Departments
 */
export const externalDepartmentsList = (
    params?: ExternalDepartmentsListParams,
 signal?: AbortSignal
) => {
      return customInstance<ExternalDepartmentsList200One | ExternalDepartmentsList200Two>(
      {url: `/external/departments/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getExternalDepartmentsListQueryKey = (params?: ExternalDepartmentsListParams,) => [`/external/departments/`, ...(params ? [params]: [])];

    
export type ExternalDepartmentsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof externalDepartmentsList>>>
export type ExternalDepartmentsListInfiniteQueryError = ErrorType<unknown>

export const useExternalDepartmentsListInfinite = <TData = Awaited<ReturnType<typeof externalDepartmentsList>>, TError = ErrorType<unknown>>(
 params?: ExternalDepartmentsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof externalDepartmentsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalDepartmentsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalDepartmentsList>>> = ({ signal }) => externalDepartmentsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof externalDepartmentsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ExternalDepartmentsListQueryResult = NonNullable<Awaited<ReturnType<typeof externalDepartmentsList>>>
export type ExternalDepartmentsListQueryError = ErrorType<unknown>

export const useExternalDepartmentsList = <TData = Awaited<ReturnType<typeof externalDepartmentsList>>, TError = ErrorType<unknown>>(
 params?: ExternalDepartmentsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof externalDepartmentsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalDepartmentsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalDepartmentsList>>> = ({ signal }) => externalDepartmentsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof externalDepartmentsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Retrieve a external Department
 */
export const externalDepartmentsRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<ExternalATSDepartments>(
      {url: `/external/departments/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getExternalDepartmentsReadQueryKey = (id: string,) => [`/external/departments/${id}/`];

    
export type ExternalDepartmentsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof externalDepartmentsRead>>>
export type ExternalDepartmentsReadInfiniteQueryError = ErrorType<unknown>

export const useExternalDepartmentsReadInfinite = <TData = Awaited<ReturnType<typeof externalDepartmentsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof externalDepartmentsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalDepartmentsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalDepartmentsRead>>> = ({ signal }) => externalDepartmentsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof externalDepartmentsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ExternalDepartmentsReadQueryResult = NonNullable<Awaited<ReturnType<typeof externalDepartmentsRead>>>
export type ExternalDepartmentsReadQueryError = ErrorType<unknown>

export const useExternalDepartmentsRead = <TData = Awaited<ReturnType<typeof externalDepartmentsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof externalDepartmentsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalDepartmentsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalDepartmentsRead>>> = ({ signal }) => externalDepartmentsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof externalDepartmentsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const externalForceSyncApplicationCreate = (
    refreshATSApplication: RefreshATSApplication,
 ) => {
      return customInstance<ExternalATSApplication>(
      {url: `/external/force_sync/application/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: refreshATSApplication
    },
      );
    }
  


    export type ExternalForceSyncApplicationCreateMutationResult = NonNullable<Awaited<ReturnType<typeof externalForceSyncApplicationCreate>>>
    export type ExternalForceSyncApplicationCreateMutationBody = RefreshATSApplication
    export type ExternalForceSyncApplicationCreateMutationError = ErrorType<unknown>

    export const useExternalForceSyncApplicationCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof externalForceSyncApplicationCreate>>, TError,{data: RefreshATSApplication}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof externalForceSyncApplicationCreate>>, {data: RefreshATSApplication}> = (props) => {
          const {data} = props ?? {};

          return  externalForceSyncApplicationCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof externalForceSyncApplicationCreate>>, TError, {data: RefreshATSApplication}, TContext>(mutationFn, mutationOptions);
    }
    
export const externalForceSyncCandidateApplicationsCreate = (
    refreshATSCandidateApplications: RefreshATSCandidateApplications,
 ) => {
      return customInstance<ExternalATSApplication[]>(
      {url: `/external/force_sync/candidate_applications/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: refreshATSCandidateApplications
    },
      );
    }
  


    export type ExternalForceSyncCandidateApplicationsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof externalForceSyncCandidateApplicationsCreate>>>
    export type ExternalForceSyncCandidateApplicationsCreateMutationBody = RefreshATSCandidateApplications
    export type ExternalForceSyncCandidateApplicationsCreateMutationError = ErrorType<unknown>

    export const useExternalForceSyncCandidateApplicationsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof externalForceSyncCandidateApplicationsCreate>>, TError,{data: RefreshATSCandidateApplications}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof externalForceSyncCandidateApplicationsCreate>>, {data: RefreshATSCandidateApplications}> = (props) => {
          const {data} = props ?? {};

          return  externalForceSyncCandidateApplicationsCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof externalForceSyncCandidateApplicationsCreate>>, TError, {data: RefreshATSCandidateApplications}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * List all external ATS jobs for a given org
 */
export const externalJobsList = (
    params?: ExternalJobsListParams,
 signal?: AbortSignal
) => {
      return customInstance<ExternalJobsList200One | ExternalJobsList200Two>(
      {url: `/external/jobs/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getExternalJobsListQueryKey = (params?: ExternalJobsListParams,) => [`/external/jobs/`, ...(params ? [params]: [])];

    
export type ExternalJobsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof externalJobsList>>>
export type ExternalJobsListInfiniteQueryError = ErrorType<unknown>

export const useExternalJobsListInfinite = <TData = Awaited<ReturnType<typeof externalJobsList>>, TError = ErrorType<unknown>>(
 params?: ExternalJobsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof externalJobsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalJobsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalJobsList>>> = ({ signal }) => externalJobsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof externalJobsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ExternalJobsListQueryResult = NonNullable<Awaited<ReturnType<typeof externalJobsList>>>
export type ExternalJobsListQueryError = ErrorType<unknown>

export const useExternalJobsList = <TData = Awaited<ReturnType<typeof externalJobsList>>, TError = ErrorType<unknown>>(
 params?: ExternalJobsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof externalJobsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalJobsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalJobsList>>> = ({ signal }) => externalJobsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof externalJobsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Retrieve a single external ATS job
 */
export const externalJobsRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<ExternalATSJob>(
      {url: `/external/jobs/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getExternalJobsReadQueryKey = (id: string,) => [`/external/jobs/${id}/`];

    
export type ExternalJobsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof externalJobsRead>>>
export type ExternalJobsReadInfiniteQueryError = ErrorType<unknown>

export const useExternalJobsReadInfinite = <TData = Awaited<ReturnType<typeof externalJobsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof externalJobsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalJobsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalJobsRead>>> = ({ signal }) => externalJobsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof externalJobsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ExternalJobsReadQueryResult = NonNullable<Awaited<ReturnType<typeof externalJobsRead>>>
export type ExternalJobsReadQueryError = ErrorType<unknown>

export const useExternalJobsRead = <TData = Awaited<ReturnType<typeof externalJobsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof externalJobsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalJobsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalJobsRead>>> = ({ signal }) => externalJobsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof externalJobsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * List all external Offices
 */
export const externalOfficesList = (
    params?: ExternalOfficesListParams,
 signal?: AbortSignal
) => {
      return customInstance<ExternalOfficesList200One | ExternalOfficesList200Two>(
      {url: `/external/offices/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getExternalOfficesListQueryKey = (params?: ExternalOfficesListParams,) => [`/external/offices/`, ...(params ? [params]: [])];

    
export type ExternalOfficesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof externalOfficesList>>>
export type ExternalOfficesListInfiniteQueryError = ErrorType<unknown>

export const useExternalOfficesListInfinite = <TData = Awaited<ReturnType<typeof externalOfficesList>>, TError = ErrorType<unknown>>(
 params?: ExternalOfficesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof externalOfficesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalOfficesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalOfficesList>>> = ({ signal }) => externalOfficesList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof externalOfficesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ExternalOfficesListQueryResult = NonNullable<Awaited<ReturnType<typeof externalOfficesList>>>
export type ExternalOfficesListQueryError = ErrorType<unknown>

export const useExternalOfficesList = <TData = Awaited<ReturnType<typeof externalOfficesList>>, TError = ErrorType<unknown>>(
 params?: ExternalOfficesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof externalOfficesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalOfficesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalOfficesList>>> = ({ signal }) => externalOfficesList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof externalOfficesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Retrieve a external Offices
 */
export const externalOfficesRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<ExternalATSOffice>(
      {url: `/external/offices/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getExternalOfficesReadQueryKey = (id: string,) => [`/external/offices/${id}/`];

    
export type ExternalOfficesReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof externalOfficesRead>>>
export type ExternalOfficesReadInfiniteQueryError = ErrorType<unknown>

export const useExternalOfficesReadInfinite = <TData = Awaited<ReturnType<typeof externalOfficesRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof externalOfficesRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalOfficesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalOfficesRead>>> = ({ signal }) => externalOfficesRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof externalOfficesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ExternalOfficesReadQueryResult = NonNullable<Awaited<ReturnType<typeof externalOfficesRead>>>
export type ExternalOfficesReadQueryError = ErrorType<unknown>

export const useExternalOfficesRead = <TData = Awaited<ReturnType<typeof externalOfficesRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof externalOfficesRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalOfficesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalOfficesRead>>> = ({ signal }) => externalOfficesRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof externalOfficesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const externalRefreshCandidateCreate = (
    refreshATSCandidate: RefreshATSCandidate,
 ) => {
      return customInstance<RefreshATSCandidate>(
      {url: `/external/refresh_candidate/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: refreshATSCandidate
    },
      );
    }
  


    export type ExternalRefreshCandidateCreateMutationResult = NonNullable<Awaited<ReturnType<typeof externalRefreshCandidateCreate>>>
    export type ExternalRefreshCandidateCreateMutationBody = RefreshATSCandidate
    export type ExternalRefreshCandidateCreateMutationError = ErrorType<unknown>

    export const useExternalRefreshCandidateCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof externalRefreshCandidateCreate>>, TError,{data: RefreshATSCandidate}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof externalRefreshCandidateCreate>>, {data: RefreshATSCandidate}> = (props) => {
          const {data} = props ?? {};

          return  externalRefreshCandidateCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof externalRefreshCandidateCreate>>, TError, {data: RefreshATSCandidate}, TContext>(mutationFn, mutationOptions);
    }
    
export const externalStagesList = (
    params?: ExternalStagesListParams,
 signal?: AbortSignal
) => {
      return customInstance<ExternalStagesList200One | ExternalStagesList200Two>(
      {url: `/external/stages/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getExternalStagesListQueryKey = (params?: ExternalStagesListParams,) => [`/external/stages/`, ...(params ? [params]: [])];

    
export type ExternalStagesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof externalStagesList>>>
export type ExternalStagesListInfiniteQueryError = ErrorType<unknown>

export const useExternalStagesListInfinite = <TData = Awaited<ReturnType<typeof externalStagesList>>, TError = ErrorType<unknown>>(
 params?: ExternalStagesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof externalStagesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalStagesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalStagesList>>> = ({ signal }) => externalStagesList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof externalStagesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ExternalStagesListQueryResult = NonNullable<Awaited<ReturnType<typeof externalStagesList>>>
export type ExternalStagesListQueryError = ErrorType<unknown>

export const useExternalStagesList = <TData = Awaited<ReturnType<typeof externalStagesList>>, TError = ErrorType<unknown>>(
 params?: ExternalStagesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof externalStagesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalStagesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalStagesList>>> = ({ signal }) => externalStagesList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof externalStagesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * List all external ats job stages [names] for a given org
 */
export const externalStagesNamesList = (
    params?: ExternalStagesNamesListParams,
 signal?: AbortSignal
) => {
      return customInstance<ExternalStagesNamesList200One | ExternalStagesNamesList200Two>(
      {url: `/external/stages/names/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getExternalStagesNamesListQueryKey = (params?: ExternalStagesNamesListParams,) => [`/external/stages/names/`, ...(params ? [params]: [])];

    
export type ExternalStagesNamesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof externalStagesNamesList>>>
export type ExternalStagesNamesListInfiniteQueryError = ErrorType<unknown>

export const useExternalStagesNamesListInfinite = <TData = Awaited<ReturnType<typeof externalStagesNamesList>>, TError = ErrorType<unknown>>(
 params?: ExternalStagesNamesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof externalStagesNamesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalStagesNamesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalStagesNamesList>>> = ({ signal }) => externalStagesNamesList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof externalStagesNamesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ExternalStagesNamesListQueryResult = NonNullable<Awaited<ReturnType<typeof externalStagesNamesList>>>
export type ExternalStagesNamesListQueryError = ErrorType<unknown>

export const useExternalStagesNamesList = <TData = Awaited<ReturnType<typeof externalStagesNamesList>>, TError = ErrorType<unknown>>(
 params?: ExternalStagesNamesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof externalStagesNamesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalStagesNamesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalStagesNamesList>>> = ({ signal }) => externalStagesNamesList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof externalStagesNamesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const externalStagesRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<ExternalATSJobInterviewStageAPI>(
      {url: `/external/stages/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getExternalStagesReadQueryKey = (id: string,) => [`/external/stages/${id}/`];

    
export type ExternalStagesReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof externalStagesRead>>>
export type ExternalStagesReadInfiniteQueryError = ErrorType<unknown>

export const useExternalStagesReadInfinite = <TData = Awaited<ReturnType<typeof externalStagesRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof externalStagesRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalStagesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalStagesRead>>> = ({ signal }) => externalStagesRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof externalStagesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ExternalStagesReadQueryResult = NonNullable<Awaited<ReturnType<typeof externalStagesRead>>>
export type ExternalStagesReadQueryError = ErrorType<unknown>

export const useExternalStagesRead = <TData = Awaited<ReturnType<typeof externalStagesRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof externalStagesRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalStagesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalStagesRead>>> = ({ signal }) => externalStagesRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof externalStagesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * List all external Remote Users
 */
export const externalUsersList = (
    params?: ExternalUsersListParams,
 signal?: AbortSignal
) => {
      return customInstance<ExternalUsersList200One | ExternalUsersList200Two>(
      {url: `/external/users/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getExternalUsersListQueryKey = (params?: ExternalUsersListParams,) => [`/external/users/`, ...(params ? [params]: [])];

    
export type ExternalUsersListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof externalUsersList>>>
export type ExternalUsersListInfiniteQueryError = ErrorType<unknown>

export const useExternalUsersListInfinite = <TData = Awaited<ReturnType<typeof externalUsersList>>, TError = ErrorType<unknown>>(
 params?: ExternalUsersListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof externalUsersList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalUsersListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalUsersList>>> = ({ signal }) => externalUsersList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof externalUsersList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ExternalUsersListQueryResult = NonNullable<Awaited<ReturnType<typeof externalUsersList>>>
export type ExternalUsersListQueryError = ErrorType<unknown>

export const useExternalUsersList = <TData = Awaited<ReturnType<typeof externalUsersList>>, TError = ErrorType<unknown>>(
 params?: ExternalUsersListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof externalUsersList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalUsersListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalUsersList>>> = ({ signal }) => externalUsersList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof externalUsersList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Retrieve or Update a single external Remote User
 */
export const externalUsersRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<ExternalATSRemoteUser>(
      {url: `/external/users/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getExternalUsersReadQueryKey = (id: string,) => [`/external/users/${id}/`];

    
export type ExternalUsersReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof externalUsersRead>>>
export type ExternalUsersReadInfiniteQueryError = ErrorType<unknown>

export const useExternalUsersReadInfinite = <TData = Awaited<ReturnType<typeof externalUsersRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof externalUsersRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalUsersReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalUsersRead>>> = ({ signal }) => externalUsersRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof externalUsersRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type ExternalUsersReadQueryResult = NonNullable<Awaited<ReturnType<typeof externalUsersRead>>>
export type ExternalUsersReadQueryError = ErrorType<unknown>

export const useExternalUsersRead = <TData = Awaited<ReturnType<typeof externalUsersRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof externalUsersRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getExternalUsersReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof externalUsersRead>>> = ({ signal }) => externalUsersRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof externalUsersRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Retrieve or Update a single external Remote User
 */
export const externalUsersPartialUpdate = (
    id: string,
    externalATSRemoteUserUpdate: ExternalATSRemoteUserUpdate,
 ) => {
      return customInstance<ExternalATSRemoteUserUpdate>(
      {url: `/external/users/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: externalATSRemoteUserUpdate
    },
      );
    }
  


    export type ExternalUsersPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof externalUsersPartialUpdate>>>
    export type ExternalUsersPartialUpdateMutationBody = ExternalATSRemoteUserUpdate
    export type ExternalUsersPartialUpdateMutationError = ErrorType<unknown>

    export const useExternalUsersPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof externalUsersPartialUpdate>>, TError,{id: string;data: ExternalATSRemoteUserUpdate}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof externalUsersPartialUpdate>>, {id: string;data: ExternalATSRemoteUserUpdate}> = (props) => {
          const {id,data} = props ?? {};

          return  externalUsersPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof externalUsersPartialUpdate>>, TError, {id: string;data: ExternalATSRemoteUserUpdate}, TContext>(mutationFn, mutationOptions);
    }
    
export const featureFlagsList = (
    params?: FeatureFlagsListParams,
 signal?: AbortSignal
) => {
      return customInstance<FeatureSchema>(
      {url: `/feature_flags/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getFeatureFlagsListQueryKey = (params?: FeatureFlagsListParams,) => [`/feature_flags/`, ...(params ? [params]: [])];

    
export type FeatureFlagsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof featureFlagsList>>>
export type FeatureFlagsListInfiniteQueryError = ErrorType<unknown>

export const useFeatureFlagsListInfinite = <TData = Awaited<ReturnType<typeof featureFlagsList>>, TError = ErrorType<unknown>>(
 params?: FeatureFlagsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof featureFlagsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFeatureFlagsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof featureFlagsList>>> = ({ signal }) => featureFlagsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof featureFlagsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type FeatureFlagsListQueryResult = NonNullable<Awaited<ReturnType<typeof featureFlagsList>>>
export type FeatureFlagsListQueryError = ErrorType<unknown>

export const useFeatureFlagsList = <TData = Awaited<ReturnType<typeof featureFlagsList>>, TError = ErrorType<unknown>>(
 params?: FeatureFlagsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof featureFlagsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFeatureFlagsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof featureFlagsList>>> = ({ signal }) => featureFlagsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof featureFlagsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const featureFlagsRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<FeatureFlag>(
      {url: `/feature_flags/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getFeatureFlagsReadQueryKey = (id: string,) => [`/feature_flags/${id}/`];

    
export type FeatureFlagsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof featureFlagsRead>>>
export type FeatureFlagsReadInfiniteQueryError = ErrorType<unknown>

export const useFeatureFlagsReadInfinite = <TData = Awaited<ReturnType<typeof featureFlagsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof featureFlagsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFeatureFlagsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof featureFlagsRead>>> = ({ signal }) => featureFlagsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof featureFlagsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type FeatureFlagsReadQueryResult = NonNullable<Awaited<ReturnType<typeof featureFlagsRead>>>
export type FeatureFlagsReadQueryError = ErrorType<unknown>

export const useFeatureFlagsRead = <TData = Awaited<ReturnType<typeof featureFlagsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof featureFlagsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFeatureFlagsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof featureFlagsRead>>> = ({ signal }) => featureFlagsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof featureFlagsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const followUpList = (
    params?: FollowUpListParams,
 signal?: AbortSignal
) => {
      return customInstance<FollowUpList200One | FollowUpList200Two>(
      {url: `/follow_up/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getFollowUpListQueryKey = (params?: FollowUpListParams,) => [`/follow_up/`, ...(params ? [params]: [])];

    
export type FollowUpListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof followUpList>>>
export type FollowUpListInfiniteQueryError = ErrorType<unknown>

export const useFollowUpListInfinite = <TData = Awaited<ReturnType<typeof followUpList>>, TError = ErrorType<unknown>>(
 params?: FollowUpListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof followUpList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFollowUpListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof followUpList>>> = ({ signal }) => followUpList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof followUpList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type FollowUpListQueryResult = NonNullable<Awaited<ReturnType<typeof followUpList>>>
export type FollowUpListQueryError = ErrorType<unknown>

export const useFollowUpList = <TData = Awaited<ReturnType<typeof followUpList>>, TError = ErrorType<unknown>>(
 params?: FollowUpListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof followUpList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFollowUpListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof followUpList>>> = ({ signal }) => followUpList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof followUpList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const followUpRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<Followup>(
      {url: `/follow_up/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getFollowUpReadQueryKey = (id: string,) => [`/follow_up/${id}/`];

    
export type FollowUpReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof followUpRead>>>
export type FollowUpReadInfiniteQueryError = ErrorType<unknown>

export const useFollowUpReadInfinite = <TData = Awaited<ReturnType<typeof followUpRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof followUpRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFollowUpReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof followUpRead>>> = ({ signal }) => followUpRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof followUpRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type FollowUpReadQueryResult = NonNullable<Awaited<ReturnType<typeof followUpRead>>>
export type FollowUpReadQueryError = ErrorType<unknown>

export const useFollowUpRead = <TData = Awaited<ReturnType<typeof followUpRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof followUpRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getFollowUpReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof followUpRead>>> = ({ signal }) => followUpRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof followUpRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Add a followup message
 */
export const followUpAddMessageUpdate = (
    id: string,
    followupMessageBody: FollowupMessageBody,
 ) => {
      return customInstance<FollowupMessage>(
      {url: `/follow_up/${id}/add_message/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: followupMessageBody
    },
      );
    }
  


    export type FollowUpAddMessageUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof followUpAddMessageUpdate>>>
    export type FollowUpAddMessageUpdateMutationBody = FollowupMessageBody
    export type FollowUpAddMessageUpdateMutationError = ErrorType<unknown>

    export const useFollowUpAddMessageUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof followUpAddMessageUpdate>>, TError,{id: string;data: FollowupMessageBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof followUpAddMessageUpdate>>, {id: string;data: FollowupMessageBody}> = (props) => {
          const {id,data} = props ?? {};

          return  followUpAddMessageUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof followUpAddMessageUpdate>>, TError, {id: string;data: FollowupMessageBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Add a followup message
 */
export const followUpAddMessagePartialUpdate = (
    id: string,
    followupMessageBody: FollowupMessageBody,
 ) => {
      return customInstance<FollowupMessage>(
      {url: `/follow_up/${id}/add_message/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: followupMessageBody
    },
      );
    }
  


    export type FollowUpAddMessagePartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof followUpAddMessagePartialUpdate>>>
    export type FollowUpAddMessagePartialUpdateMutationBody = FollowupMessageBody
    export type FollowUpAddMessagePartialUpdateMutationError = ErrorType<unknown>

    export const useFollowUpAddMessagePartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof followUpAddMessagePartialUpdate>>, TError,{id: string;data: FollowupMessageBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof followUpAddMessagePartialUpdate>>, {id: string;data: FollowupMessageBody}> = (props) => {
          const {id,data} = props ?? {};

          return  followUpAddMessagePartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof followUpAddMessagePartialUpdate>>, TError, {id: string;data: FollowupMessageBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const inboundWebhooksGcalEventsWatchYu7l3KjBjdUX47Dxp3FYcCreate = (
    
 ) => {
      return customInstance<void>(
      {url: `/inbound-webhooks/gcal/events/watch/Yu7l3KjBjdUX47Dxp3FYc/`, method: 'post'
    },
      );
    }
  


    export type InboundWebhooksGcalEventsWatchYu7l3KjBjdUX47Dxp3FYcCreateMutationResult = NonNullable<Awaited<ReturnType<typeof inboundWebhooksGcalEventsWatchYu7l3KjBjdUX47Dxp3FYcCreate>>>
    
    export type InboundWebhooksGcalEventsWatchYu7l3KjBjdUX47Dxp3FYcCreateMutationError = ErrorType<unknown>

    export const useInboundWebhooksGcalEventsWatchYu7l3KjBjdUX47Dxp3FYcCreate = <TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inboundWebhooksGcalEventsWatchYu7l3KjBjdUX47Dxp3FYcCreate>>, TError,TVariables, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof inboundWebhooksGcalEventsWatchYu7l3KjBjdUX47Dxp3FYcCreate>>, TVariables> = () => {
          

          return  inboundWebhooksGcalEventsWatchYu7l3KjBjdUX47Dxp3FYcCreate()
        }

        

      return useMutation<Awaited<ReturnType<typeof inboundWebhooksGcalEventsWatchYu7l3KjBjdUX47Dxp3FYcCreate>>, TError, TVariables, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Inbound webhook for Merge initial sync
 */
export const inboundWebhooksMergeSyncNotificationInitialCreate = (
    mergeWebhook: MergeWebhook,
 ) => {
      return customInstance<MergeWebhook>(
      {url: `/inbound-webhooks/merge/sync-notification/initial/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: mergeWebhook
    },
      );
    }
  


    export type InboundWebhooksMergeSyncNotificationInitialCreateMutationResult = NonNullable<Awaited<ReturnType<typeof inboundWebhooksMergeSyncNotificationInitialCreate>>>
    export type InboundWebhooksMergeSyncNotificationInitialCreateMutationBody = MergeWebhook
    export type InboundWebhooksMergeSyncNotificationInitialCreateMutationError = ErrorType<unknown>

    export const useInboundWebhooksMergeSyncNotificationInitialCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inboundWebhooksMergeSyncNotificationInitialCreate>>, TError,{data: MergeWebhook}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof inboundWebhooksMergeSyncNotificationInitialCreate>>, {data: MergeWebhook}> = (props) => {
          const {data} = props ?? {};

          return  inboundWebhooksMergeSyncNotificationInitialCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof inboundWebhooksMergeSyncNotificationInitialCreate>>, TError, {data: MergeWebhook}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * used for receiving both:
    - actual subscription notification
    - validation on creation subscription
     (https://learn.microsoft.com/en-us/graph/change-notifications-delivery-webhooks?tabs=http#notificationurl-validation)
 */
export const inboundWebhooksOutlookEventsWatchKd8DDDFEio73Bx21Create = (
    
 ) => {
      return customInstance<void>(
      {url: `/inbound-webhooks/outlook/events/watch/kd8DDDFEio73Bx21`, method: 'post'
    },
      );
    }
  


    export type InboundWebhooksOutlookEventsWatchKd8DDDFEio73Bx21CreateMutationResult = NonNullable<Awaited<ReturnType<typeof inboundWebhooksOutlookEventsWatchKd8DDDFEio73Bx21Create>>>
    
    export type InboundWebhooksOutlookEventsWatchKd8DDDFEio73Bx21CreateMutationError = ErrorType<unknown>

    export const useInboundWebhooksOutlookEventsWatchKd8DDDFEio73Bx21Create = <TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inboundWebhooksOutlookEventsWatchKd8DDDFEio73Bx21Create>>, TError,TVariables, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof inboundWebhooksOutlookEventsWatchKd8DDDFEio73Bx21Create>>, TVariables> = () => {
          

          return  inboundWebhooksOutlookEventsWatchKd8DDDFEio73Bx21Create()
        }

        

      return useMutation<Awaited<ReturnType<typeof inboundWebhooksOutlookEventsWatchKd8DDDFEio73Bx21Create>>, TError, TVariables, TContext>(mutationFn, mutationOptions);
    }
    
export const inboundWebhooksOutlookWatchLifecycle87d2a498C88a4744A6160483847b85c6Create = (
    
 ) => {
      return customInstance<void>(
      {url: `/inbound-webhooks/outlook/watch/lifecycle/87d2a498-c88a-4744-a616-0483847b85c6`, method: 'post'
    },
      );
    }
  


    export type InboundWebhooksOutlookWatchLifecycle87d2a498C88a4744A6160483847b85c6CreateMutationResult = NonNullable<Awaited<ReturnType<typeof inboundWebhooksOutlookWatchLifecycle87d2a498C88a4744A6160483847b85c6Create>>>
    
    export type InboundWebhooksOutlookWatchLifecycle87d2a498C88a4744A6160483847b85c6CreateMutationError = ErrorType<unknown>

    export const useInboundWebhooksOutlookWatchLifecycle87d2a498C88a4744A6160483847b85c6Create = <TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inboundWebhooksOutlookWatchLifecycle87d2a498C88a4744A6160483847b85c6Create>>, TError,TVariables, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof inboundWebhooksOutlookWatchLifecycle87d2a498C88a4744A6160483847b85c6Create>>, TVariables> = () => {
          

          return  inboundWebhooksOutlookWatchLifecycle87d2a498C88a4744A6160483847b85c6Create()
        }

        

      return useMutation<Awaited<ReturnType<typeof inboundWebhooksOutlookWatchLifecycle87d2a498C88a4744A6160483847b85c6Create>>, TError, TVariables, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Comes in following format:
{'text': 'PERSON:[5](https://app.posthog.com/person/5),
    ORG_HOST:demo.cxp.fyi'}
 */
export const inboundWebhooksPosthogE9ej93jsnssnss88s8s8s88sh4h482Create = (
    
 ) => {
      return customInstance<void>(
      {url: `/inbound-webhooks/posthog/e9ej93jsnssnss88s8s8s88sh4h482/`, method: 'post'
    },
      );
    }
  


    export type InboundWebhooksPosthogE9ej93jsnssnss88s8s8s88sh4h482CreateMutationResult = NonNullable<Awaited<ReturnType<typeof inboundWebhooksPosthogE9ej93jsnssnss88s8s8s88sh4h482Create>>>
    
    export type InboundWebhooksPosthogE9ej93jsnssnss88s8s8s88sh4h482CreateMutationError = ErrorType<unknown>

    export const useInboundWebhooksPosthogE9ej93jsnssnss88s8s8s88sh4h482Create = <TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inboundWebhooksPosthogE9ej93jsnssnss88s8s8s88sh4h482Create>>, TError,TVariables, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof inboundWebhooksPosthogE9ej93jsnssnss88s8s8s88sh4h482Create>>, TVariables> = () => {
          

          return  inboundWebhooksPosthogE9ej93jsnssnss88s8s8s88sh4h482Create()
        }

        

      return useMutation<Awaited<ReturnType<typeof inboundWebhooksPosthogE9ej93jsnssnss88s8s8s88sh4h482Create>>, TError, TVariables, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Receives webhook event from postmark
 */
export const inboundWebhooksPostmarkShwNaNEMKjBjdUXCreate = (
    
 ) => {
      return customInstance<void>(
      {url: `/inbound-webhooks/postmark/shwNaNEMKjBjdUX/`, method: 'post'
    },
      );
    }
  


    export type InboundWebhooksPostmarkShwNaNEMKjBjdUXCreateMutationResult = NonNullable<Awaited<ReturnType<typeof inboundWebhooksPostmarkShwNaNEMKjBjdUXCreate>>>
    
    export type InboundWebhooksPostmarkShwNaNEMKjBjdUXCreateMutationError = ErrorType<unknown>

    export const useInboundWebhooksPostmarkShwNaNEMKjBjdUXCreate = <TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inboundWebhooksPostmarkShwNaNEMKjBjdUXCreate>>, TError,TVariables, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof inboundWebhooksPostmarkShwNaNEMKjBjdUXCreate>>, TVariables> = () => {
          

          return  inboundWebhooksPostmarkShwNaNEMKjBjdUXCreate()
        }

        

      return useMutation<Awaited<ReturnType<typeof inboundWebhooksPostmarkShwNaNEMKjBjdUXCreate>>, TError, TVariables, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Slack request url
 */
export const inboundWebhooksSlackEventsE1TT687d3Ry59268857Create = (
    
 ) => {
      return customInstance<void>(
      {url: `/inbound-webhooks/slack/events/e1TT687d3Ry59268857/`, method: 'post'
    },
      );
    }
  


    export type InboundWebhooksSlackEventsE1TT687d3Ry59268857CreateMutationResult = NonNullable<Awaited<ReturnType<typeof inboundWebhooksSlackEventsE1TT687d3Ry59268857Create>>>
    
    export type InboundWebhooksSlackEventsE1TT687d3Ry59268857CreateMutationError = ErrorType<unknown>

    export const useInboundWebhooksSlackEventsE1TT687d3Ry59268857Create = <TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inboundWebhooksSlackEventsE1TT687d3Ry59268857Create>>, TError,TVariables, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof inboundWebhooksSlackEventsE1TT687d3Ry59268857Create>>, TVariables> = () => {
          

          return  inboundWebhooksSlackEventsE1TT687d3Ry59268857Create()
        }

        

      return useMutation<Awaited<ReturnType<typeof inboundWebhooksSlackEventsE1TT687d3Ry59268857Create>>, TError, TVariables, TContext>(mutationFn, mutationOptions);
    }
    
export const inboundWebhooksZoomDkj68vd3U8r5P7Create = (
    zoomWebhook: ZoomWebhook,
 ) => {
      return customInstance<ZoomWebhook>(
      {url: `/inbound-webhooks/zoom/dkj68vd3U8r5P7/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: zoomWebhook
    },
      );
    }
  


    export type InboundWebhooksZoomDkj68vd3U8r5P7CreateMutationResult = NonNullable<Awaited<ReturnType<typeof inboundWebhooksZoomDkj68vd3U8r5P7Create>>>
    export type InboundWebhooksZoomDkj68vd3U8r5P7CreateMutationBody = ZoomWebhook
    export type InboundWebhooksZoomDkj68vd3U8r5P7CreateMutationError = ErrorType<unknown>

    export const useInboundWebhooksZoomDkj68vd3U8r5P7Create = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof inboundWebhooksZoomDkj68vd3U8r5P7Create>>, TError,{data: ZoomWebhook}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof inboundWebhooksZoomDkj68vd3U8r5P7Create>>, {data: ZoomWebhook}> = (props) => {
          const {data} = props ?? {};

          return  inboundWebhooksZoomDkj68vd3U8r5P7Create(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof inboundWebhooksZoomDkj68vd3U8r5P7Create>>, TError, {data: ZoomWebhook}, TContext>(mutationFn, mutationOptions);
    }
    
export const jobsList = (
    params?: JobsListParams,
 signal?: AbortSignal
) => {
      return customInstance<JobsList200One | JobsList200Two>(
      {url: `/jobs/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getJobsListQueryKey = (params?: JobsListParams,) => [`/jobs/`, ...(params ? [params]: [])];

    
export type JobsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof jobsList>>>
export type JobsListInfiniteQueryError = ErrorType<unknown>

export const useJobsListInfinite = <TData = Awaited<ReturnType<typeof jobsList>>, TError = ErrorType<unknown>>(
 params?: JobsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof jobsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJobsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof jobsList>>> = ({ signal }) => jobsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof jobsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type JobsListQueryResult = NonNullable<Awaited<ReturnType<typeof jobsList>>>
export type JobsListQueryError = ErrorType<unknown>

export const useJobsList = <TData = Awaited<ReturnType<typeof jobsList>>, TError = ErrorType<unknown>>(
 params?: JobsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jobsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJobsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof jobsList>>> = ({ signal }) => jobsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof jobsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const jobsCreate = (
    jobBody: JobBody,
 ) => {
      return customInstance<Job>(
      {url: `/jobs/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: jobBody
    },
      );
    }
  


    export type JobsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof jobsCreate>>>
    export type JobsCreateMutationBody = JobBody
    export type JobsCreateMutationError = ErrorType<unknown>

    export const useJobsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jobsCreate>>, TError,{data: JobBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof jobsCreate>>, {data: JobBody}> = (props) => {
          const {data} = props ?? {};

          return  jobsCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof jobsCreate>>, TError, {data: JobBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const jobsExposedStagesList = (
    params?: JobsExposedStagesListParams,
 signal?: AbortSignal
) => {
      return customInstance<JobsExposedStagesList200One | JobsExposedStagesList200Two>(
      {url: `/jobs/exposed_stages/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getJobsExposedStagesListQueryKey = (params?: JobsExposedStagesListParams,) => [`/jobs/exposed_stages/`, ...(params ? [params]: [])];

    
export type JobsExposedStagesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof jobsExposedStagesList>>>
export type JobsExposedStagesListInfiniteQueryError = ErrorType<unknown>

export const useJobsExposedStagesListInfinite = <TData = Awaited<ReturnType<typeof jobsExposedStagesList>>, TError = ErrorType<unknown>>(
 params?: JobsExposedStagesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof jobsExposedStagesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJobsExposedStagesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof jobsExposedStagesList>>> = ({ signal }) => jobsExposedStagesList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof jobsExposedStagesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type JobsExposedStagesListQueryResult = NonNullable<Awaited<ReturnType<typeof jobsExposedStagesList>>>
export type JobsExposedStagesListQueryError = ErrorType<unknown>

export const useJobsExposedStagesList = <TData = Awaited<ReturnType<typeof jobsExposedStagesList>>, TError = ErrorType<unknown>>(
 params?: JobsExposedStagesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jobsExposedStagesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJobsExposedStagesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof jobsExposedStagesList>>> = ({ signal }) => jobsExposedStagesList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof jobsExposedStagesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const jobsRead = (
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<Job>(
      {url: `/jobs/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getJobsReadQueryKey = (id: number,) => [`/jobs/${id}/`];

    
export type JobsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof jobsRead>>>
export type JobsReadInfiniteQueryError = ErrorType<unknown>

export const useJobsReadInfinite = <TData = Awaited<ReturnType<typeof jobsRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof jobsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJobsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof jobsRead>>> = ({ signal }) => jobsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof jobsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type JobsReadQueryResult = NonNullable<Awaited<ReturnType<typeof jobsRead>>>
export type JobsReadQueryError = ErrorType<unknown>

export const useJobsRead = <TData = Awaited<ReturnType<typeof jobsRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jobsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJobsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof jobsRead>>> = ({ signal }) => jobsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof jobsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const jobsUpdate = (
    id: number,
    jobBody: JobBody,
 ) => {
      return customInstance<Job>(
      {url: `/jobs/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: jobBody
    },
      );
    }
  


    export type JobsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof jobsUpdate>>>
    export type JobsUpdateMutationBody = JobBody
    export type JobsUpdateMutationError = ErrorType<unknown>

    export const useJobsUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jobsUpdate>>, TError,{id: number;data: JobBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof jobsUpdate>>, {id: number;data: JobBody}> = (props) => {
          const {id,data} = props ?? {};

          return  jobsUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof jobsUpdate>>, TError, {id: number;data: JobBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const jobsPartialUpdate = (
    id: number,
    jobBody: JobBody,
 ) => {
      return customInstance<Job>(
      {url: `/jobs/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: jobBody
    },
      );
    }
  


    export type JobsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof jobsPartialUpdate>>>
    export type JobsPartialUpdateMutationBody = JobBody
    export type JobsPartialUpdateMutationError = ErrorType<unknown>

    export const useJobsPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jobsPartialUpdate>>, TError,{id: number;data: JobBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof jobsPartialUpdate>>, {id: number;data: JobBody}> = (props) => {
          const {id,data} = props ?? {};

          return  jobsPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof jobsPartialUpdate>>, TError, {id: number;data: JobBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Copy stages to a job
 */
export const jobsCopyCreate = (
    id: number,
    copyJob: CopyJob,
 ) => {
      return customInstance<Job>(
      {url: `/jobs/${id}/copy/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: copyJob
    },
      );
    }
  


    export type JobsCopyCreateMutationResult = NonNullable<Awaited<ReturnType<typeof jobsCopyCreate>>>
    export type JobsCopyCreateMutationBody = CopyJob
    export type JobsCopyCreateMutationError = ErrorType<unknown>

    export const useJobsCopyCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jobsCopyCreate>>, TError,{id: number;data: CopyJob}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof jobsCopyCreate>>, {id: number;data: CopyJob}> = (props) => {
          const {id,data} = props ?? {};

          return  jobsCopyCreate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof jobsCopyCreate>>, TError, {id: number;data: CopyJob}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Copy stages to a job
 */
export const jobsImportStagesCreate = (
    id: number,
    importExposedStages: ImportExposedStages,
 ) => {
      return customInstance<ExposedJobStage[]>(
      {url: `/jobs/${id}/import_stages/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: importExposedStages
    },
      );
    }
  


    export type JobsImportStagesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof jobsImportStagesCreate>>>
    export type JobsImportStagesCreateMutationBody = ImportExposedStages
    export type JobsImportStagesCreateMutationError = ErrorType<unknown>

    export const useJobsImportStagesCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jobsImportStagesCreate>>, TError,{id: number;data: ImportExposedStages}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof jobsImportStagesCreate>>, {id: number;data: ImportExposedStages}> = (props) => {
          const {id,data} = props ?? {};

          return  jobsImportStagesCreate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof jobsImportStagesCreate>>, TError, {id: number;data: ImportExposedStages}, TContext>(mutationFn, mutationOptions);
    }
    
export const jobsStagesBlocksList = (
    id: string,
    stageId: string,
    params?: JobsStagesBlocksListParams,
 signal?: AbortSignal
) => {
      return customInstance<JobsStagesBlocksList200One | JobsStagesBlocksList200Two>(
      {url: `/jobs/${id}/stages/${stageId}/blocks/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getJobsStagesBlocksListQueryKey = (id: string,
    stageId: string,
    params?: JobsStagesBlocksListParams,) => [`/jobs/${id}/stages/${stageId}/blocks/`, ...(params ? [params]: [])];

    
export type JobsStagesBlocksListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof jobsStagesBlocksList>>>
export type JobsStagesBlocksListInfiniteQueryError = ErrorType<unknown>

export const useJobsStagesBlocksListInfinite = <TData = Awaited<ReturnType<typeof jobsStagesBlocksList>>, TError = ErrorType<unknown>>(
 id: string,
    stageId: string,
    params?: JobsStagesBlocksListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof jobsStagesBlocksList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJobsStagesBlocksListQueryKey(id,stageId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof jobsStagesBlocksList>>> = ({ signal }) => jobsStagesBlocksList(id,stageId,params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof jobsStagesBlocksList>>, TError, TData>(queryKey, queryFn, {enabled: !!(id && stageId), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type JobsStagesBlocksListQueryResult = NonNullable<Awaited<ReturnType<typeof jobsStagesBlocksList>>>
export type JobsStagesBlocksListQueryError = ErrorType<unknown>

export const useJobsStagesBlocksList = <TData = Awaited<ReturnType<typeof jobsStagesBlocksList>>, TError = ErrorType<unknown>>(
 id: string,
    stageId: string,
    params?: JobsStagesBlocksListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jobsStagesBlocksList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJobsStagesBlocksListQueryKey(id,stageId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof jobsStagesBlocksList>>> = ({ signal }) => jobsStagesBlocksList(id,stageId,params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof jobsStagesBlocksList>>, TError, TData>(queryKey, queryFn, {enabled: !!(id && stageId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const jobsStagesBlocksCreate = (
    id: string,
    stageId: string,
    stageBlockBody: StageBlockBody,
 ) => {
      return customInstance<StageBlock>(
      {url: `/jobs/${id}/stages/${stageId}/blocks/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: stageBlockBody
    },
      );
    }
  


    export type JobsStagesBlocksCreateMutationResult = NonNullable<Awaited<ReturnType<typeof jobsStagesBlocksCreate>>>
    export type JobsStagesBlocksCreateMutationBody = StageBlockBody
    export type JobsStagesBlocksCreateMutationError = ErrorType<unknown>

    export const useJobsStagesBlocksCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jobsStagesBlocksCreate>>, TError,{id: string;stageId: string;data: StageBlockBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof jobsStagesBlocksCreate>>, {id: string;stageId: string;data: StageBlockBody}> = (props) => {
          const {id,stageId,data} = props ?? {};

          return  jobsStagesBlocksCreate(id,stageId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof jobsStagesBlocksCreate>>, TError, {id: string;stageId: string;data: StageBlockBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const jobsStagesList = (
    jobId: string,
    params?: JobsStagesListParams,
 signal?: AbortSignal
) => {
      return customInstance<JobsStagesList200One | JobsStagesList200Two>(
      {url: `/jobs/${jobId}/stages/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getJobsStagesListQueryKey = (jobId: string,
    params?: JobsStagesListParams,) => [`/jobs/${jobId}/stages/`, ...(params ? [params]: [])];

    
export type JobsStagesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof jobsStagesList>>>
export type JobsStagesListInfiniteQueryError = ErrorType<unknown>

export const useJobsStagesListInfinite = <TData = Awaited<ReturnType<typeof jobsStagesList>>, TError = ErrorType<unknown>>(
 jobId: string,
    params?: JobsStagesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof jobsStagesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJobsStagesListQueryKey(jobId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof jobsStagesList>>> = ({ signal }) => jobsStagesList(jobId,params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof jobsStagesList>>, TError, TData>(queryKey, queryFn, {enabled: !!(jobId), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type JobsStagesListQueryResult = NonNullable<Awaited<ReturnType<typeof jobsStagesList>>>
export type JobsStagesListQueryError = ErrorType<unknown>

export const useJobsStagesList = <TData = Awaited<ReturnType<typeof jobsStagesList>>, TError = ErrorType<unknown>>(
 jobId: string,
    params?: JobsStagesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jobsStagesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJobsStagesListQueryKey(jobId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof jobsStagesList>>> = ({ signal }) => jobsStagesList(jobId,params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof jobsStagesList>>, TError, TData>(queryKey, queryFn, {enabled: !!(jobId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const jobsStagesCreate = (
    jobId: string,
    exposedJobStageBody: ExposedJobStageBody,
 ) => {
      return customInstance<ExposedJobStage>(
      {url: `/jobs/${jobId}/stages/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: exposedJobStageBody
    },
      );
    }
  


    export type JobsStagesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof jobsStagesCreate>>>
    export type JobsStagesCreateMutationBody = ExposedJobStageBody
    export type JobsStagesCreateMutationError = ErrorType<unknown>

    export const useJobsStagesCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jobsStagesCreate>>, TError,{jobId: string;data: ExposedJobStageBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof jobsStagesCreate>>, {jobId: string;data: ExposedJobStageBody}> = (props) => {
          const {jobId,data} = props ?? {};

          return  jobsStagesCreate(jobId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof jobsStagesCreate>>, TError, {jobId: string;data: ExposedJobStageBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const jobsStagesRead = (
    jobId: string,
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<ExposedJobStage>(
      {url: `/jobs/${jobId}/stages/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getJobsStagesReadQueryKey = (jobId: string,
    id: number,) => [`/jobs/${jobId}/stages/${id}/`];

    
export type JobsStagesReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof jobsStagesRead>>>
export type JobsStagesReadInfiniteQueryError = ErrorType<unknown>

export const useJobsStagesReadInfinite = <TData = Awaited<ReturnType<typeof jobsStagesRead>>, TError = ErrorType<unknown>>(
 jobId: string,
    id: number, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof jobsStagesRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJobsStagesReadQueryKey(jobId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof jobsStagesRead>>> = ({ signal }) => jobsStagesRead(jobId,id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof jobsStagesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(jobId && id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type JobsStagesReadQueryResult = NonNullable<Awaited<ReturnType<typeof jobsStagesRead>>>
export type JobsStagesReadQueryError = ErrorType<unknown>

export const useJobsStagesRead = <TData = Awaited<ReturnType<typeof jobsStagesRead>>, TError = ErrorType<unknown>>(
 jobId: string,
    id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jobsStagesRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJobsStagesReadQueryKey(jobId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof jobsStagesRead>>> = ({ signal }) => jobsStagesRead(jobId,id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof jobsStagesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(jobId && id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const jobsStagesUpdate = (
    jobId: string,
    id: number,
    exposedJobStageBody: ExposedJobStageBody,
 ) => {
      return customInstance<ExposedJobStage>(
      {url: `/jobs/${jobId}/stages/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: exposedJobStageBody
    },
      );
    }
  


    export type JobsStagesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof jobsStagesUpdate>>>
    export type JobsStagesUpdateMutationBody = ExposedJobStageBody
    export type JobsStagesUpdateMutationError = ErrorType<unknown>

    export const useJobsStagesUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jobsStagesUpdate>>, TError,{jobId: string;id: number;data: ExposedJobStageBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof jobsStagesUpdate>>, {jobId: string;id: number;data: ExposedJobStageBody}> = (props) => {
          const {jobId,id,data} = props ?? {};

          return  jobsStagesUpdate(jobId,id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof jobsStagesUpdate>>, TError, {jobId: string;id: number;data: ExposedJobStageBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const jobsStagesPartialUpdate = (
    jobId: string,
    id: number,
    exposedJobStageBody: ExposedJobStageBody,
 ) => {
      return customInstance<ExposedJobStage>(
      {url: `/jobs/${jobId}/stages/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: exposedJobStageBody
    },
      );
    }
  


    export type JobsStagesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof jobsStagesPartialUpdate>>>
    export type JobsStagesPartialUpdateMutationBody = ExposedJobStageBody
    export type JobsStagesPartialUpdateMutationError = ErrorType<unknown>

    export const useJobsStagesPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jobsStagesPartialUpdate>>, TError,{jobId: string;id: number;data: ExposedJobStageBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof jobsStagesPartialUpdate>>, {jobId: string;id: number;data: ExposedJobStageBody}> = (props) => {
          const {jobId,id,data} = props ?? {};

          return  jobsStagesPartialUpdate(jobId,id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof jobsStagesPartialUpdate>>, TError, {jobId: string;id: number;data: ExposedJobStageBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const jobsStagesDelete = (
    jobId: string,
    id: number,
 ) => {
      return customInstance<void>(
      {url: `/jobs/${jobId}/stages/${id}/`, method: 'delete'
    },
      );
    }
  


    export type JobsStagesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof jobsStagesDelete>>>
    
    export type JobsStagesDeleteMutationError = ErrorType<unknown>

    export const useJobsStagesDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jobsStagesDelete>>, TError,{jobId: string;id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof jobsStagesDelete>>, {jobId: string;id: number}> = (props) => {
          const {jobId,id} = props ?? {};

          return  jobsStagesDelete(jobId,id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof jobsStagesDelete>>, TError, {jobId: string;id: number}, TContext>(mutationFn, mutationOptions);
    }
    
export const jobsStagesBlocksRead = (
    jobId: string,
    stageId: string,
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<StageBlock>(
      {url: `/jobs/${jobId}/stages/${stageId}/blocks/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getJobsStagesBlocksReadQueryKey = (jobId: string,
    stageId: string,
    id: number,) => [`/jobs/${jobId}/stages/${stageId}/blocks/${id}/`];

    
export type JobsStagesBlocksReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof jobsStagesBlocksRead>>>
export type JobsStagesBlocksReadInfiniteQueryError = ErrorType<unknown>

export const useJobsStagesBlocksReadInfinite = <TData = Awaited<ReturnType<typeof jobsStagesBlocksRead>>, TError = ErrorType<unknown>>(
 jobId: string,
    stageId: string,
    id: number, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof jobsStagesBlocksRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJobsStagesBlocksReadQueryKey(jobId,stageId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof jobsStagesBlocksRead>>> = ({ signal }) => jobsStagesBlocksRead(jobId,stageId,id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof jobsStagesBlocksRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(jobId && stageId && id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type JobsStagesBlocksReadQueryResult = NonNullable<Awaited<ReturnType<typeof jobsStagesBlocksRead>>>
export type JobsStagesBlocksReadQueryError = ErrorType<unknown>

export const useJobsStagesBlocksRead = <TData = Awaited<ReturnType<typeof jobsStagesBlocksRead>>, TError = ErrorType<unknown>>(
 jobId: string,
    stageId: string,
    id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof jobsStagesBlocksRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getJobsStagesBlocksReadQueryKey(jobId,stageId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof jobsStagesBlocksRead>>> = ({ signal }) => jobsStagesBlocksRead(jobId,stageId,id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof jobsStagesBlocksRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(jobId && stageId && id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const jobsStagesBlocksUpdate = (
    jobId: string,
    stageId: string,
    id: number,
    stageBlockBody: StageBlockBody,
 ) => {
      return customInstance<StageBlock>(
      {url: `/jobs/${jobId}/stages/${stageId}/blocks/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: stageBlockBody
    },
      );
    }
  


    export type JobsStagesBlocksUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof jobsStagesBlocksUpdate>>>
    export type JobsStagesBlocksUpdateMutationBody = StageBlockBody
    export type JobsStagesBlocksUpdateMutationError = ErrorType<unknown>

    export const useJobsStagesBlocksUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jobsStagesBlocksUpdate>>, TError,{jobId: string;stageId: string;id: number;data: StageBlockBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof jobsStagesBlocksUpdate>>, {jobId: string;stageId: string;id: number;data: StageBlockBody}> = (props) => {
          const {jobId,stageId,id,data} = props ?? {};

          return  jobsStagesBlocksUpdate(jobId,stageId,id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof jobsStagesBlocksUpdate>>, TError, {jobId: string;stageId: string;id: number;data: StageBlockBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const jobsStagesBlocksPartialUpdate = (
    jobId: string,
    stageId: string,
    id: number,
    stageBlockBody: StageBlockBody,
 ) => {
      return customInstance<StageBlock>(
      {url: `/jobs/${jobId}/stages/${stageId}/blocks/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: stageBlockBody
    },
      );
    }
  


    export type JobsStagesBlocksPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof jobsStagesBlocksPartialUpdate>>>
    export type JobsStagesBlocksPartialUpdateMutationBody = StageBlockBody
    export type JobsStagesBlocksPartialUpdateMutationError = ErrorType<unknown>

    export const useJobsStagesBlocksPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jobsStagesBlocksPartialUpdate>>, TError,{jobId: string;stageId: string;id: number;data: StageBlockBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof jobsStagesBlocksPartialUpdate>>, {jobId: string;stageId: string;id: number;data: StageBlockBody}> = (props) => {
          const {jobId,stageId,id,data} = props ?? {};

          return  jobsStagesBlocksPartialUpdate(jobId,stageId,id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof jobsStagesBlocksPartialUpdate>>, TError, {jobId: string;stageId: string;id: number;data: StageBlockBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const jobsStagesBlocksDelete = (
    jobId: string,
    stageId: string,
    id: number,
 ) => {
      return customInstance<void>(
      {url: `/jobs/${jobId}/stages/${stageId}/blocks/${id}/`, method: 'delete'
    },
      );
    }
  


    export type JobsStagesBlocksDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof jobsStagesBlocksDelete>>>
    
    export type JobsStagesBlocksDeleteMutationError = ErrorType<unknown>

    export const useJobsStagesBlocksDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof jobsStagesBlocksDelete>>, TError,{jobId: string;stageId: string;id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof jobsStagesBlocksDelete>>, {jobId: string;stageId: string;id: number}> = (props) => {
          const {jobId,stageId,id} = props ?? {};

          return  jobsStagesBlocksDelete(jobId,stageId,id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof jobsStagesBlocksDelete>>, TError, {jobId: string;stageId: string;id: number}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Swap merge public_token for an account_token.
 */
export const mergeAtsIntegrateAccountTokenCreate = (
    mergeIntegration: MergeIntegration,
 ) => {
      return customInstance<MergeIntegration>(
      {url: `/merge-ats/integrate/account-token/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: mergeIntegration
    },
      );
    }
  


    export type MergeAtsIntegrateAccountTokenCreateMutationResult = NonNullable<Awaited<ReturnType<typeof mergeAtsIntegrateAccountTokenCreate>>>
    export type MergeAtsIntegrateAccountTokenCreateMutationBody = MergeIntegration
    export type MergeAtsIntegrateAccountTokenCreateMutationError = ErrorType<unknown>

    export const useMergeAtsIntegrateAccountTokenCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof mergeAtsIntegrateAccountTokenCreate>>, TError,{data: MergeIntegration}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof mergeAtsIntegrateAccountTokenCreate>>, {data: MergeIntegration}> = (props) => {
          const {data} = props ?? {};

          return  mergeAtsIntegrateAccountTokenCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof mergeAtsIntegrateAccountTokenCreate>>, TError, {data: MergeIntegration}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Initiate merge ATS integration.
 */
export const mergeAtsIntegrateLinkTokenCreate = (
    
 ) => {
      return customInstance<MergeToken>(
      {url: `/merge-ats/integrate/link-token/`, method: 'post'
    },
      );
    }
  


    export type MergeAtsIntegrateLinkTokenCreateMutationResult = NonNullable<Awaited<ReturnType<typeof mergeAtsIntegrateLinkTokenCreate>>>
    
    export type MergeAtsIntegrateLinkTokenCreateMutationError = ErrorType<unknown>

    export const useMergeAtsIntegrateLinkTokenCreate = <TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof mergeAtsIntegrateLinkTokenCreate>>, TError,TVariables, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof mergeAtsIntegrateLinkTokenCreate>>, TVariables> = () => {
          

          return  mergeAtsIntegrateLinkTokenCreate()
        }

        

      return useMutation<Awaited<ReturnType<typeof mergeAtsIntegrateLinkTokenCreate>>, TError, TVariables, TContext>(mutationFn, mutationOptions);
    }
    
export const notificationsList = (
    params?: NotificationsListParams,
 signal?: AbortSignal
) => {
      return customInstance<NotificationsList200One | NotificationsList200Two>(
      {url: `/notifications/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getNotificationsListQueryKey = (params?: NotificationsListParams,) => [`/notifications/`, ...(params ? [params]: [])];

    
export type NotificationsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof notificationsList>>>
export type NotificationsListInfiniteQueryError = ErrorType<unknown>

export const useNotificationsListInfinite = <TData = Awaited<ReturnType<typeof notificationsList>>, TError = ErrorType<unknown>>(
 params?: NotificationsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof notificationsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getNotificationsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationsList>>> = ({ signal }) => notificationsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof notificationsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type NotificationsListQueryResult = NonNullable<Awaited<ReturnType<typeof notificationsList>>>
export type NotificationsListQueryError = ErrorType<unknown>

export const useNotificationsList = <TData = Awaited<ReturnType<typeof notificationsList>>, TError = ErrorType<unknown>>(
 params?: NotificationsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof notificationsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getNotificationsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationsList>>> = ({ signal }) => notificationsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof notificationsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const notificationsRead = (
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<Notification>(
      {url: `/notifications/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getNotificationsReadQueryKey = (id: number,) => [`/notifications/${id}/`];

    
export type NotificationsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof notificationsRead>>>
export type NotificationsReadInfiniteQueryError = ErrorType<unknown>

export const useNotificationsReadInfinite = <TData = Awaited<ReturnType<typeof notificationsRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof notificationsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getNotificationsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationsRead>>> = ({ signal }) => notificationsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof notificationsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type NotificationsReadQueryResult = NonNullable<Awaited<ReturnType<typeof notificationsRead>>>
export type NotificationsReadQueryError = ErrorType<unknown>

export const useNotificationsRead = <TData = Awaited<ReturnType<typeof notificationsRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof notificationsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getNotificationsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof notificationsRead>>> = ({ signal }) => notificationsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof notificationsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const notificationsUpdate = (
    id: number,
    notificationUpdateBody: NotificationUpdateBody,
 ) => {
      return customInstance<NotificationUpdate>(
      {url: `/notifications/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: notificationUpdateBody
    },
      );
    }
  


    export type NotificationsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof notificationsUpdate>>>
    export type NotificationsUpdateMutationBody = NotificationUpdateBody
    export type NotificationsUpdateMutationError = ErrorType<unknown>

    export const useNotificationsUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof notificationsUpdate>>, TError,{id: number;data: NotificationUpdateBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof notificationsUpdate>>, {id: number;data: NotificationUpdateBody}> = (props) => {
          const {id,data} = props ?? {};

          return  notificationsUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof notificationsUpdate>>, TError, {id: number;data: NotificationUpdateBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const notificationsPartialUpdate = (
    id: number,
    notificationUpdateBody: NotificationUpdateBody,
 ) => {
      return customInstance<NotificationUpdate>(
      {url: `/notifications/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: notificationUpdateBody
    },
      );
    }
  


    export type NotificationsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof notificationsPartialUpdate>>>
    export type NotificationsPartialUpdateMutationBody = NotificationUpdateBody
    export type NotificationsPartialUpdateMutationError = ErrorType<unknown>

    export const useNotificationsPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof notificationsPartialUpdate>>, TError,{id: number;data: NotificationUpdateBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof notificationsPartialUpdate>>, {id: number;data: NotificationUpdateBody}> = (props) => {
          const {id,data} = props ?? {};

          return  notificationsPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof notificationsPartialUpdate>>, TError, {id: number;data: NotificationUpdateBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Create and list tags
 */
export const orgGenericTagsList = (
    params?: OrgGenericTagsListParams,
 signal?: AbortSignal
) => {
      return customInstance<OrgGenericTagsList200One | OrgGenericTagsList200Two>(
      {url: `/org/generic/tags/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getOrgGenericTagsListQueryKey = (params?: OrgGenericTagsListParams,) => [`/org/generic/tags/`, ...(params ? [params]: [])];

    
export type OrgGenericTagsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof orgGenericTagsList>>>
export type OrgGenericTagsListInfiniteQueryError = ErrorType<unknown>

export const useOrgGenericTagsListInfinite = <TData = Awaited<ReturnType<typeof orgGenericTagsList>>, TError = ErrorType<unknown>>(
 params?: OrgGenericTagsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof orgGenericTagsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgGenericTagsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgGenericTagsList>>> = ({ signal }) => orgGenericTagsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof orgGenericTagsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type OrgGenericTagsListQueryResult = NonNullable<Awaited<ReturnType<typeof orgGenericTagsList>>>
export type OrgGenericTagsListQueryError = ErrorType<unknown>

export const useOrgGenericTagsList = <TData = Awaited<ReturnType<typeof orgGenericTagsList>>, TError = ErrorType<unknown>>(
 params?: OrgGenericTagsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgGenericTagsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgGenericTagsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgGenericTagsList>>> = ({ signal }) => orgGenericTagsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof orgGenericTagsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Create and list tags
 */
export const orgGenericTagsCreate = (
    tagBody: TagBody,
 ) => {
      return customInstance<Tag>(
      {url: `/org/generic/tags/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: tagBody
    },
      );
    }
  


    export type OrgGenericTagsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof orgGenericTagsCreate>>>
    export type OrgGenericTagsCreateMutationBody = TagBody
    export type OrgGenericTagsCreateMutationError = ErrorType<unknown>

    export const useOrgGenericTagsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgGenericTagsCreate>>, TError,{data: TagBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgGenericTagsCreate>>, {data: TagBody}> = (props) => {
          const {data} = props ?? {};

          return  orgGenericTagsCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgGenericTagsCreate>>, TError, {data: TagBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Retrieve, update, and delete tags
 */
export const orgGenericTagsRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<Tag>(
      {url: `/org/generic/tags/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getOrgGenericTagsReadQueryKey = (id: string,) => [`/org/generic/tags/${id}/`];

    
export type OrgGenericTagsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof orgGenericTagsRead>>>
export type OrgGenericTagsReadInfiniteQueryError = ErrorType<unknown>

export const useOrgGenericTagsReadInfinite = <TData = Awaited<ReturnType<typeof orgGenericTagsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof orgGenericTagsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgGenericTagsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgGenericTagsRead>>> = ({ signal }) => orgGenericTagsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof orgGenericTagsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type OrgGenericTagsReadQueryResult = NonNullable<Awaited<ReturnType<typeof orgGenericTagsRead>>>
export type OrgGenericTagsReadQueryError = ErrorType<unknown>

export const useOrgGenericTagsRead = <TData = Awaited<ReturnType<typeof orgGenericTagsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgGenericTagsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgGenericTagsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgGenericTagsRead>>> = ({ signal }) => orgGenericTagsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof orgGenericTagsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Retrieve, update, and delete tags
 */
export const orgGenericTagsUpdate = (
    id: string,
    tagBody: TagBody,
 ) => {
      return customInstance<Tag>(
      {url: `/org/generic/tags/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: tagBody
    },
      );
    }
  


    export type OrgGenericTagsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof orgGenericTagsUpdate>>>
    export type OrgGenericTagsUpdateMutationBody = TagBody
    export type OrgGenericTagsUpdateMutationError = ErrorType<unknown>

    export const useOrgGenericTagsUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgGenericTagsUpdate>>, TError,{id: string;data: TagBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgGenericTagsUpdate>>, {id: string;data: TagBody}> = (props) => {
          const {id,data} = props ?? {};

          return  orgGenericTagsUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgGenericTagsUpdate>>, TError, {id: string;data: TagBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Retrieve, update, and delete tags
 */
export const orgGenericTagsPartialUpdate = (
    id: string,
    tagBody: TagBody,
 ) => {
      return customInstance<Tag>(
      {url: `/org/generic/tags/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: tagBody
    },
      );
    }
  


    export type OrgGenericTagsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof orgGenericTagsPartialUpdate>>>
    export type OrgGenericTagsPartialUpdateMutationBody = TagBody
    export type OrgGenericTagsPartialUpdateMutationError = ErrorType<unknown>

    export const useOrgGenericTagsPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgGenericTagsPartialUpdate>>, TError,{id: string;data: TagBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgGenericTagsPartialUpdate>>, {id: string;data: TagBody}> = (props) => {
          const {id,data} = props ?? {};

          return  orgGenericTagsPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgGenericTagsPartialUpdate>>, TError, {id: string;data: TagBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Retrieve, update, and delete tags
 */
export const orgGenericTagsDelete = (
    id: string,
 ) => {
      return customInstance<void>(
      {url: `/org/generic/tags/${id}/`, method: 'delete'
    },
      );
    }
  


    export type OrgGenericTagsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof orgGenericTagsDelete>>>
    
    export type OrgGenericTagsDeleteMutationError = ErrorType<unknown>

    export const useOrgGenericTagsDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgGenericTagsDelete>>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgGenericTagsDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  orgGenericTagsDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgGenericTagsDelete>>, TError, {id: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const orgGoogleRevokeDelete = (
    
 ) => {
      return customInstance<void>(
      {url: `/org/google/revoke/`, method: 'delete'
    },
      );
    }
  


    export type OrgGoogleRevokeDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof orgGoogleRevokeDelete>>>
    
    export type OrgGoogleRevokeDeleteMutationError = ErrorType<unknown>

    export const useOrgGoogleRevokeDelete = <TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgGoogleRevokeDelete>>, TError,TVariables, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgGoogleRevokeDelete>>, TVariables> = () => {
          

          return  orgGoogleRevokeDelete()
        }

        

      return useMutation<Awaited<ReturnType<typeof orgGoogleRevokeDelete>>, TError, TVariables, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Retrieve integration account for an organization
 */
export const orgIntegrationAtsRead = (
    
 signal?: AbortSignal
) => {
      return customInstance<IntegrationAccount>(
      {url: `/org/integration/ats/`, method: 'get', signal
    },
      );
    }
  

export const getOrgIntegrationAtsReadQueryKey = () => [`/org/integration/ats/`];

    
export type OrgIntegrationAtsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof orgIntegrationAtsRead>>>
export type OrgIntegrationAtsReadInfiniteQueryError = ErrorType<unknown>

export const useOrgIntegrationAtsReadInfinite = <TData = Awaited<ReturnType<typeof orgIntegrationAtsRead>>, TError = ErrorType<unknown>>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof orgIntegrationAtsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgIntegrationAtsReadQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgIntegrationAtsRead>>> = ({ signal }) => orgIntegrationAtsRead(signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof orgIntegrationAtsRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type OrgIntegrationAtsReadQueryResult = NonNullable<Awaited<ReturnType<typeof orgIntegrationAtsRead>>>
export type OrgIntegrationAtsReadQueryError = ErrorType<unknown>

export const useOrgIntegrationAtsRead = <TData = Awaited<ReturnType<typeof orgIntegrationAtsRead>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgIntegrationAtsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgIntegrationAtsReadQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgIntegrationAtsRead>>> = ({ signal }) => orgIntegrationAtsRead(signal);


  

  const query = useQuery<Awaited<ReturnType<typeof orgIntegrationAtsRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Create and list orgmember tags
 */
export const orgInterviewerTagsList = (
    params?: OrgInterviewerTagsListParams,
 signal?: AbortSignal
) => {
      return customInstance<OrgInterviewerTagsList200One | OrgInterviewerTagsList200Two>(
      {url: `/org/interviewer/tags/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getOrgInterviewerTagsListQueryKey = (params?: OrgInterviewerTagsListParams,) => [`/org/interviewer/tags/`, ...(params ? [params]: [])];

    
export type OrgInterviewerTagsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof orgInterviewerTagsList>>>
export type OrgInterviewerTagsListInfiniteQueryError = ErrorType<unknown>

export const useOrgInterviewerTagsListInfinite = <TData = Awaited<ReturnType<typeof orgInterviewerTagsList>>, TError = ErrorType<unknown>>(
 params?: OrgInterviewerTagsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof orgInterviewerTagsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgInterviewerTagsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgInterviewerTagsList>>> = ({ signal }) => orgInterviewerTagsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof orgInterviewerTagsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type OrgInterviewerTagsListQueryResult = NonNullable<Awaited<ReturnType<typeof orgInterviewerTagsList>>>
export type OrgInterviewerTagsListQueryError = ErrorType<unknown>

export const useOrgInterviewerTagsList = <TData = Awaited<ReturnType<typeof orgInterviewerTagsList>>, TError = ErrorType<unknown>>(
 params?: OrgInterviewerTagsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgInterviewerTagsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgInterviewerTagsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgInterviewerTagsList>>> = ({ signal }) => orgInterviewerTagsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof orgInterviewerTagsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Create and list orgmember tags
 */
export const orgInterviewerTagsCreate = (
    interviewerTagBody: InterviewerTagBody,
 ) => {
      return customInstance<InterviewerTag>(
      {url: `/org/interviewer/tags/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: interviewerTagBody
    },
      );
    }
  


    export type OrgInterviewerTagsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof orgInterviewerTagsCreate>>>
    export type OrgInterviewerTagsCreateMutationBody = InterviewerTagBody
    export type OrgInterviewerTagsCreateMutationError = ErrorType<unknown>

    export const useOrgInterviewerTagsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgInterviewerTagsCreate>>, TError,{data: InterviewerTagBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgInterviewerTagsCreate>>, {data: InterviewerTagBody}> = (props) => {
          const {data} = props ?? {};

          return  orgInterviewerTagsCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgInterviewerTagsCreate>>, TError, {data: InterviewerTagBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Retrieve, update, and delete orgmember tags
 */
export const orgInterviewerTagsRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<InterviewerTag>(
      {url: `/org/interviewer/tags/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getOrgInterviewerTagsReadQueryKey = (id: string,) => [`/org/interviewer/tags/${id}/`];

    
export type OrgInterviewerTagsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof orgInterviewerTagsRead>>>
export type OrgInterviewerTagsReadInfiniteQueryError = ErrorType<unknown>

export const useOrgInterviewerTagsReadInfinite = <TData = Awaited<ReturnType<typeof orgInterviewerTagsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof orgInterviewerTagsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgInterviewerTagsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgInterviewerTagsRead>>> = ({ signal }) => orgInterviewerTagsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof orgInterviewerTagsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type OrgInterviewerTagsReadQueryResult = NonNullable<Awaited<ReturnType<typeof orgInterviewerTagsRead>>>
export type OrgInterviewerTagsReadQueryError = ErrorType<unknown>

export const useOrgInterviewerTagsRead = <TData = Awaited<ReturnType<typeof orgInterviewerTagsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgInterviewerTagsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgInterviewerTagsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgInterviewerTagsRead>>> = ({ signal }) => orgInterviewerTagsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof orgInterviewerTagsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Retrieve, update, and delete orgmember tags
 */
export const orgInterviewerTagsUpdate = (
    id: string,
    interviewerTagBody: InterviewerTagBody,
 ) => {
      return customInstance<InterviewerTag>(
      {url: `/org/interviewer/tags/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: interviewerTagBody
    },
      );
    }
  


    export type OrgInterviewerTagsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof orgInterviewerTagsUpdate>>>
    export type OrgInterviewerTagsUpdateMutationBody = InterviewerTagBody
    export type OrgInterviewerTagsUpdateMutationError = ErrorType<unknown>

    export const useOrgInterviewerTagsUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgInterviewerTagsUpdate>>, TError,{id: string;data: InterviewerTagBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgInterviewerTagsUpdate>>, {id: string;data: InterviewerTagBody}> = (props) => {
          const {id,data} = props ?? {};

          return  orgInterviewerTagsUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgInterviewerTagsUpdate>>, TError, {id: string;data: InterviewerTagBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Retrieve, update, and delete orgmember tags
 */
export const orgInterviewerTagsPartialUpdate = (
    id: string,
    interviewerTagBody: InterviewerTagBody,
 ) => {
      return customInstance<InterviewerTag>(
      {url: `/org/interviewer/tags/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: interviewerTagBody
    },
      );
    }
  


    export type OrgInterviewerTagsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof orgInterviewerTagsPartialUpdate>>>
    export type OrgInterviewerTagsPartialUpdateMutationBody = InterviewerTagBody
    export type OrgInterviewerTagsPartialUpdateMutationError = ErrorType<unknown>

    export const useOrgInterviewerTagsPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgInterviewerTagsPartialUpdate>>, TError,{id: string;data: InterviewerTagBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgInterviewerTagsPartialUpdate>>, {id: string;data: InterviewerTagBody}> = (props) => {
          const {id,data} = props ?? {};

          return  orgInterviewerTagsPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgInterviewerTagsPartialUpdate>>, TError, {id: string;data: InterviewerTagBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Retrieve, update, and delete orgmember tags
 */
export const orgInterviewerTagsDelete = (
    id: string,
 ) => {
      return customInstance<void>(
      {url: `/org/interviewer/tags/${id}/`, method: 'delete'
    },
      );
    }
  


    export type OrgInterviewerTagsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof orgInterviewerTagsDelete>>>
    
    export type OrgInterviewerTagsDeleteMutationError = ErrorType<unknown>

    export const useOrgInterviewerTagsDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgInterviewerTagsDelete>>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgInterviewerTagsDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  orgInterviewerTagsDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgInterviewerTagsDelete>>, TError, {id: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Retrieve all external ATS users with a specific tag
 */
export const orgInterviewerTagsUsersList = (
    id: string,
    params?: OrgInterviewerTagsUsersListParams,
 signal?: AbortSignal
) => {
      return customInstance<ExternalATSRemoteUserLight[]>(
      {url: `/org/interviewer/tags/${id}/users/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getOrgInterviewerTagsUsersListQueryKey = (id: string,
    params?: OrgInterviewerTagsUsersListParams,) => [`/org/interviewer/tags/${id}/users/`, ...(params ? [params]: [])];

    
export type OrgInterviewerTagsUsersListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof orgInterviewerTagsUsersList>>>
export type OrgInterviewerTagsUsersListInfiniteQueryError = ErrorType<unknown>

export const useOrgInterviewerTagsUsersListInfinite = <TData = Awaited<ReturnType<typeof orgInterviewerTagsUsersList>>, TError = ErrorType<unknown>>(
 id: string,
    params?: OrgInterviewerTagsUsersListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof orgInterviewerTagsUsersList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgInterviewerTagsUsersListQueryKey(id,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgInterviewerTagsUsersList>>> = ({ signal }) => orgInterviewerTagsUsersList(id,params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof orgInterviewerTagsUsersList>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type OrgInterviewerTagsUsersListQueryResult = NonNullable<Awaited<ReturnType<typeof orgInterviewerTagsUsersList>>>
export type OrgInterviewerTagsUsersListQueryError = ErrorType<unknown>

export const useOrgInterviewerTagsUsersList = <TData = Awaited<ReturnType<typeof orgInterviewerTagsUsersList>>, TError = ErrorType<unknown>>(
 id: string,
    params?: OrgInterviewerTagsUsersListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgInterviewerTagsUsersList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgInterviewerTagsUsersListQueryKey(id,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgInterviewerTagsUsersList>>> = ({ signal }) => orgInterviewerTagsUsersList(id,params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof orgInterviewerTagsUsersList>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const orgOutlookRevokeDelete = (
    
 ) => {
      return customInstance<void>(
      {url: `/org/outlook/revoke/`, method: 'delete'
    },
      );
    }
  


    export type OrgOutlookRevokeDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof orgOutlookRevokeDelete>>>
    
    export type OrgOutlookRevokeDeleteMutationError = ErrorType<unknown>

    export const useOrgOutlookRevokeDelete = <TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgOutlookRevokeDelete>>, TError,TVariables, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgOutlookRevokeDelete>>, TVariables> = () => {
          

          return  orgOutlookRevokeDelete()
        }

        

      return useMutation<Awaited<ReturnType<typeof orgOutlookRevokeDelete>>, TError, TVariables, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * API view to retrieve org scheduling calendar
 */
export const orgSchedulingCalendarsList = (
    params?: OrgSchedulingCalendarsListParams,
 signal?: AbortSignal
) => {
      return customInstance<OrgSchedulingCalendarsList200One | OrgSchedulingCalendarsList200Two>(
      {url: `/org/scheduling/calendars/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getOrgSchedulingCalendarsListQueryKey = (params?: OrgSchedulingCalendarsListParams,) => [`/org/scheduling/calendars/`, ...(params ? [params]: [])];

    
export type OrgSchedulingCalendarsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof orgSchedulingCalendarsList>>>
export type OrgSchedulingCalendarsListInfiniteQueryError = ErrorType<unknown>

export const useOrgSchedulingCalendarsListInfinite = <TData = Awaited<ReturnType<typeof orgSchedulingCalendarsList>>, TError = ErrorType<unknown>>(
 params?: OrgSchedulingCalendarsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof orgSchedulingCalendarsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgSchedulingCalendarsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgSchedulingCalendarsList>>> = ({ signal }) => orgSchedulingCalendarsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof orgSchedulingCalendarsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type OrgSchedulingCalendarsListQueryResult = NonNullable<Awaited<ReturnType<typeof orgSchedulingCalendarsList>>>
export type OrgSchedulingCalendarsListQueryError = ErrorType<unknown>

export const useOrgSchedulingCalendarsList = <TData = Awaited<ReturnType<typeof orgSchedulingCalendarsList>>, TError = ErrorType<unknown>>(
 params?: OrgSchedulingCalendarsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgSchedulingCalendarsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgSchedulingCalendarsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgSchedulingCalendarsList>>> = ({ signal }) => orgSchedulingCalendarsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof orgSchedulingCalendarsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * API view to retrieve org scheduling calendar
 */
export const orgSchedulingCalendarsCreate = (
    orgSchedulingCalendar: OrgSchedulingCalendar,
 ) => {
      return customInstance<OrgSchedulingCalendar>(
      {url: `/org/scheduling/calendars/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: orgSchedulingCalendar
    },
      );
    }
  


    export type OrgSchedulingCalendarsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof orgSchedulingCalendarsCreate>>>
    export type OrgSchedulingCalendarsCreateMutationBody = OrgSchedulingCalendar
    export type OrgSchedulingCalendarsCreateMutationError = ErrorType<unknown>

    export const useOrgSchedulingCalendarsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgSchedulingCalendarsCreate>>, TError,{data: OrgSchedulingCalendar}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgSchedulingCalendarsCreate>>, {data: OrgSchedulingCalendar}> = (props) => {
          const {data} = props ?? {};

          return  orgSchedulingCalendarsCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgSchedulingCalendarsCreate>>, TError, {data: OrgSchedulingCalendar}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * API view to retrieve org scheduling calendar
 */
export const orgSchedulingCalendarsRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<OrgSchedulingCalendar>(
      {url: `/org/scheduling/calendars/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getOrgSchedulingCalendarsReadQueryKey = (id: string,) => [`/org/scheduling/calendars/${id}/`];

    
export type OrgSchedulingCalendarsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof orgSchedulingCalendarsRead>>>
export type OrgSchedulingCalendarsReadInfiniteQueryError = ErrorType<unknown>

export const useOrgSchedulingCalendarsReadInfinite = <TData = Awaited<ReturnType<typeof orgSchedulingCalendarsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof orgSchedulingCalendarsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgSchedulingCalendarsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgSchedulingCalendarsRead>>> = ({ signal }) => orgSchedulingCalendarsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof orgSchedulingCalendarsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type OrgSchedulingCalendarsReadQueryResult = NonNullable<Awaited<ReturnType<typeof orgSchedulingCalendarsRead>>>
export type OrgSchedulingCalendarsReadQueryError = ErrorType<unknown>

export const useOrgSchedulingCalendarsRead = <TData = Awaited<ReturnType<typeof orgSchedulingCalendarsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgSchedulingCalendarsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgSchedulingCalendarsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgSchedulingCalendarsRead>>> = ({ signal }) => orgSchedulingCalendarsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof orgSchedulingCalendarsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * API view to retrieve org scheduling calendar
 */
export const orgSchedulingCalendarsDelete = (
    id: string,
 ) => {
      return customInstance<void>(
      {url: `/org/scheduling/calendars/${id}/`, method: 'delete'
    },
      );
    }
  


    export type OrgSchedulingCalendarsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof orgSchedulingCalendarsDelete>>>
    
    export type OrgSchedulingCalendarsDeleteMutationError = ErrorType<unknown>

    export const useOrgSchedulingCalendarsDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgSchedulingCalendarsDelete>>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgSchedulingCalendarsDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  orgSchedulingCalendarsDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgSchedulingCalendarsDelete>>, TError, {id: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * API view to list all external scheduling calendars
 */
export const orgSchedulingExternalCalendarsList = (
    params?: OrgSchedulingExternalCalendarsListParams,
 signal?: AbortSignal
) => {
      return customInstance<OrgSchedulingExternalCalendarsList200One | OrgSchedulingExternalCalendarsList200Two>(
      {url: `/org/scheduling/external-calendars/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getOrgSchedulingExternalCalendarsListQueryKey = (params?: OrgSchedulingExternalCalendarsListParams,) => [`/org/scheduling/external-calendars/`, ...(params ? [params]: [])];

    
export type OrgSchedulingExternalCalendarsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof orgSchedulingExternalCalendarsList>>>
export type OrgSchedulingExternalCalendarsListInfiniteQueryError = ErrorType<unknown>

export const useOrgSchedulingExternalCalendarsListInfinite = <TData = Awaited<ReturnType<typeof orgSchedulingExternalCalendarsList>>, TError = ErrorType<unknown>>(
 params?: OrgSchedulingExternalCalendarsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof orgSchedulingExternalCalendarsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgSchedulingExternalCalendarsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgSchedulingExternalCalendarsList>>> = ({ signal }) => orgSchedulingExternalCalendarsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof orgSchedulingExternalCalendarsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type OrgSchedulingExternalCalendarsListQueryResult = NonNullable<Awaited<ReturnType<typeof orgSchedulingExternalCalendarsList>>>
export type OrgSchedulingExternalCalendarsListQueryError = ErrorType<unknown>

export const useOrgSchedulingExternalCalendarsList = <TData = Awaited<ReturnType<typeof orgSchedulingExternalCalendarsList>>, TError = ErrorType<unknown>>(
 params?: OrgSchedulingExternalCalendarsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgSchedulingExternalCalendarsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgSchedulingExternalCalendarsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgSchedulingExternalCalendarsList>>> = ({ signal }) => orgSchedulingExternalCalendarsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof orgSchedulingExternalCalendarsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const orgSettingsRead = (
    
 signal?: AbortSignal
) => {
      return customInstance<Organization>(
      {url: `/org/settings/`, method: 'get', signal
    },
      );
    }
  

export const getOrgSettingsReadQueryKey = () => [`/org/settings/`];

    
export type OrgSettingsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof orgSettingsRead>>>
export type OrgSettingsReadInfiniteQueryError = ErrorType<unknown>

export const useOrgSettingsReadInfinite = <TData = Awaited<ReturnType<typeof orgSettingsRead>>, TError = ErrorType<unknown>>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof orgSettingsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgSettingsReadQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgSettingsRead>>> = ({ signal }) => orgSettingsRead(signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof orgSettingsRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type OrgSettingsReadQueryResult = NonNullable<Awaited<ReturnType<typeof orgSettingsRead>>>
export type OrgSettingsReadQueryError = ErrorType<unknown>

export const useOrgSettingsRead = <TData = Awaited<ReturnType<typeof orgSettingsRead>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgSettingsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgSettingsReadQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgSettingsRead>>> = ({ signal }) => orgSettingsRead(signal);


  

  const query = useQuery<Awaited<ReturnType<typeof orgSettingsRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const orgSettingsUpdate = (
    organizationBody: OrganizationBody,
 ) => {
      return customInstance<Organization>(
      {url: `/org/settings/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: organizationBody
    },
      );
    }
  


    export type OrgSettingsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof orgSettingsUpdate>>>
    export type OrgSettingsUpdateMutationBody = OrganizationBody
    export type OrgSettingsUpdateMutationError = ErrorType<unknown>

    export const useOrgSettingsUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgSettingsUpdate>>, TError,{data: OrganizationBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgSettingsUpdate>>, {data: OrganizationBody}> = (props) => {
          const {data} = props ?? {};

          return  orgSettingsUpdate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgSettingsUpdate>>, TError, {data: OrganizationBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const orgSettingsPartialUpdate = (
    organizationBody: OrganizationBody,
 ) => {
      return customInstanceNoTransform<Organization>(
      {url: `/org/settings/`, method: 'patch',
      headers: {'Content-Type': 'multipart/form-data', },
      data: organizationBody
    },
      );
    }
  


    export type OrgSettingsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof orgSettingsPartialUpdate>>>
    export type OrgSettingsPartialUpdateMutationBody = OrganizationBody
    export type OrgSettingsPartialUpdateMutationError = ErrorType<unknown>

    export const useOrgSettingsPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgSettingsPartialUpdate>>, TError,{data: OrganizationBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgSettingsPartialUpdate>>, {data: OrganizationBody}> = (props) => {
          const {data} = props ?? {};

          return  orgSettingsPartialUpdate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgSettingsPartialUpdate>>, TError, {data: OrganizationBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Get Slack integration info for an org
 */
export const orgSlackInfoRead = (
    
 signal?: AbortSignal
) => {
      return customInstance<OrgSlackIntegration>(
      {url: `/org/slack/info/`, method: 'get', signal
    },
      );
    }
  

export const getOrgSlackInfoReadQueryKey = () => [`/org/slack/info/`];

    
export type OrgSlackInfoReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof orgSlackInfoRead>>>
export type OrgSlackInfoReadInfiniteQueryError = ErrorType<unknown>

export const useOrgSlackInfoReadInfinite = <TData = Awaited<ReturnType<typeof orgSlackInfoRead>>, TError = ErrorType<unknown>>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof orgSlackInfoRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgSlackInfoReadQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgSlackInfoRead>>> = ({ signal }) => orgSlackInfoRead(signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof orgSlackInfoRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type OrgSlackInfoReadQueryResult = NonNullable<Awaited<ReturnType<typeof orgSlackInfoRead>>>
export type OrgSlackInfoReadQueryError = ErrorType<unknown>

export const useOrgSlackInfoRead = <TData = Awaited<ReturnType<typeof orgSlackInfoRead>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgSlackInfoRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgSlackInfoReadQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgSlackInfoRead>>> = ({ signal }) => orgSlackInfoRead(signal);


  

  const query = useQuery<Awaited<ReturnType<typeof orgSlackInfoRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Integrate an org with Slack
 */
export const orgSlackIntegrateCreate = (
    integrateSlack: IntegrateSlack,
 ) => {
      return customInstance<IntegrateSlack>(
      {url: `/org/slack/integrate/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: integrateSlack
    },
      );
    }
  


    export type OrgSlackIntegrateCreateMutationResult = NonNullable<Awaited<ReturnType<typeof orgSlackIntegrateCreate>>>
    export type OrgSlackIntegrateCreateMutationBody = IntegrateSlack
    export type OrgSlackIntegrateCreateMutationError = ErrorType<unknown>

    export const useOrgSlackIntegrateCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgSlackIntegrateCreate>>, TError,{data: IntegrateSlack}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgSlackIntegrateCreate>>, {data: IntegrateSlack}> = (props) => {
          const {data} = props ?? {};

          return  orgSlackIntegrateCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgSlackIntegrateCreate>>, TError, {data: IntegrateSlack}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Revoke Slack integration for an org
 */
export const orgSlackRevokeCreate = (
    genericAPIBody: GenericAPIBody,
 ) => {
      return customInstance<GenericAPI>(
      {url: `/org/slack/revoke/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: genericAPIBody
    },
      );
    }
  


    export type OrgSlackRevokeCreateMutationResult = NonNullable<Awaited<ReturnType<typeof orgSlackRevokeCreate>>>
    export type OrgSlackRevokeCreateMutationBody = GenericAPIBody
    export type OrgSlackRevokeCreateMutationError = ErrorType<unknown>

    export const useOrgSlackRevokeCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgSlackRevokeCreate>>, TError,{data: GenericAPIBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgSlackRevokeCreate>>, {data: GenericAPIBody}> = (props) => {
          const {data} = props ?? {};

          return  orgSlackRevokeCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgSlackRevokeCreate>>, TError, {data: GenericAPIBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Sync all org members' profile from Slack
- Requires the org to be connected to Slack
- raises:
400 if the org is not connected to Slack with code: no_slack_integration
 */
export const orgSlackSyncMembersCreate = (
    genericAPIBody: GenericAPIBody,
 ) => {
      return customInstance<GenericAPI>(
      {url: `/org/slack/sync-members/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: genericAPIBody
    },
      );
    }
  


    export type OrgSlackSyncMembersCreateMutationResult = NonNullable<Awaited<ReturnType<typeof orgSlackSyncMembersCreate>>>
    export type OrgSlackSyncMembersCreateMutationBody = GenericAPIBody
    export type OrgSlackSyncMembersCreateMutationError = ErrorType<unknown>

    export const useOrgSlackSyncMembersCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgSlackSyncMembersCreate>>, TError,{data: GenericAPIBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgSlackSyncMembersCreate>>, {data: GenericAPIBody}> = (props) => {
          const {data} = props ?? {};

          return  orgSlackSyncMembersCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgSlackSyncMembersCreate>>, TError, {data: GenericAPIBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Update Slack integration for an org
 */
export const orgSlackUpdateUpdate = (
    orgSlackIntegrationUpdateBody: OrgSlackIntegrationUpdateBody,
 ) => {
      return customInstance<OrgSlackIntegrationUpdate>(
      {url: `/org/slack/update/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: orgSlackIntegrationUpdateBody
    },
      );
    }
  


    export type OrgSlackUpdateUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof orgSlackUpdateUpdate>>>
    export type OrgSlackUpdateUpdateMutationBody = OrgSlackIntegrationUpdateBody
    export type OrgSlackUpdateUpdateMutationError = ErrorType<unknown>

    export const useOrgSlackUpdateUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgSlackUpdateUpdate>>, TError,{data: OrgSlackIntegrationUpdateBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgSlackUpdateUpdate>>, {data: OrgSlackIntegrationUpdateBody}> = (props) => {
          const {data} = props ?? {};

          return  orgSlackUpdateUpdate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgSlackUpdateUpdate>>, TError, {data: OrgSlackIntegrationUpdateBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Update Slack integration for an org
 */
export const orgSlackUpdatePartialUpdate = (
    orgSlackIntegrationUpdateBody: OrgSlackIntegrationUpdateBody,
 ) => {
      return customInstance<OrgSlackIntegrationUpdate>(
      {url: `/org/slack/update/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: orgSlackIntegrationUpdateBody
    },
      );
    }
  


    export type OrgSlackUpdatePartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof orgSlackUpdatePartialUpdate>>>
    export type OrgSlackUpdatePartialUpdateMutationBody = OrgSlackIntegrationUpdateBody
    export type OrgSlackUpdatePartialUpdateMutationError = ErrorType<unknown>

    export const useOrgSlackUpdatePartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgSlackUpdatePartialUpdate>>, TError,{data: OrgSlackIntegrationUpdateBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgSlackUpdatePartialUpdate>>, {data: OrgSlackIntegrationUpdateBody}> = (props) => {
          const {data} = props ?? {};

          return  orgSlackUpdatePartialUpdate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgSlackUpdatePartialUpdate>>, TError, {data: OrgSlackIntegrationUpdateBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const orgTeamMeRead = (
    
 signal?: AbortSignal
) => {
      return customInstance<MyOrgMember>(
      {url: `/org/team/me/`, method: 'get', signal
    },
      );
    }
  

export const getOrgTeamMeReadQueryKey = () => [`/org/team/me/`];

    
export type OrgTeamMeReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof orgTeamMeRead>>>
export type OrgTeamMeReadInfiniteQueryError = ErrorType<unknown>

export const useOrgTeamMeReadInfinite = <TData = Awaited<ReturnType<typeof orgTeamMeRead>>, TError = ErrorType<unknown>>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof orgTeamMeRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgTeamMeReadQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgTeamMeRead>>> = ({ signal }) => orgTeamMeRead(signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof orgTeamMeRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type OrgTeamMeReadQueryResult = NonNullable<Awaited<ReturnType<typeof orgTeamMeRead>>>
export type OrgTeamMeReadQueryError = ErrorType<unknown>

export const useOrgTeamMeRead = <TData = Awaited<ReturnType<typeof orgTeamMeRead>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgTeamMeRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgTeamMeReadQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgTeamMeRead>>> = ({ signal }) => orgTeamMeRead(signal);


  

  const query = useQuery<Awaited<ReturnType<typeof orgTeamMeRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const orgTeamMembersList = (
    params?: OrgTeamMembersListParams,
 signal?: AbortSignal
) => {
      return customInstance<OrgTeamMembersList200One | OrgTeamMembersList200Two>(
      {url: `/org/team/members/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getOrgTeamMembersListQueryKey = (params?: OrgTeamMembersListParams,) => [`/org/team/members/`, ...(params ? [params]: [])];

    
export type OrgTeamMembersListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersList>>>
export type OrgTeamMembersListInfiniteQueryError = ErrorType<unknown>

export const useOrgTeamMembersListInfinite = <TData = Awaited<ReturnType<typeof orgTeamMembersList>>, TError = ErrorType<unknown>>(
 params?: OrgTeamMembersListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof orgTeamMembersList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgTeamMembersListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgTeamMembersList>>> = ({ signal }) => orgTeamMembersList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof orgTeamMembersList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type OrgTeamMembersListQueryResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersList>>>
export type OrgTeamMembersListQueryError = ErrorType<unknown>

export const useOrgTeamMembersList = <TData = Awaited<ReturnType<typeof orgTeamMembersList>>, TError = ErrorType<unknown>>(
 params?: OrgTeamMembersListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgTeamMembersList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgTeamMembersListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgTeamMembersList>>> = ({ signal }) => orgTeamMembersList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof orgTeamMembersList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const orgTeamMembersCreate = (
    organizationMemberEditableBody: OrganizationMemberEditableBody,
 ) => {
      return customInstanceNoTransform<OrganizationMemberEditable>(
      {url: `/org/team/members/`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
      data: organizationMemberEditableBody
    },
      );
    }
  


    export type OrgTeamMembersCreateMutationResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersCreate>>>
    export type OrgTeamMembersCreateMutationBody = OrganizationMemberEditableBody
    export type OrgTeamMembersCreateMutationError = ErrorType<unknown>

    export const useOrgTeamMembersCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgTeamMembersCreate>>, TError,{data: OrganizationMemberEditableBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgTeamMembersCreate>>, {data: OrganizationMemberEditableBody}> = (props) => {
          const {data} = props ?? {};

          return  orgTeamMembersCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgTeamMembersCreate>>, TError, {data: OrganizationMemberEditableBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * "
Export interviewer tags for org members (to CSV)
 */
export const orgTeamMembersTagsExportList = (
    params?: OrgTeamMembersTagsExportListParams,
 signal?: AbortSignal
) => {
      return customInstance<OrganizationMemberExportCSV[]>(
      {url: `/org/team/members/tags/export/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getOrgTeamMembersTagsExportListQueryKey = (params?: OrgTeamMembersTagsExportListParams,) => [`/org/team/members/tags/export/`, ...(params ? [params]: [])];

    
export type OrgTeamMembersTagsExportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersTagsExportList>>>
export type OrgTeamMembersTagsExportListInfiniteQueryError = ErrorType<unknown>

export const useOrgTeamMembersTagsExportListInfinite = <TData = Awaited<ReturnType<typeof orgTeamMembersTagsExportList>>, TError = ErrorType<unknown>>(
 params?: OrgTeamMembersTagsExportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof orgTeamMembersTagsExportList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgTeamMembersTagsExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgTeamMembersTagsExportList>>> = ({ signal }) => orgTeamMembersTagsExportList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof orgTeamMembersTagsExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type OrgTeamMembersTagsExportListQueryResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersTagsExportList>>>
export type OrgTeamMembersTagsExportListQueryError = ErrorType<unknown>

export const useOrgTeamMembersTagsExportList = <TData = Awaited<ReturnType<typeof orgTeamMembersTagsExportList>>, TError = ErrorType<unknown>>(
 params?: OrgTeamMembersTagsExportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgTeamMembersTagsExportList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgTeamMembersTagsExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgTeamMembersTagsExportList>>> = ({ signal }) => orgTeamMembersTagsExportList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof orgTeamMembersTagsExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Import interviewer tags for org members from a csv/excel file
 */
export const orgTeamMembersTagsImportCreate = (
    orgTeamMembersTagsImportCreateBody: OrgTeamMembersTagsImportCreateBody,
 ) => {const formData = new FormData();
formData.append('file', orgTeamMembersTagsImportCreateBody.file)

      return customInstanceNoTransform<OrganizationMemberTagsImportCSV>(
      {url: `/org/team/members/tags/import/`, method: 'post',
      headers: {'Content-Type': 'multipart/form-data', },
       data: formData
    },
      );
    }
  


    export type OrgTeamMembersTagsImportCreateMutationResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersTagsImportCreate>>>
    export type OrgTeamMembersTagsImportCreateMutationBody = OrgTeamMembersTagsImportCreateBody
    export type OrgTeamMembersTagsImportCreateMutationError = ErrorType<unknown>

    export const useOrgTeamMembersTagsImportCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgTeamMembersTagsImportCreate>>, TError,{data: OrgTeamMembersTagsImportCreateBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgTeamMembersTagsImportCreate>>, {data: OrgTeamMembersTagsImportCreateBody}> = (props) => {
          const {data} = props ?? {};

          return  orgTeamMembersTagsImportCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgTeamMembersTagsImportCreate>>, TError, {data: OrgTeamMembersTagsImportCreateBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const orgTeamMembersRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<OrganizationMemberEditable>(
      {url: `/org/team/members/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getOrgTeamMembersReadQueryKey = (id: string,) => [`/org/team/members/${id}/`];

    
export type OrgTeamMembersReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersRead>>>
export type OrgTeamMembersReadInfiniteQueryError = ErrorType<unknown>

export const useOrgTeamMembersReadInfinite = <TData = Awaited<ReturnType<typeof orgTeamMembersRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof orgTeamMembersRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgTeamMembersReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgTeamMembersRead>>> = ({ signal }) => orgTeamMembersRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof orgTeamMembersRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type OrgTeamMembersReadQueryResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersRead>>>
export type OrgTeamMembersReadQueryError = ErrorType<unknown>

export const useOrgTeamMembersRead = <TData = Awaited<ReturnType<typeof orgTeamMembersRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgTeamMembersRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgTeamMembersReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgTeamMembersRead>>> = ({ signal }) => orgTeamMembersRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof orgTeamMembersRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const orgTeamMembersUpdate = (
    id: string,
    organizationMemberEditableBody: OrganizationMemberEditableBody,
 ) => {
      return customInstance<OrganizationMemberEditable>(
      {url: `/org/team/members/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: organizationMemberEditableBody
    },
      );
    }
  


    export type OrgTeamMembersUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersUpdate>>>
    export type OrgTeamMembersUpdateMutationBody = OrganizationMemberEditableBody
    export type OrgTeamMembersUpdateMutationError = ErrorType<unknown>

    export const useOrgTeamMembersUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgTeamMembersUpdate>>, TError,{id: string;data: OrganizationMemberEditableBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgTeamMembersUpdate>>, {id: string;data: OrganizationMemberEditableBody}> = (props) => {
          const {id,data} = props ?? {};

          return  orgTeamMembersUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgTeamMembersUpdate>>, TError, {id: string;data: OrganizationMemberEditableBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const orgTeamMembersPartialUpdate = (
    id: string,
    organizationMemberEditableBody: OrganizationMemberEditableBody,
 ) => {
      return customInstanceNoTransform<OrganizationMemberEditable>(
      {url: `/org/team/members/${id}/`, method: 'patch',
      headers: {'Content-Type': 'multipart/form-data', },
      data: organizationMemberEditableBody
    },
      );
    }
  


    export type OrgTeamMembersPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersPartialUpdate>>>
    export type OrgTeamMembersPartialUpdateMutationBody = OrganizationMemberEditableBody
    export type OrgTeamMembersPartialUpdateMutationError = ErrorType<unknown>

    export const useOrgTeamMembersPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgTeamMembersPartialUpdate>>, TError,{id: string;data: OrganizationMemberEditableBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgTeamMembersPartialUpdate>>, {id: string;data: OrganizationMemberEditableBody}> = (props) => {
          const {id,data} = props ?? {};

          return  orgTeamMembersPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgTeamMembersPartialUpdate>>, TError, {id: string;data: OrganizationMemberEditableBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Sync an org member's profile from Slack
- Requires the org to be connected to Slack
- raises:
400 if the org is not connected to Slack with code: no_slack_integration
 */
export const orgTeamMembersSyncSlackProfileCreate = (
    id: string,
    genericAPIBody: GenericAPIBody,
 ) => {
      return customInstance<GenericAPI>(
      {url: `/org/team/members/${id}/sync-slack-profile/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: genericAPIBody
    },
      );
    }
  


    export type OrgTeamMembersSyncSlackProfileCreateMutationResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersSyncSlackProfileCreate>>>
    export type OrgTeamMembersSyncSlackProfileCreateMutationBody = GenericAPIBody
    export type OrgTeamMembersSyncSlackProfileCreateMutationError = ErrorType<unknown>

    export const useOrgTeamMembersSyncSlackProfileCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgTeamMembersSyncSlackProfileCreate>>, TError,{id: string;data: GenericAPIBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgTeamMembersSyncSlackProfileCreate>>, {id: string;data: GenericAPIBody}> = (props) => {
          const {id,data} = props ?? {};

          return  orgTeamMembersSyncSlackProfileCreate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgTeamMembersSyncSlackProfileCreate>>, TError, {id: string;data: GenericAPIBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Sync an org member's profile from Zoom
- Requires the org to be connected to Zoom
 */
export const orgTeamMembersSyncZoomCreate = (
    id: string,
    genericAPIBody: GenericAPIBody,
 ) => {
      return customInstance<GenericAPI>(
      {url: `/org/team/members/${id}/sync-zoom/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: genericAPIBody
    },
      );
    }
  


    export type OrgTeamMembersSyncZoomCreateMutationResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersSyncZoomCreate>>>
    export type OrgTeamMembersSyncZoomCreateMutationBody = GenericAPIBody
    export type OrgTeamMembersSyncZoomCreateMutationError = ErrorType<unknown>

    export const useOrgTeamMembersSyncZoomCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgTeamMembersSyncZoomCreate>>, TError,{id: string;data: GenericAPIBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgTeamMembersSyncZoomCreate>>, {id: string;data: GenericAPIBody}> = (props) => {
          const {id,data} = props ?? {};

          return  orgTeamMembersSyncZoomCreate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgTeamMembersSyncZoomCreate>>, TError, {id: string;data: GenericAPIBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * List and create interviewer tag activities
 */
export const orgTeamMembersTagActivitiesList = (
    memberId: string,
    params?: OrgTeamMembersTagActivitiesListParams,
 signal?: AbortSignal
) => {
      return customInstance<OrgTeamMembersTagActivitiesList200One | OrgTeamMembersTagActivitiesList200Two>(
      {url: `/org/team/members/${memberId}/tag_activities/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getOrgTeamMembersTagActivitiesListQueryKey = (memberId: string,
    params?: OrgTeamMembersTagActivitiesListParams,) => [`/org/team/members/${memberId}/tag_activities/`, ...(params ? [params]: [])];

    
export type OrgTeamMembersTagActivitiesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesList>>>
export type OrgTeamMembersTagActivitiesListInfiniteQueryError = ErrorType<unknown>

export const useOrgTeamMembersTagActivitiesListInfinite = <TData = Awaited<ReturnType<typeof orgTeamMembersTagActivitiesList>>, TError = ErrorType<unknown>>(
 memberId: string,
    params?: OrgTeamMembersTagActivitiesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgTeamMembersTagActivitiesListQueryKey(memberId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesList>>> = ({ signal }) => orgTeamMembersTagActivitiesList(memberId,params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesList>>, TError, TData>(queryKey, queryFn, {enabled: !!(memberId), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type OrgTeamMembersTagActivitiesListQueryResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesList>>>
export type OrgTeamMembersTagActivitiesListQueryError = ErrorType<unknown>

export const useOrgTeamMembersTagActivitiesList = <TData = Awaited<ReturnType<typeof orgTeamMembersTagActivitiesList>>, TError = ErrorType<unknown>>(
 memberId: string,
    params?: OrgTeamMembersTagActivitiesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgTeamMembersTagActivitiesListQueryKey(memberId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesList>>> = ({ signal }) => orgTeamMembersTagActivitiesList(memberId,params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesList>>, TError, TData>(queryKey, queryFn, {enabled: !!(memberId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * List and create interviewer tag activities
 */
export const orgTeamMembersTagActivitiesCreate = (
    memberId: string,
    orgmemberInterviewerTagActivity: OrgmemberInterviewerTagActivity,
 ) => {
      return customInstance<OrgmemberInterviewerTagActivity>(
      {url: `/org/team/members/${memberId}/tag_activities/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: orgmemberInterviewerTagActivity
    },
      );
    }
  


    export type OrgTeamMembersTagActivitiesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesCreate>>>
    export type OrgTeamMembersTagActivitiesCreateMutationBody = OrgmemberInterviewerTagActivity
    export type OrgTeamMembersTagActivitiesCreateMutationError = ErrorType<unknown>

    export const useOrgTeamMembersTagActivitiesCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesCreate>>, TError,{memberId: string;data: OrgmemberInterviewerTagActivity}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesCreate>>, {memberId: string;data: OrgmemberInterviewerTagActivity}> = (props) => {
          const {memberId,data} = props ?? {};

          return  orgTeamMembersTagActivitiesCreate(memberId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesCreate>>, TError, {memberId: string;data: OrgmemberInterviewerTagActivity}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Retrieve, update, and delete interviewer tag activities
 */
export const orgTeamMembersTagActivitiesRead = (
    memberId: string,
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<OrgmemberInterviewerTagActivityUpdate>(
      {url: `/org/team/members/${memberId}/tag_activities/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getOrgTeamMembersTagActivitiesReadQueryKey = (memberId: string,
    id: string,) => [`/org/team/members/${memberId}/tag_activities/${id}/`];

    
export type OrgTeamMembersTagActivitiesReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesRead>>>
export type OrgTeamMembersTagActivitiesReadInfiniteQueryError = ErrorType<unknown>

export const useOrgTeamMembersTagActivitiesReadInfinite = <TData = Awaited<ReturnType<typeof orgTeamMembersTagActivitiesRead>>, TError = ErrorType<unknown>>(
 memberId: string,
    id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgTeamMembersTagActivitiesReadQueryKey(memberId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesRead>>> = ({ signal }) => orgTeamMembersTagActivitiesRead(memberId,id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(memberId && id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type OrgTeamMembersTagActivitiesReadQueryResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesRead>>>
export type OrgTeamMembersTagActivitiesReadQueryError = ErrorType<unknown>

export const useOrgTeamMembersTagActivitiesRead = <TData = Awaited<ReturnType<typeof orgTeamMembersTagActivitiesRead>>, TError = ErrorType<unknown>>(
 memberId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgTeamMembersTagActivitiesReadQueryKey(memberId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesRead>>> = ({ signal }) => orgTeamMembersTagActivitiesRead(memberId,id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(memberId && id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Retrieve, update, and delete interviewer tag activities
 */
export const orgTeamMembersTagActivitiesUpdate = (
    memberId: string,
    id: string,
    orgmemberInterviewerTagActivityUpdateBody: OrgmemberInterviewerTagActivityUpdateBody,
 ) => {
      return customInstance<OrgmemberInterviewerTagActivityUpdate>(
      {url: `/org/team/members/${memberId}/tag_activities/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: orgmemberInterviewerTagActivityUpdateBody
    },
      );
    }
  


    export type OrgTeamMembersTagActivitiesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesUpdate>>>
    export type OrgTeamMembersTagActivitiesUpdateMutationBody = OrgmemberInterviewerTagActivityUpdateBody
    export type OrgTeamMembersTagActivitiesUpdateMutationError = ErrorType<unknown>

    export const useOrgTeamMembersTagActivitiesUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesUpdate>>, TError,{memberId: string;id: string;data: OrgmemberInterviewerTagActivityUpdateBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesUpdate>>, {memberId: string;id: string;data: OrgmemberInterviewerTagActivityUpdateBody}> = (props) => {
          const {memberId,id,data} = props ?? {};

          return  orgTeamMembersTagActivitiesUpdate(memberId,id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesUpdate>>, TError, {memberId: string;id: string;data: OrgmemberInterviewerTagActivityUpdateBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Retrieve, update, and delete interviewer tag activities
 */
export const orgTeamMembersTagActivitiesPartialUpdate = (
    memberId: string,
    id: string,
    orgmemberInterviewerTagActivityUpdateBody: OrgmemberInterviewerTagActivityUpdateBody,
 ) => {
      return customInstance<OrgmemberInterviewerTagActivityUpdate>(
      {url: `/org/team/members/${memberId}/tag_activities/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: orgmemberInterviewerTagActivityUpdateBody
    },
      );
    }
  


    export type OrgTeamMembersTagActivitiesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesPartialUpdate>>>
    export type OrgTeamMembersTagActivitiesPartialUpdateMutationBody = OrgmemberInterviewerTagActivityUpdateBody
    export type OrgTeamMembersTagActivitiesPartialUpdateMutationError = ErrorType<unknown>

    export const useOrgTeamMembersTagActivitiesPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesPartialUpdate>>, TError,{memberId: string;id: string;data: OrgmemberInterviewerTagActivityUpdateBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesPartialUpdate>>, {memberId: string;id: string;data: OrgmemberInterviewerTagActivityUpdateBody}> = (props) => {
          const {memberId,id,data} = props ?? {};

          return  orgTeamMembersTagActivitiesPartialUpdate(memberId,id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesPartialUpdate>>, TError, {memberId: string;id: string;data: OrgmemberInterviewerTagActivityUpdateBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Retrieve, update, and delete interviewer tag activities
 */
export const orgTeamMembersTagActivitiesDelete = (
    memberId: string,
    id: string,
 ) => {
      return customInstance<void>(
      {url: `/org/team/members/${memberId}/tag_activities/${id}/`, method: 'delete'
    },
      );
    }
  


    export type OrgTeamMembersTagActivitiesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesDelete>>>
    
    export type OrgTeamMembersTagActivitiesDeleteMutationError = ErrorType<unknown>

    export const useOrgTeamMembersTagActivitiesDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesDelete>>, TError,{memberId: string;id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesDelete>>, {memberId: string;id: string}> = (props) => {
          const {memberId,id} = props ?? {};

          return  orgTeamMembersTagActivitiesDelete(memberId,id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgTeamMembersTagActivitiesDelete>>, TError, {memberId: string;id: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const orgTeamMembersTagsUpdate = (
    orgMemberId: string,
    tagId: string,
    modifyOrgMemberInterviewerTagBody: ModifyOrgMemberInterviewerTagBody,
 ) => {
      return customInstance<ModifyOrgMemberInterviewerTag>(
      {url: `/org/team/members/${orgMemberId}/tags/${tagId}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: modifyOrgMemberInterviewerTagBody
    },
      );
    }
  


    export type OrgTeamMembersTagsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersTagsUpdate>>>
    export type OrgTeamMembersTagsUpdateMutationBody = ModifyOrgMemberInterviewerTagBody
    export type OrgTeamMembersTagsUpdateMutationError = ErrorType<unknown>

    export const useOrgTeamMembersTagsUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgTeamMembersTagsUpdate>>, TError,{orgMemberId: string;tagId: string;data: ModifyOrgMemberInterviewerTagBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgTeamMembersTagsUpdate>>, {orgMemberId: string;tagId: string;data: ModifyOrgMemberInterviewerTagBody}> = (props) => {
          const {orgMemberId,tagId,data} = props ?? {};

          return  orgTeamMembersTagsUpdate(orgMemberId,tagId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgTeamMembersTagsUpdate>>, TError, {orgMemberId: string;tagId: string;data: ModifyOrgMemberInterviewerTagBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const orgTeamMembersTagsPartialUpdate = (
    orgMemberId: string,
    tagId: string,
    modifyOrgMemberInterviewerTagBody: ModifyOrgMemberInterviewerTagBody,
 ) => {
      return customInstance<ModifyOrgMemberInterviewerTag>(
      {url: `/org/team/members/${orgMemberId}/tags/${tagId}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: modifyOrgMemberInterviewerTagBody
    },
      );
    }
  


    export type OrgTeamMembersTagsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof orgTeamMembersTagsPartialUpdate>>>
    export type OrgTeamMembersTagsPartialUpdateMutationBody = ModifyOrgMemberInterviewerTagBody
    export type OrgTeamMembersTagsPartialUpdateMutationError = ErrorType<unknown>

    export const useOrgTeamMembersTagsPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgTeamMembersTagsPartialUpdate>>, TError,{orgMemberId: string;tagId: string;data: ModifyOrgMemberInterviewerTagBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgTeamMembersTagsPartialUpdate>>, {orgMemberId: string;tagId: string;data: ModifyOrgMemberInterviewerTagBody}> = (props) => {
          const {orgMemberId,tagId,data} = props ?? {};

          return  orgTeamMembersTagsPartialUpdate(orgMemberId,tagId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgTeamMembersTagsPartialUpdate>>, TError, {orgMemberId: string;tagId: string;data: ModifyOrgMemberInterviewerTagBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const orgZoomRevokeDelete = (
    
 ) => {
      return customInstance<void>(
      {url: `/org/zoom/revoke/`, method: 'delete'
    },
      );
    }
  


    export type OrgZoomRevokeDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof orgZoomRevokeDelete>>>
    
    export type OrgZoomRevokeDeleteMutationError = ErrorType<unknown>

    export const useOrgZoomRevokeDelete = <TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgZoomRevokeDelete>>, TError,TVariables, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgZoomRevokeDelete>>, TVariables> = () => {
          

          return  orgZoomRevokeDelete()
        }

        

      return useMutation<Awaited<ReturnType<typeof orgZoomRevokeDelete>>, TError, TVariables, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Sync all org members' zoom
- Requires the org to be connected to Zoom
 */
export const orgZoomSyncMembersCreate = (
    genericAPIBody: GenericAPIBody,
 ) => {
      return customInstance<GenericAPI>(
      {url: `/org/zoom/sync-members/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: genericAPIBody
    },
      );
    }
  


    export type OrgZoomSyncMembersCreateMutationResult = NonNullable<Awaited<ReturnType<typeof orgZoomSyncMembersCreate>>>
    export type OrgZoomSyncMembersCreateMutationBody = GenericAPIBody
    export type OrgZoomSyncMembersCreateMutationError = ErrorType<unknown>

    export const useOrgZoomSyncMembersCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof orgZoomSyncMembersCreate>>, TError,{data: GenericAPIBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof orgZoomSyncMembersCreate>>, {data: GenericAPIBody}> = (props) => {
          const {data} = props ?? {};

          return  orgZoomSyncMembersCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof orgZoomSyncMembersCreate>>, TError, {data: GenericAPIBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Retrieve all zoom users
- Requires the org to be connected to Zoom
 */
export const orgZoomUsersList = (
    params?: OrgZoomUsersListParams,
 signal?: AbortSignal
) => {
      return customInstance<OrgZoomUsersList200One | OrgZoomUsersList200Two>(
      {url: `/org/zoom/users/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getOrgZoomUsersListQueryKey = (params?: OrgZoomUsersListParams,) => [`/org/zoom/users/`, ...(params ? [params]: [])];

    
export type OrgZoomUsersListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof orgZoomUsersList>>>
export type OrgZoomUsersListInfiniteQueryError = ErrorType<unknown>

export const useOrgZoomUsersListInfinite = <TData = Awaited<ReturnType<typeof orgZoomUsersList>>, TError = ErrorType<unknown>>(
 params?: OrgZoomUsersListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof orgZoomUsersList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgZoomUsersListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgZoomUsersList>>> = ({ signal }) => orgZoomUsersList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof orgZoomUsersList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type OrgZoomUsersListQueryResult = NonNullable<Awaited<ReturnType<typeof orgZoomUsersList>>>
export type OrgZoomUsersListQueryError = ErrorType<unknown>

export const useOrgZoomUsersList = <TData = Awaited<ReturnType<typeof orgZoomUsersList>>, TError = ErrorType<unknown>>(
 params?: OrgZoomUsersListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgZoomUsersList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgZoomUsersListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgZoomUsersList>>> = ({ signal }) => orgZoomUsersList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof orgZoomUsersList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Public organization endpoint
 */
export const orgRead = (
    subdomain: string,
 signal?: AbortSignal
) => {
      return customInstance<OrganizationPublic>(
      {url: `/org/${subdomain}/`, method: 'get', signal
    },
      );
    }
  

export const getOrgReadQueryKey = (subdomain: string,) => [`/org/${subdomain}/`];

    
export type OrgReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof orgRead>>>
export type OrgReadInfiniteQueryError = ErrorType<unknown>

export const useOrgReadInfinite = <TData = Awaited<ReturnType<typeof orgRead>>, TError = ErrorType<unknown>>(
 subdomain: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof orgRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgReadQueryKey(subdomain);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgRead>>> = ({ signal }) => orgRead(subdomain, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof orgRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(subdomain), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type OrgReadQueryResult = NonNullable<Awaited<ReturnType<typeof orgRead>>>
export type OrgReadQueryError = ErrorType<unknown>

export const useOrgRead = <TData = Awaited<ReturnType<typeof orgRead>>, TError = ErrorType<unknown>>(
 subdomain: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof orgRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getOrgReadQueryKey(subdomain);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof orgRead>>> = ({ signal }) => orgRead(subdomain, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof orgRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(subdomain), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const portalApplicationsStagesRead = (
    applicationId: string,
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<PortalApplicationStage>(
      {url: `/portal/applications/${applicationId}/stages/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getPortalApplicationsStagesReadQueryKey = (applicationId: string,
    id: number,) => [`/portal/applications/${applicationId}/stages/${id}/`];

    
export type PortalApplicationsStagesReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof portalApplicationsStagesRead>>>
export type PortalApplicationsStagesReadInfiniteQueryError = ErrorType<unknown>

export const usePortalApplicationsStagesReadInfinite = <TData = Awaited<ReturnType<typeof portalApplicationsStagesRead>>, TError = ErrorType<unknown>>(
 applicationId: string,
    id: number, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof portalApplicationsStagesRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPortalApplicationsStagesReadQueryKey(applicationId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof portalApplicationsStagesRead>>> = ({ signal }) => portalApplicationsStagesRead(applicationId,id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof portalApplicationsStagesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(applicationId && id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type PortalApplicationsStagesReadQueryResult = NonNullable<Awaited<ReturnType<typeof portalApplicationsStagesRead>>>
export type PortalApplicationsStagesReadQueryError = ErrorType<unknown>

export const usePortalApplicationsStagesRead = <TData = Awaited<ReturnType<typeof portalApplicationsStagesRead>>, TError = ErrorType<unknown>>(
 applicationId: string,
    id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof portalApplicationsStagesRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPortalApplicationsStagesReadQueryKey(applicationId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof portalApplicationsStagesRead>>> = ({ signal }) => portalApplicationsStagesRead(applicationId,id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof portalApplicationsStagesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(applicationId && id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Candidates api to redeem coupon code
 */
export const portalCouponRedeemCreate = (
    couponCodeRead: CouponCodeRead,
 ) => {
      return customInstance<CouponCodeRead>(
      {url: `/portal/coupon/redeem/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: couponCodeRead
    },
      );
    }
  


    export type PortalCouponRedeemCreateMutationResult = NonNullable<Awaited<ReturnType<typeof portalCouponRedeemCreate>>>
    export type PortalCouponRedeemCreateMutationBody = CouponCodeRead
    export type PortalCouponRedeemCreateMutationError = ErrorType<unknown>

    export const usePortalCouponRedeemCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof portalCouponRedeemCreate>>, TError,{data: CouponCodeRead}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof portalCouponRedeemCreate>>, {data: CouponCodeRead}> = (props) => {
          const {data} = props ?? {};

          return  portalCouponRedeemCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof portalCouponRedeemCreate>>, TError, {data: CouponCodeRead}, TContext>(mutationFn, mutationOptions);
    }
    
export const portalFollowUpList = (
    params?: PortalFollowUpListParams,
 signal?: AbortSignal
) => {
      return customInstance<PortalFollowUpList200One | PortalFollowUpList200Two>(
      {url: `/portal/follow_up/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getPortalFollowUpListQueryKey = (params?: PortalFollowUpListParams,) => [`/portal/follow_up/`, ...(params ? [params]: [])];

    
export type PortalFollowUpListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof portalFollowUpList>>>
export type PortalFollowUpListInfiniteQueryError = ErrorType<unknown>

export const usePortalFollowUpListInfinite = <TData = Awaited<ReturnType<typeof portalFollowUpList>>, TError = ErrorType<unknown>>(
 params?: PortalFollowUpListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof portalFollowUpList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPortalFollowUpListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof portalFollowUpList>>> = ({ signal }) => portalFollowUpList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof portalFollowUpList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type PortalFollowUpListQueryResult = NonNullable<Awaited<ReturnType<typeof portalFollowUpList>>>
export type PortalFollowUpListQueryError = ErrorType<unknown>

export const usePortalFollowUpList = <TData = Awaited<ReturnType<typeof portalFollowUpList>>, TError = ErrorType<unknown>>(
 params?: PortalFollowUpListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof portalFollowUpList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPortalFollowUpListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof portalFollowUpList>>> = ({ signal }) => portalFollowUpList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof portalFollowUpList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const portalFollowUpRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<Followup>(
      {url: `/portal/follow_up/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getPortalFollowUpReadQueryKey = (id: string,) => [`/portal/follow_up/${id}/`];

    
export type PortalFollowUpReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof portalFollowUpRead>>>
export type PortalFollowUpReadInfiniteQueryError = ErrorType<unknown>

export const usePortalFollowUpReadInfinite = <TData = Awaited<ReturnType<typeof portalFollowUpRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof portalFollowUpRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPortalFollowUpReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof portalFollowUpRead>>> = ({ signal }) => portalFollowUpRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof portalFollowUpRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type PortalFollowUpReadQueryResult = NonNullable<Awaited<ReturnType<typeof portalFollowUpRead>>>
export type PortalFollowUpReadQueryError = ErrorType<unknown>

export const usePortalFollowUpRead = <TData = Awaited<ReturnType<typeof portalFollowUpRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof portalFollowUpRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPortalFollowUpReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof portalFollowUpRead>>> = ({ signal }) => portalFollowUpRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof portalFollowUpRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Mark a followup as read
 */
export const portalFollowUpMarkReadCreate = (
    id: string,
    followupMarkRead: FollowupMarkRead,
 ) => {
      return customInstance<FollowupMarkRead>(
      {url: `/portal/follow_up/${id}/mark_read/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: followupMarkRead
    },
      );
    }
  


    export type PortalFollowUpMarkReadCreateMutationResult = NonNullable<Awaited<ReturnType<typeof portalFollowUpMarkReadCreate>>>
    export type PortalFollowUpMarkReadCreateMutationBody = FollowupMarkRead
    export type PortalFollowUpMarkReadCreateMutationError = ErrorType<unknown>

    export const usePortalFollowUpMarkReadCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof portalFollowUpMarkReadCreate>>, TError,{id: string;data: FollowupMarkRead}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof portalFollowUpMarkReadCreate>>, {id: string;data: FollowupMarkRead}> = (props) => {
          const {id,data} = props ?? {};

          return  portalFollowUpMarkReadCreate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof portalFollowUpMarkReadCreate>>, TError, {id: string;data: FollowupMarkRead}, TContext>(mutationFn, mutationOptions);
    }
    
export const portalMeRead = (
    
 signal?: AbortSignal
) => {
      return customInstance<CandidateProfile>(
      {url: `/portal/me/`, method: 'get', signal
    },
      );
    }
  

export const getPortalMeReadQueryKey = () => [`/portal/me/`];

    
export type PortalMeReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof portalMeRead>>>
export type PortalMeReadInfiniteQueryError = ErrorType<unknown>

export const usePortalMeReadInfinite = <TData = Awaited<ReturnType<typeof portalMeRead>>, TError = ErrorType<unknown>>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof portalMeRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPortalMeReadQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof portalMeRead>>> = ({ signal }) => portalMeRead(signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof portalMeRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type PortalMeReadQueryResult = NonNullable<Awaited<ReturnType<typeof portalMeRead>>>
export type PortalMeReadQueryError = ErrorType<unknown>

export const usePortalMeRead = <TData = Awaited<ReturnType<typeof portalMeRead>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof portalMeRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPortalMeReadQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof portalMeRead>>> = ({ signal }) => portalMeRead(signal);


  

  const query = useQuery<Awaited<ReturnType<typeof portalMeRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * override post to wrap in transaction.atomic
this is needed to rollback event creation if
external event creation fails
 */
export const portalSchedulingEventsCreate = (
    portalEvent: PortalEvent,
 ) => {
      return customInstance<PortalEvent>(
      {url: `/portal/scheduling/events/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: portalEvent
    },
      );
    }
  


    export type PortalSchedulingEventsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof portalSchedulingEventsCreate>>>
    export type PortalSchedulingEventsCreateMutationBody = PortalEvent
    export type PortalSchedulingEventsCreateMutationError = ErrorType<unknown>

    export const usePortalSchedulingEventsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof portalSchedulingEventsCreate>>, TError,{data: PortalEvent}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof portalSchedulingEventsCreate>>, {data: PortalEvent}> = (props) => {
          const {data} = props ?? {};

          return  portalSchedulingEventsCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof portalSchedulingEventsCreate>>, TError, {data: PortalEvent}, TContext>(mutationFn, mutationOptions);
    }
    
export const portalSchedulingEventsRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<PortalEvent>(
      {url: `/portal/scheduling/events/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getPortalSchedulingEventsReadQueryKey = (id: string,) => [`/portal/scheduling/events/${id}/`];

    
export type PortalSchedulingEventsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof portalSchedulingEventsRead>>>
export type PortalSchedulingEventsReadInfiniteQueryError = ErrorType<unknown>

export const usePortalSchedulingEventsReadInfinite = <TData = Awaited<ReturnType<typeof portalSchedulingEventsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof portalSchedulingEventsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPortalSchedulingEventsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof portalSchedulingEventsRead>>> = ({ signal }) => portalSchedulingEventsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof portalSchedulingEventsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type PortalSchedulingEventsReadQueryResult = NonNullable<Awaited<ReturnType<typeof portalSchedulingEventsRead>>>
export type PortalSchedulingEventsReadQueryError = ErrorType<unknown>

export const usePortalSchedulingEventsRead = <TData = Awaited<ReturnType<typeof portalSchedulingEventsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof portalSchedulingEventsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPortalSchedulingEventsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof portalSchedulingEventsRead>>> = ({ signal }) => portalSchedulingEventsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof portalSchedulingEventsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Cancel an event
 */
export const portalSchedulingEventsCancelDelete = (
    id: string,
 ) => {
      return customInstance<void>(
      {url: `/portal/scheduling/events/${id}/cancel/`, method: 'delete'
    },
      );
    }
  


    export type PortalSchedulingEventsCancelDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof portalSchedulingEventsCancelDelete>>>
    
    export type PortalSchedulingEventsCancelDeleteMutationError = ErrorType<unknown>

    export const usePortalSchedulingEventsCancelDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof portalSchedulingEventsCancelDelete>>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof portalSchedulingEventsCancelDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  portalSchedulingEventsCancelDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof portalSchedulingEventsCancelDelete>>, TError, {id: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Reschedule an event
 */
export const portalSchedulingEventsRescheduleUpdate = (
    id: string,
    eventLimitedWriteBody: EventLimitedWriteBody,
 ) => {
      return customInstance<EventLimitedWrite>(
      {url: `/portal/scheduling/events/${id}/reschedule/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: eventLimitedWriteBody
    },
      );
    }
  


    export type PortalSchedulingEventsRescheduleUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof portalSchedulingEventsRescheduleUpdate>>>
    export type PortalSchedulingEventsRescheduleUpdateMutationBody = EventLimitedWriteBody
    export type PortalSchedulingEventsRescheduleUpdateMutationError = ErrorType<unknown>

    export const usePortalSchedulingEventsRescheduleUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof portalSchedulingEventsRescheduleUpdate>>, TError,{id: string;data: EventLimitedWriteBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof portalSchedulingEventsRescheduleUpdate>>, {id: string;data: EventLimitedWriteBody}> = (props) => {
          const {id,data} = props ?? {};

          return  portalSchedulingEventsRescheduleUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof portalSchedulingEventsRescheduleUpdate>>, TError, {id: string;data: EventLimitedWriteBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Reschedule an event
 */
export const portalSchedulingEventsReschedulePartialUpdate = (
    id: string,
    eventLimitedWriteBody: EventLimitedWriteBody,
 ) => {
      return customInstance<EventLimitedWrite>(
      {url: `/portal/scheduling/events/${id}/reschedule/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: eventLimitedWriteBody
    },
      );
    }
  


    export type PortalSchedulingEventsReschedulePartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof portalSchedulingEventsReschedulePartialUpdate>>>
    export type PortalSchedulingEventsReschedulePartialUpdateMutationBody = EventLimitedWriteBody
    export type PortalSchedulingEventsReschedulePartialUpdateMutationError = ErrorType<unknown>

    export const usePortalSchedulingEventsReschedulePartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof portalSchedulingEventsReschedulePartialUpdate>>, TError,{id: string;data: EventLimitedWriteBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof portalSchedulingEventsReschedulePartialUpdate>>, {id: string;data: EventLimitedWriteBody}> = (props) => {
          const {id,data} = props ?? {};

          return  portalSchedulingEventsReschedulePartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof portalSchedulingEventsReschedulePartialUpdate>>, TError, {id: string;data: EventLimitedWriteBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Portal API endpoint for retrieving an OrganizationMember
 */
export const portalSchedulingRecruiterJobAvailabilityRead = (
    ownerSlug: string,
    jobPubId: string,
    params?: PortalSchedulingRecruiterJobAvailabilityReadParams,
 signal?: AbortSignal
) => {
      return customInstance<PortalRecruiterAvailability>(
      {url: `/portal/scheduling/recruiter/${ownerSlug}/job/${jobPubId}/availability/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getPortalSchedulingRecruiterJobAvailabilityReadQueryKey = (ownerSlug: string,
    jobPubId: string,
    params?: PortalSchedulingRecruiterJobAvailabilityReadParams,) => [`/portal/scheduling/recruiter/${ownerSlug}/job/${jobPubId}/availability/`, ...(params ? [params]: [])];

    
export type PortalSchedulingRecruiterJobAvailabilityReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof portalSchedulingRecruiterJobAvailabilityRead>>>
export type PortalSchedulingRecruiterJobAvailabilityReadInfiniteQueryError = ErrorType<unknown>

export const usePortalSchedulingRecruiterJobAvailabilityReadInfinite = <TData = Awaited<ReturnType<typeof portalSchedulingRecruiterJobAvailabilityRead>>, TError = ErrorType<unknown>>(
 ownerSlug: string,
    jobPubId: string,
    params?: PortalSchedulingRecruiterJobAvailabilityReadParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof portalSchedulingRecruiterJobAvailabilityRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPortalSchedulingRecruiterJobAvailabilityReadQueryKey(ownerSlug,jobPubId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof portalSchedulingRecruiterJobAvailabilityRead>>> = ({ signal }) => portalSchedulingRecruiterJobAvailabilityRead(ownerSlug,jobPubId,params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof portalSchedulingRecruiterJobAvailabilityRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(ownerSlug && jobPubId), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type PortalSchedulingRecruiterJobAvailabilityReadQueryResult = NonNullable<Awaited<ReturnType<typeof portalSchedulingRecruiterJobAvailabilityRead>>>
export type PortalSchedulingRecruiterJobAvailabilityReadQueryError = ErrorType<unknown>

export const usePortalSchedulingRecruiterJobAvailabilityRead = <TData = Awaited<ReturnType<typeof portalSchedulingRecruiterJobAvailabilityRead>>, TError = ErrorType<unknown>>(
 ownerSlug: string,
    jobPubId: string,
    params?: PortalSchedulingRecruiterJobAvailabilityReadParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof portalSchedulingRecruiterJobAvailabilityRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPortalSchedulingRecruiterJobAvailabilityReadQueryKey(ownerSlug,jobPubId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof portalSchedulingRecruiterJobAvailabilityRead>>> = ({ signal }) => portalSchedulingRecruiterJobAvailabilityRead(ownerSlug,jobPubId,params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof portalSchedulingRecruiterJobAvailabilityRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(ownerSlug && jobPubId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const portalSchedulingRecruitingEventsRead = (
    pubId: string,
 signal?: AbortSignal
) => {
      return customInstance<PortalRecruitingEvent>(
      {url: `/portal/scheduling/recruiting/events/${pubId}/`, method: 'get', signal
    },
      );
    }
  

export const getPortalSchedulingRecruitingEventsReadQueryKey = (pubId: string,) => [`/portal/scheduling/recruiting/events/${pubId}/`];

    
export type PortalSchedulingRecruitingEventsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof portalSchedulingRecruitingEventsRead>>>
export type PortalSchedulingRecruitingEventsReadInfiniteQueryError = ErrorType<unknown>

export const usePortalSchedulingRecruitingEventsReadInfinite = <TData = Awaited<ReturnType<typeof portalSchedulingRecruitingEventsRead>>, TError = ErrorType<unknown>>(
 pubId: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof portalSchedulingRecruitingEventsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPortalSchedulingRecruitingEventsReadQueryKey(pubId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof portalSchedulingRecruitingEventsRead>>> = ({ signal }) => portalSchedulingRecruitingEventsRead(pubId, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof portalSchedulingRecruitingEventsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(pubId), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type PortalSchedulingRecruitingEventsReadQueryResult = NonNullable<Awaited<ReturnType<typeof portalSchedulingRecruitingEventsRead>>>
export type PortalSchedulingRecruitingEventsReadQueryError = ErrorType<unknown>

export const usePortalSchedulingRecruitingEventsRead = <TData = Awaited<ReturnType<typeof portalSchedulingRecruitingEventsRead>>, TError = ErrorType<unknown>>(
 pubId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof portalSchedulingRecruitingEventsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPortalSchedulingRecruitingEventsReadQueryKey(pubId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof portalSchedulingRecruitingEventsRead>>> = ({ signal }) => portalSchedulingRecruitingEventsRead(pubId, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof portalSchedulingRecruitingEventsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(pubId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const portalSchedulingRecruitingEventsCheckinCreate = (
    pubId: string,
    portalRecruitingEventCheckin: PortalRecruitingEventCheckin,
 ) => {
      return customInstance<ExternalATSApplication>(
      {url: `/portal/scheduling/recruiting/events/${pubId}/checkin/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: portalRecruitingEventCheckin
    },
      );
    }
  


    export type PortalSchedulingRecruitingEventsCheckinCreateMutationResult = NonNullable<Awaited<ReturnType<typeof portalSchedulingRecruitingEventsCheckinCreate>>>
    export type PortalSchedulingRecruitingEventsCheckinCreateMutationBody = PortalRecruitingEventCheckin
    export type PortalSchedulingRecruitingEventsCheckinCreateMutationError = ErrorType<unknown>

    export const usePortalSchedulingRecruitingEventsCheckinCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof portalSchedulingRecruitingEventsCheckinCreate>>, TError,{pubId: string;data: PortalRecruitingEventCheckin}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof portalSchedulingRecruitingEventsCheckinCreate>>, {pubId: string;data: PortalRecruitingEventCheckin}> = (props) => {
          const {pubId,data} = props ?? {};

          return  portalSchedulingRecruitingEventsCheckinCreate(pubId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof portalSchedulingRecruitingEventsCheckinCreate>>, TError, {pubId: string;data: PortalRecruitingEventCheckin}, TContext>(mutationFn, mutationOptions);
    }
    
export const portalSchedulingRequestsAvailabilitySuggestionsRead = (
    pubId: string,
 signal?: AbortSignal
) => {
      return customInstance<AvailabilitySuggestion[]>(
      {url: `/portal/scheduling/requests/${pubId}/availability-suggestions/`, method: 'get', signal
    },
      );
    }
  

export const getPortalSchedulingRequestsAvailabilitySuggestionsReadQueryKey = (pubId: string,) => [`/portal/scheduling/requests/${pubId}/availability-suggestions/`];

    
export type PortalSchedulingRequestsAvailabilitySuggestionsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilitySuggestionsRead>>>
export type PortalSchedulingRequestsAvailabilitySuggestionsReadInfiniteQueryError = ErrorType<unknown>

export const usePortalSchedulingRequestsAvailabilitySuggestionsReadInfinite = <TData = Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilitySuggestionsRead>>, TError = ErrorType<unknown>>(
 pubId: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilitySuggestionsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPortalSchedulingRequestsAvailabilitySuggestionsReadQueryKey(pubId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilitySuggestionsRead>>> = ({ signal }) => portalSchedulingRequestsAvailabilitySuggestionsRead(pubId, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilitySuggestionsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(pubId), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type PortalSchedulingRequestsAvailabilitySuggestionsReadQueryResult = NonNullable<Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilitySuggestionsRead>>>
export type PortalSchedulingRequestsAvailabilitySuggestionsReadQueryError = ErrorType<unknown>

export const usePortalSchedulingRequestsAvailabilitySuggestionsRead = <TData = Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilitySuggestionsRead>>, TError = ErrorType<unknown>>(
 pubId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilitySuggestionsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPortalSchedulingRequestsAvailabilitySuggestionsReadQueryKey(pubId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilitySuggestionsRead>>> = ({ signal }) => portalSchedulingRequestsAvailabilitySuggestionsRead(pubId, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilitySuggestionsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(pubId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const portalSchedulingRequestsAvailabilityRead = (
    pubId: string,
 signal?: AbortSignal
) => {
      return customInstance<PortalSchedulingRequestAvailability>(
      {url: `/portal/scheduling/requests/${pubId}/availability/`, method: 'get', signal
    },
      );
    }
  

export const getPortalSchedulingRequestsAvailabilityReadQueryKey = (pubId: string,) => [`/portal/scheduling/requests/${pubId}/availability/`];

    
export type PortalSchedulingRequestsAvailabilityReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilityRead>>>
export type PortalSchedulingRequestsAvailabilityReadInfiniteQueryError = ErrorType<unknown>

export const usePortalSchedulingRequestsAvailabilityReadInfinite = <TData = Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilityRead>>, TError = ErrorType<unknown>>(
 pubId: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilityRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPortalSchedulingRequestsAvailabilityReadQueryKey(pubId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilityRead>>> = ({ signal }) => portalSchedulingRequestsAvailabilityRead(pubId, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilityRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(pubId), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type PortalSchedulingRequestsAvailabilityReadQueryResult = NonNullable<Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilityRead>>>
export type PortalSchedulingRequestsAvailabilityReadQueryError = ErrorType<unknown>

export const usePortalSchedulingRequestsAvailabilityRead = <TData = Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilityRead>>, TError = ErrorType<unknown>>(
 pubId: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilityRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getPortalSchedulingRequestsAvailabilityReadQueryKey(pubId);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilityRead>>> = ({ signal }) => portalSchedulingRequestsAvailabilityRead(pubId, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof portalSchedulingRequestsAvailabilityRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(pubId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const portalSchedulingRequestsSubmitUpdate = (
    pubId: string,
    submitSchedulingRequestBody: SubmitSchedulingRequestBody,
 ) => {
      return customInstance<SubmitSchedulingRequest>(
      {url: `/portal/scheduling/requests/${pubId}/submit/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: submitSchedulingRequestBody
    },
      );
    }
  


    export type PortalSchedulingRequestsSubmitUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof portalSchedulingRequestsSubmitUpdate>>>
    export type PortalSchedulingRequestsSubmitUpdateMutationBody = SubmitSchedulingRequestBody
    export type PortalSchedulingRequestsSubmitUpdateMutationError = ErrorType<unknown>

    export const usePortalSchedulingRequestsSubmitUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof portalSchedulingRequestsSubmitUpdate>>, TError,{pubId: string;data: SubmitSchedulingRequestBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof portalSchedulingRequestsSubmitUpdate>>, {pubId: string;data: SubmitSchedulingRequestBody}> = (props) => {
          const {pubId,data} = props ?? {};

          return  portalSchedulingRequestsSubmitUpdate(pubId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof portalSchedulingRequestsSubmitUpdate>>, TError, {pubId: string;data: SubmitSchedulingRequestBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const portalSchedulingRequestsSubmitPartialUpdate = (
    pubId: string,
    submitSchedulingRequestBody: SubmitSchedulingRequestBody,
 ) => {
      return customInstance<SubmitSchedulingRequest>(
      {url: `/portal/scheduling/requests/${pubId}/submit/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: submitSchedulingRequestBody
    },
      );
    }
  


    export type PortalSchedulingRequestsSubmitPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof portalSchedulingRequestsSubmitPartialUpdate>>>
    export type PortalSchedulingRequestsSubmitPartialUpdateMutationBody = SubmitSchedulingRequestBody
    export type PortalSchedulingRequestsSubmitPartialUpdateMutationError = ErrorType<unknown>

    export const usePortalSchedulingRequestsSubmitPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof portalSchedulingRequestsSubmitPartialUpdate>>, TError,{pubId: string;data: SubmitSchedulingRequestBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof portalSchedulingRequestsSubmitPartialUpdate>>, {pubId: string;data: SubmitSchedulingRequestBody}> = (props) => {
          const {pubId,data} = props ?? {};

          return  portalSchedulingRequestsSubmitPartialUpdate(pubId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof portalSchedulingRequestsSubmitPartialUpdate>>, TError, {pubId: string;data: SubmitSchedulingRequestBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const portalSchedulingRoundsQuickCreateCreate = (
    pubId: string,
    portalSchedulingRequestQuickCreate: PortalSchedulingRequestQuickCreate,
 ) => {
      return customInstance<PortalSchedulingRequestModel>(
      {url: `/portal/scheduling/rounds/${pubId}/quick_create/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: portalSchedulingRequestQuickCreate
    },
      );
    }
  


    export type PortalSchedulingRoundsQuickCreateCreateMutationResult = NonNullable<Awaited<ReturnType<typeof portalSchedulingRoundsQuickCreateCreate>>>
    export type PortalSchedulingRoundsQuickCreateCreateMutationBody = PortalSchedulingRequestQuickCreate
    export type PortalSchedulingRoundsQuickCreateCreateMutationError = ErrorType<unknown>

    export const usePortalSchedulingRoundsQuickCreateCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof portalSchedulingRoundsQuickCreateCreate>>, TError,{pubId: string;data: PortalSchedulingRequestQuickCreate}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof portalSchedulingRoundsQuickCreateCreate>>, {pubId: string;data: PortalSchedulingRequestQuickCreate}> = (props) => {
          const {pubId,data} = props ?? {};

          return  portalSchedulingRoundsQuickCreateCreate(pubId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof portalSchedulingRoundsQuickCreateCreate>>, TError, {pubId: string;data: PortalSchedulingRequestQuickCreate}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingAnalyticsDeclinesInterviewersList = (
    params?: SchedulingAnalyticsDeclinesInterviewersListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingAnalyticsDeclinesInterviewersList200One | SchedulingAnalyticsDeclinesInterviewersList200Two>(
      {url: `/scheduling/analytics/declines/interviewers/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingAnalyticsDeclinesInterviewersListQueryKey = (params?: SchedulingAnalyticsDeclinesInterviewersListParams,) => [`/scheduling/analytics/declines/interviewers/`, ...(params ? [params]: [])];

    
export type SchedulingAnalyticsDeclinesInterviewersListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersList>>>
export type SchedulingAnalyticsDeclinesInterviewersListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsDeclinesInterviewersListInfinite = <TData = Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsDeclinesInterviewersListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsDeclinesInterviewersListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersList>>> = ({ signal }) => schedulingAnalyticsDeclinesInterviewersList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingAnalyticsDeclinesInterviewersListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersList>>>
export type SchedulingAnalyticsDeclinesInterviewersListQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsDeclinesInterviewersList = <TData = Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsDeclinesInterviewersListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsDeclinesInterviewersListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersList>>> = ({ signal }) => schedulingAnalyticsDeclinesInterviewersList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingAnalyticsDeclinesInterviewersExportList = (
    params?: SchedulingAnalyticsDeclinesInterviewersExportListParams,
 signal?: AbortSignal
) => {
      return customInstance<InterviewerDeclineReport[]>(
      {url: `/scheduling/analytics/declines/interviewers/export`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingAnalyticsDeclinesInterviewersExportListQueryKey = (params?: SchedulingAnalyticsDeclinesInterviewersExportListParams,) => [`/scheduling/analytics/declines/interviewers/export`, ...(params ? [params]: [])];

    
export type SchedulingAnalyticsDeclinesInterviewersExportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersExportList>>>
export type SchedulingAnalyticsDeclinesInterviewersExportListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsDeclinesInterviewersExportListInfinite = <TData = Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsDeclinesInterviewersExportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersExportList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsDeclinesInterviewersExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersExportList>>> = ({ signal }) => schedulingAnalyticsDeclinesInterviewersExportList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingAnalyticsDeclinesInterviewersExportListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersExportList>>>
export type SchedulingAnalyticsDeclinesInterviewersExportListQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsDeclinesInterviewersExportList = <TData = Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsDeclinesInterviewersExportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersExportList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsDeclinesInterviewersExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersExportList>>> = ({ signal }) => schedulingAnalyticsDeclinesInterviewersExportList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewersExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingAnalyticsDeclinesInterviewtemplatesList = (
    params?: SchedulingAnalyticsDeclinesInterviewtemplatesListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingAnalyticsDeclinesInterviewtemplatesList200One | SchedulingAnalyticsDeclinesInterviewtemplatesList200Two>(
      {url: `/scheduling/analytics/declines/interviewtemplates/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingAnalyticsDeclinesInterviewtemplatesListQueryKey = (params?: SchedulingAnalyticsDeclinesInterviewtemplatesListParams,) => [`/scheduling/analytics/declines/interviewtemplates/`, ...(params ? [params]: [])];

    
export type SchedulingAnalyticsDeclinesInterviewtemplatesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesList>>>
export type SchedulingAnalyticsDeclinesInterviewtemplatesListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsDeclinesInterviewtemplatesListInfinite = <TData = Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsDeclinesInterviewtemplatesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsDeclinesInterviewtemplatesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesList>>> = ({ signal }) => schedulingAnalyticsDeclinesInterviewtemplatesList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingAnalyticsDeclinesInterviewtemplatesListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesList>>>
export type SchedulingAnalyticsDeclinesInterviewtemplatesListQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsDeclinesInterviewtemplatesList = <TData = Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsDeclinesInterviewtemplatesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsDeclinesInterviewtemplatesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesList>>> = ({ signal }) => schedulingAnalyticsDeclinesInterviewtemplatesList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingAnalyticsDeclinesInterviewtemplatesExportList = (
    params?: SchedulingAnalyticsDeclinesInterviewtemplatesExportListParams,
 signal?: AbortSignal
) => {
      return customInstance<InterviewTemplateDeclinesAnalytics[]>(
      {url: `/scheduling/analytics/declines/interviewtemplates/export`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingAnalyticsDeclinesInterviewtemplatesExportListQueryKey = (params?: SchedulingAnalyticsDeclinesInterviewtemplatesExportListParams,) => [`/scheduling/analytics/declines/interviewtemplates/export`, ...(params ? [params]: [])];

    
export type SchedulingAnalyticsDeclinesInterviewtemplatesExportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesExportList>>>
export type SchedulingAnalyticsDeclinesInterviewtemplatesExportListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsDeclinesInterviewtemplatesExportListInfinite = <TData = Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsDeclinesInterviewtemplatesExportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesExportList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsDeclinesInterviewtemplatesExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesExportList>>> = ({ signal }) => schedulingAnalyticsDeclinesInterviewtemplatesExportList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingAnalyticsDeclinesInterviewtemplatesExportListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesExportList>>>
export type SchedulingAnalyticsDeclinesInterviewtemplatesExportListQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsDeclinesInterviewtemplatesExportList = <TData = Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsDeclinesInterviewtemplatesExportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesExportList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsDeclinesInterviewtemplatesExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesExportList>>> = ({ signal }) => schedulingAnalyticsDeclinesInterviewtemplatesExportList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingAnalyticsDeclinesInterviewtemplatesExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingAnalyticsEventsActivitiesList = (
    params?: SchedulingAnalyticsEventsActivitiesListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingAnalyticsEventsActivitiesList200One | SchedulingAnalyticsEventsActivitiesList200Two>(
      {url: `/scheduling/analytics/events/activities/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingAnalyticsEventsActivitiesListQueryKey = (params?: SchedulingAnalyticsEventsActivitiesListParams,) => [`/scheduling/analytics/events/activities/`, ...(params ? [params]: [])];

    
export type SchedulingAnalyticsEventsActivitiesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsEventsActivitiesList>>>
export type SchedulingAnalyticsEventsActivitiesListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsEventsActivitiesListInfinite = <TData = Awaited<ReturnType<typeof schedulingAnalyticsEventsActivitiesList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsEventsActivitiesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsEventsActivitiesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsEventsActivitiesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsEventsActivitiesList>>> = ({ signal }) => schedulingAnalyticsEventsActivitiesList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingAnalyticsEventsActivitiesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingAnalyticsEventsActivitiesListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsEventsActivitiesList>>>
export type SchedulingAnalyticsEventsActivitiesListQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsEventsActivitiesList = <TData = Awaited<ReturnType<typeof schedulingAnalyticsEventsActivitiesList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsEventsActivitiesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsEventsActivitiesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsEventsActivitiesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsEventsActivitiesList>>> = ({ signal }) => schedulingAnalyticsEventsActivitiesList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingAnalyticsEventsActivitiesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingAnalyticsReschedulesDepartmentsList = (
    params?: SchedulingAnalyticsReschedulesDepartmentsListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingAnalyticsReschedulesDepartmentsList200One | SchedulingAnalyticsReschedulesDepartmentsList200Two>(
      {url: `/scheduling/analytics/reschedules/departments/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingAnalyticsReschedulesDepartmentsListQueryKey = (params?: SchedulingAnalyticsReschedulesDepartmentsListParams,) => [`/scheduling/analytics/reschedules/departments/`, ...(params ? [params]: [])];

    
export type SchedulingAnalyticsReschedulesDepartmentsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsList>>>
export type SchedulingAnalyticsReschedulesDepartmentsListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsReschedulesDepartmentsListInfinite = <TData = Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsReschedulesDepartmentsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsReschedulesDepartmentsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsList>>> = ({ signal }) => schedulingAnalyticsReschedulesDepartmentsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingAnalyticsReschedulesDepartmentsListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsList>>>
export type SchedulingAnalyticsReschedulesDepartmentsListQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsReschedulesDepartmentsList = <TData = Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsReschedulesDepartmentsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsReschedulesDepartmentsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsList>>> = ({ signal }) => schedulingAnalyticsReschedulesDepartmentsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingAnalyticsReschedulesDepartmentsExportList = (
    params?: SchedulingAnalyticsReschedulesDepartmentsExportListParams,
 signal?: AbortSignal
) => {
      return customInstance<EventDepartmentReschedulesAnalytics[]>(
      {url: `/scheduling/analytics/reschedules/departments/export/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingAnalyticsReschedulesDepartmentsExportListQueryKey = (params?: SchedulingAnalyticsReschedulesDepartmentsExportListParams,) => [`/scheduling/analytics/reschedules/departments/export/`, ...(params ? [params]: [])];

    
export type SchedulingAnalyticsReschedulesDepartmentsExportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsExportList>>>
export type SchedulingAnalyticsReschedulesDepartmentsExportListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsReschedulesDepartmentsExportListInfinite = <TData = Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsReschedulesDepartmentsExportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsExportList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsReschedulesDepartmentsExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsExportList>>> = ({ signal }) => schedulingAnalyticsReschedulesDepartmentsExportList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingAnalyticsReschedulesDepartmentsExportListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsExportList>>>
export type SchedulingAnalyticsReschedulesDepartmentsExportListQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsReschedulesDepartmentsExportList = <TData = Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsReschedulesDepartmentsExportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsExportList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsReschedulesDepartmentsExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsExportList>>> = ({ signal }) => schedulingAnalyticsReschedulesDepartmentsExportList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesDepartmentsExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingAnalyticsReschedulesInterviewTemplatesList = (
    params?: SchedulingAnalyticsReschedulesInterviewTemplatesListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingAnalyticsReschedulesInterviewTemplatesList200One | SchedulingAnalyticsReschedulesInterviewTemplatesList200Two>(
      {url: `/scheduling/analytics/reschedules/interview-templates/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingAnalyticsReschedulesInterviewTemplatesListQueryKey = (params?: SchedulingAnalyticsReschedulesInterviewTemplatesListParams,) => [`/scheduling/analytics/reschedules/interview-templates/`, ...(params ? [params]: [])];

    
export type SchedulingAnalyticsReschedulesInterviewTemplatesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesList>>>
export type SchedulingAnalyticsReschedulesInterviewTemplatesListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsReschedulesInterviewTemplatesListInfinite = <TData = Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsReschedulesInterviewTemplatesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsReschedulesInterviewTemplatesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesList>>> = ({ signal }) => schedulingAnalyticsReschedulesInterviewTemplatesList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingAnalyticsReschedulesInterviewTemplatesListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesList>>>
export type SchedulingAnalyticsReschedulesInterviewTemplatesListQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsReschedulesInterviewTemplatesList = <TData = Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsReschedulesInterviewTemplatesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsReschedulesInterviewTemplatesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesList>>> = ({ signal }) => schedulingAnalyticsReschedulesInterviewTemplatesList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingAnalyticsReschedulesInterviewTemplatesExportList = (
    params?: SchedulingAnalyticsReschedulesInterviewTemplatesExportListParams,
 signal?: AbortSignal
) => {
      return customInstance<InterviewTemplateReschedulesAnalytics[]>(
      {url: `/scheduling/analytics/reschedules/interview-templates/export/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingAnalyticsReschedulesInterviewTemplatesExportListQueryKey = (params?: SchedulingAnalyticsReschedulesInterviewTemplatesExportListParams,) => [`/scheduling/analytics/reschedules/interview-templates/export/`, ...(params ? [params]: [])];

    
export type SchedulingAnalyticsReschedulesInterviewTemplatesExportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesExportList>>>
export type SchedulingAnalyticsReschedulesInterviewTemplatesExportListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsReschedulesInterviewTemplatesExportListInfinite = <TData = Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsReschedulesInterviewTemplatesExportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesExportList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsReschedulesInterviewTemplatesExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesExportList>>> = ({ signal }) => schedulingAnalyticsReschedulesInterviewTemplatesExportList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingAnalyticsReschedulesInterviewTemplatesExportListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesExportList>>>
export type SchedulingAnalyticsReschedulesInterviewTemplatesExportListQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsReschedulesInterviewTemplatesExportList = <TData = Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsReschedulesInterviewTemplatesExportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesExportList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsReschedulesInterviewTemplatesExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesExportList>>> = ({ signal }) => schedulingAnalyticsReschedulesInterviewTemplatesExportList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesInterviewTemplatesExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingAnalyticsReschedulesJobsList = (
    params?: SchedulingAnalyticsReschedulesJobsListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingAnalyticsReschedulesJobsList200One | SchedulingAnalyticsReschedulesJobsList200Two>(
      {url: `/scheduling/analytics/reschedules/jobs/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingAnalyticsReschedulesJobsListQueryKey = (params?: SchedulingAnalyticsReschedulesJobsListParams,) => [`/scheduling/analytics/reschedules/jobs/`, ...(params ? [params]: [])];

    
export type SchedulingAnalyticsReschedulesJobsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsList>>>
export type SchedulingAnalyticsReschedulesJobsListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsReschedulesJobsListInfinite = <TData = Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsReschedulesJobsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsReschedulesJobsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsList>>> = ({ signal }) => schedulingAnalyticsReschedulesJobsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingAnalyticsReschedulesJobsListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsList>>>
export type SchedulingAnalyticsReschedulesJobsListQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsReschedulesJobsList = <TData = Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsReschedulesJobsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsReschedulesJobsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsList>>> = ({ signal }) => schedulingAnalyticsReschedulesJobsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingAnalyticsReschedulesJobsExportList = (
    params?: SchedulingAnalyticsReschedulesJobsExportListParams,
 signal?: AbortSignal
) => {
      return customInstance<ExternalATSJobReschedulesAnalytics[]>(
      {url: `/scheduling/analytics/reschedules/jobs/export/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingAnalyticsReschedulesJobsExportListQueryKey = (params?: SchedulingAnalyticsReschedulesJobsExportListParams,) => [`/scheduling/analytics/reschedules/jobs/export/`, ...(params ? [params]: [])];

    
export type SchedulingAnalyticsReschedulesJobsExportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsExportList>>>
export type SchedulingAnalyticsReschedulesJobsExportListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsReschedulesJobsExportListInfinite = <TData = Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsReschedulesJobsExportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsExportList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsReschedulesJobsExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsExportList>>> = ({ signal }) => schedulingAnalyticsReschedulesJobsExportList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingAnalyticsReschedulesJobsExportListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsExportList>>>
export type SchedulingAnalyticsReschedulesJobsExportListQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsReschedulesJobsExportList = <TData = Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsReschedulesJobsExportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsExportList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsReschedulesJobsExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsExportList>>> = ({ signal }) => schedulingAnalyticsReschedulesJobsExportList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesJobsExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingAnalyticsReschedulesOfficesList = (
    params?: SchedulingAnalyticsReschedulesOfficesListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingAnalyticsReschedulesOfficesList200One | SchedulingAnalyticsReschedulesOfficesList200Two>(
      {url: `/scheduling/analytics/reschedules/offices/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingAnalyticsReschedulesOfficesListQueryKey = (params?: SchedulingAnalyticsReschedulesOfficesListParams,) => [`/scheduling/analytics/reschedules/offices/`, ...(params ? [params]: [])];

    
export type SchedulingAnalyticsReschedulesOfficesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesList>>>
export type SchedulingAnalyticsReschedulesOfficesListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsReschedulesOfficesListInfinite = <TData = Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsReschedulesOfficesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsReschedulesOfficesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesList>>> = ({ signal }) => schedulingAnalyticsReschedulesOfficesList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingAnalyticsReschedulesOfficesListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesList>>>
export type SchedulingAnalyticsReschedulesOfficesListQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsReschedulesOfficesList = <TData = Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsReschedulesOfficesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsReschedulesOfficesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesList>>> = ({ signal }) => schedulingAnalyticsReschedulesOfficesList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingAnalyticsReschedulesOfficesExportList = (
    params?: SchedulingAnalyticsReschedulesOfficesExportListParams,
 signal?: AbortSignal
) => {
      return customInstance<EventOfficeReschedulesAnalytics[]>(
      {url: `/scheduling/analytics/reschedules/offices/export/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingAnalyticsReschedulesOfficesExportListQueryKey = (params?: SchedulingAnalyticsReschedulesOfficesExportListParams,) => [`/scheduling/analytics/reschedules/offices/export/`, ...(params ? [params]: [])];

    
export type SchedulingAnalyticsReschedulesOfficesExportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesExportList>>>
export type SchedulingAnalyticsReschedulesOfficesExportListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsReschedulesOfficesExportListInfinite = <TData = Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsReschedulesOfficesExportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesExportList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsReschedulesOfficesExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesExportList>>> = ({ signal }) => schedulingAnalyticsReschedulesOfficesExportList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingAnalyticsReschedulesOfficesExportListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesExportList>>>
export type SchedulingAnalyticsReschedulesOfficesExportListQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsReschedulesOfficesExportList = <TData = Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsReschedulesOfficesExportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesExportList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsReschedulesOfficesExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesExportList>>> = ({ signal }) => schedulingAnalyticsReschedulesOfficesExportList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingAnalyticsReschedulesOfficesExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingAnalyticsRoundsPipelineList = (
    params?: SchedulingAnalyticsRoundsPipelineListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingAnalyticsRoundsPipelineList200One | SchedulingAnalyticsRoundsPipelineList200Two>(
      {url: `/scheduling/analytics/rounds/pipeline/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingAnalyticsRoundsPipelineListQueryKey = (params?: SchedulingAnalyticsRoundsPipelineListParams,) => [`/scheduling/analytics/rounds/pipeline/`, ...(params ? [params]: [])];

    
export type SchedulingAnalyticsRoundsPipelineListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineList>>>
export type SchedulingAnalyticsRoundsPipelineListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsRoundsPipelineListInfinite = <TData = Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsRoundsPipelineListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsRoundsPipelineListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineList>>> = ({ signal }) => schedulingAnalyticsRoundsPipelineList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingAnalyticsRoundsPipelineListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineList>>>
export type SchedulingAnalyticsRoundsPipelineListQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsRoundsPipelineList = <TData = Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsRoundsPipelineListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsRoundsPipelineListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineList>>> = ({ signal }) => schedulingAnalyticsRoundsPipelineList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingAnalyticsRoundsPipelineExportList = (
    params?: SchedulingAnalyticsRoundsPipelineExportListParams,
 signal?: AbortSignal
) => {
      return customInstance<RoundPipelineAnalytics[]>(
      {url: `/scheduling/analytics/rounds/pipeline/export/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingAnalyticsRoundsPipelineExportListQueryKey = (params?: SchedulingAnalyticsRoundsPipelineExportListParams,) => [`/scheduling/analytics/rounds/pipeline/export/`, ...(params ? [params]: [])];

    
export type SchedulingAnalyticsRoundsPipelineExportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineExportList>>>
export type SchedulingAnalyticsRoundsPipelineExportListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsRoundsPipelineExportListInfinite = <TData = Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsRoundsPipelineExportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineExportList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsRoundsPipelineExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineExportList>>> = ({ signal }) => schedulingAnalyticsRoundsPipelineExportList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingAnalyticsRoundsPipelineExportListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineExportList>>>
export type SchedulingAnalyticsRoundsPipelineExportListQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsRoundsPipelineExportList = <TData = Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsRoundsPipelineExportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineExportList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsRoundsPipelineExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineExportList>>> = ({ signal }) => schedulingAnalyticsRoundsPipelineExportList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingAnalyticsRoundsPipelineExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingAnalyticsTagsCapacityList = (
    params?: SchedulingAnalyticsTagsCapacityListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingAnalyticsTagsCapacityList200One | SchedulingAnalyticsTagsCapacityList200Two>(
      {url: `/scheduling/analytics/tags/capacity/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingAnalyticsTagsCapacityListQueryKey = (params?: SchedulingAnalyticsTagsCapacityListParams,) => [`/scheduling/analytics/tags/capacity/`, ...(params ? [params]: [])];

    
export type SchedulingAnalyticsTagsCapacityListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityList>>>
export type SchedulingAnalyticsTagsCapacityListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsTagsCapacityListInfinite = <TData = Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsTagsCapacityListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsTagsCapacityListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityList>>> = ({ signal }) => schedulingAnalyticsTagsCapacityList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingAnalyticsTagsCapacityListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityList>>>
export type SchedulingAnalyticsTagsCapacityListQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsTagsCapacityList = <TData = Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsTagsCapacityListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsTagsCapacityListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityList>>> = ({ signal }) => schedulingAnalyticsTagsCapacityList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingAnalyticsTagsCapacityExportList = (
    params?: SchedulingAnalyticsTagsCapacityExportListParams,
 signal?: AbortSignal
) => {
      return customInstance<TagInterviewingCapacityWeekOverWeek[]>(
      {url: `/scheduling/analytics/tags/capacity/export/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingAnalyticsTagsCapacityExportListQueryKey = (params?: SchedulingAnalyticsTagsCapacityExportListParams,) => [`/scheduling/analytics/tags/capacity/export/`, ...(params ? [params]: [])];

    
export type SchedulingAnalyticsTagsCapacityExportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityExportList>>>
export type SchedulingAnalyticsTagsCapacityExportListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsTagsCapacityExportListInfinite = <TData = Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsTagsCapacityExportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityExportList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsTagsCapacityExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityExportList>>> = ({ signal }) => schedulingAnalyticsTagsCapacityExportList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingAnalyticsTagsCapacityExportListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityExportList>>>
export type SchedulingAnalyticsTagsCapacityExportListQueryError = ErrorType<unknown>

export const useSchedulingAnalyticsTagsCapacityExportList = <TData = Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingAnalyticsTagsCapacityExportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityExportList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingAnalyticsTagsCapacityExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityExportList>>> = ({ signal }) => schedulingAnalyticsTagsCapacityExportList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingAnalyticsTagsCapacityExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingCalendarEventsCreate = (
    calendarEventsRequest: CalendarEventsRequest,
 ) => {
      return customInstance<CalendarEventsResponse>(
      {url: `/scheduling/calendar/events/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: calendarEventsRequest
    },
      );
    }
  


    export type SchedulingCalendarEventsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingCalendarEventsCreate>>>
    export type SchedulingCalendarEventsCreateMutationBody = CalendarEventsRequest
    export type SchedulingCalendarEventsCreateMutationError = ErrorType<unknown>

    export const useSchedulingCalendarEventsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingCalendarEventsCreate>>, TError,{data: CalendarEventsRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingCalendarEventsCreate>>, {data: CalendarEventsRequest}> = (props) => {
          const {data} = props ?? {};

          return  schedulingCalendarEventsCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingCalendarEventsCreate>>, TError, {data: CalendarEventsRequest}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingCalendarExternalUsersTimezoneRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<ExtTimezoneResponse>(
      {url: `/scheduling/calendar/external-users/${id}/timezone/`, method: 'get', signal
    },
      );
    }
  

export const getSchedulingCalendarExternalUsersTimezoneReadQueryKey = (id: string,) => [`/scheduling/calendar/external-users/${id}/timezone/`];

    
export type SchedulingCalendarExternalUsersTimezoneReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingCalendarExternalUsersTimezoneRead>>>
export type SchedulingCalendarExternalUsersTimezoneReadInfiniteQueryError = ErrorType<unknown>

export const useSchedulingCalendarExternalUsersTimezoneReadInfinite = <TData = Awaited<ReturnType<typeof schedulingCalendarExternalUsersTimezoneRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingCalendarExternalUsersTimezoneRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingCalendarExternalUsersTimezoneReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingCalendarExternalUsersTimezoneRead>>> = ({ signal }) => schedulingCalendarExternalUsersTimezoneRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingCalendarExternalUsersTimezoneRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingCalendarExternalUsersTimezoneReadQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingCalendarExternalUsersTimezoneRead>>>
export type SchedulingCalendarExternalUsersTimezoneReadQueryError = ErrorType<unknown>

export const useSchedulingCalendarExternalUsersTimezoneRead = <TData = Awaited<ReturnType<typeof schedulingCalendarExternalUsersTimezoneRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingCalendarExternalUsersTimezoneRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingCalendarExternalUsersTimezoneReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingCalendarExternalUsersTimezoneRead>>> = ({ signal }) => schedulingCalendarExternalUsersTimezoneRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingCalendarExternalUsersTimezoneRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * API view to list all calendar rooms
 */
export const schedulingCalendarRoomsList = (
    params?: SchedulingCalendarRoomsListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingCalendarRoomsList200One | SchedulingCalendarRoomsList200Two>(
      {url: `/scheduling/calendar/rooms/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingCalendarRoomsListQueryKey = (params?: SchedulingCalendarRoomsListParams,) => [`/scheduling/calendar/rooms/`, ...(params ? [params]: [])];

    
export type SchedulingCalendarRoomsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingCalendarRoomsList>>>
export type SchedulingCalendarRoomsListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingCalendarRoomsListInfinite = <TData = Awaited<ReturnType<typeof schedulingCalendarRoomsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingCalendarRoomsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingCalendarRoomsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingCalendarRoomsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingCalendarRoomsList>>> = ({ signal }) => schedulingCalendarRoomsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingCalendarRoomsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingCalendarRoomsListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingCalendarRoomsList>>>
export type SchedulingCalendarRoomsListQueryError = ErrorType<unknown>

export const useSchedulingCalendarRoomsList = <TData = Awaited<ReturnType<typeof schedulingCalendarRoomsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingCalendarRoomsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingCalendarRoomsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingCalendarRoomsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingCalendarRoomsList>>> = ({ signal }) => schedulingCalendarRoomsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingCalendarRoomsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingCalendarRoomsAvailabiltyList = (
    params?: SchedulingCalendarRoomsAvailabiltyListParams,
 signal?: AbortSignal
) => {
      return customInstance<CalendarRoomAvailabilityResponse[]>(
      {url: `/scheduling/calendar/rooms/availabilty/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingCalendarRoomsAvailabiltyListQueryKey = (params?: SchedulingCalendarRoomsAvailabiltyListParams,) => [`/scheduling/calendar/rooms/availabilty/`, ...(params ? [params]: [])];

    
export type SchedulingCalendarRoomsAvailabiltyListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingCalendarRoomsAvailabiltyList>>>
export type SchedulingCalendarRoomsAvailabiltyListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingCalendarRoomsAvailabiltyListInfinite = <TData = Awaited<ReturnType<typeof schedulingCalendarRoomsAvailabiltyList>>, TError = ErrorType<unknown>>(
 params?: SchedulingCalendarRoomsAvailabiltyListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingCalendarRoomsAvailabiltyList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingCalendarRoomsAvailabiltyListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingCalendarRoomsAvailabiltyList>>> = ({ signal }) => schedulingCalendarRoomsAvailabiltyList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingCalendarRoomsAvailabiltyList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingCalendarRoomsAvailabiltyListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingCalendarRoomsAvailabiltyList>>>
export type SchedulingCalendarRoomsAvailabiltyListQueryError = ErrorType<unknown>

export const useSchedulingCalendarRoomsAvailabiltyList = <TData = Awaited<ReturnType<typeof schedulingCalendarRoomsAvailabiltyList>>, TError = ErrorType<unknown>>(
 params?: SchedulingCalendarRoomsAvailabiltyListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingCalendarRoomsAvailabiltyList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingCalendarRoomsAvailabiltyListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingCalendarRoomsAvailabiltyList>>> = ({ signal }) => schedulingCalendarRoomsAvailabiltyList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingCalendarRoomsAvailabiltyList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingCalendarRoomsSyncCreate = (
    genericAPIBody: GenericAPIBody,
 ) => {
      return customInstance<GenericAPI>(
      {url: `/scheduling/calendar/rooms/sync/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: genericAPIBody
    },
      );
    }
  


    export type SchedulingCalendarRoomsSyncCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingCalendarRoomsSyncCreate>>>
    export type SchedulingCalendarRoomsSyncCreateMutationBody = GenericAPIBody
    export type SchedulingCalendarRoomsSyncCreateMutationError = ErrorType<unknown>

    export const useSchedulingCalendarRoomsSyncCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingCalendarRoomsSyncCreate>>, TError,{data: GenericAPIBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingCalendarRoomsSyncCreate>>, {data: GenericAPIBody}> = (props) => {
          const {data} = props ?? {};

          return  schedulingCalendarRoomsSyncCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingCalendarRoomsSyncCreate>>, TError, {data: GenericAPIBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * API view to retrieve calendar room
 */
export const schedulingCalendarRoomsRead = (
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<CalendarRoom>(
      {url: `/scheduling/calendar/rooms/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getSchedulingCalendarRoomsReadQueryKey = (id: number,) => [`/scheduling/calendar/rooms/${id}/`];

    
export type SchedulingCalendarRoomsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingCalendarRoomsRead>>>
export type SchedulingCalendarRoomsReadInfiniteQueryError = ErrorType<unknown>

export const useSchedulingCalendarRoomsReadInfinite = <TData = Awaited<ReturnType<typeof schedulingCalendarRoomsRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingCalendarRoomsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingCalendarRoomsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingCalendarRoomsRead>>> = ({ signal }) => schedulingCalendarRoomsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingCalendarRoomsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingCalendarRoomsReadQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingCalendarRoomsRead>>>
export type SchedulingCalendarRoomsReadQueryError = ErrorType<unknown>

export const useSchedulingCalendarRoomsRead = <TData = Awaited<ReturnType<typeof schedulingCalendarRoomsRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingCalendarRoomsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingCalendarRoomsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingCalendarRoomsRead>>> = ({ signal }) => schedulingCalendarRoomsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingCalendarRoomsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingCalendarinvitetemplatesList = (
    params?: SchedulingCalendarinvitetemplatesListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingCalendarinvitetemplatesList200One | SchedulingCalendarinvitetemplatesList200Two>(
      {url: `/scheduling/calendarinvitetemplates/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingCalendarinvitetemplatesListQueryKey = (params?: SchedulingCalendarinvitetemplatesListParams,) => [`/scheduling/calendarinvitetemplates/`, ...(params ? [params]: [])];

    
export type SchedulingCalendarinvitetemplatesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesList>>>
export type SchedulingCalendarinvitetemplatesListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingCalendarinvitetemplatesListInfinite = <TData = Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesList>>, TError = ErrorType<unknown>>(
 params?: SchedulingCalendarinvitetemplatesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingCalendarinvitetemplatesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesList>>> = ({ signal }) => schedulingCalendarinvitetemplatesList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingCalendarinvitetemplatesListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesList>>>
export type SchedulingCalendarinvitetemplatesListQueryError = ErrorType<unknown>

export const useSchedulingCalendarinvitetemplatesList = <TData = Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesList>>, TError = ErrorType<unknown>>(
 params?: SchedulingCalendarinvitetemplatesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingCalendarinvitetemplatesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesList>>> = ({ signal }) => schedulingCalendarinvitetemplatesList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingCalendarinvitetemplatesCreate = (
    calendarInviteTemplateBody: CalendarInviteTemplateBody,
 ) => {
      return customInstance<CalendarInviteTemplate>(
      {url: `/scheduling/calendarinvitetemplates/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: calendarInviteTemplateBody
    },
      );
    }
  


    export type SchedulingCalendarinvitetemplatesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesCreate>>>
    export type SchedulingCalendarinvitetemplatesCreateMutationBody = CalendarInviteTemplateBody
    export type SchedulingCalendarinvitetemplatesCreateMutationError = ErrorType<unknown>

    export const useSchedulingCalendarinvitetemplatesCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesCreate>>, TError,{data: CalendarInviteTemplateBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesCreate>>, {data: CalendarInviteTemplateBody}> = (props) => {
          const {data} = props ?? {};

          return  schedulingCalendarinvitetemplatesCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesCreate>>, TError, {data: CalendarInviteTemplateBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingCalendarinvitetemplatesRead = (
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<CalendarInviteTemplate>(
      {url: `/scheduling/calendarinvitetemplates/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getSchedulingCalendarinvitetemplatesReadQueryKey = (id: number,) => [`/scheduling/calendarinvitetemplates/${id}/`];

    
export type SchedulingCalendarinvitetemplatesReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesRead>>>
export type SchedulingCalendarinvitetemplatesReadInfiniteQueryError = ErrorType<unknown>

export const useSchedulingCalendarinvitetemplatesReadInfinite = <TData = Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingCalendarinvitetemplatesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesRead>>> = ({ signal }) => schedulingCalendarinvitetemplatesRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingCalendarinvitetemplatesReadQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesRead>>>
export type SchedulingCalendarinvitetemplatesReadQueryError = ErrorType<unknown>

export const useSchedulingCalendarinvitetemplatesRead = <TData = Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingCalendarinvitetemplatesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesRead>>> = ({ signal }) => schedulingCalendarinvitetemplatesRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingCalendarinvitetemplatesUpdate = (
    id: number,
    calendarInviteTemplateBody: CalendarInviteTemplateBody,
 ) => {
      return customInstance<CalendarInviteTemplate>(
      {url: `/scheduling/calendarinvitetemplates/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: calendarInviteTemplateBody
    },
      );
    }
  


    export type SchedulingCalendarinvitetemplatesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesUpdate>>>
    export type SchedulingCalendarinvitetemplatesUpdateMutationBody = CalendarInviteTemplateBody
    export type SchedulingCalendarinvitetemplatesUpdateMutationError = ErrorType<unknown>

    export const useSchedulingCalendarinvitetemplatesUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesUpdate>>, TError,{id: number;data: CalendarInviteTemplateBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesUpdate>>, {id: number;data: CalendarInviteTemplateBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingCalendarinvitetemplatesUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesUpdate>>, TError, {id: number;data: CalendarInviteTemplateBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * made atomic to ensure that the activity is created only after update is successful
 */
export const schedulingCalendarinvitetemplatesPartialUpdate = (
    id: number,
    calendarInviteTemplateBody: CalendarInviteTemplateBody,
 ) => {
      return customInstance<CalendarInviteTemplate>(
      {url: `/scheduling/calendarinvitetemplates/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: calendarInviteTemplateBody
    },
      );
    }
  


    export type SchedulingCalendarinvitetemplatesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesPartialUpdate>>>
    export type SchedulingCalendarinvitetemplatesPartialUpdateMutationBody = CalendarInviteTemplateBody
    export type SchedulingCalendarinvitetemplatesPartialUpdateMutationError = ErrorType<unknown>

    export const useSchedulingCalendarinvitetemplatesPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesPartialUpdate>>, TError,{id: number;data: CalendarInviteTemplateBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesPartialUpdate>>, {id: number;data: CalendarInviteTemplateBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingCalendarinvitetemplatesPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesPartialUpdate>>, TError, {id: number;data: CalendarInviteTemplateBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingCalendarinvitetemplatesDelete = (
    id: number,
 ) => {
      return customInstance<void>(
      {url: `/scheduling/calendarinvitetemplates/${id}/`, method: 'delete'
    },
      );
    }
  


    export type SchedulingCalendarinvitetemplatesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesDelete>>>
    
    export type SchedulingCalendarinvitetemplatesDeleteMutationError = ErrorType<unknown>

    export const useSchedulingCalendarinvitetemplatesDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  schedulingCalendarinvitetemplatesDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingCalendarinvitetemplatesCopyCreate = (
    id: string,
    copyCalendarInviteTemplate: CopyCalendarInviteTemplate,
 ) => {
      return customInstance<CalendarInviteTemplate>(
      {url: `/scheduling/calendarinvitetemplates/${id}/copy/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: copyCalendarInviteTemplate
    },
      );
    }
  


    export type SchedulingCalendarinvitetemplatesCopyCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesCopyCreate>>>
    export type SchedulingCalendarinvitetemplatesCopyCreateMutationBody = CopyCalendarInviteTemplate
    export type SchedulingCalendarinvitetemplatesCopyCreateMutationError = ErrorType<unknown>

    export const useSchedulingCalendarinvitetemplatesCopyCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesCopyCreate>>, TError,{id: string;data: CopyCalendarInviteTemplate}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesCopyCreate>>, {id: string;data: CopyCalendarInviteTemplate}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingCalendarinvitetemplatesCopyCreate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingCalendarinvitetemplatesCopyCreate>>, TError, {id: string;data: CopyCalendarInviteTemplate}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingEventsDepartmentsList = (
    params?: SchedulingEventsDepartmentsListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingEventsDepartmentsList200One | SchedulingEventsDepartmentsList200Two>(
      {url: `/scheduling/events-departments/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingEventsDepartmentsListQueryKey = (params?: SchedulingEventsDepartmentsListParams,) => [`/scheduling/events-departments/`, ...(params ? [params]: [])];

    
export type SchedulingEventsDepartmentsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsDepartmentsList>>>
export type SchedulingEventsDepartmentsListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingEventsDepartmentsListInfinite = <TData = Awaited<ReturnType<typeof schedulingEventsDepartmentsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsDepartmentsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingEventsDepartmentsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsDepartmentsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsDepartmentsList>>> = ({ signal }) => schedulingEventsDepartmentsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingEventsDepartmentsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingEventsDepartmentsListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsDepartmentsList>>>
export type SchedulingEventsDepartmentsListQueryError = ErrorType<unknown>

export const useSchedulingEventsDepartmentsList = <TData = Awaited<ReturnType<typeof schedulingEventsDepartmentsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsDepartmentsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingEventsDepartmentsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsDepartmentsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsDepartmentsList>>> = ({ signal }) => schedulingEventsDepartmentsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingEventsDepartmentsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingEventsDepartmentsExportList = (
    params?: SchedulingEventsDepartmentsExportListParams,
 signal?: AbortSignal
) => {
      return customInstance<EventDepartmentAnalytics[]>(
      {url: `/scheduling/events-departments/export/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingEventsDepartmentsExportListQueryKey = (params?: SchedulingEventsDepartmentsExportListParams,) => [`/scheduling/events-departments/export/`, ...(params ? [params]: [])];

    
export type SchedulingEventsDepartmentsExportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsDepartmentsExportList>>>
export type SchedulingEventsDepartmentsExportListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingEventsDepartmentsExportListInfinite = <TData = Awaited<ReturnType<typeof schedulingEventsDepartmentsExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsDepartmentsExportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingEventsDepartmentsExportList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsDepartmentsExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsDepartmentsExportList>>> = ({ signal }) => schedulingEventsDepartmentsExportList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingEventsDepartmentsExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingEventsDepartmentsExportListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsDepartmentsExportList>>>
export type SchedulingEventsDepartmentsExportListQueryError = ErrorType<unknown>

export const useSchedulingEventsDepartmentsExportList = <TData = Awaited<ReturnType<typeof schedulingEventsDepartmentsExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsDepartmentsExportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingEventsDepartmentsExportList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsDepartmentsExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsDepartmentsExportList>>> = ({ signal }) => schedulingEventsDepartmentsExportList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingEventsDepartmentsExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingEventsInterviewTemplatesList = (
    params?: SchedulingEventsInterviewTemplatesListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingEventsInterviewTemplatesList200One | SchedulingEventsInterviewTemplatesList200Two>(
      {url: `/scheduling/events-interview-templates/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingEventsInterviewTemplatesListQueryKey = (params?: SchedulingEventsInterviewTemplatesListParams,) => [`/scheduling/events-interview-templates/`, ...(params ? [params]: [])];

    
export type SchedulingEventsInterviewTemplatesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesList>>>
export type SchedulingEventsInterviewTemplatesListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingEventsInterviewTemplatesListInfinite = <TData = Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsInterviewTemplatesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsInterviewTemplatesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesList>>> = ({ signal }) => schedulingEventsInterviewTemplatesList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingEventsInterviewTemplatesListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesList>>>
export type SchedulingEventsInterviewTemplatesListQueryError = ErrorType<unknown>

export const useSchedulingEventsInterviewTemplatesList = <TData = Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsInterviewTemplatesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsInterviewTemplatesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesList>>> = ({ signal }) => schedulingEventsInterviewTemplatesList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingEventsInterviewTemplatesExportList = (
    params?: SchedulingEventsInterviewTemplatesExportListParams,
 signal?: AbortSignal
) => {
      return customInstance<InterviewTemplateAnalytics[]>(
      {url: `/scheduling/events-interview-templates/export/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingEventsInterviewTemplatesExportListQueryKey = (params?: SchedulingEventsInterviewTemplatesExportListParams,) => [`/scheduling/events-interview-templates/export/`, ...(params ? [params]: [])];

    
export type SchedulingEventsInterviewTemplatesExportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesExportList>>>
export type SchedulingEventsInterviewTemplatesExportListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingEventsInterviewTemplatesExportListInfinite = <TData = Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsInterviewTemplatesExportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesExportList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsInterviewTemplatesExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesExportList>>> = ({ signal }) => schedulingEventsInterviewTemplatesExportList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingEventsInterviewTemplatesExportListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesExportList>>>
export type SchedulingEventsInterviewTemplatesExportListQueryError = ErrorType<unknown>

export const useSchedulingEventsInterviewTemplatesExportList = <TData = Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsInterviewTemplatesExportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesExportList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsInterviewTemplatesExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesExportList>>> = ({ signal }) => schedulingEventsInterviewTemplatesExportList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingEventsInterviewTemplatesExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingEventsInterviewersList = (
    params?: SchedulingEventsInterviewersListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingEventsInterviewersList200One | SchedulingEventsInterviewersList200Two>(
      {url: `/scheduling/events-interviewers/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingEventsInterviewersListQueryKey = (params?: SchedulingEventsInterviewersListParams,) => [`/scheduling/events-interviewers/`, ...(params ? [params]: [])];

    
export type SchedulingEventsInterviewersListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsInterviewersList>>>
export type SchedulingEventsInterviewersListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingEventsInterviewersListInfinite = <TData = Awaited<ReturnType<typeof schedulingEventsInterviewersList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsInterviewersListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingEventsInterviewersList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsInterviewersListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsInterviewersList>>> = ({ signal }) => schedulingEventsInterviewersList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingEventsInterviewersList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingEventsInterviewersListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsInterviewersList>>>
export type SchedulingEventsInterviewersListQueryError = ErrorType<unknown>

export const useSchedulingEventsInterviewersList = <TData = Awaited<ReturnType<typeof schedulingEventsInterviewersList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsInterviewersListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingEventsInterviewersList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsInterviewersListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsInterviewersList>>> = ({ signal }) => schedulingEventsInterviewersList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingEventsInterviewersList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingEventsInterviewersExportList = (
    params?: SchedulingEventsInterviewersExportListParams,
 signal?: AbortSignal
) => {
      return customInstance<EventInterviewerRemoteUserLight[]>(
      {url: `/scheduling/events-interviewers/export/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingEventsInterviewersExportListQueryKey = (params?: SchedulingEventsInterviewersExportListParams,) => [`/scheduling/events-interviewers/export/`, ...(params ? [params]: [])];

    
export type SchedulingEventsInterviewersExportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsInterviewersExportList>>>
export type SchedulingEventsInterviewersExportListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingEventsInterviewersExportListInfinite = <TData = Awaited<ReturnType<typeof schedulingEventsInterviewersExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsInterviewersExportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingEventsInterviewersExportList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsInterviewersExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsInterviewersExportList>>> = ({ signal }) => schedulingEventsInterviewersExportList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingEventsInterviewersExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingEventsInterviewersExportListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsInterviewersExportList>>>
export type SchedulingEventsInterviewersExportListQueryError = ErrorType<unknown>

export const useSchedulingEventsInterviewersExportList = <TData = Awaited<ReturnType<typeof schedulingEventsInterviewersExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsInterviewersExportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingEventsInterviewersExportList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsInterviewersExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsInterviewersExportList>>> = ({ signal }) => schedulingEventsInterviewersExportList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingEventsInterviewersExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingEventsJobsList = (
    params?: SchedulingEventsJobsListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingEventsJobsList200One | SchedulingEventsJobsList200Two>(
      {url: `/scheduling/events-jobs/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingEventsJobsListQueryKey = (params?: SchedulingEventsJobsListParams,) => [`/scheduling/events-jobs/`, ...(params ? [params]: [])];

    
export type SchedulingEventsJobsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsJobsList>>>
export type SchedulingEventsJobsListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingEventsJobsListInfinite = <TData = Awaited<ReturnType<typeof schedulingEventsJobsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsJobsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingEventsJobsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsJobsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsJobsList>>> = ({ signal }) => schedulingEventsJobsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingEventsJobsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingEventsJobsListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsJobsList>>>
export type SchedulingEventsJobsListQueryError = ErrorType<unknown>

export const useSchedulingEventsJobsList = <TData = Awaited<ReturnType<typeof schedulingEventsJobsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsJobsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingEventsJobsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsJobsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsJobsList>>> = ({ signal }) => schedulingEventsJobsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingEventsJobsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingEventsJobsExportList = (
    params?: SchedulingEventsJobsExportListParams,
 signal?: AbortSignal
) => {
      return customInstance<ExternalATSJobSchedulingAnalytics[]>(
      {url: `/scheduling/events-jobs/export/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingEventsJobsExportListQueryKey = (params?: SchedulingEventsJobsExportListParams,) => [`/scheduling/events-jobs/export/`, ...(params ? [params]: [])];

    
export type SchedulingEventsJobsExportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsJobsExportList>>>
export type SchedulingEventsJobsExportListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingEventsJobsExportListInfinite = <TData = Awaited<ReturnType<typeof schedulingEventsJobsExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsJobsExportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingEventsJobsExportList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsJobsExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsJobsExportList>>> = ({ signal }) => schedulingEventsJobsExportList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingEventsJobsExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingEventsJobsExportListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsJobsExportList>>>
export type SchedulingEventsJobsExportListQueryError = ErrorType<unknown>

export const useSchedulingEventsJobsExportList = <TData = Awaited<ReturnType<typeof schedulingEventsJobsExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsJobsExportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingEventsJobsExportList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsJobsExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsJobsExportList>>> = ({ signal }) => schedulingEventsJobsExportList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingEventsJobsExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingEventsOfficesList = (
    params?: SchedulingEventsOfficesListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingEventsOfficesList200One | SchedulingEventsOfficesList200Two>(
      {url: `/scheduling/events-offices/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingEventsOfficesListQueryKey = (params?: SchedulingEventsOfficesListParams,) => [`/scheduling/events-offices/`, ...(params ? [params]: [])];

    
export type SchedulingEventsOfficesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsOfficesList>>>
export type SchedulingEventsOfficesListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingEventsOfficesListInfinite = <TData = Awaited<ReturnType<typeof schedulingEventsOfficesList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsOfficesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingEventsOfficesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsOfficesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsOfficesList>>> = ({ signal }) => schedulingEventsOfficesList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingEventsOfficesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingEventsOfficesListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsOfficesList>>>
export type SchedulingEventsOfficesListQueryError = ErrorType<unknown>

export const useSchedulingEventsOfficesList = <TData = Awaited<ReturnType<typeof schedulingEventsOfficesList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsOfficesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingEventsOfficesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsOfficesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsOfficesList>>> = ({ signal }) => schedulingEventsOfficesList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingEventsOfficesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingEventsOfficesExportList = (
    params?: SchedulingEventsOfficesExportListParams,
 signal?: AbortSignal
) => {
      return customInstance<EventOfficeAnalytics[]>(
      {url: `/scheduling/events-offices/export/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingEventsOfficesExportListQueryKey = (params?: SchedulingEventsOfficesExportListParams,) => [`/scheduling/events-offices/export/`, ...(params ? [params]: [])];

    
export type SchedulingEventsOfficesExportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsOfficesExportList>>>
export type SchedulingEventsOfficesExportListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingEventsOfficesExportListInfinite = <TData = Awaited<ReturnType<typeof schedulingEventsOfficesExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsOfficesExportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingEventsOfficesExportList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsOfficesExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsOfficesExportList>>> = ({ signal }) => schedulingEventsOfficesExportList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingEventsOfficesExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingEventsOfficesExportListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsOfficesExportList>>>
export type SchedulingEventsOfficesExportListQueryError = ErrorType<unknown>

export const useSchedulingEventsOfficesExportList = <TData = Awaited<ReturnType<typeof schedulingEventsOfficesExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsOfficesExportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingEventsOfficesExportList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsOfficesExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsOfficesExportList>>> = ({ signal }) => schedulingEventsOfficesExportList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingEventsOfficesExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingEventsOwnersList = (
    params?: SchedulingEventsOwnersListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingEventsOwnersList200One | SchedulingEventsOwnersList200Two>(
      {url: `/scheduling/events-owners/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingEventsOwnersListQueryKey = (params?: SchedulingEventsOwnersListParams,) => [`/scheduling/events-owners/`, ...(params ? [params]: [])];

    
export type SchedulingEventsOwnersListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsOwnersList>>>
export type SchedulingEventsOwnersListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingEventsOwnersListInfinite = <TData = Awaited<ReturnType<typeof schedulingEventsOwnersList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsOwnersListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingEventsOwnersList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsOwnersListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsOwnersList>>> = ({ signal }) => schedulingEventsOwnersList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingEventsOwnersList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingEventsOwnersListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsOwnersList>>>
export type SchedulingEventsOwnersListQueryError = ErrorType<unknown>

export const useSchedulingEventsOwnersList = <TData = Awaited<ReturnType<typeof schedulingEventsOwnersList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsOwnersListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingEventsOwnersList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsOwnersListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsOwnersList>>> = ({ signal }) => schedulingEventsOwnersList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingEventsOwnersList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingEventsOwnersExportList = (
    params?: SchedulingEventsOwnersExportListParams,
 signal?: AbortSignal
) => {
      return customInstance<EventOwnerRemoteUserLight[]>(
      {url: `/scheduling/events-owners/export/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingEventsOwnersExportListQueryKey = (params?: SchedulingEventsOwnersExportListParams,) => [`/scheduling/events-owners/export/`, ...(params ? [params]: [])];

    
export type SchedulingEventsOwnersExportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsOwnersExportList>>>
export type SchedulingEventsOwnersExportListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingEventsOwnersExportListInfinite = <TData = Awaited<ReturnType<typeof schedulingEventsOwnersExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsOwnersExportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingEventsOwnersExportList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsOwnersExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsOwnersExportList>>> = ({ signal }) => schedulingEventsOwnersExportList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingEventsOwnersExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingEventsOwnersExportListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsOwnersExportList>>>
export type SchedulingEventsOwnersExportListQueryError = ErrorType<unknown>

export const useSchedulingEventsOwnersExportList = <TData = Awaited<ReturnType<typeof schedulingEventsOwnersExportList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsOwnersExportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingEventsOwnersExportList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsOwnersExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsOwnersExportList>>> = ({ signal }) => schedulingEventsOwnersExportList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingEventsOwnersExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingEventsList = (
    params?: SchedulingEventsListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingEventsList200One | SchedulingEventsList200Two>(
      {url: `/scheduling/events/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingEventsListQueryKey = (params?: SchedulingEventsListParams,) => [`/scheduling/events/`, ...(params ? [params]: [])];

    
export type SchedulingEventsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsList>>>
export type SchedulingEventsListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingEventsListInfinite = <TData = Awaited<ReturnType<typeof schedulingEventsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingEventsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsList>>> = ({ signal }) => schedulingEventsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingEventsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingEventsListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsList>>>
export type SchedulingEventsListQueryError = ErrorType<unknown>

export const useSchedulingEventsList = <TData = Awaited<ReturnType<typeof schedulingEventsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingEventsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingEventsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsList>>> = ({ signal }) => schedulingEventsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingEventsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingEventsRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<EventLimitedWrite>(
      {url: `/scheduling/events/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getSchedulingEventsReadQueryKey = (id: string,) => [`/scheduling/events/${id}/`];

    
export type SchedulingEventsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsRead>>>
export type SchedulingEventsReadInfiniteQueryError = ErrorType<unknown>

export const useSchedulingEventsReadInfinite = <TData = Awaited<ReturnType<typeof schedulingEventsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingEventsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsRead>>> = ({ signal }) => schedulingEventsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingEventsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingEventsReadQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingEventsRead>>>
export type SchedulingEventsReadQueryError = ErrorType<unknown>

export const useSchedulingEventsRead = <TData = Awaited<ReturnType<typeof schedulingEventsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingEventsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingEventsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingEventsRead>>> = ({ signal }) => schedulingEventsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingEventsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingGroupEventsList = (
    params?: SchedulingGroupEventsListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingGroupEventsList200One | SchedulingGroupEventsList200Two>(
      {url: `/scheduling/group-events/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingGroupEventsListQueryKey = (params?: SchedulingGroupEventsListParams,) => [`/scheduling/group-events/`, ...(params ? [params]: [])];

    
export type SchedulingGroupEventsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingGroupEventsList>>>
export type SchedulingGroupEventsListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingGroupEventsListInfinite = <TData = Awaited<ReturnType<typeof schedulingGroupEventsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingGroupEventsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingGroupEventsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingGroupEventsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingGroupEventsList>>> = ({ signal }) => schedulingGroupEventsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingGroupEventsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingGroupEventsListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingGroupEventsList>>>
export type SchedulingGroupEventsListQueryError = ErrorType<unknown>

export const useSchedulingGroupEventsList = <TData = Awaited<ReturnType<typeof schedulingGroupEventsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingGroupEventsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingGroupEventsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingGroupEventsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingGroupEventsList>>> = ({ signal }) => schedulingGroupEventsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingGroupEventsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * api to get applications in a stage with interviewplantemplate but no scheduling request
 */
export const schedulingGroupApplicationsMissingSchedulingRequestList = (
    params?: SchedulingGroupApplicationsMissingSchedulingRequestListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingGroupApplicationsMissingSchedulingRequestList200One | SchedulingGroupApplicationsMissingSchedulingRequestList200Two>(
      {url: `/scheduling/group/applications-missing-scheduling-request/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingGroupApplicationsMissingSchedulingRequestListQueryKey = (params?: SchedulingGroupApplicationsMissingSchedulingRequestListParams,) => [`/scheduling/group/applications-missing-scheduling-request/`, ...(params ? [params]: [])];

    
export type SchedulingGroupApplicationsMissingSchedulingRequestListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingGroupApplicationsMissingSchedulingRequestList>>>
export type SchedulingGroupApplicationsMissingSchedulingRequestListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingGroupApplicationsMissingSchedulingRequestListInfinite = <TData = Awaited<ReturnType<typeof schedulingGroupApplicationsMissingSchedulingRequestList>>, TError = ErrorType<unknown>>(
 params?: SchedulingGroupApplicationsMissingSchedulingRequestListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingGroupApplicationsMissingSchedulingRequestList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingGroupApplicationsMissingSchedulingRequestListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingGroupApplicationsMissingSchedulingRequestList>>> = ({ signal }) => schedulingGroupApplicationsMissingSchedulingRequestList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingGroupApplicationsMissingSchedulingRequestList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingGroupApplicationsMissingSchedulingRequestListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingGroupApplicationsMissingSchedulingRequestList>>>
export type SchedulingGroupApplicationsMissingSchedulingRequestListQueryError = ErrorType<unknown>

export const useSchedulingGroupApplicationsMissingSchedulingRequestList = <TData = Awaited<ReturnType<typeof schedulingGroupApplicationsMissingSchedulingRequestList>>, TError = ErrorType<unknown>>(
 params?: SchedulingGroupApplicationsMissingSchedulingRequestListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingGroupApplicationsMissingSchedulingRequestList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingGroupApplicationsMissingSchedulingRequestListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingGroupApplicationsMissingSchedulingRequestList>>> = ({ signal }) => schedulingGroupApplicationsMissingSchedulingRequestList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingGroupApplicationsMissingSchedulingRequestList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingGroupGenerateSlotAvailabilityV2Create = (
    interviewerBlockAvailabilityPayload: InterviewerBlockAvailabilityPayload,
 ) => {
      return customInstance<InterviewerBlockAvailabilityResponse>(
      {url: `/scheduling/group/generate-slot-availability/v2/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: interviewerBlockAvailabilityPayload
    },
      );
    }
  


    export type SchedulingGroupGenerateSlotAvailabilityV2CreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingGroupGenerateSlotAvailabilityV2Create>>>
    export type SchedulingGroupGenerateSlotAvailabilityV2CreateMutationBody = InterviewerBlockAvailabilityPayload
    export type SchedulingGroupGenerateSlotAvailabilityV2CreateMutationError = ErrorType<unknown>

    export const useSchedulingGroupGenerateSlotAvailabilityV2Create = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingGroupGenerateSlotAvailabilityV2Create>>, TError,{data: InterviewerBlockAvailabilityPayload}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingGroupGenerateSlotAvailabilityV2Create>>, {data: InterviewerBlockAvailabilityPayload}> = (props) => {
          const {data} = props ?? {};

          return  schedulingGroupGenerateSlotAvailabilityV2Create(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingGroupGenerateSlotAvailabilityV2Create>>, TError, {data: InterviewerBlockAvailabilityPayload}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingGroupInterviewsCreate = (
    adminCreateInterviewEvent: AdminCreateInterviewEvent,
 ) => {
      return customInstance<AdminCreateInterviewEvent>(
      {url: `/scheduling/group/interviews/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: adminCreateInterviewEvent
    },
      );
    }
  


    export type SchedulingGroupInterviewsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingGroupInterviewsCreate>>>
    export type SchedulingGroupInterviewsCreateMutationBody = AdminCreateInterviewEvent
    export type SchedulingGroupInterviewsCreateMutationError = ErrorType<unknown>

    export const useSchedulingGroupInterviewsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingGroupInterviewsCreate>>, TError,{data: AdminCreateInterviewEvent}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingGroupInterviewsCreate>>, {data: AdminCreateInterviewEvent}> = (props) => {
          const {data} = props ?? {};

          return  schedulingGroupInterviewsCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingGroupInterviewsCreate>>, TError, {data: AdminCreateInterviewEvent}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingGroupInterviewsBulkCreate = (
    adminCreateBulkInterviewEvent: AdminCreateBulkInterviewEvent,
 ) => {
      return customInstance<AdminCreateBulkInterviewEvent>(
      {url: `/scheduling/group/interviews/bulk/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: adminCreateBulkInterviewEvent
    },
      );
    }
  


    export type SchedulingGroupInterviewsBulkCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingGroupInterviewsBulkCreate>>>
    export type SchedulingGroupInterviewsBulkCreateMutationBody = AdminCreateBulkInterviewEvent
    export type SchedulingGroupInterviewsBulkCreateMutationError = ErrorType<unknown>

    export const useSchedulingGroupInterviewsBulkCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingGroupInterviewsBulkCreate>>, TError,{data: AdminCreateBulkInterviewEvent}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingGroupInterviewsBulkCreate>>, {data: AdminCreateBulkInterviewEvent}> = (props) => {
          const {data} = props ?? {};

          return  schedulingGroupInterviewsBulkCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingGroupInterviewsBulkCreate>>, TError, {data: AdminCreateBulkInterviewEvent}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingGroupRequestScheduleRecommendationsCreate = (
    id: string,
    interviewRecommendationRequest: InterviewRecommendationRequest,
 ) => {
      return customInstance<InterviewRecommendationResponse>(
      {url: `/scheduling/group/request/${id}/schedule-recommendations/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: interviewRecommendationRequest
    },
      );
    }
  


    export type SchedulingGroupRequestScheduleRecommendationsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingGroupRequestScheduleRecommendationsCreate>>>
    export type SchedulingGroupRequestScheduleRecommendationsCreateMutationBody = InterviewRecommendationRequest
    export type SchedulingGroupRequestScheduleRecommendationsCreateMutationError = ErrorType<unknown>

    export const useSchedulingGroupRequestScheduleRecommendationsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingGroupRequestScheduleRecommendationsCreate>>, TError,{id: string;data: InterviewRecommendationRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingGroupRequestScheduleRecommendationsCreate>>, {id: string;data: InterviewRecommendationRequest}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingGroupRequestScheduleRecommendationsCreate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingGroupRequestScheduleRecommendationsCreate>>, TError, {id: string;data: InterviewRecommendationRequest}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingInterviewplantemplatesList = (
    params?: SchedulingInterviewplantemplatesListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingInterviewplantemplatesList200One | SchedulingInterviewplantemplatesList200Two>(
      {url: `/scheduling/interviewplantemplates/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingInterviewplantemplatesListQueryKey = (params?: SchedulingInterviewplantemplatesListParams,) => [`/scheduling/interviewplantemplates/`, ...(params ? [params]: [])];

    
export type SchedulingInterviewplantemplatesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewplantemplatesList>>>
export type SchedulingInterviewplantemplatesListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingInterviewplantemplatesListInfinite = <TData = Awaited<ReturnType<typeof schedulingInterviewplantemplatesList>>, TError = ErrorType<unknown>>(
 params?: SchedulingInterviewplantemplatesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingInterviewplantemplatesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingInterviewplantemplatesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingInterviewplantemplatesList>>> = ({ signal }) => schedulingInterviewplantemplatesList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingInterviewplantemplatesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingInterviewplantemplatesListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewplantemplatesList>>>
export type SchedulingInterviewplantemplatesListQueryError = ErrorType<unknown>

export const useSchedulingInterviewplantemplatesList = <TData = Awaited<ReturnType<typeof schedulingInterviewplantemplatesList>>, TError = ErrorType<unknown>>(
 params?: SchedulingInterviewplantemplatesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingInterviewplantemplatesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingInterviewplantemplatesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingInterviewplantemplatesList>>> = ({ signal }) => schedulingInterviewplantemplatesList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingInterviewplantemplatesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingInterviewplantemplatesCreate = (
    interviewPlanTemplate: InterviewPlanTemplate,
 ) => {
      return customInstance<InterviewPlanTemplate>(
      {url: `/scheduling/interviewplantemplates/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: interviewPlanTemplate
    },
      );
    }
  


    export type SchedulingInterviewplantemplatesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewplantemplatesCreate>>>
    export type SchedulingInterviewplantemplatesCreateMutationBody = InterviewPlanTemplate
    export type SchedulingInterviewplantemplatesCreateMutationError = ErrorType<unknown>

    export const useSchedulingInterviewplantemplatesCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingInterviewplantemplatesCreate>>, TError,{data: InterviewPlanTemplate}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingInterviewplantemplatesCreate>>, {data: InterviewPlanTemplate}> = (props) => {
          const {data} = props ?? {};

          return  schedulingInterviewplantemplatesCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingInterviewplantemplatesCreate>>, TError, {data: InterviewPlanTemplate}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingInterviewplantemplatesRead = (
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<InterviewPlanTemplateRead>(
      {url: `/scheduling/interviewplantemplates/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getSchedulingInterviewplantemplatesReadQueryKey = (id: number,) => [`/scheduling/interviewplantemplates/${id}/`];

    
export type SchedulingInterviewplantemplatesReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewplantemplatesRead>>>
export type SchedulingInterviewplantemplatesReadInfiniteQueryError = ErrorType<unknown>

export const useSchedulingInterviewplantemplatesReadInfinite = <TData = Awaited<ReturnType<typeof schedulingInterviewplantemplatesRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingInterviewplantemplatesRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingInterviewplantemplatesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingInterviewplantemplatesRead>>> = ({ signal }) => schedulingInterviewplantemplatesRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingInterviewplantemplatesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingInterviewplantemplatesReadQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewplantemplatesRead>>>
export type SchedulingInterviewplantemplatesReadQueryError = ErrorType<unknown>

export const useSchedulingInterviewplantemplatesRead = <TData = Awaited<ReturnType<typeof schedulingInterviewplantemplatesRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingInterviewplantemplatesRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingInterviewplantemplatesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingInterviewplantemplatesRead>>> = ({ signal }) => schedulingInterviewplantemplatesRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingInterviewplantemplatesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingInterviewplantemplatesUpdate = (
    id: number,
    interviewPlanTemplateReadBody: InterviewPlanTemplateReadBody,
 ) => {
      return customInstance<InterviewPlanTemplateRead>(
      {url: `/scheduling/interviewplantemplates/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: interviewPlanTemplateReadBody
    },
      );
    }
  


    export type SchedulingInterviewplantemplatesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewplantemplatesUpdate>>>
    export type SchedulingInterviewplantemplatesUpdateMutationBody = InterviewPlanTemplateReadBody
    export type SchedulingInterviewplantemplatesUpdateMutationError = ErrorType<unknown>

    export const useSchedulingInterviewplantemplatesUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingInterviewplantemplatesUpdate>>, TError,{id: number;data: InterviewPlanTemplateReadBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingInterviewplantemplatesUpdate>>, {id: number;data: InterviewPlanTemplateReadBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingInterviewplantemplatesUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingInterviewplantemplatesUpdate>>, TError, {id: number;data: InterviewPlanTemplateReadBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * made atomic to ensure that the activity is created only after update is successful
 */
export const schedulingInterviewplantemplatesPartialUpdate = (
    id: number,
    interviewPlanTemplateReadBody: InterviewPlanTemplateReadBody,
 ) => {
      return customInstance<InterviewPlanTemplateRead>(
      {url: `/scheduling/interviewplantemplates/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: interviewPlanTemplateReadBody
    },
      );
    }
  


    export type SchedulingInterviewplantemplatesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewplantemplatesPartialUpdate>>>
    export type SchedulingInterviewplantemplatesPartialUpdateMutationBody = InterviewPlanTemplateReadBody
    export type SchedulingInterviewplantemplatesPartialUpdateMutationError = ErrorType<unknown>

    export const useSchedulingInterviewplantemplatesPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingInterviewplantemplatesPartialUpdate>>, TError,{id: number;data: InterviewPlanTemplateReadBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingInterviewplantemplatesPartialUpdate>>, {id: number;data: InterviewPlanTemplateReadBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingInterviewplantemplatesPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingInterviewplantemplatesPartialUpdate>>, TError, {id: number;data: InterviewPlanTemplateReadBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingInterviewplantemplatesDelete = (
    id: number,
 ) => {
      return customInstance<void>(
      {url: `/scheduling/interviewplantemplates/${id}/`, method: 'delete'
    },
      );
    }
  


    export type SchedulingInterviewplantemplatesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewplantemplatesDelete>>>
    
    export type SchedulingInterviewplantemplatesDeleteMutationError = ErrorType<unknown>

    export const useSchedulingInterviewplantemplatesDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingInterviewplantemplatesDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingInterviewplantemplatesDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  schedulingInterviewplantemplatesDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingInterviewplantemplatesDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingInterviewplantemplatesRepairMappingsCreate = (
    id: string,
    genericAPIBody: GenericAPIBody,
 ) => {
      return customInstance<InterviewPlanTemplateRead>(
      {url: `/scheduling/interviewplantemplates/${id}/repair_mappings/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: genericAPIBody
    },
      );
    }
  


    export type SchedulingInterviewplantemplatesRepairMappingsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewplantemplatesRepairMappingsCreate>>>
    export type SchedulingInterviewplantemplatesRepairMappingsCreateMutationBody = GenericAPIBody
    export type SchedulingInterviewplantemplatesRepairMappingsCreateMutationError = ErrorType<unknown>

    export const useSchedulingInterviewplantemplatesRepairMappingsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingInterviewplantemplatesRepairMappingsCreate>>, TError,{id: string;data: GenericAPIBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingInterviewplantemplatesRepairMappingsCreate>>, {id: string;data: GenericAPIBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingInterviewplantemplatesRepairMappingsCreate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingInterviewplantemplatesRepairMappingsCreate>>, TError, {id: string;data: GenericAPIBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Copy an interview plan template (round)
 */
export const schedulingInterviewplantemplatesCopyCreate = (
    templateId: string,
    copyInterviewPlanTemplate: CopyInterviewPlanTemplate,
 ) => {
      return customInstance<InterviewPlanTemplateRead>(
      {url: `/scheduling/interviewplantemplates/${templateId}/copy/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: copyInterviewPlanTemplate
    },
      );
    }
  


    export type SchedulingInterviewplantemplatesCopyCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewplantemplatesCopyCreate>>>
    export type SchedulingInterviewplantemplatesCopyCreateMutationBody = CopyInterviewPlanTemplate
    export type SchedulingInterviewplantemplatesCopyCreateMutationError = ErrorType<unknown>

    export const useSchedulingInterviewplantemplatesCopyCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingInterviewplantemplatesCopyCreate>>, TError,{templateId: string;data: CopyInterviewPlanTemplate}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingInterviewplantemplatesCopyCreate>>, {templateId: string;data: CopyInterviewPlanTemplate}> = (props) => {
          const {templateId,data} = props ?? {};

          return  schedulingInterviewplantemplatesCopyCreate(templateId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingInterviewplantemplatesCopyCreate>>, TError, {templateId: string;data: CopyInterviewPlanTemplate}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingInterviewtemplatesList = (
    params?: SchedulingInterviewtemplatesListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingInterviewtemplatesList200One | SchedulingInterviewtemplatesList200Two>(
      {url: `/scheduling/interviewtemplates/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingInterviewtemplatesListQueryKey = (params?: SchedulingInterviewtemplatesListParams,) => [`/scheduling/interviewtemplates/`, ...(params ? [params]: [])];

    
export type SchedulingInterviewtemplatesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewtemplatesList>>>
export type SchedulingInterviewtemplatesListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingInterviewtemplatesListInfinite = <TData = Awaited<ReturnType<typeof schedulingInterviewtemplatesList>>, TError = ErrorType<unknown>>(
 params?: SchedulingInterviewtemplatesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingInterviewtemplatesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingInterviewtemplatesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingInterviewtemplatesList>>> = ({ signal }) => schedulingInterviewtemplatesList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingInterviewtemplatesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingInterviewtemplatesListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewtemplatesList>>>
export type SchedulingInterviewtemplatesListQueryError = ErrorType<unknown>

export const useSchedulingInterviewtemplatesList = <TData = Awaited<ReturnType<typeof schedulingInterviewtemplatesList>>, TError = ErrorType<unknown>>(
 params?: SchedulingInterviewtemplatesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingInterviewtemplatesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingInterviewtemplatesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingInterviewtemplatesList>>> = ({ signal }) => schedulingInterviewtemplatesList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingInterviewtemplatesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingInterviewtemplatesCreate = (
    interviewTemplateReadBody: InterviewTemplateReadBody,
 ) => {
      return customInstance<InterviewTemplateRead>(
      {url: `/scheduling/interviewtemplates/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: interviewTemplateReadBody
    },
      );
    }
  


    export type SchedulingInterviewtemplatesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewtemplatesCreate>>>
    export type SchedulingInterviewtemplatesCreateMutationBody = InterviewTemplateReadBody
    export type SchedulingInterviewtemplatesCreateMutationError = ErrorType<unknown>

    export const useSchedulingInterviewtemplatesCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingInterviewtemplatesCreate>>, TError,{data: InterviewTemplateReadBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingInterviewtemplatesCreate>>, {data: InterviewTemplateReadBody}> = (props) => {
          const {data} = props ?? {};

          return  schedulingInterviewtemplatesCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingInterviewtemplatesCreate>>, TError, {data: InterviewTemplateReadBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingInterviewtemplatesRead = (
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<InterviewTemplateRead>(
      {url: `/scheduling/interviewtemplates/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getSchedulingInterviewtemplatesReadQueryKey = (id: number,) => [`/scheduling/interviewtemplates/${id}/`];

    
export type SchedulingInterviewtemplatesReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewtemplatesRead>>>
export type SchedulingInterviewtemplatesReadInfiniteQueryError = ErrorType<unknown>

export const useSchedulingInterviewtemplatesReadInfinite = <TData = Awaited<ReturnType<typeof schedulingInterviewtemplatesRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingInterviewtemplatesRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingInterviewtemplatesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingInterviewtemplatesRead>>> = ({ signal }) => schedulingInterviewtemplatesRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingInterviewtemplatesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingInterviewtemplatesReadQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewtemplatesRead>>>
export type SchedulingInterviewtemplatesReadQueryError = ErrorType<unknown>

export const useSchedulingInterviewtemplatesRead = <TData = Awaited<ReturnType<typeof schedulingInterviewtemplatesRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingInterviewtemplatesRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingInterviewtemplatesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingInterviewtemplatesRead>>> = ({ signal }) => schedulingInterviewtemplatesRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingInterviewtemplatesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingInterviewtemplatesUpdate = (
    id: number,
    interviewTemplateReadBody: InterviewTemplateReadBody,
 ) => {
      return customInstance<InterviewTemplateRead>(
      {url: `/scheduling/interviewtemplates/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: interviewTemplateReadBody
    },
      );
    }
  


    export type SchedulingInterviewtemplatesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewtemplatesUpdate>>>
    export type SchedulingInterviewtemplatesUpdateMutationBody = InterviewTemplateReadBody
    export type SchedulingInterviewtemplatesUpdateMutationError = ErrorType<unknown>

    export const useSchedulingInterviewtemplatesUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingInterviewtemplatesUpdate>>, TError,{id: number;data: InterviewTemplateReadBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingInterviewtemplatesUpdate>>, {id: number;data: InterviewTemplateReadBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingInterviewtemplatesUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingInterviewtemplatesUpdate>>, TError, {id: number;data: InterviewTemplateReadBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * made atomic to ensure that the activity is created only after update is successful
 */
export const schedulingInterviewtemplatesPartialUpdate = (
    id: number,
    interviewTemplateReadBody: InterviewTemplateReadBody,
 ) => {
      return customInstance<InterviewTemplateRead>(
      {url: `/scheduling/interviewtemplates/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: interviewTemplateReadBody
    },
      );
    }
  


    export type SchedulingInterviewtemplatesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewtemplatesPartialUpdate>>>
    export type SchedulingInterviewtemplatesPartialUpdateMutationBody = InterviewTemplateReadBody
    export type SchedulingInterviewtemplatesPartialUpdateMutationError = ErrorType<unknown>

    export const useSchedulingInterviewtemplatesPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingInterviewtemplatesPartialUpdate>>, TError,{id: number;data: InterviewTemplateReadBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingInterviewtemplatesPartialUpdate>>, {id: number;data: InterviewTemplateReadBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingInterviewtemplatesPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingInterviewtemplatesPartialUpdate>>, TError, {id: number;data: InterviewTemplateReadBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingInterviewtemplatesDelete = (
    id: number,
 ) => {
      return customInstance<void>(
      {url: `/scheduling/interviewtemplates/${id}/`, method: 'delete'
    },
      );
    }
  


    export type SchedulingInterviewtemplatesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewtemplatesDelete>>>
    
    export type SchedulingInterviewtemplatesDeleteMutationError = ErrorType<unknown>

    export const useSchedulingInterviewtemplatesDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingInterviewtemplatesDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingInterviewtemplatesDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  schedulingInterviewtemplatesDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingInterviewtemplatesDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingInterviewtemplatesSlotsList = (
    templateId: string,
    params?: SchedulingInterviewtemplatesSlotsListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingInterviewtemplatesSlotsList200One | SchedulingInterviewtemplatesSlotsList200Two>(
      {url: `/scheduling/interviewtemplates/${templateId}/slots/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingInterviewtemplatesSlotsListQueryKey = (templateId: string,
    params?: SchedulingInterviewtemplatesSlotsListParams,) => [`/scheduling/interviewtemplates/${templateId}/slots/`, ...(params ? [params]: [])];

    
export type SchedulingInterviewtemplatesSlotsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsList>>>
export type SchedulingInterviewtemplatesSlotsListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingInterviewtemplatesSlotsListInfinite = <TData = Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsList>>, TError = ErrorType<unknown>>(
 templateId: string,
    params?: SchedulingInterviewtemplatesSlotsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingInterviewtemplatesSlotsListQueryKey(templateId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsList>>> = ({ signal }) => schedulingInterviewtemplatesSlotsList(templateId,params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsList>>, TError, TData>(queryKey, queryFn, {enabled: !!(templateId), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingInterviewtemplatesSlotsListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsList>>>
export type SchedulingInterviewtemplatesSlotsListQueryError = ErrorType<unknown>

export const useSchedulingInterviewtemplatesSlotsList = <TData = Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsList>>, TError = ErrorType<unknown>>(
 templateId: string,
    params?: SchedulingInterviewtemplatesSlotsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingInterviewtemplatesSlotsListQueryKey(templateId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsList>>> = ({ signal }) => schedulingInterviewtemplatesSlotsList(templateId,params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsList>>, TError, TData>(queryKey, queryFn, {enabled: !!(templateId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingInterviewtemplatesSlotsCreate = (
    templateId: string,
    interviewerTemplateSlotCreateUpdateBody: InterviewerTemplateSlotCreateUpdateBody,
 ) => {
      return customInstance<InterviewerTemplateSlotCreateUpdate>(
      {url: `/scheduling/interviewtemplates/${templateId}/slots/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: interviewerTemplateSlotCreateUpdateBody
    },
      );
    }
  


    export type SchedulingInterviewtemplatesSlotsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsCreate>>>
    export type SchedulingInterviewtemplatesSlotsCreateMutationBody = InterviewerTemplateSlotCreateUpdateBody
    export type SchedulingInterviewtemplatesSlotsCreateMutationError = ErrorType<unknown>

    export const useSchedulingInterviewtemplatesSlotsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsCreate>>, TError,{templateId: string;data: InterviewerTemplateSlotCreateUpdateBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsCreate>>, {templateId: string;data: InterviewerTemplateSlotCreateUpdateBody}> = (props) => {
          const {templateId,data} = props ?? {};

          return  schedulingInterviewtemplatesSlotsCreate(templateId,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsCreate>>, TError, {templateId: string;data: InterviewerTemplateSlotCreateUpdateBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingInterviewtemplatesSlotsRead = (
    templateId: string,
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<InterviewerTemplateSlotCreateUpdate>(
      {url: `/scheduling/interviewtemplates/${templateId}/slots/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getSchedulingInterviewtemplatesSlotsReadQueryKey = (templateId: string,
    id: string,) => [`/scheduling/interviewtemplates/${templateId}/slots/${id}/`];

    
export type SchedulingInterviewtemplatesSlotsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsRead>>>
export type SchedulingInterviewtemplatesSlotsReadInfiniteQueryError = ErrorType<unknown>

export const useSchedulingInterviewtemplatesSlotsReadInfinite = <TData = Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsRead>>, TError = ErrorType<unknown>>(
 templateId: string,
    id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingInterviewtemplatesSlotsReadQueryKey(templateId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsRead>>> = ({ signal }) => schedulingInterviewtemplatesSlotsRead(templateId,id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(templateId && id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingInterviewtemplatesSlotsReadQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsRead>>>
export type SchedulingInterviewtemplatesSlotsReadQueryError = ErrorType<unknown>

export const useSchedulingInterviewtemplatesSlotsRead = <TData = Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsRead>>, TError = ErrorType<unknown>>(
 templateId: string,
    id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingInterviewtemplatesSlotsReadQueryKey(templateId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsRead>>> = ({ signal }) => schedulingInterviewtemplatesSlotsRead(templateId,id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(templateId && id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingInterviewtemplatesSlotsUpdate = (
    templateId: string,
    id: string,
    interviewerTemplateSlotCreateUpdateBody: InterviewerTemplateSlotCreateUpdateBody,
 ) => {
      return customInstance<InterviewerTemplateSlotCreateUpdate>(
      {url: `/scheduling/interviewtemplates/${templateId}/slots/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: interviewerTemplateSlotCreateUpdateBody
    },
      );
    }
  


    export type SchedulingInterviewtemplatesSlotsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsUpdate>>>
    export type SchedulingInterviewtemplatesSlotsUpdateMutationBody = InterviewerTemplateSlotCreateUpdateBody
    export type SchedulingInterviewtemplatesSlotsUpdateMutationError = ErrorType<unknown>

    export const useSchedulingInterviewtemplatesSlotsUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsUpdate>>, TError,{templateId: string;id: string;data: InterviewerTemplateSlotCreateUpdateBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsUpdate>>, {templateId: string;id: string;data: InterviewerTemplateSlotCreateUpdateBody}> = (props) => {
          const {templateId,id,data} = props ?? {};

          return  schedulingInterviewtemplatesSlotsUpdate(templateId,id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsUpdate>>, TError, {templateId: string;id: string;data: InterviewerTemplateSlotCreateUpdateBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * made atomic to ensure that the activity is created only after update is successful
 */
export const schedulingInterviewtemplatesSlotsPartialUpdate = (
    templateId: string,
    id: string,
    interviewerTemplateSlotCreateUpdateBody: InterviewerTemplateSlotCreateUpdateBody,
 ) => {
      return customInstance<InterviewerTemplateSlotCreateUpdate>(
      {url: `/scheduling/interviewtemplates/${templateId}/slots/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: interviewerTemplateSlotCreateUpdateBody
    },
      );
    }
  


    export type SchedulingInterviewtemplatesSlotsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsPartialUpdate>>>
    export type SchedulingInterviewtemplatesSlotsPartialUpdateMutationBody = InterviewerTemplateSlotCreateUpdateBody
    export type SchedulingInterviewtemplatesSlotsPartialUpdateMutationError = ErrorType<unknown>

    export const useSchedulingInterviewtemplatesSlotsPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsPartialUpdate>>, TError,{templateId: string;id: string;data: InterviewerTemplateSlotCreateUpdateBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsPartialUpdate>>, {templateId: string;id: string;data: InterviewerTemplateSlotCreateUpdateBody}> = (props) => {
          const {templateId,id,data} = props ?? {};

          return  schedulingInterviewtemplatesSlotsPartialUpdate(templateId,id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsPartialUpdate>>, TError, {templateId: string;id: string;data: InterviewerTemplateSlotCreateUpdateBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingInterviewtemplatesSlotsDelete = (
    templateId: string,
    id: string,
 ) => {
      return customInstance<void>(
      {url: `/scheduling/interviewtemplates/${templateId}/slots/${id}/`, method: 'delete'
    },
      );
    }
  


    export type SchedulingInterviewtemplatesSlotsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsDelete>>>
    
    export type SchedulingInterviewtemplatesSlotsDeleteMutationError = ErrorType<unknown>

    export const useSchedulingInterviewtemplatesSlotsDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsDelete>>, TError,{templateId: string;id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsDelete>>, {templateId: string;id: string}> = (props) => {
          const {templateId,id} = props ?? {};

          return  schedulingInterviewtemplatesSlotsDelete(templateId,id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingInterviewtemplatesSlotsDelete>>, TError, {templateId: string;id: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingJobSettingsList = (
    params?: SchedulingJobSettingsListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingJobSettingsList200One | SchedulingJobSettingsList200Two>(
      {url: `/scheduling/job-settings/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingJobSettingsListQueryKey = (params?: SchedulingJobSettingsListParams,) => [`/scheduling/job-settings/`, ...(params ? [params]: [])];

    
export type SchedulingJobSettingsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingJobSettingsList>>>
export type SchedulingJobSettingsListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingJobSettingsListInfinite = <TData = Awaited<ReturnType<typeof schedulingJobSettingsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingJobSettingsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingJobSettingsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingJobSettingsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingJobSettingsList>>> = ({ signal }) => schedulingJobSettingsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingJobSettingsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingJobSettingsListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingJobSettingsList>>>
export type SchedulingJobSettingsListQueryError = ErrorType<unknown>

export const useSchedulingJobSettingsList = <TData = Awaited<ReturnType<typeof schedulingJobSettingsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingJobSettingsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingJobSettingsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingJobSettingsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingJobSettingsList>>> = ({ signal }) => schedulingJobSettingsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingJobSettingsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingJobSettingsCreate = (
    jobScheduleSettingBody: JobScheduleSettingBody,
 ) => {
      return customInstance<JobScheduleSetting>(
      {url: `/scheduling/job-settings/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: jobScheduleSettingBody
    },
      );
    }
  


    export type SchedulingJobSettingsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingJobSettingsCreate>>>
    export type SchedulingJobSettingsCreateMutationBody = JobScheduleSettingBody
    export type SchedulingJobSettingsCreateMutationError = ErrorType<unknown>

    export const useSchedulingJobSettingsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingJobSettingsCreate>>, TError,{data: JobScheduleSettingBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingJobSettingsCreate>>, {data: JobScheduleSettingBody}> = (props) => {
          const {data} = props ?? {};

          return  schedulingJobSettingsCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingJobSettingsCreate>>, TError, {data: JobScheduleSettingBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingJobSettingsRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<JobScheduleSetting>(
      {url: `/scheduling/job-settings/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getSchedulingJobSettingsReadQueryKey = (id: string,) => [`/scheduling/job-settings/${id}/`];

    
export type SchedulingJobSettingsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingJobSettingsRead>>>
export type SchedulingJobSettingsReadInfiniteQueryError = ErrorType<unknown>

export const useSchedulingJobSettingsReadInfinite = <TData = Awaited<ReturnType<typeof schedulingJobSettingsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingJobSettingsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingJobSettingsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingJobSettingsRead>>> = ({ signal }) => schedulingJobSettingsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingJobSettingsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingJobSettingsReadQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingJobSettingsRead>>>
export type SchedulingJobSettingsReadQueryError = ErrorType<unknown>

export const useSchedulingJobSettingsRead = <TData = Awaited<ReturnType<typeof schedulingJobSettingsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingJobSettingsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingJobSettingsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingJobSettingsRead>>> = ({ signal }) => schedulingJobSettingsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingJobSettingsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingJobSettingsUpdate = (
    id: string,
    jobScheduleSettingBody: JobScheduleSettingBody,
 ) => {
      return customInstance<JobScheduleSetting>(
      {url: `/scheduling/job-settings/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: jobScheduleSettingBody
    },
      );
    }
  


    export type SchedulingJobSettingsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingJobSettingsUpdate>>>
    export type SchedulingJobSettingsUpdateMutationBody = JobScheduleSettingBody
    export type SchedulingJobSettingsUpdateMutationError = ErrorType<unknown>

    export const useSchedulingJobSettingsUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingJobSettingsUpdate>>, TError,{id: string;data: JobScheduleSettingBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingJobSettingsUpdate>>, {id: string;data: JobScheduleSettingBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingJobSettingsUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingJobSettingsUpdate>>, TError, {id: string;data: JobScheduleSettingBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingJobSettingsPartialUpdate = (
    id: string,
    jobScheduleSettingBody: JobScheduleSettingBody,
 ) => {
      return customInstance<JobScheduleSetting>(
      {url: `/scheduling/job-settings/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: jobScheduleSettingBody
    },
      );
    }
  


    export type SchedulingJobSettingsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingJobSettingsPartialUpdate>>>
    export type SchedulingJobSettingsPartialUpdateMutationBody = JobScheduleSettingBody
    export type SchedulingJobSettingsPartialUpdateMutationError = ErrorType<unknown>

    export const useSchedulingJobSettingsPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingJobSettingsPartialUpdate>>, TError,{id: string;data: JobScheduleSettingBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingJobSettingsPartialUpdate>>, {id: string;data: JobScheduleSettingBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingJobSettingsPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingJobSettingsPartialUpdate>>, TError, {id: string;data: JobScheduleSettingBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingJobsBookingLinksRead = (
    recruiterId: string,
    params?: SchedulingJobsBookingLinksReadParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingJobsBookingLinksRead200One | SchedulingJobsBookingLinksRead200Two>(
      {url: `/scheduling/jobs/booking-links/${recruiterId}/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingJobsBookingLinksReadQueryKey = (recruiterId: string,
    params?: SchedulingJobsBookingLinksReadParams,) => [`/scheduling/jobs/booking-links/${recruiterId}/`, ...(params ? [params]: [])];

    
export type SchedulingJobsBookingLinksReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingJobsBookingLinksRead>>>
export type SchedulingJobsBookingLinksReadInfiniteQueryError = ErrorType<unknown>

export const useSchedulingJobsBookingLinksReadInfinite = <TData = Awaited<ReturnType<typeof schedulingJobsBookingLinksRead>>, TError = ErrorType<unknown>>(
 recruiterId: string,
    params?: SchedulingJobsBookingLinksReadParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingJobsBookingLinksRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingJobsBookingLinksReadQueryKey(recruiterId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingJobsBookingLinksRead>>> = ({ signal }) => schedulingJobsBookingLinksRead(recruiterId,params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingJobsBookingLinksRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(recruiterId), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingJobsBookingLinksReadQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingJobsBookingLinksRead>>>
export type SchedulingJobsBookingLinksReadQueryError = ErrorType<unknown>

export const useSchedulingJobsBookingLinksRead = <TData = Awaited<ReturnType<typeof schedulingJobsBookingLinksRead>>, TError = ErrorType<unknown>>(
 recruiterId: string,
    params?: SchedulingJobsBookingLinksReadParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingJobsBookingLinksRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingJobsBookingLinksReadQueryKey(recruiterId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingJobsBookingLinksRead>>> = ({ signal }) => schedulingJobsBookingLinksRead(recruiterId,params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingJobsBookingLinksRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(recruiterId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingMembersList = (
    params?: SchedulingMembersListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingMembersList200One | SchedulingMembersList200Two>(
      {url: `/scheduling/members/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingMembersListQueryKey = (params?: SchedulingMembersListParams,) => [`/scheduling/members/`, ...(params ? [params]: [])];

    
export type SchedulingMembersListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingMembersList>>>
export type SchedulingMembersListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingMembersListInfinite = <TData = Awaited<ReturnType<typeof schedulingMembersList>>, TError = ErrorType<unknown>>(
 params?: SchedulingMembersListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingMembersList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingMembersListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingMembersList>>> = ({ signal }) => schedulingMembersList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingMembersList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingMembersListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingMembersList>>>
export type SchedulingMembersListQueryError = ErrorType<unknown>

export const useSchedulingMembersList = <TData = Awaited<ReturnType<typeof schedulingMembersList>>, TError = ErrorType<unknown>>(
 params?: SchedulingMembersListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingMembersList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingMembersListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingMembersList>>> = ({ signal }) => schedulingMembersList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingMembersList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingMembersSettingsRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<OrgMemberInterviewerScheduleSetting>(
      {url: `/scheduling/members/${id}/settings/`, method: 'get', signal
    },
      );
    }
  

export const getSchedulingMembersSettingsReadQueryKey = (id: string,) => [`/scheduling/members/${id}/settings/`];

    
export type SchedulingMembersSettingsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingMembersSettingsRead>>>
export type SchedulingMembersSettingsReadInfiniteQueryError = ErrorType<unknown>

export const useSchedulingMembersSettingsReadInfinite = <TData = Awaited<ReturnType<typeof schedulingMembersSettingsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingMembersSettingsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingMembersSettingsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingMembersSettingsRead>>> = ({ signal }) => schedulingMembersSettingsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingMembersSettingsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingMembersSettingsReadQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingMembersSettingsRead>>>
export type SchedulingMembersSettingsReadQueryError = ErrorType<unknown>

export const useSchedulingMembersSettingsRead = <TData = Awaited<ReturnType<typeof schedulingMembersSettingsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingMembersSettingsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingMembersSettingsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingMembersSettingsRead>>> = ({ signal }) => schedulingMembersSettingsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingMembersSettingsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingMembersSettingsUpdate = (
    id: string,
    orgMemberInterviewerScheduleSettingBody: OrgMemberInterviewerScheduleSettingBody,
 ) => {
      return customInstance<OrgMemberInterviewerScheduleSetting>(
      {url: `/scheduling/members/${id}/settings/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: orgMemberInterviewerScheduleSettingBody
    },
      );
    }
  


    export type SchedulingMembersSettingsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingMembersSettingsUpdate>>>
    export type SchedulingMembersSettingsUpdateMutationBody = OrgMemberInterviewerScheduleSettingBody
    export type SchedulingMembersSettingsUpdateMutationError = ErrorType<unknown>

    export const useSchedulingMembersSettingsUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingMembersSettingsUpdate>>, TError,{id: string;data: OrgMemberInterviewerScheduleSettingBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingMembersSettingsUpdate>>, {id: string;data: OrgMemberInterviewerScheduleSettingBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingMembersSettingsUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingMembersSettingsUpdate>>, TError, {id: string;data: OrgMemberInterviewerScheduleSettingBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingMembersSettingsPartialUpdate = (
    id: string,
    orgMemberInterviewerScheduleSettingBody: OrgMemberInterviewerScheduleSettingBody,
 ) => {
      return customInstance<OrgMemberInterviewerScheduleSetting>(
      {url: `/scheduling/members/${id}/settings/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: orgMemberInterviewerScheduleSettingBody
    },
      );
    }
  


    export type SchedulingMembersSettingsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingMembersSettingsPartialUpdate>>>
    export type SchedulingMembersSettingsPartialUpdateMutationBody = OrgMemberInterviewerScheduleSettingBody
    export type SchedulingMembersSettingsPartialUpdateMutationError = ErrorType<unknown>

    export const useSchedulingMembersSettingsPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingMembersSettingsPartialUpdate>>, TError,{id: string;data: OrgMemberInterviewerScheduleSettingBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingMembersSettingsPartialUpdate>>, {id: string;data: OrgMemberInterviewerScheduleSettingBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingMembersSettingsPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingMembersSettingsPartialUpdate>>, TError, {id: string;data: OrgMemberInterviewerScheduleSettingBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingRecruitingCheckinCreate = (
    recruitingEventCheckIn: RecruitingEventCheckIn,
 ) => {
      return customInstance<RecruitingEventCheckIn>(
      {url: `/scheduling/recruiting/checkin/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: recruitingEventCheckIn
    },
      );
    }
  


    export type SchedulingRecruitingCheckinCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingRecruitingCheckinCreate>>>
    export type SchedulingRecruitingCheckinCreateMutationBody = RecruitingEventCheckIn
    export type SchedulingRecruitingCheckinCreateMutationError = ErrorType<unknown>

    export const useSchedulingRecruitingCheckinCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingRecruitingCheckinCreate>>, TError,{data: RecruitingEventCheckIn}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingRecruitingCheckinCreate>>, {data: RecruitingEventCheckIn}> = (props) => {
          const {data} = props ?? {};

          return  schedulingRecruitingCheckinCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingRecruitingCheckinCreate>>, TError, {data: RecruitingEventCheckIn}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * API view to list and create recruiting events
 */
export const schedulingRecruitingEventsList = (
    params?: SchedulingRecruitingEventsListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingRecruitingEventsList200One | SchedulingRecruitingEventsList200Two>(
      {url: `/scheduling/recruiting/events/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingRecruitingEventsListQueryKey = (params?: SchedulingRecruitingEventsListParams,) => [`/scheduling/recruiting/events/`, ...(params ? [params]: [])];

    
export type SchedulingRecruitingEventsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingRecruitingEventsList>>>
export type SchedulingRecruitingEventsListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingRecruitingEventsListInfinite = <TData = Awaited<ReturnType<typeof schedulingRecruitingEventsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingRecruitingEventsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingRecruitingEventsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingRecruitingEventsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingRecruitingEventsList>>> = ({ signal }) => schedulingRecruitingEventsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingRecruitingEventsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingRecruitingEventsListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingRecruitingEventsList>>>
export type SchedulingRecruitingEventsListQueryError = ErrorType<unknown>

export const useSchedulingRecruitingEventsList = <TData = Awaited<ReturnType<typeof schedulingRecruitingEventsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingRecruitingEventsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingRecruitingEventsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingRecruitingEventsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingRecruitingEventsList>>> = ({ signal }) => schedulingRecruitingEventsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingRecruitingEventsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * API view to list and create recruiting events
 */
export const schedulingRecruitingEventsCreate = (
    recruitingEvent: RecruitingEvent,
 ) => {
      return customInstance<RecruitingEvent>(
      {url: `/scheduling/recruiting/events/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: recruitingEvent
    },
      );
    }
  


    export type SchedulingRecruitingEventsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingRecruitingEventsCreate>>>
    export type SchedulingRecruitingEventsCreateMutationBody = RecruitingEvent
    export type SchedulingRecruitingEventsCreateMutationError = ErrorType<unknown>

    export const useSchedulingRecruitingEventsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingRecruitingEventsCreate>>, TError,{data: RecruitingEvent}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingRecruitingEventsCreate>>, {data: RecruitingEvent}> = (props) => {
          const {data} = props ?? {};

          return  schedulingRecruitingEventsCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingRecruitingEventsCreate>>, TError, {data: RecruitingEvent}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * API view to retrieve, update and destroy a recruiting event
 */
export const schedulingRecruitingEventsRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<RecruitingEventDetails>(
      {url: `/scheduling/recruiting/events/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getSchedulingRecruitingEventsReadQueryKey = (id: string,) => [`/scheduling/recruiting/events/${id}/`];

    
export type SchedulingRecruitingEventsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingRecruitingEventsRead>>>
export type SchedulingRecruitingEventsReadInfiniteQueryError = ErrorType<unknown>

export const useSchedulingRecruitingEventsReadInfinite = <TData = Awaited<ReturnType<typeof schedulingRecruitingEventsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingRecruitingEventsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingRecruitingEventsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingRecruitingEventsRead>>> = ({ signal }) => schedulingRecruitingEventsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingRecruitingEventsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingRecruitingEventsReadQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingRecruitingEventsRead>>>
export type SchedulingRecruitingEventsReadQueryError = ErrorType<unknown>

export const useSchedulingRecruitingEventsRead = <TData = Awaited<ReturnType<typeof schedulingRecruitingEventsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingRecruitingEventsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingRecruitingEventsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingRecruitingEventsRead>>> = ({ signal }) => schedulingRecruitingEventsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingRecruitingEventsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * API view to retrieve, update and destroy a recruiting event
 */
export const schedulingRecruitingEventsUpdate = (
    id: string,
    recruitingEventDetailsBody: RecruitingEventDetailsBody,
 ) => {
      return customInstance<RecruitingEventDetails>(
      {url: `/scheduling/recruiting/events/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: recruitingEventDetailsBody
    },
      );
    }
  


    export type SchedulingRecruitingEventsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingRecruitingEventsUpdate>>>
    export type SchedulingRecruitingEventsUpdateMutationBody = RecruitingEventDetailsBody
    export type SchedulingRecruitingEventsUpdateMutationError = ErrorType<unknown>

    export const useSchedulingRecruitingEventsUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingRecruitingEventsUpdate>>, TError,{id: string;data: RecruitingEventDetailsBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingRecruitingEventsUpdate>>, {id: string;data: RecruitingEventDetailsBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingRecruitingEventsUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingRecruitingEventsUpdate>>, TError, {id: string;data: RecruitingEventDetailsBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * API view to retrieve, update and destroy a recruiting event
 */
export const schedulingRecruitingEventsPartialUpdate = (
    id: string,
    recruitingEventDetailsBody: RecruitingEventDetailsBody,
 ) => {
      return customInstance<RecruitingEventDetails>(
      {url: `/scheduling/recruiting/events/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: recruitingEventDetailsBody
    },
      );
    }
  


    export type SchedulingRecruitingEventsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingRecruitingEventsPartialUpdate>>>
    export type SchedulingRecruitingEventsPartialUpdateMutationBody = RecruitingEventDetailsBody
    export type SchedulingRecruitingEventsPartialUpdateMutationError = ErrorType<unknown>

    export const useSchedulingRecruitingEventsPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingRecruitingEventsPartialUpdate>>, TError,{id: string;data: RecruitingEventDetailsBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingRecruitingEventsPartialUpdate>>, {id: string;data: RecruitingEventDetailsBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingRecruitingEventsPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingRecruitingEventsPartialUpdate>>, TError, {id: string;data: RecruitingEventDetailsBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * API view to retrieve, update and destroy a recruiting event
 */
export const schedulingRecruitingEventsDelete = (
    id: string,
 ) => {
      return customInstance<void>(
      {url: `/scheduling/recruiting/events/${id}/`, method: 'delete'
    },
      );
    }
  


    export type SchedulingRecruitingEventsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingRecruitingEventsDelete>>>
    
    export type SchedulingRecruitingEventsDeleteMutationError = ErrorType<unknown>

    export const useSchedulingRecruitingEventsDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingRecruitingEventsDelete>>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingRecruitingEventsDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  schedulingRecruitingEventsDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingRecruitingEventsDelete>>, TError, {id: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingRecruitingEventsApplicationsList = (
    id: string,
    params?: SchedulingRecruitingEventsApplicationsListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingRecruitingEventsApplicationsList200One | SchedulingRecruitingEventsApplicationsList200Two>(
      {url: `/scheduling/recruiting/events/${id}/applications/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingRecruitingEventsApplicationsListQueryKey = (id: string,
    params?: SchedulingRecruitingEventsApplicationsListParams,) => [`/scheduling/recruiting/events/${id}/applications/`, ...(params ? [params]: [])];

    
export type SchedulingRecruitingEventsApplicationsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingRecruitingEventsApplicationsList>>>
export type SchedulingRecruitingEventsApplicationsListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingRecruitingEventsApplicationsListInfinite = <TData = Awaited<ReturnType<typeof schedulingRecruitingEventsApplicationsList>>, TError = ErrorType<unknown>>(
 id: string,
    params?: SchedulingRecruitingEventsApplicationsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingRecruitingEventsApplicationsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingRecruitingEventsApplicationsListQueryKey(id,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingRecruitingEventsApplicationsList>>> = ({ signal }) => schedulingRecruitingEventsApplicationsList(id,params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingRecruitingEventsApplicationsList>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingRecruitingEventsApplicationsListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingRecruitingEventsApplicationsList>>>
export type SchedulingRecruitingEventsApplicationsListQueryError = ErrorType<unknown>

export const useSchedulingRecruitingEventsApplicationsList = <TData = Awaited<ReturnType<typeof schedulingRecruitingEventsApplicationsList>>, TError = ErrorType<unknown>>(
 id: string,
    params?: SchedulingRecruitingEventsApplicationsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingRecruitingEventsApplicationsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingRecruitingEventsApplicationsListQueryKey(id,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingRecruitingEventsApplicationsList>>> = ({ signal }) => schedulingRecruitingEventsApplicationsList(id,params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingRecruitingEventsApplicationsList>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingSchedulinginterviewrequestsList = (
    params?: SchedulingSchedulinginterviewrequestsListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingSchedulinginterviewrequestsList200One | SchedulingSchedulinginterviewrequestsList200Two>(
      {url: `/scheduling/schedulinginterviewrequests/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingSchedulinginterviewrequestsListQueryKey = (params?: SchedulingSchedulinginterviewrequestsListParams,) => [`/scheduling/schedulinginterviewrequests/`, ...(params ? [params]: [])];

    
export type SchedulingSchedulinginterviewrequestsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsList>>>
export type SchedulingSchedulinginterviewrequestsListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingSchedulinginterviewrequestsListInfinite = <TData = Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingSchedulinginterviewrequestsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingSchedulinginterviewrequestsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsList>>> = ({ signal }) => schedulingSchedulinginterviewrequestsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingSchedulinginterviewrequestsListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsList>>>
export type SchedulingSchedulinginterviewrequestsListQueryError = ErrorType<unknown>

export const useSchedulingSchedulinginterviewrequestsList = <TData = Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingSchedulinginterviewrequestsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingSchedulinginterviewrequestsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsList>>> = ({ signal }) => schedulingSchedulinginterviewrequestsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingSchedulinginterviewrequestsCreate = (
    schedulingInterviewRequestBody: SchedulingInterviewRequestBody,
 ) => {
      return customInstance<SchedulingInterviewRequest>(
      {url: `/scheduling/schedulinginterviewrequests/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: schedulingInterviewRequestBody
    },
      );
    }
  


    export type SchedulingSchedulinginterviewrequestsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsCreate>>>
    export type SchedulingSchedulinginterviewrequestsCreateMutationBody = SchedulingInterviewRequestBody
    export type SchedulingSchedulinginterviewrequestsCreateMutationError = ErrorType<unknown>

    export const useSchedulingSchedulinginterviewrequestsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsCreate>>, TError,{data: SchedulingInterviewRequestBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsCreate>>, {data: SchedulingInterviewRequestBody}> = (props) => {
          const {data} = props ?? {};

          return  schedulingSchedulinginterviewrequestsCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsCreate>>, TError, {data: SchedulingInterviewRequestBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingSchedulinginterviewrequestsRead = (
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingInterviewRequest>(
      {url: `/scheduling/schedulinginterviewrequests/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getSchedulingSchedulinginterviewrequestsReadQueryKey = (id: number,) => [`/scheduling/schedulinginterviewrequests/${id}/`];

    
export type SchedulingSchedulinginterviewrequestsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsRead>>>
export type SchedulingSchedulinginterviewrequestsReadInfiniteQueryError = ErrorType<unknown>

export const useSchedulingSchedulinginterviewrequestsReadInfinite = <TData = Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingSchedulinginterviewrequestsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsRead>>> = ({ signal }) => schedulingSchedulinginterviewrequestsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingSchedulinginterviewrequestsReadQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsRead>>>
export type SchedulingSchedulinginterviewrequestsReadQueryError = ErrorType<unknown>

export const useSchedulingSchedulinginterviewrequestsRead = <TData = Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingSchedulinginterviewrequestsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsRead>>> = ({ signal }) => schedulingSchedulinginterviewrequestsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingSchedulinginterviewrequestsUpdate = (
    id: number,
    schedulingInterviewRequestBody: SchedulingInterviewRequestBody,
 ) => {
      return customInstance<SchedulingInterviewRequest>(
      {url: `/scheduling/schedulinginterviewrequests/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: schedulingInterviewRequestBody
    },
      );
    }
  


    export type SchedulingSchedulinginterviewrequestsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsUpdate>>>
    export type SchedulingSchedulinginterviewrequestsUpdateMutationBody = SchedulingInterviewRequestBody
    export type SchedulingSchedulinginterviewrequestsUpdateMutationError = ErrorType<unknown>

    export const useSchedulingSchedulinginterviewrequestsUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsUpdate>>, TError,{id: number;data: SchedulingInterviewRequestBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsUpdate>>, {id: number;data: SchedulingInterviewRequestBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingSchedulinginterviewrequestsUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsUpdate>>, TError, {id: number;data: SchedulingInterviewRequestBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * made atomic to ensure that the activity is created only after update is successful
 */
export const schedulingSchedulinginterviewrequestsPartialUpdate = (
    id: number,
    schedulingInterviewRequestBody: SchedulingInterviewRequestBody,
 ) => {
      return customInstance<SchedulingInterviewRequest>(
      {url: `/scheduling/schedulinginterviewrequests/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: schedulingInterviewRequestBody
    },
      );
    }
  


    export type SchedulingSchedulinginterviewrequestsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsPartialUpdate>>>
    export type SchedulingSchedulinginterviewrequestsPartialUpdateMutationBody = SchedulingInterviewRequestBody
    export type SchedulingSchedulinginterviewrequestsPartialUpdateMutationError = ErrorType<unknown>

    export const useSchedulingSchedulinginterviewrequestsPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsPartialUpdate>>, TError,{id: number;data: SchedulingInterviewRequestBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsPartialUpdate>>, {id: number;data: SchedulingInterviewRequestBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingSchedulinginterviewrequestsPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsPartialUpdate>>, TError, {id: number;data: SchedulingInterviewRequestBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingSchedulinginterviewrequestsDelete = (
    id: number,
 ) => {
      return customInstance<void>(
      {url: `/scheduling/schedulinginterviewrequests/${id}/`, method: 'delete'
    },
      );
    }
  


    export type SchedulingSchedulinginterviewrequestsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsDelete>>>
    
    export type SchedulingSchedulinginterviewrequestsDeleteMutationError = ErrorType<unknown>

    export const useSchedulingSchedulinginterviewrequestsDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  schedulingSchedulinginterviewrequestsDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingSchedulinginterviewrequestsAtsInterviewConfigUpdate = (
    id: number,
    schedulingInterviewRequestATSMappingBody: SchedulingInterviewRequestATSMappingBody,
 ) => {
      return customInstance<SchedulingInterviewRequestATSMapping>(
      {url: `/scheduling/schedulinginterviewrequests/${id}/ats_interview_config/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: schedulingInterviewRequestATSMappingBody
    },
      );
    }
  


    export type SchedulingSchedulinginterviewrequestsAtsInterviewConfigUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsAtsInterviewConfigUpdate>>>
    export type SchedulingSchedulinginterviewrequestsAtsInterviewConfigUpdateMutationBody = SchedulingInterviewRequestATSMappingBody
    export type SchedulingSchedulinginterviewrequestsAtsInterviewConfigUpdateMutationError = ErrorType<unknown>

    export const useSchedulingSchedulinginterviewrequestsAtsInterviewConfigUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsAtsInterviewConfigUpdate>>, TError,{id: number;data: SchedulingInterviewRequestATSMappingBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsAtsInterviewConfigUpdate>>, {id: number;data: SchedulingInterviewRequestATSMappingBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingSchedulinginterviewrequestsAtsInterviewConfigUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsAtsInterviewConfigUpdate>>, TError, {id: number;data: SchedulingInterviewRequestATSMappingBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingSchedulinginterviewrequestsAtsInterviewConfigPartialUpdate = (
    id: number,
    schedulingInterviewRequestATSMappingBody: SchedulingInterviewRequestATSMappingBody,
 ) => {
      return customInstance<SchedulingInterviewRequestATSMapping>(
      {url: `/scheduling/schedulinginterviewrequests/${id}/ats_interview_config/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: schedulingInterviewRequestATSMappingBody
    },
      );
    }
  


    export type SchedulingSchedulinginterviewrequestsAtsInterviewConfigPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsAtsInterviewConfigPartialUpdate>>>
    export type SchedulingSchedulinginterviewrequestsAtsInterviewConfigPartialUpdateMutationBody = SchedulingInterviewRequestATSMappingBody
    export type SchedulingSchedulinginterviewrequestsAtsInterviewConfigPartialUpdateMutationError = ErrorType<unknown>

    export const useSchedulingSchedulinginterviewrequestsAtsInterviewConfigPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsAtsInterviewConfigPartialUpdate>>, TError,{id: number;data: SchedulingInterviewRequestATSMappingBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsAtsInterviewConfigPartialUpdate>>, {id: number;data: SchedulingInterviewRequestATSMappingBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingSchedulinginterviewrequestsAtsInterviewConfigPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsAtsInterviewConfigPartialUpdate>>, TError, {id: number;data: SchedulingInterviewRequestATSMappingBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingSchedulinginterviewrequestsCancelDelete = (
    id: number,
 ) => {
      return customInstance<void>(
      {url: `/scheduling/schedulinginterviewrequests/${id}/cancel/`, method: 'delete'
    },
      );
    }
  


    export type SchedulingSchedulinginterviewrequestsCancelDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsCancelDelete>>>
    
    export type SchedulingSchedulinginterviewrequestsCancelDeleteMutationError = ErrorType<unknown>

    export const useSchedulingSchedulinginterviewrequestsCancelDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsCancelDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsCancelDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  schedulingSchedulinginterviewrequestsCancelDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingSchedulinginterviewrequestsCancelDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingSchedulingrequestsList = (
    params?: SchedulingSchedulingrequestsListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingSchedulingrequestsList200One | SchedulingSchedulingrequestsList200Two>(
      {url: `/scheduling/schedulingrequests/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingSchedulingrequestsListQueryKey = (params?: SchedulingSchedulingrequestsListParams,) => [`/scheduling/schedulingrequests/`, ...(params ? [params]: [])];

    
export type SchedulingSchedulingrequestsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsList>>>
export type SchedulingSchedulingrequestsListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingSchedulingrequestsListInfinite = <TData = Awaited<ReturnType<typeof schedulingSchedulingrequestsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingSchedulingrequestsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingSchedulingrequestsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsList>>> = ({ signal }) => schedulingSchedulingrequestsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingSchedulingrequestsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingSchedulingrequestsListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsList>>>
export type SchedulingSchedulingrequestsListQueryError = ErrorType<unknown>

export const useSchedulingSchedulingrequestsList = <TData = Awaited<ReturnType<typeof schedulingSchedulingrequestsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingSchedulingrequestsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingSchedulingrequestsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsList>>> = ({ signal }) => schedulingSchedulingrequestsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingSchedulingrequestsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingSchedulingrequestsCreate = (
    schedulingRequest: SchedulingRequest,
 ) => {
      return customInstance<SchedulingRequest>(
      {url: `/scheduling/schedulingrequests/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: schedulingRequest
    },
      );
    }
  


    export type SchedulingSchedulingrequestsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsCreate>>>
    export type SchedulingSchedulingrequestsCreateMutationBody = SchedulingRequest
    export type SchedulingSchedulingrequestsCreateMutationError = ErrorType<unknown>

    export const useSchedulingSchedulingrequestsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsCreate>>, TError,{data: SchedulingRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsCreate>>, {data: SchedulingRequest}> = (props) => {
          const {data} = props ?? {};

          return  schedulingSchedulingrequestsCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingSchedulingrequestsCreate>>, TError, {data: SchedulingRequest}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingSchedulingrequestsSlackChannelsList = (
    params?: SchedulingSchedulingrequestsSlackChannelsListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingSchedulingrequestsSlackChannelsList200One | SchedulingSchedulingrequestsSlackChannelsList200Two>(
      {url: `/scheduling/schedulingrequests/slack/channels/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingSchedulingrequestsSlackChannelsListQueryKey = (params?: SchedulingSchedulingrequestsSlackChannelsListParams,) => [`/scheduling/schedulingrequests/slack/channels/`, ...(params ? [params]: [])];

    
export type SchedulingSchedulingrequestsSlackChannelsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsSlackChannelsList>>>
export type SchedulingSchedulingrequestsSlackChannelsListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingSchedulingrequestsSlackChannelsListInfinite = <TData = Awaited<ReturnType<typeof schedulingSchedulingrequestsSlackChannelsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingSchedulingrequestsSlackChannelsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsSlackChannelsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingSchedulingrequestsSlackChannelsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsSlackChannelsList>>> = ({ signal }) => schedulingSchedulingrequestsSlackChannelsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingSchedulingrequestsSlackChannelsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingSchedulingrequestsSlackChannelsListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsSlackChannelsList>>>
export type SchedulingSchedulingrequestsSlackChannelsListQueryError = ErrorType<unknown>

export const useSchedulingSchedulingrequestsSlackChannelsList = <TData = Awaited<ReturnType<typeof schedulingSchedulingrequestsSlackChannelsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingSchedulingrequestsSlackChannelsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsSlackChannelsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingSchedulingrequestsSlackChannelsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsSlackChannelsList>>> = ({ signal }) => schedulingSchedulingrequestsSlackChannelsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingSchedulingrequestsSlackChannelsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingSchedulingrequestsRead = (
    id: string,
    params?: SchedulingSchedulingrequestsReadParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingRequestRead>(
      {url: `/scheduling/schedulingrequests/${id}/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingSchedulingrequestsReadQueryKey = (id: string,
    params?: SchedulingSchedulingrequestsReadParams,) => [`/scheduling/schedulingrequests/${id}/`, ...(params ? [params]: [])];

    
export type SchedulingSchedulingrequestsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsRead>>>
export type SchedulingSchedulingrequestsReadInfiniteQueryError = ErrorType<unknown>

export const useSchedulingSchedulingrequestsReadInfinite = <TData = Awaited<ReturnType<typeof schedulingSchedulingrequestsRead>>, TError = ErrorType<unknown>>(
 id: string,
    params?: SchedulingSchedulingrequestsReadParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingSchedulingrequestsReadQueryKey(id,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsRead>>> = ({ signal }) => schedulingSchedulingrequestsRead(id,params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingSchedulingrequestsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingSchedulingrequestsReadQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsRead>>>
export type SchedulingSchedulingrequestsReadQueryError = ErrorType<unknown>

export const useSchedulingSchedulingrequestsRead = <TData = Awaited<ReturnType<typeof schedulingSchedulingrequestsRead>>, TError = ErrorType<unknown>>(
 id: string,
    params?: SchedulingSchedulingrequestsReadParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingSchedulingrequestsReadQueryKey(id,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsRead>>> = ({ signal }) => schedulingSchedulingrequestsRead(id,params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingSchedulingrequestsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingSchedulingrequestsUpdate = (
    id: string,
    schedulingRequestReadBody: SchedulingRequestReadBody,
 ) => {
      return customInstance<SchedulingRequestRead>(
      {url: `/scheduling/schedulingrequests/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: schedulingRequestReadBody
    },
      );
    }
  


    export type SchedulingSchedulingrequestsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsUpdate>>>
    export type SchedulingSchedulingrequestsUpdateMutationBody = SchedulingRequestReadBody
    export type SchedulingSchedulingrequestsUpdateMutationError = ErrorType<unknown>

    export const useSchedulingSchedulingrequestsUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsUpdate>>, TError,{id: string;data: SchedulingRequestReadBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsUpdate>>, {id: string;data: SchedulingRequestReadBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingSchedulingrequestsUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingSchedulingrequestsUpdate>>, TError, {id: string;data: SchedulingRequestReadBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * made atomic to ensure that the activity is created only after update is successful
 */
export const schedulingSchedulingrequestsPartialUpdate = (
    id: string,
    schedulingRequestReadBody: SchedulingRequestReadBody,
 ) => {
      return customInstance<SchedulingRequestRead>(
      {url: `/scheduling/schedulingrequests/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: schedulingRequestReadBody
    },
      );
    }
  


    export type SchedulingSchedulingrequestsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsPartialUpdate>>>
    export type SchedulingSchedulingrequestsPartialUpdateMutationBody = SchedulingRequestReadBody
    export type SchedulingSchedulingrequestsPartialUpdateMutationError = ErrorType<unknown>

    export const useSchedulingSchedulingrequestsPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsPartialUpdate>>, TError,{id: string;data: SchedulingRequestReadBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsPartialUpdate>>, {id: string;data: SchedulingRequestReadBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingSchedulingrequestsPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingSchedulingrequestsPartialUpdate>>, TError, {id: string;data: SchedulingRequestReadBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingSchedulingrequestsDelete = (
    id: string,
 ) => {
      return customInstance<void>(
      {url: `/scheduling/schedulingrequests/${id}/`, method: 'delete'
    },
      );
    }
  


    export type SchedulingSchedulingrequestsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsDelete>>>
    
    export type SchedulingSchedulingrequestsDeleteMutationError = ErrorType<unknown>

    export const useSchedulingSchedulingrequestsDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsDelete>>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  schedulingSchedulingrequestsDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingSchedulingrequestsDelete>>, TError, {id: string}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingSchedulingrequestsCancelDelete = (
    id: string,
 ) => {
      return customInstance<void>(
      {url: `/scheduling/schedulingrequests/${id}/cancel/`, method: 'delete'
    },
      );
    }
  


    export type SchedulingSchedulingrequestsCancelDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsCancelDelete>>>
    
    export type SchedulingSchedulingrequestsCancelDeleteMutationError = ErrorType<unknown>

    export const useSchedulingSchedulingrequestsCancelDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsCancelDelete>>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsCancelDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  schedulingSchedulingrequestsCancelDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingSchedulingrequestsCancelDelete>>, TError, {id: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Refreshes the application from the ATS and updates the scheduling request
 */
export const schedulingSchedulingrequestsRefreshApplicationCreate = (
    id: string,
    schedulingRequestReadBody: SchedulingRequestReadBody,
 ) => {
      return customInstance<SchedulingRequestRead>(
      {url: `/scheduling/schedulingrequests/${id}/refresh_application/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: schedulingRequestReadBody
    },
      );
    }
  


    export type SchedulingSchedulingrequestsRefreshApplicationCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsRefreshApplicationCreate>>>
    export type SchedulingSchedulingrequestsRefreshApplicationCreateMutationBody = SchedulingRequestReadBody
    export type SchedulingSchedulingrequestsRefreshApplicationCreateMutationError = ErrorType<unknown>

    export const useSchedulingSchedulingrequestsRefreshApplicationCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsRefreshApplicationCreate>>, TError,{id: string;data: SchedulingRequestReadBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsRefreshApplicationCreate>>, {id: string;data: SchedulingRequestReadBody}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingSchedulingrequestsRefreshApplicationCreate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingSchedulingrequestsRefreshApplicationCreate>>, TError, {id: string;data: SchedulingRequestReadBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Fixes wrong mappings on a scheduling request
    - stage
    - event.stage
    - ats interviews
 */
export const schedulingSchedulingrequestsRepairMappingsCreate = (
    id: string,
    schedulingRequestRepairStage: SchedulingRequestRepairStage,
 ) => {
      return customInstance<SchedulingRequestRead>(
      {url: `/scheduling/schedulingrequests/${id}/repair_mappings/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: schedulingRequestRepairStage
    },
      );
    }
  


    export type SchedulingSchedulingrequestsRepairMappingsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsRepairMappingsCreate>>>
    export type SchedulingSchedulingrequestsRepairMappingsCreateMutationBody = SchedulingRequestRepairStage
    export type SchedulingSchedulingrequestsRepairMappingsCreateMutationError = ErrorType<unknown>

    export const useSchedulingSchedulingrequestsRepairMappingsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsRepairMappingsCreate>>, TError,{id: string;data: SchedulingRequestRepairStage}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsRepairMappingsCreate>>, {id: string;data: SchedulingRequestRepairStage}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingSchedulingrequestsRepairMappingsCreate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingSchedulingrequestsRepairMappingsCreate>>, TError, {id: string;data: SchedulingRequestRepairStage}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Create a new slack channel for a scheduling request
 */
export const schedulingSchedulingrequestsSlackCreateChannelCreate = (
    id: number,
    createSchedulingRequestSlackChannel: CreateSchedulingRequestSlackChannel,
 ) => {
      return customInstance<CreateSchedulingRequestSlackChannel>(
      {url: `/scheduling/schedulingrequests/${id}/slack/create-channel/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: createSchedulingRequestSlackChannel
    },
      );
    }
  


    export type SchedulingSchedulingrequestsSlackCreateChannelCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsSlackCreateChannelCreate>>>
    export type SchedulingSchedulingrequestsSlackCreateChannelCreateMutationBody = CreateSchedulingRequestSlackChannel
    export type SchedulingSchedulingrequestsSlackCreateChannelCreateMutationError = ErrorType<unknown>

    export const useSchedulingSchedulingrequestsSlackCreateChannelCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsSlackCreateChannelCreate>>, TError,{id: number;data: CreateSchedulingRequestSlackChannel}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsSlackCreateChannelCreate>>, {id: number;data: CreateSchedulingRequestSlackChannel}> = (props) => {
          const {id,data} = props ?? {};

          return  schedulingSchedulingrequestsSlackCreateChannelCreate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingSchedulingrequestsSlackCreateChannelCreate>>, TError, {id: number;data: CreateSchedulingRequestSlackChannel}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingSchedulingrequestsActivitiesList = (
    schedulingRequestId: string,
    params?: SchedulingSchedulingrequestsActivitiesListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingSchedulingrequestsActivitiesList200One | SchedulingSchedulingrequestsActivitiesList200Two>(
      {url: `/scheduling/schedulingrequests/${schedulingRequestId}/activities/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingSchedulingrequestsActivitiesListQueryKey = (schedulingRequestId: string,
    params?: SchedulingSchedulingrequestsActivitiesListParams,) => [`/scheduling/schedulingrequests/${schedulingRequestId}/activities/`, ...(params ? [params]: [])];

    
export type SchedulingSchedulingrequestsActivitiesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesList>>>
export type SchedulingSchedulingrequestsActivitiesListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingSchedulingrequestsActivitiesListInfinite = <TData = Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesList>>, TError = ErrorType<unknown>>(
 schedulingRequestId: string,
    params?: SchedulingSchedulingrequestsActivitiesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingSchedulingrequestsActivitiesListQueryKey(schedulingRequestId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesList>>> = ({ signal }) => schedulingSchedulingrequestsActivitiesList(schedulingRequestId,params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesList>>, TError, TData>(queryKey, queryFn, {enabled: !!(schedulingRequestId), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingSchedulingrequestsActivitiesListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesList>>>
export type SchedulingSchedulingrequestsActivitiesListQueryError = ErrorType<unknown>

export const useSchedulingSchedulingrequestsActivitiesList = <TData = Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesList>>, TError = ErrorType<unknown>>(
 schedulingRequestId: string,
    params?: SchedulingSchedulingrequestsActivitiesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingSchedulingrequestsActivitiesListQueryKey(schedulingRequestId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesList>>> = ({ signal }) => schedulingSchedulingrequestsActivitiesList(schedulingRequestId,params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesList>>, TError, TData>(queryKey, queryFn, {enabled: !!(schedulingRequestId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingSchedulingrequestsActivitiesRead = (
    schedulingRequestId: string,
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingActivity>(
      {url: `/scheduling/schedulingrequests/${schedulingRequestId}/activities/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getSchedulingSchedulingrequestsActivitiesReadQueryKey = (schedulingRequestId: string,
    id: number,) => [`/scheduling/schedulingrequests/${schedulingRequestId}/activities/${id}/`];

    
export type SchedulingSchedulingrequestsActivitiesReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesRead>>>
export type SchedulingSchedulingrequestsActivitiesReadInfiniteQueryError = ErrorType<unknown>

export const useSchedulingSchedulingrequestsActivitiesReadInfinite = <TData = Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesRead>>, TError = ErrorType<unknown>>(
 schedulingRequestId: string,
    id: number, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingSchedulingrequestsActivitiesReadQueryKey(schedulingRequestId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesRead>>> = ({ signal }) => schedulingSchedulingrequestsActivitiesRead(schedulingRequestId,id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(schedulingRequestId && id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingSchedulingrequestsActivitiesReadQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesRead>>>
export type SchedulingSchedulingrequestsActivitiesReadQueryError = ErrorType<unknown>

export const useSchedulingSchedulingrequestsActivitiesRead = <TData = Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesRead>>, TError = ErrorType<unknown>>(
 schedulingRequestId: string,
    id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingSchedulingrequestsActivitiesReadQueryKey(schedulingRequestId,id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesRead>>> = ({ signal }) => schedulingSchedulingrequestsActivitiesRead(schedulingRequestId,id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(schedulingRequestId && id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingSchedulingrequestsActivitiesUpdate = (
    schedulingRequestId: string,
    id: number,
    schedulingActivityWriteBody: SchedulingActivityWriteBody,
 ) => {
      return customInstance<SchedulingActivityWrite>(
      {url: `/scheduling/schedulingrequests/${schedulingRequestId}/activities/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: schedulingActivityWriteBody
    },
      );
    }
  


    export type SchedulingSchedulingrequestsActivitiesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesUpdate>>>
    export type SchedulingSchedulingrequestsActivitiesUpdateMutationBody = SchedulingActivityWriteBody
    export type SchedulingSchedulingrequestsActivitiesUpdateMutationError = ErrorType<unknown>

    export const useSchedulingSchedulingrequestsActivitiesUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesUpdate>>, TError,{schedulingRequestId: string;id: number;data: SchedulingActivityWriteBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesUpdate>>, {schedulingRequestId: string;id: number;data: SchedulingActivityWriteBody}> = (props) => {
          const {schedulingRequestId,id,data} = props ?? {};

          return  schedulingSchedulingrequestsActivitiesUpdate(schedulingRequestId,id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesUpdate>>, TError, {schedulingRequestId: string;id: number;data: SchedulingActivityWriteBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingSchedulingrequestsActivitiesPartialUpdate = (
    schedulingRequestId: string,
    id: number,
    schedulingActivityWriteBody: SchedulingActivityWriteBody,
 ) => {
      return customInstance<SchedulingActivity>(
      {url: `/scheduling/schedulingrequests/${schedulingRequestId}/activities/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: schedulingActivityWriteBody
    },
      );
    }
  


    export type SchedulingSchedulingrequestsActivitiesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesPartialUpdate>>>
    export type SchedulingSchedulingrequestsActivitiesPartialUpdateMutationBody = SchedulingActivityWriteBody
    export type SchedulingSchedulingrequestsActivitiesPartialUpdateMutationError = ErrorType<unknown>

    export const useSchedulingSchedulingrequestsActivitiesPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesPartialUpdate>>, TError,{schedulingRequestId: string;id: number;data: SchedulingActivityWriteBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesPartialUpdate>>, {schedulingRequestId: string;id: number;data: SchedulingActivityWriteBody}> = (props) => {
          const {schedulingRequestId,id,data} = props ?? {};

          return  schedulingSchedulingrequestsActivitiesPartialUpdate(schedulingRequestId,id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingSchedulingrequestsActivitiesPartialUpdate>>, TError, {schedulingRequestId: string;id: number;data: SchedulingActivityWriteBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingTokensList = (
    params?: SchedulingTokensListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingTokensList200One | SchedulingTokensList200Two>(
      {url: `/scheduling/tokens/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingTokensListQueryKey = (params?: SchedulingTokensListParams,) => [`/scheduling/tokens/`, ...(params ? [params]: [])];

    
export type SchedulingTokensListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingTokensList>>>
export type SchedulingTokensListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingTokensListInfinite = <TData = Awaited<ReturnType<typeof schedulingTokensList>>, TError = ErrorType<unknown>>(
 params?: SchedulingTokensListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingTokensList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingTokensListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingTokensList>>> = ({ signal }) => schedulingTokensList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingTokensList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingTokensListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingTokensList>>>
export type SchedulingTokensListQueryError = ErrorType<unknown>

export const useSchedulingTokensList = <TData = Awaited<ReturnType<typeof schedulingTokensList>>, TError = ErrorType<unknown>>(
 params?: SchedulingTokensListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingTokensList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingTokensListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingTokensList>>> = ({ signal }) => schedulingTokensList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingTokensList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * API view to list all user scheduling calendars
 */
export const schedulingUserSchedulingCalendarsList = (
    params?: SchedulingUserSchedulingCalendarsListParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingUserSchedulingCalendarsList200One | SchedulingUserSchedulingCalendarsList200Two>(
      {url: `/scheduling/user-scheduling-calendars/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSchedulingUserSchedulingCalendarsListQueryKey = (params?: SchedulingUserSchedulingCalendarsListParams,) => [`/scheduling/user-scheduling-calendars/`, ...(params ? [params]: [])];

    
export type SchedulingUserSchedulingCalendarsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsList>>>
export type SchedulingUserSchedulingCalendarsListInfiniteQueryError = ErrorType<unknown>

export const useSchedulingUserSchedulingCalendarsListInfinite = <TData = Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingUserSchedulingCalendarsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingUserSchedulingCalendarsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsList>>> = ({ signal }) => schedulingUserSchedulingCalendarsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingUserSchedulingCalendarsListQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsList>>>
export type SchedulingUserSchedulingCalendarsListQueryError = ErrorType<unknown>

export const useSchedulingUserSchedulingCalendarsList = <TData = Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsList>>, TError = ErrorType<unknown>>(
 params?: SchedulingUserSchedulingCalendarsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingUserSchedulingCalendarsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsList>>> = ({ signal }) => schedulingUserSchedulingCalendarsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Sync scheduling calendar
 */
export const schedulingUserSchedulingCalendarsExternalUsersSyncCreate = (
    id: string,
    genericAPIBody: GenericAPIBody,
    params?: SchedulingUserSchedulingCalendarsExternalUsersSyncCreateParams,
 ) => {
      return customInstance<GenericAPI>(
      {url: `/scheduling/user-scheduling-calendars/external-users/${id}/sync/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: genericAPIBody,
        params
    },
      );
    }
  


    export type SchedulingUserSchedulingCalendarsExternalUsersSyncCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsExternalUsersSyncCreate>>>
    export type SchedulingUserSchedulingCalendarsExternalUsersSyncCreateMutationBody = GenericAPIBody
    export type SchedulingUserSchedulingCalendarsExternalUsersSyncCreateMutationError = ErrorType<unknown>

    export const useSchedulingUserSchedulingCalendarsExternalUsersSyncCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsExternalUsersSyncCreate>>, TError,{id: string;data: GenericAPIBody;params?: SchedulingUserSchedulingCalendarsExternalUsersSyncCreateParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsExternalUsersSyncCreate>>, {id: string;data: GenericAPIBody;params?: SchedulingUserSchedulingCalendarsExternalUsersSyncCreateParams}> = (props) => {
          const {id,data,params} = props ?? {};

          return  schedulingUserSchedulingCalendarsExternalUsersSyncCreate(id,data,params,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsExternalUsersSyncCreate>>, TError, {id: string;data: GenericAPIBody;params?: SchedulingUserSchedulingCalendarsExternalUsersSyncCreateParams}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Sync current user's scheduling calendar
 */
export const schedulingUserSchedulingCalendarsMeSyncCreate = (
    genericAPIBody: GenericAPIBody,
    params?: SchedulingUserSchedulingCalendarsMeSyncCreateParams,
 ) => {
      return customInstance<GenericAPI>(
      {url: `/scheduling/user-scheduling-calendars/me/sync/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: genericAPIBody,
        params
    },
      );
    }
  


    export type SchedulingUserSchedulingCalendarsMeSyncCreateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsMeSyncCreate>>>
    export type SchedulingUserSchedulingCalendarsMeSyncCreateMutationBody = GenericAPIBody
    export type SchedulingUserSchedulingCalendarsMeSyncCreateMutationError = ErrorType<unknown>

    export const useSchedulingUserSchedulingCalendarsMeSyncCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsMeSyncCreate>>, TError,{data: GenericAPIBody;params?: SchedulingUserSchedulingCalendarsMeSyncCreateParams}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsMeSyncCreate>>, {data: GenericAPIBody;params?: SchedulingUserSchedulingCalendarsMeSyncCreateParams}> = (props) => {
          const {data,params} = props ?? {};

          return  schedulingUserSchedulingCalendarsMeSyncCreate(data,params,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsMeSyncCreate>>, TError, {data: GenericAPIBody;params?: SchedulingUserSchedulingCalendarsMeSyncCreateParams}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * API view to retrieve user scheduling calendar
 */
export const schedulingUserSchedulingCalendarsRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<UserSchedulingCalendar>(
      {url: `/scheduling/user-scheduling-calendars/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getSchedulingUserSchedulingCalendarsReadQueryKey = (id: string,) => [`/scheduling/user-scheduling-calendars/${id}/`];

    
export type SchedulingUserSchedulingCalendarsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsRead>>>
export type SchedulingUserSchedulingCalendarsReadInfiniteQueryError = ErrorType<unknown>

export const useSchedulingUserSchedulingCalendarsReadInfinite = <TData = Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingUserSchedulingCalendarsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsRead>>> = ({ signal }) => schedulingUserSchedulingCalendarsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingUserSchedulingCalendarsReadQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsRead>>>
export type SchedulingUserSchedulingCalendarsReadQueryError = ErrorType<unknown>

export const useSchedulingUserSchedulingCalendarsRead = <TData = Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingUserSchedulingCalendarsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsRead>>> = ({ signal }) => schedulingUserSchedulingCalendarsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingUserSchedulingCalendarsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingUserSettingsRead = (
    
 signal?: AbortSignal
) => {
      return customInstance<UserScheduleSetting>(
      {url: `/scheduling/user-settings/`, method: 'get', signal
    },
      );
    }
  

export const getSchedulingUserSettingsReadQueryKey = () => [`/scheduling/user-settings/`];

    
export type SchedulingUserSettingsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingUserSettingsRead>>>
export type SchedulingUserSettingsReadInfiniteQueryError = ErrorType<unknown>

export const useSchedulingUserSettingsReadInfinite = <TData = Awaited<ReturnType<typeof schedulingUserSettingsRead>>, TError = ErrorType<unknown>>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof schedulingUserSettingsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingUserSettingsReadQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingUserSettingsRead>>> = ({ signal }) => schedulingUserSettingsRead(signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof schedulingUserSettingsRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SchedulingUserSettingsReadQueryResult = NonNullable<Awaited<ReturnType<typeof schedulingUserSettingsRead>>>
export type SchedulingUserSettingsReadQueryError = ErrorType<unknown>

export const useSchedulingUserSettingsRead = <TData = Awaited<ReturnType<typeof schedulingUserSettingsRead>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof schedulingUserSettingsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSchedulingUserSettingsReadQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof schedulingUserSettingsRead>>> = ({ signal }) => schedulingUserSettingsRead(signal);


  

  const query = useQuery<Awaited<ReturnType<typeof schedulingUserSettingsRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const schedulingUserSettingsUpdate = (
    userScheduleSettingBody: UserScheduleSettingBody,
 ) => {
      return customInstance<UserScheduleSetting>(
      {url: `/scheduling/user-settings/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: userScheduleSettingBody
    },
      );
    }
  


    export type SchedulingUserSettingsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingUserSettingsUpdate>>>
    export type SchedulingUserSettingsUpdateMutationBody = UserScheduleSettingBody
    export type SchedulingUserSettingsUpdateMutationError = ErrorType<unknown>

    export const useSchedulingUserSettingsUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingUserSettingsUpdate>>, TError,{data: UserScheduleSettingBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingUserSettingsUpdate>>, {data: UserScheduleSettingBody}> = (props) => {
          const {data} = props ?? {};

          return  schedulingUserSettingsUpdate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingUserSettingsUpdate>>, TError, {data: UserScheduleSettingBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const schedulingUserSettingsPartialUpdate = (
    userScheduleSettingBody: UserScheduleSettingBody,
 ) => {
      return customInstance<UserScheduleSetting>(
      {url: `/scheduling/user-settings/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: userScheduleSettingBody
    },
      );
    }
  


    export type SchedulingUserSettingsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof schedulingUserSettingsPartialUpdate>>>
    export type SchedulingUserSettingsPartialUpdateMutationBody = UserScheduleSettingBody
    export type SchedulingUserSettingsPartialUpdateMutationError = ErrorType<unknown>

    export const useSchedulingUserSettingsPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof schedulingUserSettingsPartialUpdate>>, TError,{data: UserScheduleSettingBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof schedulingUserSettingsPartialUpdate>>, {data: UserScheduleSettingBody}> = (props) => {
          const {data} = props ?? {};

          return  schedulingUserSettingsPartialUpdate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof schedulingUserSettingsPartialUpdate>>, TError, {data: UserScheduleSettingBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const statsRead = (
    params?: StatsReadParams,
 signal?: AbortSignal
) => {
      return customInstance<Stats>(
      {url: `/stats/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getStatsReadQueryKey = (params?: StatsReadParams,) => [`/stats/`, ...(params ? [params]: [])];

    
export type StatsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof statsRead>>>
export type StatsReadInfiniteQueryError = ErrorType<unknown>

export const useStatsReadInfinite = <TData = Awaited<ReturnType<typeof statsRead>>, TError = ErrorType<unknown>>(
 params?: StatsReadParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof statsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsReadQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsRead>>> = ({ signal }) => statsRead(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof statsRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type StatsReadQueryResult = NonNullable<Awaited<ReturnType<typeof statsRead>>>
export type StatsReadQueryError = ErrorType<unknown>

export const useStatsRead = <TData = Awaited<ReturnType<typeof statsRead>>, TError = ErrorType<unknown>>(
 params?: StatsReadParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof statsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsReadQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsRead>>> = ({ signal }) => statsRead(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof statsRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const statsAnalyticsEnagagementRead = (
    params?: StatsAnalyticsEnagagementReadParams,
 signal?: AbortSignal
) => {
      return customInstance<CandidateEngagementAnalytics>(
      {url: `/stats/analytics/enagagement/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getStatsAnalyticsEnagagementReadQueryKey = (params?: StatsAnalyticsEnagagementReadParams,) => [`/stats/analytics/enagagement/`, ...(params ? [params]: [])];

    
export type StatsAnalyticsEnagagementReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof statsAnalyticsEnagagementRead>>>
export type StatsAnalyticsEnagagementReadInfiniteQueryError = ErrorType<unknown>

export const useStatsAnalyticsEnagagementReadInfinite = <TData = Awaited<ReturnType<typeof statsAnalyticsEnagagementRead>>, TError = ErrorType<unknown>>(
 params?: StatsAnalyticsEnagagementReadParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof statsAnalyticsEnagagementRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsAnalyticsEnagagementReadQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsAnalyticsEnagagementRead>>> = ({ signal }) => statsAnalyticsEnagagementRead(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof statsAnalyticsEnagagementRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type StatsAnalyticsEnagagementReadQueryResult = NonNullable<Awaited<ReturnType<typeof statsAnalyticsEnagagementRead>>>
export type StatsAnalyticsEnagagementReadQueryError = ErrorType<unknown>

export const useStatsAnalyticsEnagagementRead = <TData = Awaited<ReturnType<typeof statsAnalyticsEnagagementRead>>, TError = ErrorType<unknown>>(
 params?: StatsAnalyticsEnagagementReadParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof statsAnalyticsEnagagementRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsAnalyticsEnagagementReadQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsAnalyticsEnagagementRead>>> = ({ signal }) => statsAnalyticsEnagagementRead(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof statsAnalyticsEnagagementRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const statsAnalyticsInterviewDeclinesRead = (
    params?: StatsAnalyticsInterviewDeclinesReadParams,
 signal?: AbortSignal
) => {
      return customInstance<DeclineAnalytics>(
      {url: `/stats/analytics/interview-declines/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getStatsAnalyticsInterviewDeclinesReadQueryKey = (params?: StatsAnalyticsInterviewDeclinesReadParams,) => [`/stats/analytics/interview-declines/`, ...(params ? [params]: [])];

    
export type StatsAnalyticsInterviewDeclinesReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof statsAnalyticsInterviewDeclinesRead>>>
export type StatsAnalyticsInterviewDeclinesReadInfiniteQueryError = ErrorType<unknown>

export const useStatsAnalyticsInterviewDeclinesReadInfinite = <TData = Awaited<ReturnType<typeof statsAnalyticsInterviewDeclinesRead>>, TError = ErrorType<unknown>>(
 params?: StatsAnalyticsInterviewDeclinesReadParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof statsAnalyticsInterviewDeclinesRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsAnalyticsInterviewDeclinesReadQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsAnalyticsInterviewDeclinesRead>>> = ({ signal }) => statsAnalyticsInterviewDeclinesRead(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof statsAnalyticsInterviewDeclinesRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type StatsAnalyticsInterviewDeclinesReadQueryResult = NonNullable<Awaited<ReturnType<typeof statsAnalyticsInterviewDeclinesRead>>>
export type StatsAnalyticsInterviewDeclinesReadQueryError = ErrorType<unknown>

export const useStatsAnalyticsInterviewDeclinesRead = <TData = Awaited<ReturnType<typeof statsAnalyticsInterviewDeclinesRead>>, TError = ErrorType<unknown>>(
 params?: StatsAnalyticsInterviewDeclinesReadParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof statsAnalyticsInterviewDeclinesRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsAnalyticsInterviewDeclinesReadQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsAnalyticsInterviewDeclinesRead>>> = ({ signal }) => statsAnalyticsInterviewDeclinesRead(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof statsAnalyticsInterviewDeclinesRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const statsAnalyticsPulseRead = (
    params?: StatsAnalyticsPulseReadParams,
 signal?: AbortSignal
) => {
      return customInstance<PulseAnalytics>(
      {url: `/stats/analytics/pulse/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getStatsAnalyticsPulseReadQueryKey = (params?: StatsAnalyticsPulseReadParams,) => [`/stats/analytics/pulse/`, ...(params ? [params]: [])];

    
export type StatsAnalyticsPulseReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof statsAnalyticsPulseRead>>>
export type StatsAnalyticsPulseReadInfiniteQueryError = ErrorType<unknown>

export const useStatsAnalyticsPulseReadInfinite = <TData = Awaited<ReturnType<typeof statsAnalyticsPulseRead>>, TError = ErrorType<unknown>>(
 params?: StatsAnalyticsPulseReadParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof statsAnalyticsPulseRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsAnalyticsPulseReadQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsAnalyticsPulseRead>>> = ({ signal }) => statsAnalyticsPulseRead(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof statsAnalyticsPulseRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type StatsAnalyticsPulseReadQueryResult = NonNullable<Awaited<ReturnType<typeof statsAnalyticsPulseRead>>>
export type StatsAnalyticsPulseReadQueryError = ErrorType<unknown>

export const useStatsAnalyticsPulseRead = <TData = Awaited<ReturnType<typeof statsAnalyticsPulseRead>>, TError = ErrorType<unknown>>(
 params?: StatsAnalyticsPulseReadParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof statsAnalyticsPulseRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsAnalyticsPulseReadQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsAnalyticsPulseRead>>> = ({ signal }) => statsAnalyticsPulseRead(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof statsAnalyticsPulseRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const statsAnalyticsReSchedulingRead = (
    params?: StatsAnalyticsReSchedulingReadParams,
 signal?: AbortSignal
) => {
      return customInstance<ReschedulingAnalytics>(
      {url: `/stats/analytics/re-scheduling/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getStatsAnalyticsReSchedulingReadQueryKey = (params?: StatsAnalyticsReSchedulingReadParams,) => [`/stats/analytics/re-scheduling/`, ...(params ? [params]: [])];

    
export type StatsAnalyticsReSchedulingReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof statsAnalyticsReSchedulingRead>>>
export type StatsAnalyticsReSchedulingReadInfiniteQueryError = ErrorType<unknown>

export const useStatsAnalyticsReSchedulingReadInfinite = <TData = Awaited<ReturnType<typeof statsAnalyticsReSchedulingRead>>, TError = ErrorType<unknown>>(
 params?: StatsAnalyticsReSchedulingReadParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof statsAnalyticsReSchedulingRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsAnalyticsReSchedulingReadQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsAnalyticsReSchedulingRead>>> = ({ signal }) => statsAnalyticsReSchedulingRead(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof statsAnalyticsReSchedulingRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type StatsAnalyticsReSchedulingReadQueryResult = NonNullable<Awaited<ReturnType<typeof statsAnalyticsReSchedulingRead>>>
export type StatsAnalyticsReSchedulingReadQueryError = ErrorType<unknown>

export const useStatsAnalyticsReSchedulingRead = <TData = Awaited<ReturnType<typeof statsAnalyticsReSchedulingRead>>, TError = ErrorType<unknown>>(
 params?: StatsAnalyticsReSchedulingReadParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof statsAnalyticsReSchedulingRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsAnalyticsReSchedulingReadQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsAnalyticsReSchedulingRead>>> = ({ signal }) => statsAnalyticsReSchedulingRead(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof statsAnalyticsReSchedulingRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const statsAnalyticsSchedulingRead = (
    params?: StatsAnalyticsSchedulingReadParams,
 signal?: AbortSignal
) => {
      return customInstance<SchedulingAnalytics>(
      {url: `/stats/analytics/scheduling/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getStatsAnalyticsSchedulingReadQueryKey = (params?: StatsAnalyticsSchedulingReadParams,) => [`/stats/analytics/scheduling/`, ...(params ? [params]: [])];

    
export type StatsAnalyticsSchedulingReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof statsAnalyticsSchedulingRead>>>
export type StatsAnalyticsSchedulingReadInfiniteQueryError = ErrorType<unknown>

export const useStatsAnalyticsSchedulingReadInfinite = <TData = Awaited<ReturnType<typeof statsAnalyticsSchedulingRead>>, TError = ErrorType<unknown>>(
 params?: StatsAnalyticsSchedulingReadParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof statsAnalyticsSchedulingRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsAnalyticsSchedulingReadQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsAnalyticsSchedulingRead>>> = ({ signal }) => statsAnalyticsSchedulingRead(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof statsAnalyticsSchedulingRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type StatsAnalyticsSchedulingReadQueryResult = NonNullable<Awaited<ReturnType<typeof statsAnalyticsSchedulingRead>>>
export type StatsAnalyticsSchedulingReadQueryError = ErrorType<unknown>

export const useStatsAnalyticsSchedulingRead = <TData = Awaited<ReturnType<typeof statsAnalyticsSchedulingRead>>, TError = ErrorType<unknown>>(
 params?: StatsAnalyticsSchedulingReadParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof statsAnalyticsSchedulingRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsAnalyticsSchedulingReadQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsAnalyticsSchedulingRead>>> = ({ signal }) => statsAnalyticsSchedulingRead(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof statsAnalyticsSchedulingRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const statsAnalyticsSessionRead = (
    params?: StatsAnalyticsSessionReadParams,
 signal?: AbortSignal
) => {
      return customInstance<SessionAnalytics>(
      {url: `/stats/analytics/session/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getStatsAnalyticsSessionReadQueryKey = (params?: StatsAnalyticsSessionReadParams,) => [`/stats/analytics/session/`, ...(params ? [params]: [])];

    
export type StatsAnalyticsSessionReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof statsAnalyticsSessionRead>>>
export type StatsAnalyticsSessionReadInfiniteQueryError = ErrorType<unknown>

export const useStatsAnalyticsSessionReadInfinite = <TData = Awaited<ReturnType<typeof statsAnalyticsSessionRead>>, TError = ErrorType<unknown>>(
 params?: StatsAnalyticsSessionReadParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof statsAnalyticsSessionRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsAnalyticsSessionReadQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsAnalyticsSessionRead>>> = ({ signal }) => statsAnalyticsSessionRead(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof statsAnalyticsSessionRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type StatsAnalyticsSessionReadQueryResult = NonNullable<Awaited<ReturnType<typeof statsAnalyticsSessionRead>>>
export type StatsAnalyticsSessionReadQueryError = ErrorType<unknown>

export const useStatsAnalyticsSessionRead = <TData = Awaited<ReturnType<typeof statsAnalyticsSessionRead>>, TError = ErrorType<unknown>>(
 params?: StatsAnalyticsSessionReadParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof statsAnalyticsSessionRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsAnalyticsSessionReadQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsAnalyticsSessionRead>>> = ({ signal }) => statsAnalyticsSessionRead(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof statsAnalyticsSessionRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const statsCandidateRead = (
    candidateId: string,
    params?: StatsCandidateReadParams,
 signal?: AbortSignal
) => {
      return customInstance<GroupStats>(
      {url: `/stats/candidate/${candidateId}/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getStatsCandidateReadQueryKey = (candidateId: string,
    params?: StatsCandidateReadParams,) => [`/stats/candidate/${candidateId}/`, ...(params ? [params]: [])];

    
export type StatsCandidateReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof statsCandidateRead>>>
export type StatsCandidateReadInfiniteQueryError = ErrorType<unknown>

export const useStatsCandidateReadInfinite = <TData = Awaited<ReturnType<typeof statsCandidateRead>>, TError = ErrorType<unknown>>(
 candidateId: string,
    params?: StatsCandidateReadParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof statsCandidateRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsCandidateReadQueryKey(candidateId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsCandidateRead>>> = ({ signal }) => statsCandidateRead(candidateId,params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof statsCandidateRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(candidateId), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type StatsCandidateReadQueryResult = NonNullable<Awaited<ReturnType<typeof statsCandidateRead>>>
export type StatsCandidateReadQueryError = ErrorType<unknown>

export const useStatsCandidateRead = <TData = Awaited<ReturnType<typeof statsCandidateRead>>, TError = ErrorType<unknown>>(
 candidateId: string,
    params?: StatsCandidateReadParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof statsCandidateRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsCandidateReadQueryKey(candidateId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsCandidateRead>>> = ({ signal }) => statsCandidateRead(candidateId,params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof statsCandidateRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(candidateId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const statsJobActivationsRead = (
    
 signal?: AbortSignal
) => {
      return customInstance<JobActivation>(
      {url: `/stats/job/activations/`, method: 'get', signal
    },
      );
    }
  

export const getStatsJobActivationsReadQueryKey = () => [`/stats/job/activations/`];

    
export type StatsJobActivationsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof statsJobActivationsRead>>>
export type StatsJobActivationsReadInfiniteQueryError = ErrorType<unknown>

export const useStatsJobActivationsReadInfinite = <TData = Awaited<ReturnType<typeof statsJobActivationsRead>>, TError = ErrorType<unknown>>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof statsJobActivationsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsJobActivationsReadQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsJobActivationsRead>>> = ({ signal }) => statsJobActivationsRead(signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof statsJobActivationsRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type StatsJobActivationsReadQueryResult = NonNullable<Awaited<ReturnType<typeof statsJobActivationsRead>>>
export type StatsJobActivationsReadQueryError = ErrorType<unknown>

export const useStatsJobActivationsRead = <TData = Awaited<ReturnType<typeof statsJobActivationsRead>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof statsJobActivationsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsJobActivationsReadQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsJobActivationsRead>>> = ({ signal }) => statsJobActivationsRead(signal);


  

  const query = useQuery<Awaited<ReturnType<typeof statsJobActivationsRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const statsJobRead = (
    jobId: string,
    params?: StatsJobReadParams,
 signal?: AbortSignal
) => {
      return customInstance<GroupStats>(
      {url: `/stats/job/${jobId}/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getStatsJobReadQueryKey = (jobId: string,
    params?: StatsJobReadParams,) => [`/stats/job/${jobId}/`, ...(params ? [params]: [])];

    
export type StatsJobReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof statsJobRead>>>
export type StatsJobReadInfiniteQueryError = ErrorType<unknown>

export const useStatsJobReadInfinite = <TData = Awaited<ReturnType<typeof statsJobRead>>, TError = ErrorType<unknown>>(
 jobId: string,
    params?: StatsJobReadParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof statsJobRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsJobReadQueryKey(jobId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsJobRead>>> = ({ signal }) => statsJobRead(jobId,params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof statsJobRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(jobId), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type StatsJobReadQueryResult = NonNullable<Awaited<ReturnType<typeof statsJobRead>>>
export type StatsJobReadQueryError = ErrorType<unknown>

export const useStatsJobRead = <TData = Awaited<ReturnType<typeof statsJobRead>>, TError = ErrorType<unknown>>(
 jobId: string,
    params?: StatsJobReadParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof statsJobRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsJobReadQueryKey(jobId,params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsJobRead>>> = ({ signal }) => statsJobRead(jobId,params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof statsJobRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(jobId), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns Exposed Stages with aggregated pulse stats
 */
export const statsPulsesExposedStagesList = (
    params?: StatsPulsesExposedStagesListParams,
 signal?: AbortSignal
) => {
      return customInstance<StatsPulsesExposedStagesList200One | StatsPulsesExposedStagesList200Two>(
      {url: `/stats/pulses/exposed-stages/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getStatsPulsesExposedStagesListQueryKey = (params?: StatsPulsesExposedStagesListParams,) => [`/stats/pulses/exposed-stages/`, ...(params ? [params]: [])];

    
export type StatsPulsesExposedStagesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof statsPulsesExposedStagesList>>>
export type StatsPulsesExposedStagesListInfiniteQueryError = ErrorType<unknown>

export const useStatsPulsesExposedStagesListInfinite = <TData = Awaited<ReturnType<typeof statsPulsesExposedStagesList>>, TError = ErrorType<unknown>>(
 params?: StatsPulsesExposedStagesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof statsPulsesExposedStagesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsPulsesExposedStagesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsPulsesExposedStagesList>>> = ({ signal }) => statsPulsesExposedStagesList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof statsPulsesExposedStagesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type StatsPulsesExposedStagesListQueryResult = NonNullable<Awaited<ReturnType<typeof statsPulsesExposedStagesList>>>
export type StatsPulsesExposedStagesListQueryError = ErrorType<unknown>

export const useStatsPulsesExposedStagesList = <TData = Awaited<ReturnType<typeof statsPulsesExposedStagesList>>, TError = ErrorType<unknown>>(
 params?: StatsPulsesExposedStagesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof statsPulsesExposedStagesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsPulsesExposedStagesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsPulsesExposedStagesList>>> = ({ signal }) => statsPulsesExposedStagesList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof statsPulsesExposedStagesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns External Jobs (live ones) with aggregated pulse stats
 */
export const statsPulsesExternalJobsList = (
    params?: StatsPulsesExternalJobsListParams,
 signal?: AbortSignal
) => {
      return customInstance<StatsPulsesExternalJobsList200One | StatsPulsesExternalJobsList200Two>(
      {url: `/stats/pulses/external-jobs/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getStatsPulsesExternalJobsListQueryKey = (params?: StatsPulsesExternalJobsListParams,) => [`/stats/pulses/external-jobs/`, ...(params ? [params]: [])];

    
export type StatsPulsesExternalJobsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof statsPulsesExternalJobsList>>>
export type StatsPulsesExternalJobsListInfiniteQueryError = ErrorType<unknown>

export const useStatsPulsesExternalJobsListInfinite = <TData = Awaited<ReturnType<typeof statsPulsesExternalJobsList>>, TError = ErrorType<unknown>>(
 params?: StatsPulsesExternalJobsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof statsPulsesExternalJobsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsPulsesExternalJobsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsPulsesExternalJobsList>>> = ({ signal }) => statsPulsesExternalJobsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof statsPulsesExternalJobsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type StatsPulsesExternalJobsListQueryResult = NonNullable<Awaited<ReturnType<typeof statsPulsesExternalJobsList>>>
export type StatsPulsesExternalJobsListQueryError = ErrorType<unknown>

export const useStatsPulsesExternalJobsList = <TData = Awaited<ReturnType<typeof statsPulsesExternalJobsList>>, TError = ErrorType<unknown>>(
 params?: StatsPulsesExternalJobsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof statsPulsesExternalJobsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsPulsesExternalJobsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsPulsesExternalJobsList>>> = ({ signal }) => statsPulsesExternalJobsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof statsPulsesExternalJobsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


/**
 * Returns unique sessions for an org for the last 30 days.
 */
export const statsSessionsList = (
    params?: StatsSessionsListParams,
 signal?: AbortSignal
) => {
      return customInstance<StatsSessionsList200One | StatsSessionsList200Two>(
      {url: `/stats/sessions/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getStatsSessionsListQueryKey = (params?: StatsSessionsListParams,) => [`/stats/sessions/`, ...(params ? [params]: [])];

    
export type StatsSessionsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof statsSessionsList>>>
export type StatsSessionsListInfiniteQueryError = ErrorType<unknown>

export const useStatsSessionsListInfinite = <TData = Awaited<ReturnType<typeof statsSessionsList>>, TError = ErrorType<unknown>>(
 params?: StatsSessionsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof statsSessionsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsSessionsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsSessionsList>>> = ({ signal }) => statsSessionsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof statsSessionsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type StatsSessionsListQueryResult = NonNullable<Awaited<ReturnType<typeof statsSessionsList>>>
export type StatsSessionsListQueryError = ErrorType<unknown>

export const useStatsSessionsList = <TData = Awaited<ReturnType<typeof statsSessionsList>>, TError = ErrorType<unknown>>(
 params?: StatsSessionsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof statsSessionsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getStatsSessionsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof statsSessionsList>>> = ({ signal }) => statsSessionsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof statsSessionsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const surveyPulsesList = (
    params?: SurveyPulsesListParams,
 signal?: AbortSignal
) => {
      return customInstance<SurveyPulsesList200One | SurveyPulsesList200Two>(
      {url: `/survey/pulses/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSurveyPulsesListQueryKey = (params?: SurveyPulsesListParams,) => [`/survey/pulses/`, ...(params ? [params]: [])];

    
export type SurveyPulsesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof surveyPulsesList>>>
export type SurveyPulsesListInfiniteQueryError = ErrorType<unknown>

export const useSurveyPulsesListInfinite = <TData = Awaited<ReturnType<typeof surveyPulsesList>>, TError = ErrorType<unknown>>(
 params?: SurveyPulsesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof surveyPulsesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSurveyPulsesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof surveyPulsesList>>> = ({ signal }) => surveyPulsesList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof surveyPulsesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SurveyPulsesListQueryResult = NonNullable<Awaited<ReturnType<typeof surveyPulsesList>>>
export type SurveyPulsesListQueryError = ErrorType<unknown>

export const useSurveyPulsesList = <TData = Awaited<ReturnType<typeof surveyPulsesList>>, TError = ErrorType<unknown>>(
 params?: SurveyPulsesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof surveyPulsesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSurveyPulsesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof surveyPulsesList>>> = ({ signal }) => surveyPulsesList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof surveyPulsesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const surveyPulsesCreate = (
    candidateStagePulseSurveyBody: CandidateStagePulseSurveyBody,
 ) => {
      return customInstance<CandidateStagePulseSurvey>(
      {url: `/survey/pulses/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: candidateStagePulseSurveyBody
    },
      );
    }
  


    export type SurveyPulsesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof surveyPulsesCreate>>>
    export type SurveyPulsesCreateMutationBody = CandidateStagePulseSurveyBody
    export type SurveyPulsesCreateMutationError = ErrorType<unknown>

    export const useSurveyPulsesCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof surveyPulsesCreate>>, TError,{data: CandidateStagePulseSurveyBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof surveyPulsesCreate>>, {data: CandidateStagePulseSurveyBody}> = (props) => {
          const {data} = props ?? {};

          return  surveyPulsesCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof surveyPulsesCreate>>, TError, {data: CandidateStagePulseSurveyBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const surveyPulsesReportList = (
    params?: SurveyPulsesReportListParams,
 signal?: AbortSignal
) => {
      return customInstance<PulseCSV[]>(
      {url: `/survey/pulses/report/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getSurveyPulsesReportListQueryKey = (params?: SurveyPulsesReportListParams,) => [`/survey/pulses/report/`, ...(params ? [params]: [])];

    
export type SurveyPulsesReportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof surveyPulsesReportList>>>
export type SurveyPulsesReportListInfiniteQueryError = ErrorType<unknown>

export const useSurveyPulsesReportListInfinite = <TData = Awaited<ReturnType<typeof surveyPulsesReportList>>, TError = ErrorType<unknown>>(
 params?: SurveyPulsesReportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof surveyPulsesReportList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSurveyPulsesReportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof surveyPulsesReportList>>> = ({ signal }) => surveyPulsesReportList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof surveyPulsesReportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SurveyPulsesReportListQueryResult = NonNullable<Awaited<ReturnType<typeof surveyPulsesReportList>>>
export type SurveyPulsesReportListQueryError = ErrorType<unknown>

export const useSurveyPulsesReportList = <TData = Awaited<ReturnType<typeof surveyPulsesReportList>>, TError = ErrorType<unknown>>(
 params?: SurveyPulsesReportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof surveyPulsesReportList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSurveyPulsesReportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof surveyPulsesReportList>>> = ({ signal }) => surveyPulsesReportList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof surveyPulsesReportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const surveyPulsesRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<CandidateStagePulseSurvey>(
      {url: `/survey/pulses/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getSurveyPulsesReadQueryKey = (id: string,) => [`/survey/pulses/${id}/`];

    
export type SurveyPulsesReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof surveyPulsesRead>>>
export type SurveyPulsesReadInfiniteQueryError = ErrorType<unknown>

export const useSurveyPulsesReadInfinite = <TData = Awaited<ReturnType<typeof surveyPulsesRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof surveyPulsesRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSurveyPulsesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof surveyPulsesRead>>> = ({ signal }) => surveyPulsesRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof surveyPulsesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type SurveyPulsesReadQueryResult = NonNullable<Awaited<ReturnType<typeof surveyPulsesRead>>>
export type SurveyPulsesReadQueryError = ErrorType<unknown>

export const useSurveyPulsesRead = <TData = Awaited<ReturnType<typeof surveyPulsesRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof surveyPulsesRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getSurveyPulsesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof surveyPulsesRead>>> = ({ signal }) => surveyPulsesRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof surveyPulsesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const surveyPulsesUpdate = (
    id: string,
    candidateStagePulseSurveyBody: CandidateStagePulseSurveyBody,
 ) => {
      return customInstance<CandidateStagePulseSurvey>(
      {url: `/survey/pulses/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: candidateStagePulseSurveyBody
    },
      );
    }
  


    export type SurveyPulsesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof surveyPulsesUpdate>>>
    export type SurveyPulsesUpdateMutationBody = CandidateStagePulseSurveyBody
    export type SurveyPulsesUpdateMutationError = ErrorType<unknown>

    export const useSurveyPulsesUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof surveyPulsesUpdate>>, TError,{id: string;data: CandidateStagePulseSurveyBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof surveyPulsesUpdate>>, {id: string;data: CandidateStagePulseSurveyBody}> = (props) => {
          const {id,data} = props ?? {};

          return  surveyPulsesUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof surveyPulsesUpdate>>, TError, {id: string;data: CandidateStagePulseSurveyBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const surveyPulsesPartialUpdate = (
    id: string,
    candidateStagePulseSurveyBody: CandidateStagePulseSurveyBody,
 ) => {
      return customInstance<CandidateStagePulseSurvey>(
      {url: `/survey/pulses/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: candidateStagePulseSurveyBody
    },
      );
    }
  


    export type SurveyPulsesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof surveyPulsesPartialUpdate>>>
    export type SurveyPulsesPartialUpdateMutationBody = CandidateStagePulseSurveyBody
    export type SurveyPulsesPartialUpdateMutationError = ErrorType<unknown>

    export const useSurveyPulsesPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof surveyPulsesPartialUpdate>>, TError,{id: string;data: CandidateStagePulseSurveyBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof surveyPulsesPartialUpdate>>, {id: string;data: CandidateStagePulseSurveyBody}> = (props) => {
          const {id,data} = props ?? {};

          return  surveyPulsesPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof surveyPulsesPartialUpdate>>, TError, {id: string;data: CandidateStagePulseSurveyBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Takes a refresh type JSON web token and returns an access type JSON web
token if the refresh token is valid.
 */
export const tokenRefreshCreate = (
    tokenRefresh: TokenRefresh,
 ) => {
      return customInstance<TokenRefresh>(
      {url: `/token/refresh/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: tokenRefresh
    },
      );
    }
  


    export type TokenRefreshCreateMutationResult = NonNullable<Awaited<ReturnType<typeof tokenRefreshCreate>>>
    export type TokenRefreshCreateMutationBody = TokenRefresh
    export type TokenRefreshCreateMutationError = ErrorType<unknown>

    export const useTokenRefreshCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tokenRefreshCreate>>, TError,{data: TokenRefresh}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tokenRefreshCreate>>, {data: TokenRefresh}> = (props) => {
          const {data} = props ?? {};

          return  tokenRefreshCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof tokenRefreshCreate>>, TError, {data: TokenRefresh}, TContext>(mutationFn, mutationOptions);
    }
    
export const tokenWojqerojcoijweCreate = (
    customTokenObtainPair: CustomTokenObtainPair,
 ) => {
      return customInstance<CustomTokenObtainPair>(
      {url: `/token/wojqerojcoijwe/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: customTokenObtainPair
    },
      );
    }
  


    export type TokenWojqerojcoijweCreateMutationResult = NonNullable<Awaited<ReturnType<typeof tokenWojqerojcoijweCreate>>>
    export type TokenWojqerojcoijweCreateMutationBody = CustomTokenObtainPair
    export type TokenWojqerojcoijweCreateMutationError = ErrorType<unknown>

    export const useTokenWojqerojcoijweCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof tokenWojqerojcoijweCreate>>, TError,{data: CustomTokenObtainPair}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof tokenWojqerojcoijweCreate>>, {data: CustomTokenObtainPair}> = (props) => {
          const {data} = props ?? {};

          return  tokenWojqerojcoijweCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof tokenWojqerojcoijweCreate>>, TError, {data: CustomTokenObtainPair}, TContext>(mutationFn, mutationOptions);
    }
    
export const trainingClassAttendanceList = (
    params?: TrainingClassAttendanceListParams,
 signal?: AbortSignal
) => {
      return customInstance<TrainingClassAttendanceList200One | TrainingClassAttendanceList200Two>(
      {url: `/training/class-attendance/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getTrainingClassAttendanceListQueryKey = (params?: TrainingClassAttendanceListParams,) => [`/training/class-attendance/`, ...(params ? [params]: [])];

    
export type TrainingClassAttendanceListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof trainingClassAttendanceList>>>
export type TrainingClassAttendanceListInfiniteQueryError = ErrorType<unknown>

export const useTrainingClassAttendanceListInfinite = <TData = Awaited<ReturnType<typeof trainingClassAttendanceList>>, TError = ErrorType<unknown>>(
 params?: TrainingClassAttendanceListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof trainingClassAttendanceList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingClassAttendanceListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingClassAttendanceList>>> = ({ signal }) => trainingClassAttendanceList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof trainingClassAttendanceList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type TrainingClassAttendanceListQueryResult = NonNullable<Awaited<ReturnType<typeof trainingClassAttendanceList>>>
export type TrainingClassAttendanceListQueryError = ErrorType<unknown>

export const useTrainingClassAttendanceList = <TData = Awaited<ReturnType<typeof trainingClassAttendanceList>>, TError = ErrorType<unknown>>(
 params?: TrainingClassAttendanceListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof trainingClassAttendanceList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingClassAttendanceListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingClassAttendanceList>>> = ({ signal }) => trainingClassAttendanceList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof trainingClassAttendanceList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const trainingClassAttendanceCreate = (
    trainingClassEventAttendanceBody: TrainingClassEventAttendanceBody,
 ) => {
      return customInstance<TrainingClassEventAttendance>(
      {url: `/training/class-attendance/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: trainingClassEventAttendanceBody
    },
      );
    }
  


    export type TrainingClassAttendanceCreateMutationResult = NonNullable<Awaited<ReturnType<typeof trainingClassAttendanceCreate>>>
    export type TrainingClassAttendanceCreateMutationBody = TrainingClassEventAttendanceBody
    export type TrainingClassAttendanceCreateMutationError = ErrorType<unknown>

    export const useTrainingClassAttendanceCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trainingClassAttendanceCreate>>, TError,{data: TrainingClassEventAttendanceBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof trainingClassAttendanceCreate>>, {data: TrainingClassEventAttendanceBody}> = (props) => {
          const {data} = props ?? {};

          return  trainingClassAttendanceCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof trainingClassAttendanceCreate>>, TError, {data: TrainingClassEventAttendanceBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const trainingClassAttendanceRead = (
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<TrainingClassEventAttendance>(
      {url: `/training/class-attendance/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getTrainingClassAttendanceReadQueryKey = (id: number,) => [`/training/class-attendance/${id}/`];

    
export type TrainingClassAttendanceReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof trainingClassAttendanceRead>>>
export type TrainingClassAttendanceReadInfiniteQueryError = ErrorType<unknown>

export const useTrainingClassAttendanceReadInfinite = <TData = Awaited<ReturnType<typeof trainingClassAttendanceRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof trainingClassAttendanceRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingClassAttendanceReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingClassAttendanceRead>>> = ({ signal }) => trainingClassAttendanceRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof trainingClassAttendanceRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type TrainingClassAttendanceReadQueryResult = NonNullable<Awaited<ReturnType<typeof trainingClassAttendanceRead>>>
export type TrainingClassAttendanceReadQueryError = ErrorType<unknown>

export const useTrainingClassAttendanceRead = <TData = Awaited<ReturnType<typeof trainingClassAttendanceRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof trainingClassAttendanceRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingClassAttendanceReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingClassAttendanceRead>>> = ({ signal }) => trainingClassAttendanceRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof trainingClassAttendanceRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const trainingClassAttendanceUpdate = (
    id: number,
    trainingClassEventAttendanceBody: TrainingClassEventAttendanceBody,
 ) => {
      return customInstance<TrainingClassEventAttendance>(
      {url: `/training/class-attendance/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: trainingClassEventAttendanceBody
    },
      );
    }
  


    export type TrainingClassAttendanceUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof trainingClassAttendanceUpdate>>>
    export type TrainingClassAttendanceUpdateMutationBody = TrainingClassEventAttendanceBody
    export type TrainingClassAttendanceUpdateMutationError = ErrorType<unknown>

    export const useTrainingClassAttendanceUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trainingClassAttendanceUpdate>>, TError,{id: number;data: TrainingClassEventAttendanceBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof trainingClassAttendanceUpdate>>, {id: number;data: TrainingClassEventAttendanceBody}> = (props) => {
          const {id,data} = props ?? {};

          return  trainingClassAttendanceUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof trainingClassAttendanceUpdate>>, TError, {id: number;data: TrainingClassEventAttendanceBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const trainingClassAttendancePartialUpdate = (
    id: number,
    trainingClassEventAttendanceBody: TrainingClassEventAttendanceBody,
 ) => {
      return customInstance<TrainingClassEventAttendance>(
      {url: `/training/class-attendance/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: trainingClassEventAttendanceBody
    },
      );
    }
  


    export type TrainingClassAttendancePartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof trainingClassAttendancePartialUpdate>>>
    export type TrainingClassAttendancePartialUpdateMutationBody = TrainingClassEventAttendanceBody
    export type TrainingClassAttendancePartialUpdateMutationError = ErrorType<unknown>

    export const useTrainingClassAttendancePartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trainingClassAttendancePartialUpdate>>, TError,{id: number;data: TrainingClassEventAttendanceBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof trainingClassAttendancePartialUpdate>>, {id: number;data: TrainingClassEventAttendanceBody}> = (props) => {
          const {id,data} = props ?? {};

          return  trainingClassAttendancePartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof trainingClassAttendancePartialUpdate>>, TError, {id: number;data: TrainingClassEventAttendanceBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const trainingClassAttendanceDelete = (
    id: number,
 ) => {
      return customInstance<void>(
      {url: `/training/class-attendance/${id}/`, method: 'delete'
    },
      );
    }
  


    export type TrainingClassAttendanceDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof trainingClassAttendanceDelete>>>
    
    export type TrainingClassAttendanceDeleteMutationError = ErrorType<unknown>

    export const useTrainingClassAttendanceDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trainingClassAttendanceDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof trainingClassAttendanceDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  trainingClassAttendanceDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof trainingClassAttendanceDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions);
    }
    
export const trainingClassesList = (
    params?: TrainingClassesListParams,
 signal?: AbortSignal
) => {
      return customInstance<TrainingClassesList200One | TrainingClassesList200Two>(
      {url: `/training/classes/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getTrainingClassesListQueryKey = (params?: TrainingClassesListParams,) => [`/training/classes/`, ...(params ? [params]: [])];

    
export type TrainingClassesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof trainingClassesList>>>
export type TrainingClassesListInfiniteQueryError = ErrorType<unknown>

export const useTrainingClassesListInfinite = <TData = Awaited<ReturnType<typeof trainingClassesList>>, TError = ErrorType<unknown>>(
 params?: TrainingClassesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof trainingClassesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingClassesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingClassesList>>> = ({ signal }) => trainingClassesList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof trainingClassesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type TrainingClassesListQueryResult = NonNullable<Awaited<ReturnType<typeof trainingClassesList>>>
export type TrainingClassesListQueryError = ErrorType<unknown>

export const useTrainingClassesList = <TData = Awaited<ReturnType<typeof trainingClassesList>>, TError = ErrorType<unknown>>(
 params?: TrainingClassesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof trainingClassesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingClassesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingClassesList>>> = ({ signal }) => trainingClassesList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof trainingClassesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const trainingClassesCreate = (
    trainingClassWriteBody: TrainingClassWriteBody,
 ) => {
      return customInstance<TrainingClassWrite>(
      {url: `/training/classes/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: trainingClassWriteBody
    },
      );
    }
  


    export type TrainingClassesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof trainingClassesCreate>>>
    export type TrainingClassesCreateMutationBody = TrainingClassWriteBody
    export type TrainingClassesCreateMutationError = ErrorType<unknown>

    export const useTrainingClassesCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trainingClassesCreate>>, TError,{data: TrainingClassWriteBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof trainingClassesCreate>>, {data: TrainingClassWriteBody}> = (props) => {
          const {data} = props ?? {};

          return  trainingClassesCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof trainingClassesCreate>>, TError, {data: TrainingClassWriteBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const trainingClassesRead = (
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<TrainingClassWrite>(
      {url: `/training/classes/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getTrainingClassesReadQueryKey = (id: number,) => [`/training/classes/${id}/`];

    
export type TrainingClassesReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof trainingClassesRead>>>
export type TrainingClassesReadInfiniteQueryError = ErrorType<unknown>

export const useTrainingClassesReadInfinite = <TData = Awaited<ReturnType<typeof trainingClassesRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof trainingClassesRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingClassesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingClassesRead>>> = ({ signal }) => trainingClassesRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof trainingClassesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type TrainingClassesReadQueryResult = NonNullable<Awaited<ReturnType<typeof trainingClassesRead>>>
export type TrainingClassesReadQueryError = ErrorType<unknown>

export const useTrainingClassesRead = <TData = Awaited<ReturnType<typeof trainingClassesRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof trainingClassesRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingClassesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingClassesRead>>> = ({ signal }) => trainingClassesRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof trainingClassesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const trainingClassesUpdate = (
    id: number,
    trainingClassWriteBody: TrainingClassWriteBody,
 ) => {
      return customInstance<TrainingClassWrite>(
      {url: `/training/classes/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: trainingClassWriteBody
    },
      );
    }
  


    export type TrainingClassesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof trainingClassesUpdate>>>
    export type TrainingClassesUpdateMutationBody = TrainingClassWriteBody
    export type TrainingClassesUpdateMutationError = ErrorType<unknown>

    export const useTrainingClassesUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trainingClassesUpdate>>, TError,{id: number;data: TrainingClassWriteBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof trainingClassesUpdate>>, {id: number;data: TrainingClassWriteBody}> = (props) => {
          const {id,data} = props ?? {};

          return  trainingClassesUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof trainingClassesUpdate>>, TError, {id: number;data: TrainingClassWriteBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const trainingClassesPartialUpdate = (
    id: number,
    trainingClassWriteBody: TrainingClassWriteBody,
 ) => {
      return customInstance<TrainingClassWrite>(
      {url: `/training/classes/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: trainingClassWriteBody
    },
      );
    }
  


    export type TrainingClassesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof trainingClassesPartialUpdate>>>
    export type TrainingClassesPartialUpdateMutationBody = TrainingClassWriteBody
    export type TrainingClassesPartialUpdateMutationError = ErrorType<unknown>

    export const useTrainingClassesPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trainingClassesPartialUpdate>>, TError,{id: number;data: TrainingClassWriteBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof trainingClassesPartialUpdate>>, {id: number;data: TrainingClassWriteBody}> = (props) => {
          const {id,data} = props ?? {};

          return  trainingClassesPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof trainingClassesPartialUpdate>>, TError, {id: number;data: TrainingClassWriteBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const trainingClassesDelete = (
    id: number,
 ) => {
      return customInstance<void>(
      {url: `/training/classes/${id}/`, method: 'delete'
    },
      );
    }
  


    export type TrainingClassesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof trainingClassesDelete>>>
    
    export type TrainingClassesDeleteMutationError = ErrorType<unknown>

    export const useTrainingClassesDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trainingClassesDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof trainingClassesDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  trainingClassesDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof trainingClassesDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions);
    }
    
export const trainingPathsList = (
    params?: TrainingPathsListParams,
 signal?: AbortSignal
) => {
      return customInstance<TrainingPathsList200One | TrainingPathsList200Two>(
      {url: `/training/paths/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getTrainingPathsListQueryKey = (params?: TrainingPathsListParams,) => [`/training/paths/`, ...(params ? [params]: [])];

    
export type TrainingPathsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof trainingPathsList>>>
export type TrainingPathsListInfiniteQueryError = ErrorType<unknown>

export const useTrainingPathsListInfinite = <TData = Awaited<ReturnType<typeof trainingPathsList>>, TError = ErrorType<unknown>>(
 params?: TrainingPathsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof trainingPathsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingPathsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingPathsList>>> = ({ signal }) => trainingPathsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof trainingPathsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type TrainingPathsListQueryResult = NonNullable<Awaited<ReturnType<typeof trainingPathsList>>>
export type TrainingPathsListQueryError = ErrorType<unknown>

export const useTrainingPathsList = <TData = Awaited<ReturnType<typeof trainingPathsList>>, TError = ErrorType<unknown>>(
 params?: TrainingPathsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof trainingPathsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingPathsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingPathsList>>> = ({ signal }) => trainingPathsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof trainingPathsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const trainingPathsCreate = (
    trainingPathWriteBody: TrainingPathWriteBody,
 ) => {
      return customInstance<TrainingPathWrite>(
      {url: `/training/paths/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: trainingPathWriteBody
    },
      );
    }
  


    export type TrainingPathsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof trainingPathsCreate>>>
    export type TrainingPathsCreateMutationBody = TrainingPathWriteBody
    export type TrainingPathsCreateMutationError = ErrorType<unknown>

    export const useTrainingPathsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trainingPathsCreate>>, TError,{data: TrainingPathWriteBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof trainingPathsCreate>>, {data: TrainingPathWriteBody}> = (props) => {
          const {data} = props ?? {};

          return  trainingPathsCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof trainingPathsCreate>>, TError, {data: TrainingPathWriteBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const trainingPathsProgressReportList = (
    params?: TrainingPathsProgressReportListParams,
 signal?: AbortSignal
) => {
      return customInstance<TrainingPathsProgressReportList200One | TrainingPathsProgressReportList200Two>(
      {url: `/training/paths/progress-report/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getTrainingPathsProgressReportListQueryKey = (params?: TrainingPathsProgressReportListParams,) => [`/training/paths/progress-report/`, ...(params ? [params]: [])];

    
export type TrainingPathsProgressReportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof trainingPathsProgressReportList>>>
export type TrainingPathsProgressReportListInfiniteQueryError = ErrorType<unknown>

export const useTrainingPathsProgressReportListInfinite = <TData = Awaited<ReturnType<typeof trainingPathsProgressReportList>>, TError = ErrorType<unknown>>(
 params?: TrainingPathsProgressReportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof trainingPathsProgressReportList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingPathsProgressReportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingPathsProgressReportList>>> = ({ signal }) => trainingPathsProgressReportList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof trainingPathsProgressReportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type TrainingPathsProgressReportListQueryResult = NonNullable<Awaited<ReturnType<typeof trainingPathsProgressReportList>>>
export type TrainingPathsProgressReportListQueryError = ErrorType<unknown>

export const useTrainingPathsProgressReportList = <TData = Awaited<ReturnType<typeof trainingPathsProgressReportList>>, TError = ErrorType<unknown>>(
 params?: TrainingPathsProgressReportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof trainingPathsProgressReportList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingPathsProgressReportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingPathsProgressReportList>>> = ({ signal }) => trainingPathsProgressReportList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof trainingPathsProgressReportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const trainingPathsProgressReportExportList = (
    params?: TrainingPathsProgressReportExportListParams,
 signal?: AbortSignal
) => {
      return customInstance<TrainingPathProgressionReport[]>(
      {url: `/training/paths/progress-report/export/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getTrainingPathsProgressReportExportListQueryKey = (params?: TrainingPathsProgressReportExportListParams,) => [`/training/paths/progress-report/export/`, ...(params ? [params]: [])];

    
export type TrainingPathsProgressReportExportListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof trainingPathsProgressReportExportList>>>
export type TrainingPathsProgressReportExportListInfiniteQueryError = ErrorType<unknown>

export const useTrainingPathsProgressReportExportListInfinite = <TData = Awaited<ReturnType<typeof trainingPathsProgressReportExportList>>, TError = ErrorType<unknown>>(
 params?: TrainingPathsProgressReportExportListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof trainingPathsProgressReportExportList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingPathsProgressReportExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingPathsProgressReportExportList>>> = ({ signal }) => trainingPathsProgressReportExportList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof trainingPathsProgressReportExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type TrainingPathsProgressReportExportListQueryResult = NonNullable<Awaited<ReturnType<typeof trainingPathsProgressReportExportList>>>
export type TrainingPathsProgressReportExportListQueryError = ErrorType<unknown>

export const useTrainingPathsProgressReportExportList = <TData = Awaited<ReturnType<typeof trainingPathsProgressReportExportList>>, TError = ErrorType<unknown>>(
 params?: TrainingPathsProgressReportExportListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof trainingPathsProgressReportExportList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingPathsProgressReportExportListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingPathsProgressReportExportList>>> = ({ signal }) => trainingPathsProgressReportExportList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof trainingPathsProgressReportExportList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const trainingPathsRead = (
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<TrainingPathRead>(
      {url: `/training/paths/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getTrainingPathsReadQueryKey = (id: number,) => [`/training/paths/${id}/`];

    
export type TrainingPathsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof trainingPathsRead>>>
export type TrainingPathsReadInfiniteQueryError = ErrorType<unknown>

export const useTrainingPathsReadInfinite = <TData = Awaited<ReturnType<typeof trainingPathsRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof trainingPathsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingPathsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingPathsRead>>> = ({ signal }) => trainingPathsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof trainingPathsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type TrainingPathsReadQueryResult = NonNullable<Awaited<ReturnType<typeof trainingPathsRead>>>
export type TrainingPathsReadQueryError = ErrorType<unknown>

export const useTrainingPathsRead = <TData = Awaited<ReturnType<typeof trainingPathsRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof trainingPathsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingPathsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingPathsRead>>> = ({ signal }) => trainingPathsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof trainingPathsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const trainingPathsUpdate = (
    id: number,
    trainingPathWriteBody: TrainingPathWriteBody,
 ) => {
      return customInstance<TrainingPathWrite>(
      {url: `/training/paths/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: trainingPathWriteBody
    },
      );
    }
  


    export type TrainingPathsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof trainingPathsUpdate>>>
    export type TrainingPathsUpdateMutationBody = TrainingPathWriteBody
    export type TrainingPathsUpdateMutationError = ErrorType<unknown>

    export const useTrainingPathsUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trainingPathsUpdate>>, TError,{id: number;data: TrainingPathWriteBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof trainingPathsUpdate>>, {id: number;data: TrainingPathWriteBody}> = (props) => {
          const {id,data} = props ?? {};

          return  trainingPathsUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof trainingPathsUpdate>>, TError, {id: number;data: TrainingPathWriteBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const trainingPathsPartialUpdate = (
    id: number,
    trainingPathWriteBody: TrainingPathWriteBody,
 ) => {
      return customInstance<TrainingPathWrite>(
      {url: `/training/paths/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: trainingPathWriteBody
    },
      );
    }
  


    export type TrainingPathsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof trainingPathsPartialUpdate>>>
    export type TrainingPathsPartialUpdateMutationBody = TrainingPathWriteBody
    export type TrainingPathsPartialUpdateMutationError = ErrorType<unknown>

    export const useTrainingPathsPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trainingPathsPartialUpdate>>, TError,{id: number;data: TrainingPathWriteBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof trainingPathsPartialUpdate>>, {id: number;data: TrainingPathWriteBody}> = (props) => {
          const {id,data} = props ?? {};

          return  trainingPathsPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof trainingPathsPartialUpdate>>, TError, {id: number;data: TrainingPathWriteBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const trainingPathsDelete = (
    id: number,
 ) => {
      return customInstance<void>(
      {url: `/training/paths/${id}/`, method: 'delete'
    },
      );
    }
  


    export type TrainingPathsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof trainingPathsDelete>>>
    
    export type TrainingPathsDeleteMutationError = ErrorType<unknown>

    export const useTrainingPathsDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trainingPathsDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof trainingPathsDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  trainingPathsDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof trainingPathsDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions);
    }
    
export const trainingTraineesList = (
    params?: TrainingTraineesListParams,
 signal?: AbortSignal
) => {
      return customInstance<TrainingTraineesList200One | TrainingTraineesList200Two>(
      {url: `/training/trainees/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getTrainingTraineesListQueryKey = (params?: TrainingTraineesListParams,) => [`/training/trainees/`, ...(params ? [params]: [])];

    
export type TrainingTraineesListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof trainingTraineesList>>>
export type TrainingTraineesListInfiniteQueryError = ErrorType<unknown>

export const useTrainingTraineesListInfinite = <TData = Awaited<ReturnType<typeof trainingTraineesList>>, TError = ErrorType<unknown>>(
 params?: TrainingTraineesListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof trainingTraineesList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingTraineesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingTraineesList>>> = ({ signal }) => trainingTraineesList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof trainingTraineesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type TrainingTraineesListQueryResult = NonNullable<Awaited<ReturnType<typeof trainingTraineesList>>>
export type TrainingTraineesListQueryError = ErrorType<unknown>

export const useTrainingTraineesList = <TData = Awaited<ReturnType<typeof trainingTraineesList>>, TError = ErrorType<unknown>>(
 params?: TrainingTraineesListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof trainingTraineesList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingTraineesListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingTraineesList>>> = ({ signal }) => trainingTraineesList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof trainingTraineesList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const trainingTraineesCreate = (
    traineeBody: TraineeBody,
 ) => {
      return customInstance<Trainee>(
      {url: `/training/trainees/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: traineeBody
    },
      );
    }
  


    export type TrainingTraineesCreateMutationResult = NonNullable<Awaited<ReturnType<typeof trainingTraineesCreate>>>
    export type TrainingTraineesCreateMutationBody = TraineeBody
    export type TrainingTraineesCreateMutationError = ErrorType<unknown>

    export const useTrainingTraineesCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trainingTraineesCreate>>, TError,{data: TraineeBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof trainingTraineesCreate>>, {data: TraineeBody}> = (props) => {
          const {data} = props ?? {};

          return  trainingTraineesCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof trainingTraineesCreate>>, TError, {data: TraineeBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const trainingTraineesRead = (
    id: number,
 signal?: AbortSignal
) => {
      return customInstance<Trainee>(
      {url: `/training/trainees/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getTrainingTraineesReadQueryKey = (id: number,) => [`/training/trainees/${id}/`];

    
export type TrainingTraineesReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof trainingTraineesRead>>>
export type TrainingTraineesReadInfiniteQueryError = ErrorType<unknown>

export const useTrainingTraineesReadInfinite = <TData = Awaited<ReturnType<typeof trainingTraineesRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof trainingTraineesRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingTraineesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingTraineesRead>>> = ({ signal }) => trainingTraineesRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof trainingTraineesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type TrainingTraineesReadQueryResult = NonNullable<Awaited<ReturnType<typeof trainingTraineesRead>>>
export type TrainingTraineesReadQueryError = ErrorType<unknown>

export const useTrainingTraineesRead = <TData = Awaited<ReturnType<typeof trainingTraineesRead>>, TError = ErrorType<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof trainingTraineesRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getTrainingTraineesReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof trainingTraineesRead>>> = ({ signal }) => trainingTraineesRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof trainingTraineesRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const trainingTraineesUpdate = (
    id: number,
    traineeBody: TraineeBody,
 ) => {
      return customInstance<Trainee>(
      {url: `/training/trainees/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: traineeBody
    },
      );
    }
  


    export type TrainingTraineesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof trainingTraineesUpdate>>>
    export type TrainingTraineesUpdateMutationBody = TraineeBody
    export type TrainingTraineesUpdateMutationError = ErrorType<unknown>

    export const useTrainingTraineesUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trainingTraineesUpdate>>, TError,{id: number;data: TraineeBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof trainingTraineesUpdate>>, {id: number;data: TraineeBody}> = (props) => {
          const {id,data} = props ?? {};

          return  trainingTraineesUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof trainingTraineesUpdate>>, TError, {id: number;data: TraineeBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const trainingTraineesPartialUpdate = (
    id: number,
    traineeBody: TraineeBody,
 ) => {
      return customInstance<Trainee>(
      {url: `/training/trainees/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: traineeBody
    },
      );
    }
  


    export type TrainingTraineesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof trainingTraineesPartialUpdate>>>
    export type TrainingTraineesPartialUpdateMutationBody = TraineeBody
    export type TrainingTraineesPartialUpdateMutationError = ErrorType<unknown>

    export const useTrainingTraineesPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trainingTraineesPartialUpdate>>, TError,{id: number;data: TraineeBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof trainingTraineesPartialUpdate>>, {id: number;data: TraineeBody}> = (props) => {
          const {id,data} = props ?? {};

          return  trainingTraineesPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof trainingTraineesPartialUpdate>>, TError, {id: number;data: TraineeBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const trainingTraineesDelete = (
    id: number,
 ) => {
      return customInstance<void>(
      {url: `/training/trainees/${id}/`, method: 'delete'
    },
      );
    }
  


    export type TrainingTraineesDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof trainingTraineesDelete>>>
    
    export type TrainingTraineesDeleteMutationError = ErrorType<unknown>

    export const useTrainingTraineesDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof trainingTraineesDelete>>, TError,{id: number}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof trainingTraineesDelete>>, {id: number}> = (props) => {
          const {id} = props ?? {};

          return  trainingTraineesDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof trainingTraineesDelete>>, TError, {id: number}, TContext>(mutationFn, mutationOptions);
    }
    
export const usersRead = (
    
 signal?: AbortSignal
) => {
      return customInstance<UpdateUser>(
      {url: `/users/`, method: 'get', signal
    },
      );
    }
  

export const getUsersReadQueryKey = () => [`/users/`];

    
export type UsersReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof usersRead>>>
export type UsersReadInfiniteQueryError = ErrorType<unknown>

export const useUsersReadInfinite = <TData = Awaited<ReturnType<typeof usersRead>>, TError = ErrorType<unknown>>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersReadQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersRead>>> = ({ signal }) => usersRead(signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof usersRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type UsersReadQueryResult = NonNullable<Awaited<ReturnType<typeof usersRead>>>
export type UsersReadQueryError = ErrorType<unknown>

export const useUsersRead = <TData = Awaited<ReturnType<typeof usersRead>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersReadQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersRead>>> = ({ signal }) => usersRead(signal);


  

  const query = useQuery<Awaited<ReturnType<typeof usersRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const usersUpdate = (
    updateUserBody: UpdateUserBody,
 ) => {
      return customInstance<UpdateUser>(
      {url: `/users/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: updateUserBody
    },
      );
    }
  


    export type UsersUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof usersUpdate>>>
    export type UsersUpdateMutationBody = UpdateUserBody
    export type UsersUpdateMutationError = ErrorType<unknown>

    export const useUsersUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersUpdate>>, TError,{data: UpdateUserBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersUpdate>>, {data: UpdateUserBody}> = (props) => {
          const {data} = props ?? {};

          return  usersUpdate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersUpdate>>, TError, {data: UpdateUserBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const usersPartialUpdate = (
    updateUserBody: UpdateUserBody,
 ) => {
      return customInstance<UpdateUser>(
      {url: `/users/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: updateUserBody
    },
      );
    }
  


    export type UsersPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof usersPartialUpdate>>>
    export type UsersPartialUpdateMutationBody = UpdateUserBody
    export type UsersPartialUpdateMutationError = ErrorType<unknown>

    export const useUsersPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersPartialUpdate>>, TError,{data: UpdateUserBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersPartialUpdate>>, {data: UpdateUserBody}> = (props) => {
          const {data} = props ?? {};

          return  usersPartialUpdate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersPartialUpdate>>, TError, {data: UpdateUserBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Login with magic link
 */
export const usersLoginTokenCreate = (
    candidateMagicLink: CandidateMagicLink,
 ) => {
      return customInstance<CandidateMagicLink>(
      {url: `/users/login/token/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: candidateMagicLink
    },
      );
    }
  


    export type UsersLoginTokenCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersLoginTokenCreate>>>
    export type UsersLoginTokenCreateMutationBody = CandidateMagicLink
    export type UsersLoginTokenCreateMutationError = ErrorType<unknown>

    export const useUsersLoginTokenCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersLoginTokenCreate>>, TError,{data: CandidateMagicLink}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersLoginTokenCreate>>, {data: CandidateMagicLink}> = (props) => {
          const {data} = props ?? {};

          return  usersLoginTokenCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersLoginTokenCreate>>, TError, {data: CandidateMagicLink}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Triggered by admin: Send a magic link invitation to candidate
 */
export const usersMagicLinkCreate = (
    candidateInvitationBody: CandidateInvitationBody,
 ) => {
      return customInstance<CandidateInvitation>(
      {url: `/users/magic-link/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: candidateInvitationBody
    },
      );
    }
  


    export type UsersMagicLinkCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersMagicLinkCreate>>>
    export type UsersMagicLinkCreateMutationBody = CandidateInvitationBody
    export type UsersMagicLinkCreateMutationError = ErrorType<unknown>

    export const useUsersMagicLinkCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersMagicLinkCreate>>, TError,{data: CandidateInvitationBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersMagicLinkCreate>>, {data: CandidateInvitationBody}> = (props) => {
          const {data} = props ?? {};

          return  usersMagicLinkCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersMagicLinkCreate>>, TError, {data: CandidateInvitationBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Triggered by user: Re-send magic link invitation (Unauthenticated)
 */
export const usersMagicLinkResendCreate = (
    candidateInvitationBody: CandidateInvitationBody,
 ) => {
      return customInstance<CandidateInvitation>(
      {url: `/users/magic-link/resend/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: candidateInvitationBody
    },
      );
    }
  


    export type UsersMagicLinkResendCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersMagicLinkResendCreate>>>
    export type UsersMagicLinkResendCreateMutationBody = CandidateInvitationBody
    export type UsersMagicLinkResendCreateMutationError = ErrorType<unknown>

    export const useUsersMagicLinkResendCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersMagicLinkResendCreate>>, TError,{data: CandidateInvitationBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersMagicLinkResendCreate>>, {data: CandidateInvitationBody}> = (props) => {
          const {data} = props ?? {};

          return  usersMagicLinkResendCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersMagicLinkResendCreate>>, TError, {data: CandidateInvitationBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const usersMeEmailsList = (
    params?: UsersMeEmailsListParams,
 signal?: AbortSignal
) => {
      return customInstance<UsersMeEmailsList200One | UsersMeEmailsList200Two>(
      {url: `/users/me/emails/`, method: 'get',
        params, signal
    },
      );
    }
  

export const getUsersMeEmailsListQueryKey = (params?: UsersMeEmailsListParams,) => [`/users/me/emails/`, ...(params ? [params]: [])];

    
export type UsersMeEmailsListInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof usersMeEmailsList>>>
export type UsersMeEmailsListInfiniteQueryError = ErrorType<unknown>

export const useUsersMeEmailsListInfinite = <TData = Awaited<ReturnType<typeof usersMeEmailsList>>, TError = ErrorType<unknown>>(
 params?: UsersMeEmailsListParams, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersMeEmailsList>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersMeEmailsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersMeEmailsList>>> = ({ signal }) => usersMeEmailsList(params, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof usersMeEmailsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type UsersMeEmailsListQueryResult = NonNullable<Awaited<ReturnType<typeof usersMeEmailsList>>>
export type UsersMeEmailsListQueryError = ErrorType<unknown>

export const useUsersMeEmailsList = <TData = Awaited<ReturnType<typeof usersMeEmailsList>>, TError = ErrorType<unknown>>(
 params?: UsersMeEmailsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersMeEmailsList>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersMeEmailsListQueryKey(params);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersMeEmailsList>>> = ({ signal }) => usersMeEmailsList(params, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof usersMeEmailsList>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const usersMeEmailsCreate = (
    senderEmailAddress: SenderEmailAddress,
 ) => {
      return customInstance<SenderEmailAddress>(
      {url: `/users/me/emails/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: senderEmailAddress
    },
      );
    }
  


    export type UsersMeEmailsCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersMeEmailsCreate>>>
    export type UsersMeEmailsCreateMutationBody = SenderEmailAddress
    export type UsersMeEmailsCreateMutationError = ErrorType<unknown>

    export const useUsersMeEmailsCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersMeEmailsCreate>>, TError,{data: SenderEmailAddress}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersMeEmailsCreate>>, {data: SenderEmailAddress}> = (props) => {
          const {data} = props ?? {};

          return  usersMeEmailsCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersMeEmailsCreate>>, TError, {data: SenderEmailAddress}, TContext>(mutationFn, mutationOptions);
    }
    
export const usersMeEmailsRead = (
    id: string,
 signal?: AbortSignal
) => {
      return customInstance<ReadonlySenderEmailAddress>(
      {url: `/users/me/emails/${id}/`, method: 'get', signal
    },
      );
    }
  

export const getUsersMeEmailsReadQueryKey = (id: string,) => [`/users/me/emails/${id}/`];

    
export type UsersMeEmailsReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof usersMeEmailsRead>>>
export type UsersMeEmailsReadInfiniteQueryError = ErrorType<unknown>

export const useUsersMeEmailsReadInfinite = <TData = Awaited<ReturnType<typeof usersMeEmailsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersMeEmailsRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersMeEmailsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersMeEmailsRead>>> = ({ signal }) => usersMeEmailsRead(id, signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof usersMeEmailsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type UsersMeEmailsReadQueryResult = NonNullable<Awaited<ReturnType<typeof usersMeEmailsRead>>>
export type UsersMeEmailsReadQueryError = ErrorType<unknown>

export const useUsersMeEmailsRead = <TData = Awaited<ReturnType<typeof usersMeEmailsRead>>, TError = ErrorType<unknown>>(
 id: string, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersMeEmailsRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersMeEmailsReadQueryKey(id);

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersMeEmailsRead>>> = ({ signal }) => usersMeEmailsRead(id, signal);


  

  const query = useQuery<Awaited<ReturnType<typeof usersMeEmailsRead>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions}) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const usersMeEmailsUpdate = (
    id: string,
    readonlySenderEmailAddressBody: ReadonlySenderEmailAddressBody,
 ) => {
      return customInstance<ReadonlySenderEmailAddress>(
      {url: `/users/me/emails/${id}/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: readonlySenderEmailAddressBody
    },
      );
    }
  


    export type UsersMeEmailsUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof usersMeEmailsUpdate>>>
    export type UsersMeEmailsUpdateMutationBody = ReadonlySenderEmailAddressBody
    export type UsersMeEmailsUpdateMutationError = ErrorType<unknown>

    export const useUsersMeEmailsUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersMeEmailsUpdate>>, TError,{id: string;data: ReadonlySenderEmailAddressBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersMeEmailsUpdate>>, {id: string;data: ReadonlySenderEmailAddressBody}> = (props) => {
          const {id,data} = props ?? {};

          return  usersMeEmailsUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersMeEmailsUpdate>>, TError, {id: string;data: ReadonlySenderEmailAddressBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const usersMeEmailsPartialUpdate = (
    id: string,
    readonlySenderEmailAddressBody: ReadonlySenderEmailAddressBody,
 ) => {
      return customInstance<ReadonlySenderEmailAddress>(
      {url: `/users/me/emails/${id}/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: readonlySenderEmailAddressBody
    },
      );
    }
  


    export type UsersMeEmailsPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof usersMeEmailsPartialUpdate>>>
    export type UsersMeEmailsPartialUpdateMutationBody = ReadonlySenderEmailAddressBody
    export type UsersMeEmailsPartialUpdateMutationError = ErrorType<unknown>

    export const useUsersMeEmailsPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersMeEmailsPartialUpdate>>, TError,{id: string;data: ReadonlySenderEmailAddressBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersMeEmailsPartialUpdate>>, {id: string;data: ReadonlySenderEmailAddressBody}> = (props) => {
          const {id,data} = props ?? {};

          return  usersMeEmailsPartialUpdate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersMeEmailsPartialUpdate>>, TError, {id: string;data: ReadonlySenderEmailAddressBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const usersMeEmailsDelete = (
    id: string,
 ) => {
      return customInstance<void>(
      {url: `/users/me/emails/${id}/`, method: 'delete'
    },
      );
    }
  


    export type UsersMeEmailsDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof usersMeEmailsDelete>>>
    
    export type UsersMeEmailsDeleteMutationError = ErrorType<unknown>

    export const useUsersMeEmailsDelete = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersMeEmailsDelete>>, TError,{id: string}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersMeEmailsDelete>>, {id: string}> = (props) => {
          const {id} = props ?? {};

          return  usersMeEmailsDelete(id,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersMeEmailsDelete>>, TError, {id: string}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Interact to resend the confirmation email for email signature verification
 */
export const usersMeEmailsResendConfirmationCreate = (
    id: string,
    genericAPIBody: GenericAPIBody,
 ) => {
      return customInstance<SenderEmailAddress>(
      {url: `/users/me/emails/${id}/resend-confirmation/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: genericAPIBody
    },
      );
    }
  


    export type UsersMeEmailsResendConfirmationCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersMeEmailsResendConfirmationCreate>>>
    export type UsersMeEmailsResendConfirmationCreateMutationBody = GenericAPIBody
    export type UsersMeEmailsResendConfirmationCreateMutationError = ErrorType<unknown>

    export const useUsersMeEmailsResendConfirmationCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersMeEmailsResendConfirmationCreate>>, TError,{id: string;data: GenericAPIBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersMeEmailsResendConfirmationCreate>>, {id: string;data: GenericAPIBody}> = (props) => {
          const {id,data} = props ?? {};

          return  usersMeEmailsResendConfirmationCreate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersMeEmailsResendConfirmationCreate>>, TError, {id: string;data: GenericAPIBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Interact to revoke the email signature
 */
export const usersMeEmailsRevokeCreate = (
    id: string,
    genericAPIBody: GenericAPIBody,
 ) => {
      return customInstance<SenderEmailAddress>(
      {url: `/users/me/emails/${id}/revoke/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: genericAPIBody
    },
      );
    }
  


    export type UsersMeEmailsRevokeCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersMeEmailsRevokeCreate>>>
    export type UsersMeEmailsRevokeCreateMutationBody = GenericAPIBody
    export type UsersMeEmailsRevokeCreateMutationError = ErrorType<unknown>

    export const useUsersMeEmailsRevokeCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersMeEmailsRevokeCreate>>, TError,{id: string;data: GenericAPIBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersMeEmailsRevokeCreate>>, {id: string;data: GenericAPIBody}> = (props) => {
          const {id,data} = props ?? {};

          return  usersMeEmailsRevokeCreate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersMeEmailsRevokeCreate>>, TError, {id: string;data: GenericAPIBody}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Interact to verify the email signature
 */
export const usersMeEmailsVerifyCreate = (
    id: string,
    genericAPIBody: GenericAPIBody,
 ) => {
      return customInstance<SenderEmailAddress>(
      {url: `/users/me/emails/${id}/verify/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: genericAPIBody
    },
      );
    }
  


    export type UsersMeEmailsVerifyCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersMeEmailsVerifyCreate>>>
    export type UsersMeEmailsVerifyCreateMutationBody = GenericAPIBody
    export type UsersMeEmailsVerifyCreateMutationError = ErrorType<unknown>

    export const useUsersMeEmailsVerifyCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersMeEmailsVerifyCreate>>, TError,{id: string;data: GenericAPIBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersMeEmailsVerifyCreate>>, {id: string;data: GenericAPIBody}> = (props) => {
          const {id,data} = props ?? {};

          return  usersMeEmailsVerifyCreate(id,data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersMeEmailsVerifyCreate>>, TError, {id: string;data: GenericAPIBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const usersMePreferencesRead = (
    
 signal?: AbortSignal
) => {
      return customInstance<UserPreference>(
      {url: `/users/me/preferences/`, method: 'get', signal
    },
      );
    }
  

export const getUsersMePreferencesReadQueryKey = () => [`/users/me/preferences/`];

    
export type UsersMePreferencesReadInfiniteQueryResult = NonNullable<Awaited<ReturnType<typeof usersMePreferencesRead>>>
export type UsersMePreferencesReadInfiniteQueryError = ErrorType<unknown>

export const useUsersMePreferencesReadInfinite = <TData = Awaited<ReturnType<typeof usersMePreferencesRead>>, TError = ErrorType<unknown>>(
  options?: { query?:UseInfiniteQueryOptions<Awaited<ReturnType<typeof usersMePreferencesRead>>, TError, TData>, }

  ):  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersMePreferencesReadQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersMePreferencesRead>>> = ({ signal }) => usersMePreferencesRead(signal);


  

  const query = useInfiniteQuery<Awaited<ReturnType<typeof usersMePreferencesRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseInfiniteQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}

export type UsersMePreferencesReadQueryResult = NonNullable<Awaited<ReturnType<typeof usersMePreferencesRead>>>
export type UsersMePreferencesReadQueryError = ErrorType<unknown>

export const useUsersMePreferencesRead = <TData = Awaited<ReturnType<typeof usersMePreferencesRead>>, TError = ErrorType<unknown>>(
  options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof usersMePreferencesRead>>, TError, TData>, }

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions} = options ?? {};

  const queryKey =  queryOptions?.queryKey ?? getUsersMePreferencesReadQueryKey();

  


  const queryFn: QueryFunction<Awaited<ReturnType<typeof usersMePreferencesRead>>> = ({ signal }) => usersMePreferencesRead(signal);


  

  const query = useQuery<Awaited<ReturnType<typeof usersMePreferencesRead>>, TError, TData>(queryKey, queryFn, queryOptions) as  UseQueryResult<TData, TError> & { queryKey: QueryKey };

  query.queryKey = queryKey;

  return query;
}


export const usersMePreferencesUpdate = (
    userPreferenceBody: UserPreferenceBody,
 ) => {
      return customInstance<UserPreference>(
      {url: `/users/me/preferences/`, method: 'put',
      headers: {'Content-Type': 'application/json', },
      data: userPreferenceBody
    },
      );
    }
  


    export type UsersMePreferencesUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof usersMePreferencesUpdate>>>
    export type UsersMePreferencesUpdateMutationBody = UserPreferenceBody
    export type UsersMePreferencesUpdateMutationError = ErrorType<unknown>

    export const useUsersMePreferencesUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersMePreferencesUpdate>>, TError,{data: UserPreferenceBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersMePreferencesUpdate>>, {data: UserPreferenceBody}> = (props) => {
          const {data} = props ?? {};

          return  usersMePreferencesUpdate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersMePreferencesUpdate>>, TError, {data: UserPreferenceBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const usersMePreferencesPartialUpdate = (
    userPreferenceBody: UserPreferenceBody,
 ) => {
      return customInstance<UserPreference>(
      {url: `/users/me/preferences/`, method: 'patch',
      headers: {'Content-Type': 'application/json', },
      data: userPreferenceBody
    },
      );
    }
  


    export type UsersMePreferencesPartialUpdateMutationResult = NonNullable<Awaited<ReturnType<typeof usersMePreferencesPartialUpdate>>>
    export type UsersMePreferencesPartialUpdateMutationBody = UserPreferenceBody
    export type UsersMePreferencesPartialUpdateMutationError = ErrorType<unknown>

    export const useUsersMePreferencesPartialUpdate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersMePreferencesPartialUpdate>>, TError,{data: UserPreferenceBody}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersMePreferencesPartialUpdate>>, {data: UserPreferenceBody}> = (props) => {
          const {data} = props ?? {};

          return  usersMePreferencesPartialUpdate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersMePreferencesPartialUpdate>>, TError, {data: UserPreferenceBody}, TContext>(mutationFn, mutationOptions);
    }
    
export const usersSocialGcalRevokeDelete = (
    
 ) => {
      return customInstance<void>(
      {url: `/users/social/gcal/revoke/`, method: 'delete'
    },
      );
    }
  


    export type UsersSocialGcalRevokeDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof usersSocialGcalRevokeDelete>>>
    
    export type UsersSocialGcalRevokeDeleteMutationError = ErrorType<unknown>

    export const useUsersSocialGcalRevokeDelete = <TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersSocialGcalRevokeDelete>>, TError,TVariables, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersSocialGcalRevokeDelete>>, TVariables> = () => {
          

          return  usersSocialGcalRevokeDelete()
        }

        

      return useMutation<Awaited<ReturnType<typeof usersSocialGcalRevokeDelete>>, TError, TVariables, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Authenticate with Google OAuth2
 */
export const usersSocialGoogleCreate = (
    googleOauth2Body: GoogleOauth2Body,
 ) => {
      return customInstance<Oauth2TokenResponse>(
      {url: `/users/social/google/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: googleOauth2Body
    },
      );
    }
  


    export type UsersSocialGoogleCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersSocialGoogleCreate>>>
    export type UsersSocialGoogleCreateMutationBody = GoogleOauth2Body
    export type UsersSocialGoogleCreateMutationError = ErrorType<unknown>

    export const useUsersSocialGoogleCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersSocialGoogleCreate>>, TError,{data: GoogleOauth2Body}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersSocialGoogleCreate>>, {data: GoogleOauth2Body}> = (props) => {
          const {data} = props ?? {};

          return  usersSocialGoogleCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersSocialGoogleCreate>>, TError, {data: GoogleOauth2Body}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Authenticate with Google OAuth2
 */
export const usersSocialGoogleCalendarCreate = (
    googleOauth2Body: GoogleOauth2Body,
 ) => {
      return customInstance<GoogleOauth2>(
      {url: `/users/social/google/calendar/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: googleOauth2Body
    },
      );
    }
  


    export type UsersSocialGoogleCalendarCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersSocialGoogleCalendarCreate>>>
    export type UsersSocialGoogleCalendarCreateMutationBody = GoogleOauth2Body
    export type UsersSocialGoogleCalendarCreateMutationError = ErrorType<unknown>

    export const useUsersSocialGoogleCalendarCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersSocialGoogleCalendarCreate>>, TError,{data: GoogleOauth2Body}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersSocialGoogleCalendarCreate>>, {data: GoogleOauth2Body}> = (props) => {
          const {data} = props ?? {};

          return  usersSocialGoogleCalendarCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersSocialGoogleCalendarCreate>>, TError, {data: GoogleOauth2Body}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Authenticate with Microsoft OAuth2
 */
export const usersSocialMicrosoftCreate = (
    microsoftOauth2Body: MicrosoftOauth2Body,
 ) => {
      return customInstance<Oauth2TokenResponse>(
      {url: `/users/social/microsoft/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: microsoftOauth2Body
    },
      );
    }
  


    export type UsersSocialMicrosoftCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersSocialMicrosoftCreate>>>
    export type UsersSocialMicrosoftCreateMutationBody = MicrosoftOauth2Body
    export type UsersSocialMicrosoftCreateMutationError = ErrorType<unknown>

    export const useUsersSocialMicrosoftCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersSocialMicrosoftCreate>>, TError,{data: MicrosoftOauth2Body}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersSocialMicrosoftCreate>>, {data: MicrosoftOauth2Body}> = (props) => {
          const {data} = props ?? {};

          return  usersSocialMicrosoftCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersSocialMicrosoftCreate>>, TError, {data: MicrosoftOauth2Body}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Authenticate with Microsoft OAuth2
 */
export const usersSocialMicrosoftCalendarCreate = (
    microsoftOauth2Body: MicrosoftOauth2Body,
 ) => {
      return customInstance<MicrosoftOauth2>(
      {url: `/users/social/microsoft/calendar/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: microsoftOauth2Body
    },
      );
    }
  


    export type UsersSocialMicrosoftCalendarCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersSocialMicrosoftCalendarCreate>>>
    export type UsersSocialMicrosoftCalendarCreateMutationBody = MicrosoftOauth2Body
    export type UsersSocialMicrosoftCalendarCreateMutationError = ErrorType<unknown>

    export const useUsersSocialMicrosoftCalendarCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersSocialMicrosoftCalendarCreate>>, TError,{data: MicrosoftOauth2Body}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersSocialMicrosoftCalendarCreate>>, {data: MicrosoftOauth2Body}> = (props) => {
          const {data} = props ?? {};

          return  usersSocialMicrosoftCalendarCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersSocialMicrosoftCalendarCreate>>, TError, {data: MicrosoftOauth2Body}, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Authenticate with Okta OAuth2
 */
export const usersSocialOktaCreate = (
    oktaOauth2: OktaOauth2,
 ) => {
      return customInstance<OktaOauth2>(
      {url: `/users/social/okta/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: oktaOauth2
    },
      );
    }
  


    export type UsersSocialOktaCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersSocialOktaCreate>>>
    export type UsersSocialOktaCreateMutationBody = OktaOauth2
    export type UsersSocialOktaCreateMutationError = ErrorType<unknown>

    export const useUsersSocialOktaCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersSocialOktaCreate>>, TError,{data: OktaOauth2}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersSocialOktaCreate>>, {data: OktaOauth2}> = (props) => {
          const {data} = props ?? {};

          return  usersSocialOktaCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersSocialOktaCreate>>, TError, {data: OktaOauth2}, TContext>(mutationFn, mutationOptions);
    }
    
export const usersSocialOktaConfigCreate = (
    oktaConfigRequest: OktaConfigRequest,
 ) => {
      return customInstance<OktaConfigResponse>(
      {url: `/users/social/okta/config/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: oktaConfigRequest
    },
      );
    }
  


    export type UsersSocialOktaConfigCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersSocialOktaConfigCreate>>>
    export type UsersSocialOktaConfigCreateMutationBody = OktaConfigRequest
    export type UsersSocialOktaConfigCreateMutationError = ErrorType<unknown>

    export const useUsersSocialOktaConfigCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersSocialOktaConfigCreate>>, TError,{data: OktaConfigRequest}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersSocialOktaConfigCreate>>, {data: OktaConfigRequest}> = (props) => {
          const {data} = props ?? {};

          return  usersSocialOktaConfigCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersSocialOktaConfigCreate>>, TError, {data: OktaConfigRequest}, TContext>(mutationFn, mutationOptions);
    }
    
export const usersSocialOktaDomainCreate = (
    oktaDomainSearch: OktaDomainSearch,
 ) => {
      return customInstance<OktaDetailsSearchResponse>(
      {url: `/users/social/okta/domain/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: oktaDomainSearch
    },
      );
    }
  


    export type UsersSocialOktaDomainCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersSocialOktaDomainCreate>>>
    export type UsersSocialOktaDomainCreateMutationBody = OktaDomainSearch
    export type UsersSocialOktaDomainCreateMutationError = ErrorType<unknown>

    export const useUsersSocialOktaDomainCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersSocialOktaDomainCreate>>, TError,{data: OktaDomainSearch}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersSocialOktaDomainCreate>>, {data: OktaDomainSearch}> = (props) => {
          const {data} = props ?? {};

          return  usersSocialOktaDomainCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersSocialOktaDomainCreate>>, TError, {data: OktaDomainSearch}, TContext>(mutationFn, mutationOptions);
    }
    
export const usersSocialOutlookRevokeDelete = (
    
 ) => {
      return customInstance<void>(
      {url: `/users/social/outlook/revoke/`, method: 'delete'
    },
      );
    }
  


    export type UsersSocialOutlookRevokeDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof usersSocialOutlookRevokeDelete>>>
    
    export type UsersSocialOutlookRevokeDeleteMutationError = ErrorType<unknown>

    export const useUsersSocialOutlookRevokeDelete = <TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersSocialOutlookRevokeDelete>>, TError,TVariables, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersSocialOutlookRevokeDelete>>, TVariables> = () => {
          

          return  usersSocialOutlookRevokeDelete()
        }

        

      return useMutation<Awaited<ReturnType<typeof usersSocialOutlookRevokeDelete>>, TError, TVariables, TContext>(mutationFn, mutationOptions);
    }
    
/**
 * Integrate zoom with candidate fyi
 */
export const usersSocialZoomCreate = (
    zoomIntegration: ZoomIntegration,
 ) => {
      return customInstance<ZoomIntegration>(
      {url: `/users/social/zoom/`, method: 'post',
      headers: {'Content-Type': 'application/json', },
      data: zoomIntegration
    },
      );
    }
  


    export type UsersSocialZoomCreateMutationResult = NonNullable<Awaited<ReturnType<typeof usersSocialZoomCreate>>>
    export type UsersSocialZoomCreateMutationBody = ZoomIntegration
    export type UsersSocialZoomCreateMutationError = ErrorType<unknown>

    export const useUsersSocialZoomCreate = <TError = ErrorType<unknown>,
    
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersSocialZoomCreate>>, TError,{data: ZoomIntegration}, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersSocialZoomCreate>>, {data: ZoomIntegration}> = (props) => {
          const {data} = props ?? {};

          return  usersSocialZoomCreate(data,)
        }

        

      return useMutation<Awaited<ReturnType<typeof usersSocialZoomCreate>>, TError, {data: ZoomIntegration}, TContext>(mutationFn, mutationOptions);
    }
    
export const usersSocialZoomRevokeDelete = (
    
 ) => {
      return customInstance<void>(
      {url: `/users/social/zoom/revoke/`, method: 'delete'
    },
      );
    }
  


    export type UsersSocialZoomRevokeDeleteMutationResult = NonNullable<Awaited<ReturnType<typeof usersSocialZoomRevokeDelete>>>
    
    export type UsersSocialZoomRevokeDeleteMutationError = ErrorType<unknown>

    export const useUsersSocialZoomRevokeDelete = <TError = ErrorType<unknown>,
    TVariables = void,
    TContext = unknown>(options?: { mutation?:UseMutationOptions<Awaited<ReturnType<typeof usersSocialZoomRevokeDelete>>, TError,TVariables, TContext>, }
) => {
      const {mutation: mutationOptions} = options ?? {};

      


      const mutationFn: MutationFunction<Awaited<ReturnType<typeof usersSocialZoomRevokeDelete>>, TVariables> = () => {
          

          return  usersSocialZoomRevokeDelete()
        }

        

      return useMutation<Awaited<ReturnType<typeof usersSocialZoomRevokeDelete>>, TError, TVariables, TContext>(mutationFn, mutationOptions);
    }
    
